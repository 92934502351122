import React,{ useRef, useEffect} from 'react';
import { Button } from 'devextreme-react/button';
import DataGrid, { Column, Editing, Pager, Paging, FilterRow,} from 'devextreme-react/data-grid';
import Box, {
  Item
} from 'devextreme-react/box';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import AlertDialog from 'components/alertDialog/alertDialog'
import {
  getBusquedaAvanzadaEvento,
  getEnteBySearchTerm,
  getEventoBySearchTerm,
  handleDownloadPlantilla,
  getEnteUnidadAdministrativaBySearchTermByEnte,
  getEntePuestoBySearchTermByIdUnidadAdministrativa,
  identebyusuarioid,
  getuuids
} from 'api';
import { DEFAULT_PAGE_SIZE_ONE_GRID, DEFAULT_PAGE_SIZES, DEFAULT_PAGE_SIZE_CMB } from 'constants/defaultValues';
import { getDataSourceFilter, getDataSourceSort, descargarArchivo} from 'utils/utility';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Form, {
    GroupItem,
    Label
  } from 'devextreme-react/form';

function IconRenderActa(cellData){
  let flag = cellData.data.acta_descarga_id !== null ? true : false;
  return  flag ? (
      <div>
          <Button icon={"file"} onClick={()=>{descargarActa(cellData.data.acta_descarga_id)}}/>
      </div>
  ): null;
}

function IconRenderActaFirmada(cellData){
  let flag = cellData.data.acta_firmada_descarga_id !== null ? true : false;
  return  flag ? (
      <div>
          <Button icon={"file"} onClick={()=>{descargarActa(cellData.data.acta_firmada_descarga_id)}}/>
      </div>
  ): null;
}

function IconRenderAnexo(cellData){
  let flag = cellData.data.anexos_ids_descargas !== null ? true : false;
  return  flag ? (
      <div>
          <Button icon={"file"} onClick={()=>{handleDownloadAnexos(cellData.data.anexos_ids_descargas)}}/>
      </div>
  ): null;
}

const handleDownloadAnexos=async(adjuntos_ids)=>{
  let files = await getUUIDs(adjuntos_ids);
  if(files.length){
    let arrayUuid =[];
    files.forEach((val) => {
      arrayUuid.push(val.sysfilename);
    });
    await handleDownloadPlantilla({uid:arrayUuid},"anexos.zip");
  }
}

const descargarActa = (async(id_adjunto)=>{
  if (id_adjunto) descargarArchivo(id_adjunto,  ()=>{})
});

const getUUIDs = async(adjuntos_ids)=>{
  let result = [];
  const { data: { error, message, data } } = await getuuids(adjuntos_ids);
    if (error) {
      AlertDialog({ message: message });
    } else if (data) {
      result = data;
    }
    return result;
}

const grupoUsuario = localStorage.getItem('grupo_id');

export default function () {
  //#region hook de control
  const formRef = useRef();
  //#endregion

  //#region hook de estado
  const [evento,setEvento] = useStateWithCallbackLazy({
      "evento_id":null,
      "ente_id": null,
      "unidad_administrativa_id": null,
      "puesto_id": null
})
//#endregion

  //#region carga de datos iniciales 
  useEffect(() => {
    let mounted = true; 
    async function fetchData(){
        if (mounted) { 
            if(grupoUsuario === '3'){

              let ente = await fetchIdEnteUsuario();
              if(ente !== null){
                formRef.current.instance.updateData('ente_id', ente);
                formRef.current.instance.updateData('unidad_administrativa_id', null);
                formRef.current.instance.updateData('puesto_id', null);
                unidadAdministrativaDatasource.filter([['ente_id', '=', ente]]);
                unidadAdministrativaDatasource.load();
                dataSource.reload();
              }
            }
        }
        
    }
    fetchData();
    return () => { mounted = false; };
  },[]); 
  //#endregion

  //#region fetch
  const fetchIdEnteUsuario= async() =>{
      let result = null;
      const { data: { error, message, data } } = await identebyusuarioid();
      if (error) {
        AlertDialog({ message: message });
      } else if (data.length) {
        result = data[0].id;
      }
      return result;
  }

  const fetchData = (async (take, skip, filter, sort) => {
    let result = { data: [], totalCount: 0 };
    let eventos = {...evento};
    let evento_id = eventos.evento_id;
    let ente_id = eventos.ente_id;
    let unidad_administrativa_id = eventos.unidad_administrativa_id;
    let puesto_id = eventos.puesto_id;

    const { data: { error, message, data } } = await getBusquedaAvanzadaEvento({take, skip, filter, sort, evento_id, ente_id, unidad_administrativa_id, puesto_id});
    if (error) {
      AlertDialog({ message: message });
    } else if (data.totalCount) {
      result = data;
    }
    return result;
  });

  const fetchUnidadAdministrativa = async ({seachterm = ''}, take = 1, skip = 0, ente=null) =>{
    let result = []
    const {data:{error,message,data}} = await getEnteUnidadAdministrativaBySearchTermByEnte(seachterm, take, skip, ente);
    if(error){
        AlertDialog({message});
    }else if(data.length) {
      
        result = data;
    }
    return result;
  }

  const fetchPuesto = async ({seachterm = ''}, take = 1, skip = 0, unidadAdministrativa=null) =>{
    let result = []
    const {data:{error,message,data}} = await getEntePuestoBySearchTermByIdUnidadAdministrativa(seachterm, take, skip, unidadAdministrativa);
    if(error){
        AlertDialog({message});
    }else if(data.length) {
      
        result = data;
    }
    return result;
  }
  //#endregion

  //#region dataSource
  const dataSource = new DataSource({
    pageSize: 10,
    store: new CustomStore({
      key: "id_evento",
      load: (loadOptions) => {
        return fetchData(loadOptions.take, loadOptions.skip, getDataSourceFilter(loadOptions), getDataSourceSort(loadOptions));
      }
    })
  });

  const generarDataSource = (getFunc)=>{
    const fetchFunc = async ({seachterm = ''}, take = 1, skip = 0, ) =>{
      let result = []
      const grupoUsuario = parseInt(localStorage.getItem('grupo_id'));
      const {data:{error,message,data}} = await getFunc(seachterm, take, skip, grupoUsuario);
      if(error){
          AlertDialog({message});
      }else if(data.length) {
          result = data;
      }
      return result;
    }

    const ds = new DataSource({
      pageSize: DEFAULT_PAGE_SIZE_CMB,
      store: new CustomStore({
          key:"id",
          load: (loadOptions) => {
              if(loadOptions.searchValue)
                  return fetchFunc({seachterm:loadOptions.searchValue}, loadOptions.take, loadOptions.skip);     
              else 
                  return fetchFunc({}, loadOptions.take, loadOptions.skip);             
          },
          byKey: (key) => {
              return key;
          }
        })
    });
    return ds;
  }
  
  const eventoDatasource = generarDataSource(getEventoBySearchTerm);
  const enteDatasource = generarDataSource(getEnteBySearchTerm);

  const unidadAdministrativaDatasource = new DataSource({
    pageSize: DEFAULT_PAGE_SIZE_CMB,
    store: new CustomStore({
        key:"id",
        load: (loadOptions) => {
          let filterValues = getDataSourceFilter(loadOptions);
          if(filterValues.ente_id){
            if(loadOptions.searchValue)
              return fetchUnidadAdministrativa({seachterm:loadOptions.searchValue}, loadOptions.take, loadOptions.skip, filterValues.ente_id );     
            else 
              return fetchUnidadAdministrativa({}, loadOptions.take, loadOptions.skip, filterValues.ente_id);   
          }
          return[];    
        },
        byKey: (key) => {
            return key;
        }
      })
  });

  const puestoDatasource = new DataSource({
    pageSize: DEFAULT_PAGE_SIZE_CMB,
    store: new CustomStore({
        key:"id",
        load: (loadOptions) => {
            let filterValues = getDataSourceFilter(loadOptions);
            if(filterValues.unidadAdministrativa){
              if(loadOptions.searchValue)
                return fetchPuesto({seachterm:loadOptions.searchValue}, loadOptions.take, loadOptions.skip,filterValues.unidadAdministrativa );     
              else 
                return fetchPuesto({}, loadOptions.take, loadOptions.skip, filterValues.unidadAdministrativa);  
            }
        },
        byKey: (key) => {
            return key;
        }
      })
  });
  //#endregion

  //#region eventos interfaz
  const searchFilter = () =>{
    dataSource.reload();
  }

  const cleanFilter = async() =>{
    if(grupoUsuario === '3'){
      let ente = await fetchIdEnteUsuario();
      if(ente !== null){
        formRef.current.instance.updateData('ente_id', ente);
        formRef.current.instance.updateData('unidad_administrativa_id', null);
        formRef.current.instance.updateData('puesto_id', null);
        unidadAdministrativaDatasource.filter([['ente_id', '=', ente]]);
        unidadAdministrativaDatasource.load();
        puestoDatasource.reaload();
        dataSource.reload();
      }
    }
    else{
      formRef.current.instance.updateData('evento_id',null);
      formRef.current.instance.updateData('ente_id',null);
      formRef.current.instance.updateData('unidad_administrativa_id',null);
      formRef.current.instance.updateData('puesto_id',null);
      dataSource.reload();
      puestoDatasource.reaload();
      unidadAdministrativaDatasource.realod();
    }
    
  }
  //#endregion

  //#region render
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Buscador avanzado de eventos</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
            <form>
                <Form
                    ref={formRef}
                    formData={evento}
                    readOnly={false}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                    validationGroup="eventoData"
                >
                    <GroupItem colCount={12}>
                      <Item
                        colSpan={3}
                        dataField="evento_id"
                        editorType="dxSelectBox"
                        editorOptions={{
                            valueExpr:"id",
                            searchEnabled:true,
                            searchMode:"contains",
                            displayExpr: (item) => item && item.nombre,                     
                            searchExpr:(item) => item && item.nombre,
                            dataSource: eventoDatasource,
                            placeholder:"Todos"
                        }}
                      >
                        <Label text={"Evento"}/>
                      </Item>
                      <Item
                        colSpan={3}
                        dataField="ente_id"
                        editorType="dxSelectBox"
                        visible={grupoUsuario === '3' ? false : true}
                        editorOptions={{
                          
                            valueExpr:"id",
                            searchEnabled:true,
                            searchMode:"contains",
                            displayExpr: (item) => item && item.nombre,                     
                            searchExpr:(item) => item && item.nombre,
                            dataSource: enteDatasource,
                            onValueChanged: (e)=>{ 
                              if(e.value){
                                formRef.current.instance.updateData('unidad_administrativa_id', null);
                                formRef.current.instance.updateData('puesto_id', null);
                                unidadAdministrativaDatasource.filter([['ente_id', '=', e.value]]);
                                unidadAdministrativaDatasource.load();
                              }
                            },
                            placeholder:"Todos"
                        }}
                        >
                          <Label text={"Énte publico"}/>
                      </Item>
                      <Item
                        colSpan={3}
                        dataField="unidad_administrativa_id"
                        editorType="dxSelectBox"
                        editorOptions={{
                            valueExpr:"id",
                            searchEnabled:true,
                            searchMode:"contains",
                            displayExpr: (item) => item && item.nombre,                     
                            searchExpr:(item) => item && item.nombre,
                            dataSource: unidadAdministrativaDatasource,
                            placeholder:"Todos",
                            onValueChanged: (e)=>{ 
                              if(e.value){
                                formRef.current.instance.updateData('puesto_id', null);
                                puestoDatasource.filter([['unidadAdministrativa', '=', e.value]]);
                                puestoDatasource.load();
                              }
                            }
                        }}
                        >
                          <Label text={"Unidad administrativa"}/>
                      </Item>
                      <Item
                        colSpan={3}
                        dataField="puesto_id"
                        editorType="dxSelectBox"
                        editorOptions={{
                            valueExpr:"id",
                            searchEnabled:true,
                            searchMode:"contains",
                            displayExpr: (item) => item && item.nombre,                     
                            searchExpr:(item) => item && item.nombre,
                            dataSource:puestoDatasource,
                            placeholder:"Todos"
                        }}
                        >
                          <Label text={"Puesto"}/>
                      </Item>
                        
                    </GroupItem>
                    <Item colSpan={4}>
                      <Button text={"Buscar"} type="success" onClick={()=>{searchFilter()}}/>
                      <Button style={{marginLeft:"5px"}}  text={"Limpiar"} type="default" onClick={()=>{cleanFilter()}}/>
                    </Item>
                </Form>
            </form>
        
          <Box width="100%" direction="row">
            <Item ratio={12}>
              <DataGrid 
                key="id"
                onRowPrepared={(e)=>{if('data' in e) e.rowElement.title = e.data.nombre_puesto}}
                rowAlternationEnabled={true}
                dataSource={dataSource}
                remoteOperations={{ paging: true, sorting: true, filtering: true }}
                rowAlternationEnabled={true}                
                showBorders={true}
                columnAutoWidth={true}
                width={"100%"}>
                <FilterRow visible={true} showOperationChooser={false}/>
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                        <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                        showInfo={true} />
                <Editing
                  mode="row"
                  useIcons={false}
                  allowDeleting={false}
                  allowUpdating={false}
                  
                >
                  </Editing>
                <Column dataField = "nombre_evento" caption="Evento"/>
                <Column dataField = "nombre_ente_publico" caption="Énte publico"/>
                <Column dataField = "nombre_unidad_administrativa" caption="Unidad administrativa"/>
                <Column dataField = "nombre_puesto" caption="Puesto"/>
                <Column dataField = "nombre_jerarquia" caption="Jerarquia"/>
                <Column dataField = "titular" caption="Titular" dataType="boolean" allowFiltering={false} allowSorting={false}/> 
                <Column dataField = "funcionario_saliente" caption="Funcionario saliente"/>
                <Column dataField = "funcionario_entrante" caption="Funcionario entrante"/>
                <Column dataField = "acta_descarga_id" caption="Acta" cellRender={IconRenderActa} allowFiltering={false} allowSorting={false}/>
                <Column dataField = "acta_firmada_descarga_id" caption="Acta firmada" cellRender={IconRenderActaFirmada} allowFiltering={false} allowSorting={false}/>
                <Column dataField = "anexos_ids_descargas" caption="Anexos" cellRender={IconRenderAnexo} allowFiltering={false} allowSorting={false}/>
                <Column dataField = "fecha_firma" caption="Fecha firma"/>
                <Column dataField = "captura_anexo" caption="Captura de anexo" allowFiltering={false} allowSorting={false}/> 
                <Column dataField = "captura_anexo_porcentaje" caption="Anexos capturados" allowFiltering={false} allowSorting={false}/>
                <Column dataField = "validado_anexo" caption="Anexo validados" allowFiltering={false} allowSorting={false}/> 
                <Column dataField = "validado_anexo_porcentaje" caption="% de captura" allowFiltering={false} allowSorting={false}/>
                <Column dataField = "avance_captura" caption="Avance de captura" allowFiltering={false} allowSorting={false}/>        
                <Column dataField = "estatus_seguimiento" caption="Estatus de seguimiento" allowFiltering={false} allowSorting={false}/>
                <Column dataField = "estatus_aceptacion" caption="Estatus de aceptacion" allowFiltering={false} allowSorting={false}/>
                <Column dataField = "post_firma" caption="Días Post-firma" allowFiltering={false} allowSorting={false}/>
                <Column dataField = "bloqueo" caption="Bloqueo" dataType="boolean" allowFiltering={false} allowSorting={false}/>
                <Column dataField = "cerrado" caption="cerrado  " dataType="boolean" allowFiltering={false} allowSorting={false}/>
                <Column dataField = "anexos_x_revisar" caption="Anexos por revisar" allowFiltering={false} allowSorting={false}/>
                <Column dataField = "revision_favorable" caption="Revisiones favorables" allowFiltering={false} allowSorting={false}/>
                <Column dataField = "revision_no_favorable" caption="Revisiones no favorables" allowFiltering={false} allowSorting={false}/>
                <Column dataField = "revision_porcentaje" caption="% Revisión" allowFiltering={false} allowSorting={false}/>
                <Column dataField = "revision_avance" caption="Avance de Revisión" allowFiltering={false} allowSorting={false}/>
                <Column dataField = "avance_global" caption="Avance Global" allowFiltering={false} allowSorting={false}/>
              </DataGrid>
            </Item>
          </Box>
        </div>
      </div>
    </React.Fragment>
  );
  //#endregion
};