import React,{ useRef, useEffect} from 'react';
import { Button } from 'devextreme-react/button';
import DataGrid, { Column, Editing, Pager, Paging, FilterRow,} from 'devextreme-react/data-grid';
import Box, {
  Item
} from 'devextreme-react/box';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import AlertDialog from 'components/alertDialog/alertDialog'
import {
    getHistorialPuesto,
  getEnteBySearchTerm,
  handleDownloadPlantilla,
  getEnteUnidadAdministrativaBySearchTermByEnte,
  getEntePuestoBySearchTermByIdUnidadAdministrativa,
  getuuids
} from 'api';
import { DEFAULT_PAGE_SIZE_ONE_GRID, DEFAULT_PAGE_SIZES, DEFAULT_PAGE_SIZE_CMB } from 'constants/defaultValues';
import { getDataSourceFilter, getDataSourceSort, descargarArchivo} from 'utils/utility';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Form, {
    GroupItem,
    Label
  } from 'devextreme-react/form';

const grupoUsuario = localStorage.getItem('grupo_id');

export default function () {
  //#region hook de control
  const formRef = useRef();
  //#endregion

  //#region hook de estado
  const [evento,setEvento] = useStateWithCallbackLazy({
      "ente_id": null,
      "unidad_administrativa_id": null,
      "puesto_id": null
})
//#endregion

  //#region carga de datos iniciales 
  useEffect(() => {
    let mounted = true; 
    async function fetchData(){
        if (mounted) { 
        }
        
    }
    fetchData();
    return () => { mounted = false; };
  },[]); 
  //#endregion

  //#region fetch
  const fetchData = (async (take, skip, filter, sort) => {
    let result = [];
    let eventos = {...evento};
    let ente_id = eventos.ente_id;
    let unidad_administrativa_id = eventos.unidad_administrativa_id;
    let puesto_id = eventos.puesto_id;

    const { data: { error, message, data } } = await getHistorialPuesto({take, skip, filter, sort, ente_id, unidad_administrativa_id, puesto_id});
    if (error) {
      AlertDialog({ message: message });
    } else if (data.length) {
        console.log('data perrrona', data);
      result = data;
    }
    return result;
  });

  const fetchUnidadAdministrativa = async ({seachterm = ''}, take = 1, skip = 0, ente=null) =>{
    let result = []
    const {data:{error,message,data}} = await getEnteUnidadAdministrativaBySearchTermByEnte(seachterm, take, skip, ente);
    if(error){
        AlertDialog({message});
    }else if(data.length) {
      
        result = data;
    }
    return result;
  }

  const fetchPuesto = async ({seachterm = ''}, take = 1, skip = 0, unidadAdministrativa=null) =>{
    let result = []
    const {data:{error,message,data}} = await getEntePuestoBySearchTermByIdUnidadAdministrativa(seachterm, take, skip, unidadAdministrativa);
    if(error){
        AlertDialog({message});
    }else if(data.length) {
      
        result = data;
    }
    return result;
  }
  //#endregion

  //#region dataSource
  const dataSource = new DataSource({
    pageSize: 10,
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return fetchData(loadOptions.take, loadOptions.skip, getDataSourceFilter(loadOptions), getDataSourceSort(loadOptions));
      }
    })
  });

  const generarDataSource = (getFunc)=>{
    const fetchFunc = async ({seachterm = ''}, take = 1, skip = 0, ) =>{
      let result = []
      const grupoUsuario = parseInt(localStorage.getItem('grupo_id'));
      const {data:{error,message,data}} = await getFunc(seachterm, take, skip, grupoUsuario);
      if(error){
          AlertDialog({message});
      }else if(data.length) {
          result = data;
      }
      return result;
    }

    const ds = new DataSource({
      pageSize: DEFAULT_PAGE_SIZE_CMB,
      store: new CustomStore({
          key:"id",
          load: (loadOptions) => {
              if(loadOptions.searchValue)
                  return fetchFunc({seachterm:loadOptions.searchValue}, loadOptions.take, loadOptions.skip);     
              else 
                  return fetchFunc({}, loadOptions.take, loadOptions.skip);             
          },
          byKey: (key) => {
              return key;
          }
        })
    });
    return ds;
  }
  
  const enteDatasource = generarDataSource(getEnteBySearchTerm);

  const unidadAdministrativaDatasource = new DataSource({
    pageSize: DEFAULT_PAGE_SIZE_CMB,
    store: new CustomStore({
        key:"id",
        load: (loadOptions) => {
          let filterValues = getDataSourceFilter(loadOptions);
          if(filterValues.ente_id){
            if(loadOptions.searchValue)
              return fetchUnidadAdministrativa({seachterm:loadOptions.searchValue}, loadOptions.take, loadOptions.skip, filterValues.ente_id );     
            else 
              return fetchUnidadAdministrativa({}, loadOptions.take, loadOptions.skip, filterValues.ente_id);   
          }
          return[];    
        },
        byKey: (key) => {
            return key;
        }
      })
  });

  const puestoDatasource = new DataSource({
    pageSize: DEFAULT_PAGE_SIZE_CMB,
    store: new CustomStore({
        key:"id",
        load: (loadOptions) => {
            let filterValues = getDataSourceFilter(loadOptions);
            if(filterValues.unidadAdministrativa){
              if(loadOptions.searchValue)
                return fetchPuesto({seachterm:loadOptions.searchValue}, loadOptions.take, loadOptions.skip,filterValues.unidadAdministrativa );     
              else 
                return fetchPuesto({}, loadOptions.take, loadOptions.skip, filterValues.unidadAdministrativa);  
            }
        },
        byKey: (key) => {
            return key;
        }
      })
  });
  //#endregion

  //#region eventos interfaz
  const searchFilter = () =>{
    let eventos = {...evento};
    let ente_id = eventos.ente_id;
    if(ente_id === null || ente_id === undefined){
        AlertDialog({message: "Seleccione Énte publico", isNotification: true, type:'warning'});
        return
    }else{
        dataSource.reload();
    }
    
  }

  const cleanFilter = async() =>{
      formRef.current.instance.updateData('ente_id',null);
      formRef.current.instance.updateData('unidad_administrativa_id',null);
      formRef.current.instance.updateData('puesto_id',null);
      puestoDatasource.reload();
      unidadAdministrativaDatasource.reload();
      dataSource.reload();
  }
  //#endregion

  //#region render
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Historial de puestos</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
            <form>
                <Form
                    ref={formRef}
                    formData={evento}
                    readOnly={false}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                    validationGroup="eventoData"
                >
                    <GroupItem colCount={12}>
                      <Item
                        colSpan={3}
                        dataField="ente_id"
                        editorType="dxSelectBox"
                        visible={grupoUsuario === '3' ? false : true}
                        editorOptions={{
                          
                            valueExpr:"id",
                            searchEnabled:true,
                            searchMode:"contains",
                            displayExpr: (item) => item && item.nombre,                     
                            searchExpr:(item) => item && item.nombre,
                            dataSource: enteDatasource,
                            onValueChanged: (e)=>{ 
                              if(e.value){
                                formRef.current.instance.updateData('unidad_administrativa_id', null);
                                formRef.current.instance.updateData('puesto_id', null);
                                unidadAdministrativaDatasource.filter([['ente_id', '=', e.value]]);
                                unidadAdministrativaDatasource.load();
                              }
                            },
                            placeholder:"Todos"
                        }}
                        >
                          <Label text={"Énte publico"}/>
                      </Item>
                      <Item
                        colSpan={3}
                        dataField="unidad_administrativa_id"
                        editorType="dxSelectBox"
                        editorOptions={{
                            valueExpr:"id",
                            searchEnabled:true,
                            searchMode:"contains",
                            displayExpr: (item) => item && item.nombre,                     
                            searchExpr:(item) => item && item.nombre,
                            dataSource: unidadAdministrativaDatasource,
                            placeholder:"Todos",
                            onValueChanged: (e)=>{ 
                              if(e.value){
                                formRef.current.instance.updateData('puesto_id', null);
                                puestoDatasource.filter([['unidadAdministrativa', '=', e.value]]);
                                puestoDatasource.load();
                              }
                            }
                        }}
                        >
                          <Label text={"Unidad administrativa"}/>
                      </Item>
                      <Item
                        colSpan={3}
                        dataField="puesto_id"
                        editorType="dxSelectBox"
                        editorOptions={{
                            valueExpr:"id",
                            searchEnabled:true,
                            searchMode:"contains",
                            displayExpr: (item) => item && item.nombre,                     
                            searchExpr:(item) => item && item.nombre,
                            dataSource:puestoDatasource,
                            placeholder:"Todos"
                        }}
                        >
                          <Label text={"Puesto"}/>
                      </Item>
                        
                    </GroupItem>
                    <Item colSpan={4}>
                      <Button text={"Buscar"} type="success" onClick={()=>{searchFilter()}}/>
                      <Button style={{marginLeft:"5px"}}  text={"Limpiar"} type="default" onClick={()=>{cleanFilter()}}/>
                    </Item>
                </Form>
            </form>
        
          <Box width="100%" direction="row">
            <Item ratio={12}>
              <DataGrid 
                key="id"
                onRowPrepared={(e)=>{if('data' in e) e.rowElement.title = e.data.nombre_puesto}}
                rowAlternationEnabled={true}
                dataSource={dataSource}
                rowAlternationEnabled={true}                
                showBorders={true}
                columnAutoWidth={true}
                width={"100%"}>
                <FilterRow visible={true} showOperationChooser={false}/>
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                        <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                        showInfo={true} />
                <Editing
                  mode="row"
                  useIcons={false}
                  allowDeleting={false}
                  allowUpdating={false}
                  
                >
                  </Editing>
                <Column dataField = "ente_publico" caption="Énte publico"/>
                <Column dataField = "unidad_administrativa" caption="Unidad administrativa"/>
                <Column dataField = "clave_puesto" caption="Clave"/>
                <Column dataField = "puesto" caption="Puesto"/>
                <Column dataField = "funcionario_saliente" caption="Funcionario saliente"/>
                <Column dataField = "funcionario_entrante" caption="Funcionario entrante"/>
                <Column dataField = "evento" caption="Evento"/>
                <Column dataField = "fecha_firma" caption="Fecha firma"/>
              </DataGrid>
            </Item>
          </Box>
        </div>
      </div>
    </React.Fragment>
  );
  //#endregion
};