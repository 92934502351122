import React, { useState,useEffect,useRef } from 'react';
import { useHistory } from 'react-router-dom';
import DataGrid, { Column, Editing, Pager, Paging, FilterRow, Lookup,MasterDetail,Selection} from 'devextreme-react/data-grid';
import Form, {
    GroupItem,
    Item,
    Label,
  } from 'devextreme-react/form';
  import { LoadIndicator } from 'devextreme-react/load-indicator';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import CustomPopUp from 'components/custompopup';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import AuthCheck from 'components/authCheck/authCheck.js';
import {postEvento, getEvento, getActaBySearchTerm, cerrarEvento, postAddEventoEnte, getEventoEnte, deleteEventoEnte, 
    postBlockEventoEnte,getEventoAnexoArchivo, handleDownloadPlantilla, getActasFirmadasEventos, getActaTitular, postOrganizationalStructure} from 'api';
import {
    DATASOURCE_TIPO_EVENTO, 
    DEFAULT_PAGE_SIZE_ONE_GRID,
    DEFAULT_PAGE_SIZES,
    ESTATUS_SOURCE_CONFIG,
    STRING_ALERTDIALOG_CREATE_SUCCESS,
    DEFAULT_PAGE_SIZE_CMB
} from 'constants/defaultValues';
import {getDataSourceFilter} from 'utils/utility';
import lang from 'lang';
import EntesModal from './entesModal.js';
import ReviewersModal from './reviewersModal.js';
import AlertDialog from 'components/alertDialog/alertDialog.js';
import UnidadAdministrativaDetail from './UnidadAdministrativaDetail.js';
import Swal from 'sweetalert2';
import {descargarArchivo} from 'utils/utility';
import BloqueoCapturaModal from './bloqueoCapturaModal';
import swal from 'sweetalert2';
const position = { of: '#eventos' };

const grupoUsuario = parseInt(localStorage.getItem('grupo_id'));

export default function (props) {

    function IconRenderActaTitular(data){
        let flag = permDownloadActaTitular ? true : false;
        return <div key={`dgrid_eventodetails_actas_titular_download_${data.data.id}`}>
            {
                !flag ? null : !data.data.dowloading_acta_titular  ? (
                    <i className="dx-link dx-icon-file dx-link-icon" title="Descargar archivo" style={{fontSize:'22px',textDecoration:'unset'}} onClick={()=>{dowloadActaTitular(data)}}></i>) 
                    : <div className={"dx-link dx-link-icon"}><LoadIndicator  height={20} width={20}  /></div>
            }
        </div>
        
    }

    const dowloadActaTitular = async (dato)=>{
        dataGridRef.current.instance.getDataSource().store().push(
            [{
                type: 'update',
                key: dato.key,
                data: {
                    ...dato.data, dowloading_acta_titular: 1
                }
            }]
        );

        const {data:{data, message, error}} = await getActaTitular(dato.data.id_evento, dato.data.id_ente_publico);
        if(error){
            AlertDialog({message});
        }else if(data.length){
            let id = data[0].id_descarga;
            await descargarArchivo(id);
        }else {
            AlertDialog({message:'No existe acta titular.',type:'warning'});
        }

        dataGridRef.current.instance.getDataSource().store().push(
            [{
                type: 'update',
                key: dato.key,
                data: {
                    ...dato.data, dowloading_acta_titular: 0
                }
            }]
        );
    }

    
    function IconRenderActaFirmada(data){
        let flag = permDownloadActaFirmada ? true : false;
       
        return <div key={`dgrid_eventodetails_actas_firmadas_download_${data.data.id}`}>
            {
                !flag ? null : !data.data.downloading_actas_firmadas ?
                       (<i className="dx-link dx-icon-file dx-link-icon" title="Descargar archivo" style={{fontSize:'22px',textDecoration:'unset'}} onClick={()=>{handleDonloadActaFirmada(data)}}></i>)
                       : <div className={"dx-link dx-link-icon"}><LoadIndicator  height={20} width={20}  /></div>
                        
            }
        </div>
        
    }

    const handleDonloadActaFirmada = async(dato) =>{
        const doWork = new Promise(function (accept, reject){
            setTimeout(async()=>{
                let {data:{data, error, message}} = await getActasFirmadasEventos(dato.data.id_evento, dato.data.id_ente_publico);
                if(error){
                    AlertDialog({message});
                }else if(data.length){
                    let files = [];
                    data.forEach((val)=>{
                        files.push(val.sysfilename);
                    });
        
                    await handleDownloadPlantilla({uid:files},"actas_firmadas.zip");
                }else{
                    AlertDialog({message:'No existe acta firmadas.', type:'warning'});
                }
                accept();
                
            },250);
        });

        dataGridRef.current.instance.getDataSource().store().push(
            [{
                type: 'update',
                key: dato.key,
                data: {
                    ...dato.data, downloading_actas_firmadas: 1
                }
            }]
        );
    
        await doWork;

        dataGridRef.current.instance.getDataSource().store().push(
            [{
                type: 'update',
                key: dato.key,
                data: {
                    ...dato.data, downloading_actas_firmadas: 0
                }
            }]
        );
    }



    const IconRenderAnexo = (data)=>{
        let flag = permDownloadAnexo  ? true : false// && data.data.mostrar_anexo ? true : false;
        console.debug('icon render data',{...data.data});
        return <div key={`dgrid_eventodetail_anexo_download_${data.data.id}`}  >
        {
            !flag?null:
            !data.data.downloading?
            (<i className="dx-link dx-icon-file dx-link-icon" title="Descargar archivo" style={{fontSize:'22px',textDecoration:'unset'}} onClick={() => handleDownloadAnexo(data)}></i>):
            <div className={"dx-link dx-link-icon"}><LoadIndicator  height={20} width={20}  /></div>
        }
        </div>

    }


    
    const handleDownloadAnexo = async (dato)=>{
        swal.fire({
            title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
            text: "Por favor espere.",
            imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
            showConfirmButton: false,
            allowOutsideClick: false
          });
          let progress = 0;
          setTimeout(() => {
            if(progress===0){
                swal.update({
                    html: "Recopilando información...",
                    title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                    //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                    showConfirmButton: false,
                    allowOutsideClick: false
                });
                swal.showLoading();
                setTimeout(() => {
                    if(progress===0){
                        swal.update({
                            html: "Creando archivo comprimido...",
                            title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                            //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                            showConfirmButton: false,
                            allowOutsideClick: false
                        });
                        swal.showLoading();
                    }
                },2000);
            }
        }, 200);
      

        const {data:{data, message, error}} = await getEventoAnexoArchivo({eventoId : dato.data.id_evento, enteId : dato.data.id_ente_publico
        },
          (pregress)=>{ 
              progress = pregress;
              console.log('Descargando archivo...'+pregress+"%");
          swal.update({
                  html: 'Descargando archivo...'+pregress+"%",
                  title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                  //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                  showConfirmButton: false,
                  allowOutsideClick: false
              });
              swal.showLoading();
          });
          if(error){
            AlertDialog({message});
            }else{
                swal.fire({
                    title: `<span style='font-size:30px;font-weight:bold'>`+  "Descarga finalizada" +`</span>`,
                    showConfirmButton: false,
                    icon:'success',
                    timer: 1000
                });
            }
    }
    //getEventoAnexoArchivo
    //<Column dataField="anexo" caption="Anexo" cellRender={IconRenderAnexo} />

    //#region hook de control
    const history = useHistory();
    const formRef = useRef();
    const dataGridRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [openReviewersModal, setOpenReviewersModal] = useState(false);
    const [openBlockModal, setOpenBlockModal] = useState(false);
    const permDownloadActaTitular = AuthCheck({permission: "EVENT_ENTE_ACTA_TITULAR", onlyValidator: true});
    const permDownloadActaFirmada = AuthCheck({permission: "EVENT_ENTE_ACTA_FIRMADA", onlyValidator: true});
    const permDownloadAnexo = AuthCheck({permission: "EVENT_ENTE_ANEXOS", onlyValidator: true});
    const permAsingnarRevisores = AuthCheck({permission: "EVENT_ASSIG_REVISORES", onlyValidator: true});
    const permCerrarEvento   = AuthCheck({permission: "EVENT_CLOSE_EVENTO", onlyValidator: true});
    const permDeleteEnte   = AuthCheck({permission: "EVENT_ENTE_DELETE", onlyValidator: true});
    //#endregion

    //#region hook de estado
    const [evento,setEvento] = useState({
        "id":props.location.state && props.location.state.id? props.location.state.id: null,
        "nombre":null,
        "id_evento_tipo":null,
        "orden":null,
        "fecha_publicacion": new Date(),
        "dias_rpfa":null,
        "dias_atencion":null,
        "id_acta":null,
        "entes_participantes":[],
        "estatus": null,
        "acta": {"id":null,"nombre":null}
    });
    //#endregion

    //#region hook de montado de la vista, se utiliza para generar el estado inicial
    useEffect(() => {
        let mounted = true; 
        async function fetchData(){
            if (mounted) { 
                
                if(props.location.state && props.location.state.id){
                    await setEvento(await fetchEvento(props.location.state.id));
                }
                setIsLoading(false);
            }
            
        }
        fetchData();
        return () => { mounted = false; };
      },[]); 
    //#endregion

    //#region fetch
    const fetchEvento = async (id)=>{
        let result ={...evento};
        if(id){
            const {data:{error,message, data}} = await getEvento({eventoId:id});
            if(error){
                AlertDialog({message});
                setIsLoading(false);
            }else if(data.length){
                    result.id = data[0].id;
                    result.nombre = data[0].nombre;
                    result.id_evento_tipo = data[0].id_evento_tipo;
                    result.orden = data[0].orden;
                    result.fecha_publicacion = data[0].fecha_publicacion;
                    result.dias_rpfa = data[0].dias_rpfa;
                    result.dias_atencion = data[0].dias_atencion;
                    result.id_acta = data[0].id_acta;
                    result.estatus = data[0].estatus;
                    result.acta = data[0].acta;
                    result.habiles_rpfa = data[0].habiles_rpfa === '1' ? true : false;
                    result.habiles_atencion = data[0].habiles_atencion === '1' ? true : false;

            }
        }
        return result;
    }

    const fetchEntes = async()=>{
        let result = []
        const {data:{error,message,data}} = await getEventoEnte({id_evento: evento.id, grupo_permiso: localStorage.getItem('grupo_id')? localStorage.getItem('grupo_id'):null});
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    }

    const fetchActas = async({seachterm = ''}, take = 1, skip = 0)=>{
        let result = []
        const {data:{error,message,data}} = await getActaBySearchTerm(seachterm, take, skip);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    }
    //#endregion

    //#region dataSource
    const dataSource = new DataSource({
        store: new CustomStore({
          key: ["id","id_evento"],
          load: async (loadOptions) => {
              return (await fetchEntes()).map(item=>{item.downloading=0; item.downloading_actas_firmadas = 0;  item.dowloading_acta_titular = 0;return item})
          },
          onLoaded: async () =>{
            if(localStorage.getItem("SEER_EVTO_CLV") != null){
                let eventoKey = JSON.parse(localStorage.getItem("SEER_EVTO_CLV"));
                dataGridRef.current && dataGridRef.current.instance.expandRow(eventoKey);
            }
          },
          remove:async(key)=>{
            const {data:{error,message}} = await deleteEventoEnte(key);
            if(error) AlertDialog({message});
            else AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true });
          }
        }),
        reshapeOnPush:true
      });


    const actasDatasource = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key:"id",
            load: (loadOptions) => {
                let filterValues = getDataSourceFilter(loadOptions);
                if(loadOptions.searchValue)
                    return fetchActas({seachterm:loadOptions.searchValue}, loadOptions.take, loadOptions.skip);     
                else 
                    return fetchActas({}, loadOptions.take, loadOptions.skip);             
            },
            byKey: (key) => {
                return evento.acta ? evento.acta : [] ;
            }
        })
    });
    //#endregion

    //#region eventos de interfaz
    const handleOpenModal=()=>{
        setOpenModal(!openModal);
    }

    const handleAddEntes = async (selectedRowsData, gridModalRef)=>{
        setIsLoading(true);
        let params = {
          id_evento: evento.id,
          entes: selectedRowsData
        };
        const {data:{error,message}} = await postAddEventoEnte(params);
        setIsLoading(false);
        if(error) AlertDialog({message});
        else {
            AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true,
                onWillClose: () => {
                    gridModalRef.current && gridModalRef.current.instance.clearSelection();
                } 
            });
        }
        setOpenModal(false);
    }

    const handleCerrar = async()=>{
        if(formRef.current.instance.validate().isValid){
            Swal.fire({
                icon:'warning',
                text: '¿Estas seguro de cerrar el Evento?, esto implicara finalizar la captura de todos los anexos de todos los puestos de los entes publico asignados al evento.',
                showCancelButton: true,
                confirmButtonText: `Cerrar evento`,
            }).then(async(result) => {
                if (result.isConfirmed) {
                    setIsLoading(true);
                    let newEvento = {...evento};
                    const {data:{error,message}} = await cerrarEvento(newEvento);
                        if(error){
                            AlertDialog({message});
                            setIsLoading(false);
                        } else {
                            AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true,
                                onWillClose: () => {
                                    goToList();
                                } 
                            });
                        }
                }
            })
        }
    } 

    const handleOpenReviewersModal = () =>{
        setOpenReviewersModal(!openReviewersModal);
    }


    const handleOpenBlockModal =() =>{
        setOpenBlockModal(!openBlockModal);
    }

    function goToList() {
        history.push({pathname:'/catalogos/eventos'});
    }

    const updateOrganizationalStructure = async() =>{
        setIsLoading(true);
        const {data:{error,message}} = await postOrganizationalStructure(evento);
        if(error) AlertDialog({message});
        else AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true });  
        setIsLoading(false);
    }

    const handleBlockEventoEnte = async(unidad,bloqueo)=>{
        AlertDialog({showCancelButton:true,message: bloqueo === 0 ? "¿Seguro que desea des-bloquear este ente?" : "¿Seguro que desea bloquear este ente?",isNotification:false, type:"warning",onActionCallback:async(resp)=>{
          if(resp.value){
            setIsLoading(true);
            let params = {
              id_evento:unidad.id_evento,
              id_ente_publico:unidad.id_ente_publico,
              bloqueo
            };
            const {data:{error,message}} = await postBlockEventoEnte(params);
            setIsLoading(false);
                if(error) AlertDialog({message});
                else AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true });                
          }
      }});
      }

      function canDeleteEnte(e){
        return permDeleteEnte;
      }

      const downloadAnexosEventos = async() =>{
        swal.fire({
            title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
            text: "Por favor espere.",
            imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
            showConfirmButton: false,
            allowOutsideClick: false
          });
          let progress = 0;
          setTimeout(() => {
            if(progress===0){
                swal.update({
                    html: "Recopilando información...",
                    title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                    //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                    showConfirmButton: false,
                    allowOutsideClick: false
                });
                swal.showLoading();
                setTimeout(() => {
                    if(progress===0){
                        swal.update({
                            html: "Creando archivo comprimido...",
                            title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                            //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                            showConfirmButton: false,
                            allowOutsideClick: false
                        });
                        swal.showLoading();
                    }
                },2000);
            }
        }, 200);
      
        const {data:{data, message, error}} = await getEventoAnexoArchivo({eventoId : evento.id},
          (pregress)=>{ 
              progress = pregress;
              console.log('Descargando archivo...'+pregress+"%");
          swal.update({
                  html: 'Descargando archivo...'+pregress+"%",
                  title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                  //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                  showConfirmButton: false,
                  allowOutsideClick: false
              });
              swal.showLoading();
          });
          if(error){
            AlertDialog({message});
            }else{
                swal.fire({
                    title: `<span style='font-size:30px;font-weight:bold'>`+  "Descarga finalizada" +`</span>`,
                    showConfirmButton: false,
                    icon:'success',
                    timer: 1000
                });
            }
        }
    //#endregion
    
    //#region CRUD
    const handleSubmit = async() =>{
        if(formRef.current.instance.validate().isValid){
            setIsLoading(true);
            let newEvento = {...evento};
            newEvento.habiles_rpfa = newEvento.habiles_rpfa === true ? '1' : '0';
            newEvento.habiles_atencion = newEvento.habiles_atencion === true ? '1' : '0';
            const {data:{error,message,data}} = await postEvento(newEvento);
            if(error){
                AlertDialog({message});
            } else if(data.length){
                setEvento(await fetchEvento(data[0].id));
                AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true});
            }
            setIsLoading(false);
        }
    }
    //#endregion

    //#region validaciones
    const validationRules ={
        nombre:[
            {type:"required",message:"Nombre del evento es obligatorio"},
        ],
        tipo_evento:[
            {type:"required",message:"Tipo de evento es obligatorio"},
        ],
        orden:[
            {type:"required",message:"Orden es obligatorio"},
        ],
        dias_prfa:[
            {type:"required",message:"Dias PRFA es obligatorios"},
        ],
        dias_atencion:[
            {type:"required",message:"Dias atención es obligatorios"},
        ],
        fecha_publicacion:[
            {type:"required",message:"Fecha de publicación es obligatoria"},
        ],
        acta:[
            {type:"required",message:"Acta es obligatoria"},
        ]
    };
    //#endregion

    //#region render
    return (
        <React.Fragment>
            <h2 className={'content-block'}>Eventos</h2>
            <div id="eventos" className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                {openModal && 
                    <CustomPopUp height={"85%"} width={"85%"} visible={true} onClose={()=>handleOpenModal && handleOpenModal()} title={"Selección de Ente público"}>
                        <EntesModal id={evento.id} onAccept={(selectedRowsData,gridModalRef)=>{handleAddEntes(selectedRowsData, gridModalRef)}}/>
                    </CustomPopUp>
                }
                {openReviewersModal && 
                    <CustomPopUp height={"85%"} width={"85%"} visible={true} onClose={()=>handleOpenReviewersModal && handleOpenReviewersModal()} title={"ASIGNAR REVISORES"}>
                        <ReviewersModal eventoId={evento.id} validateRevisorSistema={true} onAccept={(selectedRowsData,gridModalRef)=>{handleOpenReviewersModal()}}/>
                    </CustomPopUp>
                }
                {
                    openBlockModal &&
                    <CustomPopUp height={"85%"} width={"85%"} visible={true} onClose={()=>handleOpenBlockModal && handleOpenBlockModal()} title={"Bloqueo de captura"}>
                        <BloqueoCapturaModal eventoId={evento.id} onAccept={()=>{handleOpenBlockModal()}}/>
                    </CustomPopUp>
                }


                    <form onSubmit={handleSubmit}>
                        <Form
                            ref={formRef}
                            formData={evento}
                            readOnly={false}
                            showColonAfterLabel={true}
                            showValidationSummary={true}
                            validationGroup="eventoData"
                        >
                            <GroupItem caption={"Datos Generales"} colCount={12} disabled = {evento.estatus === 3 || localStorage.getItem('grupo_id') != 1? true : false} >
                                <Item 
                                    colSpan={6}
                                    dataField="nombre"
                                    stylingMode="underlined"
                                    editorType="dxTextBox"
                                    editorOptions={{
                                        maxLength:250, 
                                    }}
                                    validationRules={validationRules.nombre}
                                >
                                    <Label text="Nombre del evento"/>
                                </Item>
                                <Item
                                    colSpan={4}
                                    dataField="id_evento_tipo"
                                    editorType="dxSelectBox"
                                    editorOptions={{
                                        valueExpr:"id_evento_tipo",
                                        searchEnabled:true,
                                        searchMode:"contains",
                                        displayExpr: (item) => item && item.evento_tipo_nombre,                     
                                        searchExpr:(item) => item && item.evento_tipo_nombre,
                                        dataSource:DATASOURCE_TIPO_EVENTO,
                                    }}
                                    validationRules={validationRules.tipo_evento}
                                >
                                    <Label text={"Tipo de evento"}/>
                                </Item>
                                <Item 
                                    colSpan={2}
                                    dataField="orden"
                                    stylingMode="underlined"
                                    editorType="dxNumberBox"
                                    editorOptions={{
                                        format:"#",
                                        step: 0,
                                        min:0,
                                        max:9999 
                                    }}
                                    validationRules={validationRules.orden}
                                >
                                    <Label text="Orden"/>
                                </Item>
                                <Item 
                                colSpan={4}
                                dataField="fecha_publicacion"
                                stylingMode="underlined"
                                editorType="dxDateBox" 
                                editorOptions={{
                                    type:"date",
                                    width: '100%', 
                                    inputAttr:{readOnly:true},
                                    dropDownButtonTemplate:"buttonRender",
                                }}
                                validationRules={validationRules.fecha_publicacion}>
                                    <Label text={'Fecha de evento'}></Label>
                                    <Template name="buttonRender" render={()=> <Button style={{backgroundColor:"unset",marginLeft:"-13px"}} icon={"event"} />}/>
                            </Item>
                            
                                <Item 
                                    colSpan={2}
                                    dataField="dias_rpfa"
                                    stylingMode="underlined"
                                    editorType="dxNumberBox"
                                    editorOptions={{
                                        format:"#",
                                        step: 0,
                                        min:0,
                                        max:9999,
                                        hint:"Días para revisión posterior a firma de acta"
                                    }}
                                    validationRules={validationRules.dias_prfa}
                                >
                                    <Label text="Dias RPFA"/>
                                </Item>
                                <Item colSpan={2} dataField={"habiles_rpfa"} className={"dx-field"} editorType="dxCheckBox">
                                    <Label text = "Dias habiles RPFA" location="left"/>
                                </Item>
                                <Item 
                                    colSpan={2}
                                    dataField="dias_atencion"
                                    stylingMode="underlined"
                                    editorType="dxNumberBox"
                                    editorOptions={{
                                        format:"#",
                                        step: 0,
                                        min:0,
                                        max:9999 
                                    }}
                                    validationRules={validationRules.dias_atencion}
                                >
                                    <Label text="Dias atención"/>
                                </Item>
                                <Item colSpan={2} dataField={"habiles_atencion"} className={"dx-field"} editorType="dxCheckBox">
                                    <Label text = "Dias habiles de atención" location="left"/>
                                </Item>
                                <Item
                                    colSpan={4}
                                    dataField="id_acta"
                                    editorType="dxSelectBox"
                                    editorOptions={{
                                        valueExpr:"id",
                                        searchEnabled:true,
                                        searchMode:"contains",
                                        displayExpr: (item) => item && item.nombre,                     
                                        searchExpr:(item) => item && item.nombre,
                                        valueExpr:"id",
                                        dataSource:actasDatasource,
                                    }}
                                    validationRules={validationRules.acta}
                                >
                                <Label text={"Acta aplicable"}/>
                                </Item>
                                
                            </GroupItem>
                            <Item colSpan={12}>
                                {evento.id && localStorage.getItem('grupo_id') != 9 &&
                                <div style={{float:"left", display:"inline-block"}}>
                                    <Button  horizontalAlignment="left" style={{marginLeft:"5px"}} width="400" text='Actualizar estructura orgánica' type='default' onClick={()=>updateOrganizationalStructure()} />
                                </div>
                                }
                                {
                                evento.id && localStorage.getItem('grupo_id') === '1' &&
                                <div style={{marginLeft:"5px", float:"left", display:"inline-block"}}>
                                    <Button  horizontalAlignment="left" style={{marginLeft:"5px"}} width="400" text='Bloqueo de captura' type='danger' onClick={()=>handleOpenBlockModal()} />
                                </div>
                                }
                            </Item>
                            <Item colSpan={6}>
                                    <div>
                                        {localStorage.getItem('grupo_id') == 1 && <Button visible={evento.estatus === 3 ? false : true} horizontalAlignment="left" width="200" text='Guardar' type='success' onClick={handleSubmit}/>}
                                        {
                                            evento.id && AuthCheck({
                                                permission:"EVENT_ASSIG_REVISORES",
                                                component:  <Button style={{marginLeft:"5px"}} horizontalAlignment="left" width="200" text='Asignar revisores' type='default' onClick={()=>handleOpenReviewersModal()} />
                                            })
                                        }
                                        {                                     
                                            AuthCheck({
                                                permission:"EVENT_CLOSE_EVENTO",
                                                component:  <Button visible={evento.estatus === 3 ? false : props.location.state && props.location.state.id ? true : false} style={{marginLeft:"5px"}} horizontalAlignment="left" width="200" text='Cerrar evento' type='danger' onClick={()=>handleCerrar()} />
                                            })
                                        }
                                        {localStorage.getItem('grupo_id') == 1 && <Button visible={true} horizontalAlignment="left" style={{marginLeft:"5px"}} width="200" text='Descargar anexos' onClick={downloadAnexosEventos}/>}
                                        <Button style={{marginLeft:"5px"}} horizontalAlignment="left" style={{marginLeft:"5px"}} width="200" text='Regresar' type='default' onClick={()=>goToList()} />
                                        
                                    </div>
                                </Item>
                            {evento.id &&
                                <GroupItem caption={"Entes participantes"} colCount={12} disabled = {evento.estatus === 3 ? true : false}>
                                    {                                     
                                        AuthCheck({
                                            permission:"EVENT_ENTE_ADD",
                                            component: <div style={{marginBottom:"10px"}}>
                                                            <Button
                                                                icon="plus"
                                                                type="default"
                                                                text="Agregar ente"
                                                                onClick={(e)=>{handleOpenModal()}}
                                                            />
                                                        </div>
                                        })
                                    }
                                    
                                    <DataGrid 
                                        ref={dataGridRef}
                                        key="id"
                                        dataSource={dataSource}
                                        showBorders={true}
                                        columnAutoWidth={true}
                                        rowAlternationEnabled={true}
                                        repaintChangesOnly={true}
                                        onRowExpanding={ async (e) => {
                                            localStorage.setItem("SEER_EVTO_CLV",JSON.stringify(e.key));
                                            e.component.collapseAll(-1);
                                        }}
                                        >
                                            
                                            <Selection mode="single" />
                                           <MasterDetail
                                                enabled={true}
                                                component={UnidadAdministrativaDetail}
                                            /> 
                                        <FilterRow visible={false} showOperationChooser={false}/>
                                        <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                                                <Pager
                                                showPageSizeSelector={true}
                                                allowedPageSizes={DEFAULT_PAGE_SIZES}
                                                showInfo={true} />
                                        <Editing
                                        mode="row"
                                        useIcons={true}
                                        allowDeleting={true}
                                        allowUpdating={false}
                                        />
                                        <Column type="buttons" width={110}
                                        buttons={[
                                            { hint: "Bloquear", icon: 'fas fa-unlock', visible:(e)=> !e.row.data.bloqueo && grupoUsuario === 1, onClick:(e)=>handleBlockEventoEnte(e.row.data,1) }, //estatus 1 es bloqueado en BD
                                            { hint: "Desbloquear", icon: 'fas fa-lock', visible:(e)=> e.row.data.bloqueo && grupoUsuario === 1, onClick:(e)=>handleBlockEventoEnte(e.row.data,0) }, //estatus 0 es desbloqueado en BD
                                            { hint: 'Eliminar', icon: 'fas fa-trash-alt', name: 'delete', visible:canDeleteEnte }
                                        ]}
                                        />
                                        <Column dataField="id" caption="Id" visible={false} />
                                        <Column dataField="id_evento" caption="Id_evento" visible={false} />
                                        <Column dataField="orden" caption="Orden" allowFiltering={false} />
                                        <Column dataField="clave" caption="Clave" width={100} /> 
                                        <Column dataField="nombre" caption="Ente" />
                                        <Column dataField="nombre_corto" caption="Nombre corto" />                                       
                                        <Column dataField="tipo" caption="Tipo Ente" allowSorting={false} allowFiltering={false} />
                                        <Column dataField="sector" caption="Sector" allowSorting={false} allowFiltering={false} />
                                        <Column dataField="administrador" caption="Administrador" allowSorting={false} allowFiltering={false}/> 
                                        <Column dataField="total_entes" caption="Total de entes" allowSorting={false} allowFiltering={false}/> 
                                        <Column dataField="total_puestos" caption="Total de puestos" allowSorting={false} allowFiltering={false}/> 
                                        <Column dataField="captura_anexo_porcentaje" caption="% Avance captura" allowSorting={false} allowFiltering={false}/> 
                                        <Column dataField="puesto_entregas_terminadas_porcentajes" caption="% Entregas terminadas" allowSorting={false} allowFiltering={false}/>total_anexos_revisados
                                        <Column dataField="total_anexos_revisados" caption="Total de anexos revisados" allowSorting={false} allowFiltering={false}/>
                                        <Column dataField="downloading_actas_firmadas" caption="Acta firmadas" cellRender={IconRenderActaFirmada} />
                                        <Column dataField="downloading" caption="Anexo" cellRender={IconRenderAnexo} visible={true} alignment={'center'}/>

                                        <Column dataField="estatus" allowSorting={ false}
                                            caption={lang.getString("catalogo.caption.estatus")}
                                            allowFiltering={false}>
                                            <Lookup dataSource={ESTATUS_SOURCE_CONFIG} valueExpr="id" displayExpr="name"/>
                                        </Column>
                                    </DataGrid>
                                </GroupItem>
                            }
                        </Form>
                    </form>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={isLoading}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        </React.Fragment>
    );
    //#endregion
}
