import React from 'react';
import { Popup} from 'devextreme-react/popup';
import { observer } from "mobx-react-lite"
import { ProgressBar } from 'devextreme-react/progress-bar';

export default observer(({data,...props}) =>{
  function statusFormat(value) {
    return `Cargando: ${ Math.round( value * 100) }%`;
  }
    return <Popup
          visible={data.progressActivo}
          onHiding={()=> {
            data.progressActivo = false;
            data.progress = 0;
          }}
          dragEnabled={false}
          closeOnOutsideClick={false}
          showCloseButton={false}
          showTitle={true}
          title="Adjuntos"
          container=".dx-viewport"
          width={"400px"}
          height={"200px"}
        >
          <div className="form">
          <div className="progress-info">
            Esto puede tardar varios minutos dependiendo de su conexión actual.
          </div>
          <ProgressBar
            id="progress-bar-status"
            className={data.progress === 100 ? 'complete' : '' }
            width="100%"
            min={0}
            max={100}
            statusFormat={statusFormat}
            value={data.progress}
          />
        </div>
        </Popup>
})