import {instance} from '../constants/defaultValues';

export function getAnexo({anexoId = 0, take = 1, skip = 0, filter = {}, sort = {}}){
    if(anexoId){
        let ent = instance.post(`/api/read`,{
            "VC":"cat_anexo",
            "VA":"byid",
            "id":anexoId
          })
        return ent;
    }
    else{
        return instance.post(`/api/read`,{
            "VC":"cat_anexo",
            "VA":"listdata",
            "page":skip/take,
            "pagesize":take,
            ...filter,
            "sortby": sort.selector,
            "sortdesc": sort.desc
        })
    }
}


export function postAnexo(anexo){

    return instance.post(`/api/write`,{
        "VC":"cat_anexo",
        "VA":"bysystem",
        "id":anexo.id,
        "id_rubro":anexo.id_rubro,
        "clave" : anexo.clave,
        "nombre": anexo.nombre,
        "descripcion": anexo.descripcion,
        "soporte_anexo": anexo.soporte_anexo,
        "soporte_registro": anexo.soporte_registro,
        "orden" : anexo.orden,
        "estatus": 1,
        "columnas" : anexo.columnas,
        'replicable' : anexo.replicable,
    })
}

export function deleteAnexo(anexoId){
    return instance.post(`/api/delete`,{
        "VC":"cat_anexo",
        "VA":"bysystem",
        "id" : anexoId
    });
}

export function activateAnexo(anexoId){
    return instance.post(`/api/write`,{
        "VC":"cat_anexo",
        "VA":"activate_anexo",
        "id" : anexoId
    });
}

export function ligaAnexoFile(anexo){

    return instance.post(`/api/write`,{
        "VC":"cat_anexo",
        "VA":"liga_anexo_file",
        "id":anexo.id,
        "id_adjunto":anexo.id_adjunto,
    })
}

export function getEventoValidaColumnasPlantilla(parameters){
    return instance.post(`/api/read`,{
        "VC":"cat_anexo",
        "VA":"validacolumnasplantilla",
        ...parameters
    })
}

export function getAdjuntoIdPlantillaAnexo(id_evento, id_anexo){
    return instance.post(`/api/read`,{
        "VC":"cat_anexo",
        "VA":"adjuntoIdHistorial",
        "id_evento":id_evento,
        "id_anexo": id_anexo
    })
}