import React, { useEffect,useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Form, {
    ButtonItem,
    GroupItem,
    Item,
    Label,
  } from 'devextreme-react/form';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { LoadPanel } from 'devextreme-react/load-panel';
import { 
    getAnexo,
     getEventoPuestoAnexoContinuo, 
      getEventoPuestoAnexoRegistroContinuo,
      postEventoPuestoAnexoIdAdjuntoContinuo,
      postEventoPuestoAnexoRegistroIdAdjuntoContinuo,
      uploadFiles,
      downloadFile,
      getEventoPuestoAnexoRegistroPlantilla, 
      deleteEventoPuestoAnexoRegistroContinuo,
      getUUIDSAdjuntosRegistroAnexoContinuo,
      handleDownloadPlantilla,
      postEventoPuestoAnexoRegistroContinuo
    } from 'api'
import {cargarArchivo,descargarArchivo} from 'utils/utility.js';
import 'devextreme-react/autocomplete';
import AlertDialog from 'components/alertDialog/alertDialog'
import lang from 'lang';
import { Button } from 'devextreme-react/button';
import BoxImportar from '../../../components/boxImportar';
import AuthCheck from '../../../components/authCheck/authCheck.js';
import DataGrid, {Column,Editing, Scrolling, Sorting, Button as GridButton} from 'devextreme-react/data-grid';
import ModalArchivosImportar from './modalArchivosImportar'
import ModalError from './modalErrores'
import ModalRegistro from './modalRegistro'
import ModalProgressBar from './modalProgresBar'
import { makeAutoObservable ,autorun, intercept, toJS} from "mobx";
import { observer, useLocalObservable } from "mobx-react-lite"
const position = { of: '#captura' }; //TODO: CSS - Se utiliza para posicionar el panel de loading

class AnexoPlantillaDetailModel {
    id = 0
    id_rubro = null
    clave = ''
    nombre = ''
    descripcion = ''
    soporte_anexo = false
    soporte_registro = false
    orden = null
    columnas =[]
    cmbrubro = {"id":null,"cmbrubro":''}
    data = []
    refresh = null
    popupVisible = false
    filesImportar = []
    totalFiles = 0
    id_adjunto = null
    popupImportarErroresVisible = false
    registroPopupVisible = false
    importarErrores = []
    constructor() { 
        makeAutoObservable(this);
    }
    fromJS(json){
        Object.keys(json).forEach(key => {
            if (this.hasOwnProperty(key)) {
                this[key] = json[key]; 
            }
        });
    }
    fromJson(json){
        let data = json;
        if(typeof json === 'string')
            data = JSON.parse(json);
        this.fromJS(data)
    }
    clear(){
        this.id=0
        this.id_rubro=null
        this.clave=''
        this.nombre=''
        this.descripcion=''
        this.soporte_anexo=false
        this.soporte_registro=false
        this.orden=null
        this.columnas=[]
        this.cmbrubro={"id":null,"cmbrubro":''}
        this.refresh = null
        this.popupVisible = false
        this.filesImportar = []
        this.totalFiles = 0
        this.id_adjunto = null
        this.popupImportarErroresVisible = false
        this.importarErrores = []
        this.registroPopupVisible = false
    }
    forceRefresh(){
        this.refresh = true;
    } 
}

const anexo = new AnexoPlantillaDetailModel()


export default function (props) {
    //#region hook de control
    const history = useHistory();
    //const [isLoading, setIsLoading] = useState(true);
    const gridRef = useRef(null);
    const formRef = useRef(null);
    const permCanLoad = AuthCheck({permission: "CAPTURA_REGISTRO_ADJUNTO", onlyValidator: true});
    const permCanDownLoad = AuthCheck({permission: "CAPTURA_REGISTRO_ADJUNTO_DESCARGA", onlyValidator: true});
    const permCapturaAdjunto= AuthCheck({permission: "CAPTURA_ADJUNTO", onlyValidator: true});

    //#endregion

    //#region hook de estado
    /*const anexoPlantilla = 
    {
        "id":0,
        "id_rubro":null,
        "clave":'',
        "nombre":'',
        "descripcion":'',
        "soporte_anexo":false,
        "soporte_registro":false,
        "orden":null,
        "columnas":[],
        "cmbrubro":{"id":null,"cmbrubro":''},
    };*/
    //const [anexo,setAnexo] = useStateWithCallbackLazy(anexoPlantilla);

    const isLoading = useLocalObservable(()=>{return {value: true}})
    const setIsLoading = (newValue) => { isLoading.value = newValue };
    const progress = useLocalObservable(()=>{
        return{
            progress : 0,
            progressActivo : false,
            fromJS:function(json){
                Object.keys(json).forEach(key => {
                    if (this.hasOwnProperty(key)) {
                        this[key] = json[key];
                    }
                });
            },
    refreshFromModal:function(id){
        recargarCaptura(id);
    }
}});
    const captura = useLocalObservable(()=>{return{
        "continuo_anexo_id":null,
        "ente_id": null,
        "entepublico": '',
        "unidad_id": null,
        "unidadadministrativa": '',
        "puesto_id": null,
        "puesto": '',
        "id_anexo":null,
        "anexo_id": {id:null, id_puesto_anexo:null},
        "anexo": '',
        "adjunto":null,
        "id_adjunto":null,
        "nombre_adjunto":"Sin documento adjunto.",
        "visible_btn_descarga_soporte":null,
        "funcionario_puesto": null,
        refresh:null,
        fromJS:function(json){
            Object.keys(json).forEach(key => {
                if (this.hasOwnProperty(key)) {
                    this[key] = json[key];
                }
            });
        },
        refreshFromModal:function(id){
            recargarCaptura(id);
        }
    }});
  
    //#endregion
    
    //#region Cargar datos iniciales
     const recargarCaptura =async (id_anexo) =>{
            let _captura = await fetchCaptura(
                props.location.state.data.id_continuo_puesto,
                props.location.state.data.id_puesto,
                id_anexo
                ); 
            captura.fromJS(_captura);   
            captura.refresh = true; 
            console.log('refresh captura', captura);
        }

    useEffect(() => {        
        //id_puesto_anexo = props.location.state.id_puesto_anexo
        let mounted = true; 
        const  fetchData = async()=>{
            let result = {...anexo};
            let ent = {...captura};   
            if(props.location.state && props.location.state.data){  
                console.log('useEffect', props.location.state.data);
                ent = await fetchCaptura(
                    props.location.state.data.id_continuo_puesto,
                    props.location.state.data.id_puesto,
                    props.location.state.id
                ); 
                captura.fromJS(ent);   
                captura.refresh = true;
                setIsLoading(false); 
                return true;              
            }
            return false;
        }
        fetchData()
            .then((seCargo)=>{
                if(!seCargo) history.push({pathname:'/eventos/capturas/'});
            });

        //#region  reactions
        const capturaAr = autorun(()=>{
            if(captura.refresh){
                setTimeout(() => {
                    captura.refresh = false
                }, 25);
            }
        })
        /*const anexoAr = autorun(()=>{
            if(anexo.refresh){
                setTimeout(() => {
                    anexo.refresh = false
                }, 25);
            }
        })*/
       
        const setAnexoId = intercept(captura, "anexo_id", change => {
            if (change.newValue.id !== captura.anexo_id.id) {
                (async function(){
                    setIsLoading(true);
                    
                    await recargarCaptura(change.newValue.id);
                    anexo.refresh = true; 
                    let result = await fetchAnexo(captura.id_anexo);
                    let registros = await fetchEventoPuestoAnexoRegistro(captura.continuo_anexo_id)
                    console.log('REGISTROOOOSS', registros);
                    
                    anexo.fromJS({...result,data:registros.sort((a,b) => a.renglon_numero-b.renglon_numero), refresh:false });
                    
                    setIsLoading(false);    
                })();
            }
            return change;
        })

        //#endregion
        return () => { 
            mounted = false; 
            anexo.clear();
            capturaAr(); 
            //anexoAr();
            setAnexoId();
        };
      },[]); 
    //#endregion

    //#region fetch
    const fetchCaptura = (async(id_continuo_puesto, puesto_id, anexo_id) =>{        
        let result = {...captura};
           
        const { data: { error, message, data } } = await getEventoPuestoAnexoContinuo({id_continuo_puesto, puesto_id, anexo_id});
        if(error){
            AlertDialog({message: message});
        }else if(data.length){
            console.log('fetchCaptura', data);

            result.continuo_anexo_id = data[0].continuo_anexo_id;
            result.ente_id = data[0].ente_id;
            result.entepublico = data[0].entepublico;
            result.unidad_id = data[0].unidad_id;
            result.unidadadministrativa = data[0].unidadadministrativa;
            result.puesto_id = data[0].puesto_id;
            result.puesto = data[0].puesto;
            result.id_anexo = data[0].id_anexo;
            result.anexo_id = {id:data[0].anexo_id, continuo_anexo_id : data[0].continuo_anexo_id } ;
            result.anexo = data[0].anexo;
            result.cmbanexo = data[0].cmbanexo;
            result.id_adjunto = data[0].id_adjunto;
            result.nombre_adjunto = data[0].nombre_adjunto;
            result.visible_btn_descarga_soporte =data[0].visible_btn_descarga_soporte;
            result.funcionario_puesto = data[0].funcionario_puesto
        }
        return result;
    })
    const fetchAnexo = (async(anexoId) =>{
        let result = {...anexo};
        if(anexoId){           
            const {data:{error,message,data}} = await getAnexo({anexoId});
           console.log('FETCH DEL ANEXO PERRON', data);
            if(error){
                AlertDialog({message: message});
            }else if(data.length){            
                result.id = data[0].id;
                result.id_rubro = data[0].id_rubro;
                result.clave = data[0].clave;
                result.nombre = data[0].nombre;
                result.descripcion = data[0].descripcion;
                result.soporte_anexo = (data[0].soporte_anexo == "1") ;
                result.soporte_registro = (data[0].soporte_registro == "1") ;
                result.orden = data[0].orden;
                result.columnas = data[0].columnas;
                result.cmbrubro = data[0].cmbrubro;
                result.id_adjunto = data[0].id_adjunto;
            }
        }
        
        return result;
    })
    
    const fetchEventoPuestoAnexoRegistro = (async(continuo_anexo_id) =>{
        let result = [];
        if(continuo_anexo_id){           
            const {data:{error,message,data}} = await getEventoPuestoAnexoRegistroContinuo(continuo_anexo_id);
           
            if(error){
                AlertDialog({message: message});
            }
            result = data;
        }
        return result;
    })
    //#endregion

    //#region origen de datos 
    //#endregion

    //#region eventos interfaz
    const goToList = () =>{
        var auxData = props.location.state.data;
        if(auxData.isEvento)
            history.replace({ pathname: '/catalogos/eventos/detalle', state: { id:captura.evento_id } })
        if(auxData.isMisRevisiones){
            let params = {
                id_evento_puesto: auxData.id_evento_puesto,
                id_evento: auxData.id_evento,
                evento_nombre: auxData.evento_nombre,
                id_ente_publico :auxData.id_ente_publico,
                id_unidad_administrativa:auxData.id_unidad_administrativa,
                unidad_administrativa_nombre:auxData.unidad_administrativa_nombre,
                nombre_puesto:auxData.nombre_puesto,
                ev_nom:auxData.ev_nom
            }
            history.push({pathname:'/eventos/revisiones',state:{data:params}})
        }
        else
            history.goBack();
    }
    function canDelete(e){
        if(!permCanLoad) return false;

        return true;
    }
    function canLoad(e){
        if(!permCanLoad) return false;
        if(!anexo.soporte_registro) return false;

        return true;
    }

    function candownLoad(e){
        return e.row.data.id_adjunto && e.row.data.id_adjunto > 0 && permCanDownLoad && anexo.soporte_registro;
    }

    const downloadAnexosRegistros = async ()=>{
        let cap = {...captura}
        const {data:{data, message, error}} = await getUUIDSAdjuntosRegistroAnexoContinuo(cap.continuo_anexo_id)
        if(error){
            AlertDialog({message});
            return;
        }else if(data.length){
            let files = [];
            data.forEach((val)=>{
                files.push(val.sysfilename);
            });
            await handleDownloadPlantilla({uid:files},"Registros_anexos.zip");
        }else{
            AlertDialog({message:'No existen documentos adjuntos en los registros del anexo.', type:'warning'});
        }
    }

    //#endregion

    //#region eventos columna
    //#endregion

    //#region eventos CRUD

    const checkObligatorios = async()=>{
        let columns = anexo.columnas;
        let anexoData = anexo.data;
        var flagValidation = false

        columns.forEach((element)=>{
            console.log('pruebas', element, element.nombre, element.obligatorio);
            if(element.obligatorio){
              anexoData.forEach((value)=>{
                  for(var key in value){
                    if(key.toString() === element.nombre.toString() && (value[key] === null || value[key] === '')){
                        flagValidation = true
                    }
                }
              });
            }
        });
        
        if(flagValidation){
            AlertDialog({message:'Uno o mas campos de uno o mas registros obligatorios se encuentran vacios.',type:'error'});
            return;
        }else{
            goToList();
        }
    }

    const handleSubmit = (async (e) => {    
        e.preventDefault();
        setIsLoading(true);
        /*
        const {data:{error,message}} = await postEntePublicoPuesto(entePublicoPuesto);
        if(error) AlertDialog({message: message});  
        else      goToList();
        */
        setIsLoading(false);
    });
    //#endregion

    //#region validaciones
    const validationRules ={ 
        nombre:[
            {type:"required",message:"Nombre es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:1000}
        ],
    }
    //#endregion

    //#region Excel importar exportar
    const fileData = async (data) => {
        if(data.length> 1){
            data.map(function(dato,index){
                if(dato.id_adjunto == ""){
                  dato.id_adjunto = "0";
                }else if( (parseFloat(dato.id_adjunto.trim()) % 1) == 0){
                    dato.id_adjunto = dato.id_adjunto.trim();
                }else{
                    dato.id_adjunto = "0";
                }
                return dato;
            });

            let columnas = anexo.columnas.map(({nombre})=> nombre);
            columnas.push('id_adjunto');
            let columnasinexistentes = columnas.filter((item)=> !data[0].hasOwnProperty(item));
            if(columnasinexistentes.length > 0) {
                AlertDialog({title:'Formato incorrecto',message:`Las columnas ${JSON.stringify(columnasinexistentes)} no existen en el documento.`});
                return;
            }
        }
        data = data.filter((item)=> data.indexOf(item) !== 0 );
        data.forEach((elem,index)=> elem.renglon_numero = index+1);
        await saveEventoPuestoAnexoRegistros(data);
    };
    const agragarRegistro = async (registro)=>{
        registro.renglon_numero = (Math.max(0,...(toJS(anexo.data)).map(x=> x.renglon_numero)) + 1);
        const data = [...toJS(anexo.data),registro]
        console.log('data a guardar', data)
        if(await saveEventoPuestoAnexoRegistros(data)){
            AlertDialog({message:"Registro agregado exitosamente", isNotification: true});
            return true;
        }
        return false;
                  
    }
    const saveEventoPuestoAnexoRegistros = async (data) =>{
        const {data:{error,message,...rest}} = await postEventoPuestoAnexoRegistroContinuo({continuo_anexo_id : captura.continuo_anexo_id, data:toJS(data)})
        if(error || (rest.data && rest.data.length && rest.data[0].error) ){
            anexo.fromJS({popupImportarErroresVisible:true, importarErrores : rest.data[0].message})
            return false;
        }else if(data.length) {
            console.log('la wea esta',rest.data[0].data)
            anexo.fromJS({data:rest.data[0].data.sort((a,b) => a.renglon_numero-b.renglon_numero)})
            return true;
        }
    }

    const descargarPlantilla = async () => {     
        let data = toJS(anexo.data);
        let columnas  = anexo.columnas.map(({nombre})=> nombre);
        
        let anx = toJS(anexo);
        columnas.push('id_adjunto');

        if (!anexo.id_adjunto){
            AlertDialog({message:"Este Anexo no tiene una plantilla."});
            return;
        }

        const archivo = await downloadFile( anexo.id_adjunto,(progress)=>{ console.log('Descargando archivo...'+progress+"%");});
        if (!archivo.data.byteLength>0){ 
            AlertDialog({message:"No es posible descargar este archivo."});
            return;
        }

        data = data.map((item)=>columnas.map((nombre)=>{ return {[nombre]:item[nombre] }}).reduce((a,b)=>{ return{...a,...b}}))
        const parameters = {
            plantilla:{systemFile:true,uid:archivo.sysfilename},
            data,
            columnas
        }
        const plantilla = await getEventoPuestoAnexoRegistroPlantilla(parameters,(pregress)=>{ console.log('Descargando archivo...'+pregress+"%");});
        const downloadUrl = window.URL.createObjectURL(new Blob([plantilla.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download',  `${anx.clave}_plantilla.xlsx`); 
            document.body.appendChild(link);
            link.click();
            link.remove();
    };

    //#endregion //
   
    //#region estatus del anexo 
    const canDocumentoAdjunto = ()=>{
        console.log('canDocumentoAdjunto', permCapturaAdjunto, anexo.soporte_anexo)
        if(!permCapturaAdjunto) return false;
        if(!anexo.soporte_anexo) return false;

        return true;
    }

    const canQuitarDocumentoAdjunto = ()=>{
        return captura.id_adjunto;
    }

    const canDescargarDocumentoAdjunto = ()=>{
        if(!permCanDownLoad ) return false;
        if(!anexo.soporte_anexo) return false;
        return captura.id_adjunto;
    }
    //#endregion

    const deleteAnexoRecord = async(e)=>{
        setIsLoading(true);
        const {data:{error,message}} = await deleteEventoPuestoAnexoRegistroContinuo(e.data.id,e.data.id_adjunto);
        setIsLoading(false);
        if(error){
            e.cancel = true;
            AlertDialog({message});
        } 
    }

    const GridComponent = observer(() => {

        //Observar estas variables para auto actualizar
        anexo.data && anexo.data.forEach(item=> item.uploading)
        anexo.data && anexo.data.forEach(item=> item.downloading)
        anexo.data && anexo.data.forEach(item=> item.id_adjunto)

        //render
        return    <DataGrid key="id" colSpan={12}
            ref={gridRef}
            dataSource={anexo.data}
            columnAutoWidth={true}
            repaintChangesOnly={true}
            showBorders={true} 
            width={"100%"}
            rowAlternationEnabled={true}
            height={500}
            onRowRemoving={(e)=>{deleteAnexoRecord(e)}}
            >
            <Sorting mode="none" />
            <Scrolling mode="virtual"/>
            <Editing
                mode="row"
                useIcons={true}
                allowUpdating={AuthCheck({
                    permission:"CAPTURA_REGISTRO_ADJUNTO",
                    onlyValidator: true
                })}
                allowDeleting={AuthCheck({
                    permission:"CAPTURA_REGISTRO_ADJUNTO",
                    onlyValidator: true
                })}
            />
            <Column type="buttons" width={110}
                
            >
                <GridButton hint='Eliminar' icon = 'trash' visible={canDelete()} name='delete'></GridButton>
                <GridButton name='01_subir_documento'
                    render={(ev) => {
                        const data = toJS(ev.row.data)
                        return <div key={`dgrid_upload_${data.renglon_numero}`} className={"dx-link dx-link-icon"}  style={{height:18,width:18,cursor:'auto'}} >
                                {
                                    !canLoad(ev) ? null:
                                    !data.uploading?
                                    (<i className="dx-link dx-icon-upload dx-link-icon"  title="Subir documento"  onClick={() => cargarAdjuntoRegistro(ev.row.data)}></i>):
                                    <div className={"dx-link dx-link-icon"}><LoadIndicator height={12} width={12}  />&nbsp;</div>
                                }
                                </div>
                    }}></GridButton>
                <GridButton name='02_bajar_documento'
                    render={(ev) => {
                        const data = toJS(ev.row.data)
                        return <div key={`dgrid_download_${data.renglon_numero}`} className={"dx-link dx-link-icon"}  style={{height:18,width:18,cursor:'auto'}} >
                            {   
                                !candownLoad(ev)?null:
                                !!data.uploading?null:
                                !data.downloading?
                                (<i className="dx-link dx-icon-download dx-link-icon" title="Bajar documento"  onClick={() =>{ 
                                    descargarAdjunto(ev.row.data)}}></i>):
                                <div className={"dx-link dx-link-icon"}><LoadIndicator  height={12} width={12}  />&nbsp;</div>
                            }
                            </div>
                    }}
                ></GridButton>
            </Column>
            <Column dataField={'renglon_numero'} caption={'#'} />
            {anexo.columnas.map((column)=><Column dataField={column.nombre} caption={column.nombre} /> )}
            </DataGrid>
        //</Loading>}
        //</div>
        })

    
    const LoadingCaptura = observer(() => {
        return <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={isLoading.value}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                    
                />
    });

    
    const PrimaryForm = observer(() => {
        //Observar variables

        //return captura.refresh || captura.refresh === null?<Loader/>:
        return <div>
            <Form
                id="capturaPrimary"
                ref={formRef}
                formData={captura.refresh?{}:captura}
                readOnly={false}
                showColonAfterLabel={true}
                showValidationSummary={true}
                validationGroup="validationRules"
                key='frm_anexo_evento_PrimaryForm'
            >
                <GroupItem colCount={12} key="gi_anexo_evento_g1">  
                    <Item 
                        colSpan={6}
                        dataField="entepublico"
                        stylingMode="filled"
                        editorOptions={{ readOnly:true}}>
                            <Label text={lang.getString("captura.caption.ente_publico")}></Label>
                    </Item>    
                    <Item 
                        colSpan={6}
                        dataField="unidadadministrativa"
                        stylingMode="filled"
                        editorOptions={{ readOnly:true}}>
                            <Label text={lang.getString("captura.caption.unidad_administrativa")}></Label>
                    </Item> 
                    <Item 
                        colSpan={6}
                        dataField="puesto"
                        stylingMode="outlined"
                        editorOptions={{ readOnly:true,}}>
                            <Label text={lang.getString("captura.caption.puesto")}></Label>
                    </Item>
                    <Item 
                        colSpan={6}
                        dataField="funcionario_puesto"
                        stylingMode="underlined"
                        editorOptions={{ readOnly:true,}}>
                            <Label text={'Funcionario asignado al puesto'}></Label>
                    </Item>
                    <Item 
                        colSpan={12}
                        dataField="anexo"
                        stylingMode="underlined"
                        editorOptions={{ readOnly:true,}}>
                            <Label text={lang.getString("captura.caption.anexo")}></Label>
                    </Item>            
                </GroupItem>

                <GroupItem colCount={2} key="gi_anexo_evento_g4">
                    <BoxImportar capturaContinua={true} headerPosition = '0'  descargarPlantilla={descargarPlantilla} fileData={fileData} />
                         {  
                            anexo.soporte_registro &&       
                            AuthCheck({
                                permission:"CAPTURA_REGISTRO_ADJUNTO_IMPORT",
                                component: <Button style={{marginLeft:"5px",display:"inline-block"}} horizontalAlignment="left" width="195" text={'IMPORTAR ARCHIVOS'} type={'default'} onClick={(e)=>{openFileDialogImportar()}} />
                            })
                        }  
                        
                        {
                            canDocumentoAdjunto()?
                            <Button style={{marginLeft:"5px", display:"inline-block"}}
                                    horizontalAlignment="left" width="195" text={'DOCUMENTO ADJUNTO'} 
                                    type={'default'} onClick={(e)=>{cargarAdjuntoCaptura()}} />  
                            :
                            null
                        }
                        {
                            canQuitarDocumentoAdjunto()?
                            <Button style={{marginLeft:"5px", display:"inline-block"}} horizontalAlignment="left" icon={"remove"} stylingMode={"text"} onClick={(e)=>{quitarAdjuntoCaptura()}} /> 
                            :
                            null
                        }
                        {
                            /*
                                EL grupo 1: adminsitrador y 3: Ente adminsitrador pueden descargar adjunto siempre
                            */
                            canDescargarDocumentoAdjunto()?
                            <Button style={{marginLeft:"5px",display:"inline-block"}} horizontalAlignment="left"  visible={captura.nombre_adjunto}  text={captura.nombre_adjunto} elementAttr={{class: 'boton'}}  onClick={(e)=>{descargarAdjunto(captura)}} /> 
                            :null
                        }
                        {
                            <Button style={{marginLeft:"5px"}} visible={captura.visible_btn_descarga_soporte ? true : false} key='btn_udownload_all_registros'  horizontalAlignment="left" text="Descargar soporte de registros" elementAttr={{class: 'boton'}} onClick={()=>{downloadAnexosRegistros()}}/>
                        }
                </GroupItem>
                <GroupItem colCount={20} key="gi_anexo_evento_g5">      
                    {
                        AuthCheck({
                            permission:"CAPTURA_REGISTRO_ADD",
                            component: <Button style={{display:"inline-block"}}  horizontalAlignment="left" text={"Agregar registro"} type={'default'}  stylingMode={"text"} onClick={(e)=>{
                                anexo.fromJS({registroPopupVisible:true});
                                }} />
                        })
                    }
                </GroupItem>
                <GroupItem colCount={1} key="gi_anexo_evento_g6"> 
                    <GridComponent/>                    
                </GroupItem>
                columnas
                <GroupItem colCount={1} key="gi_anexo_evento_g8"> 
                    <ButtonItem horizontalAlignment="left" colSpan={2}
                        buttonOptions={{text:'Guardar',type:'success', onClick:()=>{checkObligatorios()}}}
                    /> 
                    <ButtonItem horizontalAlignment="left" colSpan={10}
                        buttonOptions={{text:'Regresar',type:'default', onClick:()=>{checkObligatorios()}}}
                    />
                </GroupItem>
            </Form>  
        </div>
    })

    const openFileDialogImportar =async function( ) {
        var inputElement = document.createElement("input");
        inputElement.type = "file";
        inputElement.accept = '*.*';
        inputElement.multiple = true;
        inputElement.addEventListener("change", handleFileChangeImportar)
        inputElement.dispatchEvent(new MouseEvent("click")); 

        await recargarCaptura();
    }

    const handleFileChangeImportar = function()  {
        let renglones = anexo.data.map(({renglon_numero})=> renglon_numero)
        let archivos = Array.from(this.files).filter(({name})=> {
            try {
                let renglon_numero = parseInt(name.split('_')[0]);
                if(renglones.includes(renglon_numero)) return true
            } catch (error) {
            }
            return false 
        })
        archivos = archivos.map((file)=> {return { numero_renglon:parseInt(file.name.split('_')[0]), archivo:file.name, file }})
        anexo.fromJS({filesImportar:archivos, popupVisible : true, totalFiles : Array.from(this.files).length })
    }
    
    const submitFiles = (async()=>{
        try{
                let file = anexo.filesImportar.map(({file}) => file);
                anexo.refresh = true;
                progress.fromJS({
                    progress : 1,
                    progressActivo : true
                })
                const {data:{error,message,data:files}} = await uploadFiles(file,
                    (pregress)=>{ 
                        console.log('Subiendo archivo...'+pregress+"%");
                        
                        if(pregress === 100)
                        {
                            setTimeout(() => {
                                progress.fromJS({
                                    progress : 0,
                                    progressActivo : false
                                })
                            }, 250);
                        }
                        else{
                            progress.fromJS({
                                progress : pregress
                            })
                        }     
                });
                
                //Swal.close();
                anexo.refresh = false;
                if(error) AlertDialog({message});
                else {
                   anexo.filesImportar.forEach((element, index)=>{
                       anexo.data.find(({renglon_numero}) => renglon_numero === element.numero_renglon).id_adjunto = files[index].idrow_file
                   })
                   const {data:{error,message}} = await postEventoPuestoAnexoRegistroContinuo({continuo_anexo_id : captura.continuo_anexo_id, data:toJS(anexo.data) })
                   if(error){
                       AlertDialog({message});
                   }
                } 
                anexo.fromJS({filesImportar:[], popupVisible : false, totalFiles : 0 })
        }catch(err){
            anexo.refresh = false;
        }
    });

    //#region subir adjunto a anexo y registros del anexo
    const cargarAdjuntoCaptura = function() {
        const alCargarArchivo = (async(idAdjunto)=>{   
            captura.id_adjunto = idAdjunto;

            const {data:{error,message}} = await postEventoPuestoAnexoIdAdjuntoContinuo({id:captura.continuo_anexo_id,id_adjunto:captura.id_adjunto});
            if(error) AlertDialog({message});
            else recargarCaptura(captura.id_anexo);
        });

        cargarArchivo('*.*', alCargarArchivo, setIsLoading);
    }
    
    const quitarAdjuntoCaptura = async() => {
        captura.id_adjunto     = 0;
        captura.nombre_adjunto = "";
        
        const {data:{error,message}} = await postEventoPuestoAnexoIdAdjuntoContinuo({id:captura.continuo_anexo_id,id_adjunto:captura.id_adjunto})
        if(error) AlertDialog({message});

        //setCaptura({...captura});
    }
    
    const cargarAdjuntoRegistro = function(fila) {
        const alCargarArchivo = (async(idAdjunto)=>{ 
            const {data:{error,message}} = await postEventoPuestoAnexoRegistroIdAdjuntoContinuo(fila.id,idAdjunto);
            if(error) AlertDialog({message});
            else fila.id_adjunto = idAdjunto;
            
            setTimeout(() => {recargarCaptura()}, 100);
        });
        const setIsLoading = (isLoading)=>{ fila.uploading = isLoading;  }
        //beginUpload();
        cargarArchivo('*.*', alCargarArchivo, setIsLoading);
    } 
    
    const descargarAdjunto = async (fila) => {
         if (!fila.id_adjunto){
             AlertDialog({message:"No hay un archivo adjunto."});
             return;
         }      
         const setIsLoading = (isLoading)=>{ fila.downloading = isLoading;  }
         descargarArchivo(fila.id_adjunto,setIsLoading)
    }
    //#endregion

    //#region render
    return (
        <React.Fragment >
            <h2 className={'content-block'}>Captura continua de anexo</h2>
            <div id="captura" className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                <form  onSubmit={handleSubmit}>
                    <PrimaryForm>  </PrimaryForm>
                    <ModalArchivosImportar data={anexo} onImportar={submitFiles}></ModalArchivosImportar>
                    <ModalError data={anexo} ></ModalError>
                    <ModalRegistro data={anexo} callback={agragarRegistro}></ModalRegistro>
                    <ModalProgressBar data={progress}></ModalProgressBar>
                    <LoadingCaptura></LoadingCaptura>
                </form>
                </div>
                
                
            </div>
      </React.Fragment>
        );
    //#endregion
}