import React, { useState } from 'react';
import { Button } from 'devextreme-react/button';
import { useHistory } from 'react-router-dom';
import DataGrid, { Column, Editing, Pager, Paging, FilterRow, Lookup } from 'devextreme-react/data-grid';
import Box, { Item } from 'devextreme-react/box';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {DEFAULT_PAGE_SIZE_ONE_GRID,
    DEFAULT_PAGE_SIZES,
    STRING_FORMATO_FECHA_GRID,
    ESTATUS_SOURCE_CONFIG
} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog'
import { getEnte, deleteEnte, activateEnte } from '../../../api';
import { getDataSourceFilter, getDataSourceSort } from 'utils/utility'
import lang from '../../../lang';
import AuthCheck from '../../../components/authCheck/authCheck.js';
import { custom } from 'devextreme/ui/dialog';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import PopUpEntidadInfo from '../../../components/PopUpEntidadInfo';

export default function() {
    //#region hook de control
    const history = useHistory();
    const[isPopUpOpen, setIsPopUpOpen] = useState(false);
    const[isPopUpInfoOpen, setIsPopUpInfoOpen] = useState(false);
    const permCanEdit = AuthCheck({permission: "ENTE_EDIT", onlyValidator: true});
    const permCanDelete = AuthCheck({permission: "ENTE_DELETE", onlyValidator: true});
    //#endregion

    //#region hook de estado
    const [entidadInfo,setEntidadInfo] = useStateWithCallbackLazy({
        "id":0,
        'registro_creacion_usuario':'',
        'registro_creacion_fecha':'',
        'registro_modificacion_usuario':'',
        'registro_modificacion_fecha':'',
        'registro_cancelacion_usuario':'',
        'registro_cancelacion_fecha':'',
    })
    //#endregion

    //#region fetch
    const fetchEntidadInfo = (async(id) =>{
        let result = {...entidadInfo};
        if(id){     
            const {data:{error,message,data}} = await getEnte({enteId:id});
        
            if(error){
                AlertDialog({message: message});
            }else if(data.length){
                result.id = data[0].id;
                result.registro_creacion_usuario = data[0].registro_creacion_usuario;
                result.registro_creacion_fecha = data[0].registro_creacion_fecha;
                result.registro_modificacion_usuario = data[0].registro_modificacion_usuario;
                result.registro_modificacion_fecha = data[0].registro_modificacion_fecha;
                result.registro_cancelacion_usuario = data[0].registro_cancelacion_usuario;
                result.registro_cancelacion_fecha = data[0].registro_cancelacion_fecha;
            }
        }
        return result;
    })
    
    const fetchData = (async (take, skip, filter, sort) =>{
        let result= {data:[], totalCount:0};
        const {data:{error,message,data}} = await getEnte({take, skip, filter, sort, grupoPermiso: localStorage.getItem('grupo_id')? localStorage.getItem('grupo_id'):null});
        console.log("ente",data);
        if(error){
            AlertDialog({message});
        }else if(data.length && data[0].totalCount) {
            result = data[0];
        }
        return result;
    })

    const deleteData = (async (key) =>{
        const {data:{error,message}} = await deleteEnte(key);
        if(error){
            AlertDialog({message});
        }
    })

    const activateData = (async (key) =>{
        const {data:{error,message}} = await activateEnte(key);
        if(error){
            AlertDialog({message});
        }
    })
    //#endregion

    //#region dataSource
    const dataSource = new DataSource({
        store: new CustomStore({
            key: "id",
            load: (loadOptions) => {
                return fetchData(
                    loadOptions.take, 
                    loadOptions.skip, 
                    getDataSourceFilter(loadOptions), 
                    getDataSourceSort(loadOptions));             
            },
            remove: (key) => {
                return deleteData(key);
            }
        })
    });
    //#endregion
    
    //#region eventos interfaz
    const goToDetail = (id = null) =>{
        history.push({pathname:'/catalogos/ente/detalle', state:{id} })
    }
    
    const openModal = () =>{
        setIsPopUpOpen(!isPopUpOpen);
    }
    const openModalInfo = async(id) =>{    
        let ent = await fetchEntidadInfo(id); 
        setEntidadInfo(ent);    
        setIsPopUpInfoOpen(!isPopUpInfoOpen);
    }

    const  activateRecord = (e) =>{
        let activateDialog = custom({
            showTitle: false,
            messageHtml: "¿Está seguro que desea activar este registro?",
            buttons: [
                {
                    text: "SÍ",
                    onClick: () => {
                        return true;
                    }
                }, 
                {
                    text: "NO",
                    onClick: () => {
                        return false;
                    }
                }, 
            ]
        });

        activateDialog.show().then(async (dialogResult) => {
            if(dialogResult){
                await activateData(e.row.data.id);
                dataSource.reload();
            }
        });
    }

    function canEdit(e){
        return e.row.data.estatus == 1 && permCanEdit;
    }

    function canDelete(e){
        return e.row.data.estatus == 1 && permCanDelete;
    }

    function canActivate(e){
        return e.row.data.estatus != 1;
    }
    //#endregion

/*
<div style={{ float:"right", marginLeft:"5px", display:"inline-block"}}>
    <Button 
        type="default"
        text={lang.getString("ente.index.imprimirlistado")}
        onClick={()=>{
            //goToPrintUsers()
        openModal();
    }}/>
</div>
*/

    //#region render
    return(
        <React.Fragment>
            <h2 className={'content-block'}>{lang.getString("ente.index.title")}</h2>
            <div className={'content-block'}>
            <div className={'dx-card responsive-paddings'}>
            <div className="dx-field-value" style={{marginBottom:"20px"}}>
            


                 
            <div style={{display:"inline-block", float:"right"}}>
            {
                AuthCheck({
                    permission:"ENTE_ADD",
                    component: <Button icon="plus"
                    type="default"
                    text={lang.getString("ente.index.agregar")}
                    onClick={()=>{goToDetail()}}/>
                })
            }
            </div>
                    
             </div>
            <Box width="100%" direction="row">
                <Item ratio={12}>
                <DataGrid 
                    key="id"
                    keyExpr="id"
                    dataSource={dataSource}
                    remoteOperations={{paging:true, sorting:true, filtering: true}}
                    columnAutoWidth={true}
                    repaintChangesOnly={true}
                    showBorders={true} width={"100%"}
                    allowColumnReordering={true}
                    rowAlternationEnabled={true}>
                    <FilterRow visible={true} showOperationChooser={false}/>
                    <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={DEFAULT_PAGE_SIZES}
                            showInfo={true} />
                    <Editing
                        mode="row"
                        useIcons={true}
                        allowDeleting={AuthCheck({
                            permission:"ENTE_DELETE",
                            onlyValidator: true
                        })}
                        allowUpdating={AuthCheck({
                            permission:"ENTE_EDIT",
                            onlyValidator: true
                        })}
                    />
                    <Column caption="Acción" type="buttons" width={110}
                        buttons={[
                            {hint:'Editar',icon:'edit',name:'edit', visible:canEdit, onClick:(event) => goToDetail(event.row.key)},
                            {hint:'Eliminar',icon:'trash',name:'delete', visible:canDelete},
                            {hint:'Activar',icon:'check',name:'activate', visible:canActivate, onClick:activateRecord},
                            {hint:'Ver',icon:'info',name:'info',onClick:(event) => openModalInfo(event.row.key)},
                        ]}
                    />
                    <Column dataField="id" caption="Id" visible={false} />
                    <Column dataField="orden" caption="Orden" />
                    <Column dataField="clave" caption="Clave" width={150} /> 
                    <Column dataField="nombre" caption="Ente" />
                    <Column dataField="nombre_corto" caption="Nombre corto" />                                       
                    <Column dataField="tipo" caption="Tipo ente" />
                    <Column dataField="sector" caption="Sector" />
                    <Column dataField="administrador" caption="Administrador" />

                    <Column dataField="estatus" width={120} caption={lang.getString("catalogo.caption.estatus")} >
                        <Lookup dataSource={ESTATUS_SOURCE_CONFIG} valueExpr="id" displayExpr="name"/>
                    </Column>
                    <Column dataField="fecha_creacion" caption={lang.getString("catalogo.caption.registro_creacion_fecha")} />
                    <Column dataField="fecha_modificacion" caption={lang.getString("catalogo.caption.registro_modificacion_fecha")} />
                </DataGrid>
                </Item>
            </Box>

            <PopUpEntidadInfo
                isPopUpInfoOpen={isPopUpInfoOpen}
                setIsPopUpInfoOpen={setIsPopUpInfoOpen}
                entidadInfo={entidadInfo}
                titulo={"Ente público"}/>
            </div>
            
            </div>
        </React.Fragment>
    );
    //#endregion
}