import React, { useState,useEffect,useRef } from 'react';
import { useHistory } from 'react-router-dom';
import TreeList, { Column,Selection,Paging,Pager,ColumnChooser } from 'devextreme-react/tree-list';
import Form, {
    ButtonItem,
    GroupItem,
    Item,
    Label
  } from 'devextreme-react/form';
import { LoadPanel } from 'devextreme-react/load-panel';
import 'devextreme-react/autocomplete';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import AlertDialog from 'components/alertDialog/alertDialog'
import {getGrupoPermisoById, postGrupoPermiso, getPermisos } from 'api';
//const usuarioId = 1; //TODO: quitar esta variable por que se debe obtener del props
const position = { of: '#usuario' }; //TODO: CSS - Se utiliza para posicionar el panel de loading

export default function (props){
    //#region hook de control
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    
    //#endregion
    const [grupo,setGrupo] = useStateWithCallbackLazy({
        "id":null,
        "grupo_nombre":null,
        "grupoPermiso":[],
    });
    const [permisosLista,setPermisosLista] = useStateWithCallbackLazy({});
    const treeListGrupoRef = useRef(null);
    //#region hook de montado de la vista, se utiliza para generar el estado inicial
    useEffect(() => {
        let mounted = true; 
        async function fetchData(){
            if (mounted) { 
                //aqui se manda a llamar los fetchs iniciales
                if(props.location.state && props.location.state.id){
                    setGrupo(await fetchGrupo(props.location.state.id));
                }
                setPermisosLista(await fetchPermisosLista());
                setIsLoading(false);
            }
        }
        fetchData();
        return () => { mounted = false; };
      },[]); 
    //#endregion

    //#region fetch
    const fetchGrupo = (async(grupoId) =>{
        let result = {...grupo};
        if(grupoId){
            const {data:{error,message,data}} = await getGrupoPermisoById(grupoId);
            if(error){
                AlertDialog({message: message});
            }else if(data.length){
                result.id = data[0].grupo_id;
                result.grupo_nombre = data[0].grupo_nombre;
                result.grupoPermiso = castFromPermisos(data[0].permisos);
            }
        }
        return result;
    })

    const fetchPermisosLista = (async () =>{
        let result = [];
        const {data:{error,message,data}} = await getPermisos();
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    })
    ////#endregion

    //#region eventos CRUD
    const handleSubmit = (async (e) => {
        e.preventDefault();
        setIsLoading(true);
        
        let newGrupo = {...grupo};
        let resultGrupoPermiso =  castToPermisos(newGrupo.grupoPermiso)
        newGrupo.grupoPermiso = resultGrupoPermiso;
        
        const {data:{error,message}} = await postGrupoPermiso(newGrupo);
        if(error){
            AlertDialog({message: message});
        }
        else{
            //Navegacion
            history.push({pathname:'/admin/grupoPermisos'});
        }
        setIsLoading(false);

    });
    //#endregion

    const validationRules ={ 
        descripcion:[
            {type:"required",message:"Obligatorio"}
        ]
    }

    //#region acciones de interfaz
    const castFromPermisos = (permisos) =>{
        let result = [];
        if(permisos)
            for(let i = 0; i < permisos.length; i++){
                result.push(permisos[i].permiso_id);
            }
        return result;
    }

    const castToPermisos = (permisosIds) =>{
        let result = [];
        for(let i = 0; i < permisosIds.length; i++){
            result.push({permiso_id: permisosIds[i]});
        }
        return result;
    }

    const onSelectionChangedPermisosGrupo = (e) =>{
        if(treeListGrupoRef.current)
       { 
           let result = []
            result = treeListGrupoRef.current.instance.getSelectedRowKeys('all');
            result = result.filter(function(item, pos) { //TODO: se quitan lo elementos duplicados no sabemos por que al cargar la referencia te trae el doble de elementos iniciales
                return result.indexOf(item) == pos;
            })
            setGrupo({...grupo,grupoPermiso:result})
        }
    }

    const goToList = () =>{
        history.push({pathname:'/admin/grupoPermisos'});
    }
    //#endregion
    

    //#region render
    return (
        <React.Fragment >
            <h2 className={'content-block'}>Grupo de permisos</h2>
            <div id="usuario" className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                <form onSubmit={handleSubmit}>
                    <Form
                       formData={grupo}
                       readOnly={false}
                       showColonAfterLabel={true}
                       showValidationSummary={true}
                       validationGroup="usuarioData"
                    >
                        <Item 
                                colSpan={4}
                                dataField="grupo_nombre"
                                stylingMode="underlined"
                                editorType="dxTextBox"
                                editorOptions={{
                                    maxLength:250, 
                                }}
                                validationRules={validationRules.descripcion}
                            >
                                <Label text='Nombre'></Label>
                        </Item>
                        <GroupItem >
                        
                        <TreeList
                            dataSource={permisosLista}
                            showBorders={true}
                            ref={treeListGrupoRef}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            selectedRowKeys={grupo.grupoPermiso}
                            keyExpr="permiso_id"
                            parentIdExpr="padre_id"
                            onSelectionChanged={(e) => onSelectionChangedPermisosGrupo(e)}
                            id="permisosLista"
                        >   
                                <Paging enabled={true} defaultPageSize={1000}/>
                                <Pager showPageSizeSelector={true} recursive={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                                <ColumnChooser enabled={false} />  
                                <Selection recursive = {true}mode="multiple" />
                                <Column dataField="permiso_modulo" width={300} />
                                <Column dataField="permiso_elemento" width={300} />
                                <Column dataField="permiso_tipo" width={300} />
                                <Column dataField="permiso_clave" width={300} />
                                <Column dataField="permiso_desrcipcion" width={300} />
                            </TreeList>
                        </GroupItem>
                        <GroupItem colSpan={12}>
                            <ButtonItem horizontalAlignment="left" colSpan={2}
                                buttonOptions={{text:'Guardar',type:'success',useSubmitBehavior:true}}
                            />
                            <ButtonItem horizontalAlignment="left" colSpan={10}
                                buttonOptions={{text:'regresar',type:'default',onClick:()=>{goToList()}}}
                            />
                        </GroupItem>
                    </Form>
                </form>
            </div>
            <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={isLoading}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
        </div>
        </React.Fragment>
        );
    //#endregion
}