import React,{useEffect} from 'react';
import './modalBitacora.scss';

export default function(props){

    useEffect(() => {
        let mounted = true; 
        async function fetchData(){
            if (mounted) {
                console.log('props',props) 
            }
        }
        fetchData();
        return () => { mounted = false; };
      },[]); 

    return(
        <div  className="blockCode">
            <pre>
                <code>
                    {props.data}
                </code>
            </pre>
        </div>
    )
}