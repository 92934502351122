import React ,{useState, useRef} from 'react';
import { DataGrid, Column,Editing,MasterDetail } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import AlertDialog from 'components/alertDialog/alertDialog'
import {
    getEnteUnidadesAdmnistrativas,
    postAddUnidadAdministrativaEnte,
    postBlockUnidadAdministrativa,
    deleteEventoUnidadAdministrativa,
    handleDownloadPlantilla,
    getEventoAnexoArchivo
} from 'api';
import {
  STRING_ALERTDIALOG_CREATE_SUCCESS
} from 'constants/defaultValues';
import CustomPopUp from 'components/custompopup';
import UnidadesAdministrativasModal from './unidadAdministrativaModal.js';
import PuestoUnidadAdministrativaDetail from './PuestoUnidadAdministrativaDetail.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import AuthCheck from '../../../components/authCheck/authCheck.js';
import ReviewersModal from './reviewersModal.js';
import swal from 'sweetalert2';

const position = { of: '#masterDetail' };

  const SomeComponent = (props) =>{

    function IconRenderActaFirmada(data){
      let flag = permActaTitular ? true : false;
      return <div key={`dgrid_unidad_administrativa_acta_firmada_download_${data.data.id_unidad_administrativa}`}>
          {
              !flag ? null : !data.data.downloading_actasFirmadas ?
                     (<i style={{fontSize:"18px",cursor:"pointer"}} onClick={()=>handleDownloadActas(data)} class="far fa-file-pdf"></i>)
                     : <div className={"dx-link dx-link-icon"}><LoadIndicator  height={20} width={20}  /></div>
                      
          }
      </div>
  }

    const dataGridRef = useRef();
    const [openModal, setOpenModal] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [enteBloqueado] = useState(props.data.data.bloqueo)
    const [openReviewersModal, setOpenReviewersModal] = useState(false);

    const permBloquear    = AuthCheck({permission: "EVENT_UNIAD_BLOQUEAR", onlyValidator: true});
    const permActaTitular = AuthCheck({permission: "EVENT_UNIAD_ACTA_TITULAR", onlyValidator: true});
    const permDelete      = AuthCheck({permission: "EVENT_UNIAD_DELETE", onlyValidator: true});
    const permAnexo       = AuthCheck({permission: "EVENT_UNIAD_ANEXO", onlyValidator: true});

    const dataRef = useRef(null);

    const fetchData = (async () => {
        let parameters ={id_ente_publico:props.data.data.id,id_evento:props.data.data.id_evento};
        let result = [];
        const { data: { error, message, data } } = await getEnteUnidadesAdmnistrativas(parameters);
        if (error) {
          AlertDialog({ message: message });
        } else if (data.length) {
          result = data;
        }
        return result;
      })

      const dataSource = new DataSource({
        store: new CustomStore({
          key: ["id_unidad_administrativa","id_evento","id_ente_publico"],
          load: async (loadOptions) => {
            if(dataRef.current === null)
                dataRef.current = (await fetchData()).map(item=>{item.downloading_anexo=0; item.downloading_actasFirmadas = 0; return item});
                return dataRef.current;
          },
          onLoaded: async () =>{
            if(localStorage.getItem("SEER_UNID_CLV") != null){
              let unidadAdminKey = JSON.parse(localStorage.getItem("SEER_UNID_CLV"));
              dataGridRef.current && dataGridRef.current.instance.expandRow(unidadAdminKey);
            }
          },
          remove:async(key)=>{
            const {data:{error,message}} = await deleteEventoUnidadAdministrativa(key);
                if(error) AlertDialog({message});
                else AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true });

                dataRef.current = null;
          }
        })
      });

      const handleBlockUnidadAdministrativa = async(unidad,bloqueo)=>{
        AlertDialog({showCancelButton:true,message: bloqueo === 0 ? "¿Seguro que desea des-bloquear esta unidad administrativa?" : "¿Seguro que desea bloquear esta unidad administrativa?",isNotification:false, type:"warning",onActionCallback:async(resp)=>{
          if(resp.value){
            setIsLoading(true);
            let params = {
              id_unidad_administrativa:unidad.id_unidad_administrativa,
              id_evento:unidad.id_evento,
              id_ente_publico:unidad.id_ente_publico,
              bloqueo
            };
            const {data:{error,message}} = await postBlockUnidadAdministrativa(params);
            setIsLoading(false);
                if(error) AlertDialog({message});
                else AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true });

            dataRef.current = null;
                
          }
      }});
      }

      const handleOpenReviewersModal = () =>{
        setOpenReviewersModal(!openReviewersModal);
      }

      const handleDownloadActas=async(data)=>{
        //let files = ["00963cfa-6058-4e99-ab57-0aaf11831de2","00963cfa-6058-4e99-ab57-0aaf11831de2"];
        dataGridRef.current.instance.getDataSource().store().push(
          [{
              type: 'update',
              key: data.key,
              data: {
                  ...data.data, downloading_actasFirmadas: 1
              }
          }]
      );
        let files = data.data.actas_firmadas;
        await handleDownloadPlantilla({uid:files},"actas.zip");

        dataGridRef.current.instance.getDataSource().store().push(
          [{
              type: 'update',
              key: data.key,
              data: {
                  ...data.data, downloading_actasFirmadas: 0
              }
          }]
      );
      }

      const handleDownloadAnexos=async(anexos)=>{
        console.log('xxxds',anexos)
        swal.fire({
          title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
          text: "Por favor espere.",
          imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
          showConfirmButton: false,
          allowOutsideClick: false
        });
        let progress = 0;
        setTimeout(() => {
          if(progress===0){
              swal.update({
                  html: "Recopilando información...",
                  title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                  //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                  showConfirmButton: false,
                  allowOutsideClick: false
              });
              swal.showLoading();
              setTimeout(() => {
                  if(progress===0){
                      swal.update({
                          html: "Creando archivo comprimido...",
                          title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                          //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                          showConfirmButton: false,
                          allowOutsideClick: false
                      });
                      swal.showLoading();
                  }
              },2000);
          }
      }, 200);
    
      const {data:{data, message, error}} = await getEventoAnexoArchivo({
        eventoId : props.data.data.id_evento,
          enteId : props.data.data.id,
          id_unidad_administrativa : anexos.data.id_unidad_administrativa
      },
        (pregress)=>{ 
            progress = pregress;
            console.log('Descargando archivo...'+pregress+"%");
        swal.update({
                html: 'Descargando archivo...'+pregress+"%",
                title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                showConfirmButton: false,
                allowOutsideClick: false
            });
            swal.showLoading();
        });
        if(error){
          AlertDialog({message});
          }else{
              swal.fire({
                  title: `<span style='font-size:30px;font-weight:bold'>`+  "Descarga finalizada" +`</span>`,
                  showConfirmButton: false,
                  icon:'success',
                  timer: 1000
              });
          }
      }

      const handelAddUnidadAdministrativa = async (selectedRowsData, gridModalRef)=>{
        setIsLoading(true);
        let params = {
          id_evento:props.data.data.id_evento,
          id_ente_publico:props.data.data.id,
          unidades:selectedRowsData
        };
        const {data:{error,message}} = await postAddUnidadAdministrativaEnte(params);
        setIsLoading(false);
            if(error) AlertDialog({message});
            else {
                AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true,
                    onWillClose: () => {
                      
                        gridModalRef.current && gridModalRef.current.instance.clearSelection();
                        
                    } 
                });
            }
        dataRef.current = null;
        setOpenModal(false)
    }

    const closeModal=()=>{
      setOpenModal(false);
      dataSource.load();
    }
    

      return (
        <React.Fragment id="UnidadAdministrativaDetail">
            {openModal && 
                <CustomPopUp height={"85%"} width={"85%"} visible={true} onClose={()=>closeModal()} title={"Seleccion de ente público"}>
                    <UnidadesAdministrativasModal id_ente={props.data.data.id} id_evento={props.data.data.id_evento} onAccept={(selectedRowsData,gridModalRef)=>{handelAddUnidadAdministrativa(selectedRowsData, gridModalRef)}}/>
                </CustomPopUp>
            }
            {openReviewersModal && 
                <CustomPopUp height={"85%"} width={"85%"} visible={true} onClose={()=>handleOpenReviewersModal && handleOpenReviewersModal()} title={"ASIGNAR REVISORES"}>
                    <ReviewersModal 
                      eventoId={props.data.key.id_evento}
                      entePublicoId={props.data.data.id}
                      //unidadAdministrativaId={props.data.key.id_unidad_administrativa}
                      revisorInterno={true}
                      onAccept={(selectedRowsData,gridModalRef)=>{handleOpenReviewersModal()}}/>
                </CustomPopUp>
            }

          <div className="master-detail-caption" style={{marginBottom:"20px"}}>     
            {
              AuthCheck({
                permission:"EVENT_UNIAD_ADD",
                component:
                <div style={{marginRight: "7px",  display:"inline-block"}}>
                    <Button
                      icon="plus"
                      type="default"
                      text="Agregar unidad administrativa"
                      disabled={enteBloqueado}
                      onClick={(e)=>{setOpenModal(!openModal)}}
                  />  
                </div>       
              })
            }
            {
              AuthCheck({
                permission:"EVENT_PUESTO_REVISORINTERNO",
                component:  
                <div style={{marginRight: "7px", display:"inline-block"}}>
                  <Button
                      type="default"
                      text="Asignar revisor interno"
                      //disabled={enteBloqueado}
                      onClick={(e)=>{handleOpenReviewersModal()}}
                  />   
                </div>  
                            
              })
            }                      
          </div>
          <DataGrid
            ref={dataGridRef}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            rowAlternationEnabled={true}
            onRowExpanding={ async (e)=>{
              localStorage.setItem("SEER_UNID_CLV",JSON.stringify(e.key));
              e.component.collapseAll(-1);
          }}
          >
              <Editing
                mode="row"
                useIcons={true}
                allowDeleting={true}
                />
                <MasterDetail
                    enabled={true}
                    component={PuestoUnidadAdministrativaDetail}
                /> 
            <Column type="buttons" width={110}
            buttons={[
                { hint: "Bloquear", icon: 'fas fa-unlock', visible:(e)=> !e.row.data.bloqueo && permBloquear, onClick:(e)=>handleBlockUnidadAdministrativa(e.row.data,1) }, //estatus 1 es bloqueado en BD
                { hint: "Desbloquear", icon: 'fas fa-lock', visible:(e)=> e.row.data.bloqueo && permBloquear, onClick:(e)=>handleBlockUnidadAdministrativa(e.row.data,0) }, //estatus 0 es desbloqueado en BD
                { hint: 'Eliminar', icon: 'fas fa-trash-alt', name: 'delete', visible: !enteBloqueado && permDelete}
            ]}/>
            <Column dataField="unidad_administrativa" width={500} />
            <Column dataField="id_evento" width={500} visible={false} />
            <Column dataField="id_ente_publico" width={500} visible={false} />
            <Column dataField="puestos_eventos" caption="Total de puestos" width={150} allowFiltering={false} allowSorting={false}/>
            <Column dataField="porcentaje_avance_captura" caption="% Avance captura" width={150} allowFiltering={false} allowSorting={false}/>
            <Column dataField="puesto_entregas_terminadas_porcentajes" caption="% Entregas terminadas" width={150} allowFiltering={false} allowSorting={false}/>
            <Column dataField="total_anexos_revisados" caption="Total anexos revisados" width={150} allowFiltering={false} allowSorting={false}/>
            <Column dataField="downloading_actasFirmadas" caption="Actas Firmadas" alignment="center" width={150} cellRender={IconRenderActaFirmada} />
            { 
              permAnexo ?
              <Column dataField="downloading" caption="Anexos" visible={true} alignment="center" width={150} cellRender={(e)=>{
                return <div key={`dgrid_unidad_administrativa_anexo_download_${e.data.id_unidad_administrativa}`}  >
                  {
                      //!e.data.anexos_firmados ? null:
                      !e.data.downloading? (<i style={{fontSize:"18px",cursor:"pointer"}} onClick={()=>handleDownloadAnexos(e)} class="far fa-file-pdf"></i>):
                      <div className={"dx-link dx-link-icon"}><LoadIndicator  height={20} width={20}  /></div>
                  }
                </div>
              } 
              } />
              :null
          }
            <Column dataField="bloqueo" dataType="boolean" caption="Bloqueo" alignment="center" width={150}/>
          </DataGrid>
          <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={position}
                visible={isLoading}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false}
                />
        </React.Fragment>
      );
  }


  export default React.memo(SomeComponent)