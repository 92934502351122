import React,{ useRef, useEffect} from 'react';
import { Button } from 'devextreme-react/button';
import DataGrid, { Column, Editing, Pager, Paging, FilterRow,Export} from 'devextreme-react/data-grid';
import Box, {
  Item
} from 'devextreme-react/box';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import AlertDialog from 'components/alertDialog/alertDialog';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import {
  getEntregaRealizadaPuesto,
  getEventoBySearchTerm,
  handleDownloadPlantilla,
  getEventoAnexoArchivo
} from 'api';
import { DEFAULT_PAGE_SIZE_ONE_GRID, DEFAULT_PAGE_SIZES, DEFAULT_PAGE_SIZE_CMB } from 'constants/defaultValues';
import { getDataSourceFilter, getDataSourceSort, descargarArchivo} from 'utils/utility';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import swal from 'sweetalert2';
import Form, {
    GroupItem,
    Label
  } from 'devextreme-react/form';

  function labelCellRender(cellData){
    
    let nota = cellData.data.termino_nota;
    return(
      <div>
        {nota !== null ? <Button icon="info" onClick={()=>{AlertDialog({message:nota, title:'Razon de inconformidad', type:'info'})}}/> : null} 
       </div>
    )
}

  export default function () {
    function IconRenderanexos(data){
      return <div key={`dgrid_eventodetails_actas_download_${data.data.id}`}>
          {
            !data.data.downloading ?
              (<i className="dx-link dx-icon-file dx-link-icon" title="Descargar archivo" style={{fontSize:'22px',textDecoration:'unset'}} onClick={()=>{handleDownloadAnexos(data.data)}}></i>)
                : <div className={"dx-link dx-link-icon"}><LoadIndicator  height={20} width={20}  /></div>
                      
          }
      </div>
  }

  const handleDownloadAnexoss=async(data)=>{
    let files  = data.anexos_uuids;
    if(files.length){
      await handleDownloadPlantilla({uid:files},`anexos_${data.ente}_${data.puesto}.zip`);
    }
  }


    //#region hook de control
        const formRef = useRef();
    //#endregion
    //#region hook de estado
    const [evento,setEvento] = useStateWithCallbackLazy({
        "evento_id":null,
  })
  //#endregion
   //#region carga de datos iniciales 
   useEffect(() => {
    let mounted = true; 
    async function fetchData(){
        if (mounted) { 
        }
    }
    fetchData();
    return () => { mounted = false; };
  },[]); 
  //#endregion

  //#region fetch
  const fetchData = (async (take, skip, filter, sort) => {
    let result = [];
    let eventos = {...evento};
    let evento_id = eventos.evento_id;

    const { data: { error, message, data } } = await getEntregaRealizadaPuesto({take, skip, filter, sort, evento_id,});
    if (error) {
      AlertDialog({ message: message });
    } else if (data.length) {
      result = data;
    }
    return result;
  });

  const handleDownloadAnexos=async(dato)=>{
    console.log('handleDownloadAnexos', dato);
    swal.fire({
        title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
        text: "Por favor espere.",
        //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
        showConfirmButton: false,
        allowOutsideClick: false
    });
    swal.showLoading();
    let progress = 0;
    setTimeout(() => {
        if(progress===0){
            swal.update({
                html: "Recopilando información...",
                title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                showConfirmButton: false,
                allowOutsideClick: false
            });
            setTimeout(() => {
                if(progress===0){
                    swal.update({
                        html: "Creando archivo comprimido. Esto puede tardar varios minutos...",
                        title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                        //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                        showConfirmButton: false,
                        allowOutsideClick: false
                    });
                }
            },2000);
        }
    }, 200);

    const {data:{data, message, error}} = await getEventoAnexoArchivo({evento_puesto_id: dato.id, puesto_id:dato.id_puesto, id_unidad_administrativa:dato.id_unidad_administrativa},
        (pregress)=>{ 
            progress = pregress;
            console.log('Descargando archivo...'+pregress+"%");
        swal.update({
                html: 'Descargando archivo...'+pregress+"%",
                title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                showConfirmButton: false,
                allowOutsideClick: false
            });
        });
    if(error){
        AlertDialog({message});
    }else{
        swal.fire({
            title: `<span style='font-size:30px;font-weight:bold'>`+  "Descarga finalizada" +`</span>`,
            showConfirmButton: false,
            icon:'success',
            timer: 1000
        });
    }
    return;
  }
  //#endregion

  //#region dataSource
  const dataSource = new DataSource({
    pageSize: 10,
    store: new CustomStore({
      key: "id",
      load: async(loadOptions) => {
        return (await fetchData(loadOptions.take, loadOptions.skip, getDataSourceFilter(loadOptions), getDataSourceSort(loadOptions))).map(item=>{item.downloading=0;return item})

      }
    })
  });

  const generarDataSource = (getFunc)=>{
    const fetchFunc = async ({seachterm = ''}, take = 1, skip = 0, ) =>{
      let result = []
      const grupoUsuario = parseInt(localStorage.getItem('grupo_id'));
      const {data:{error,message,data}} = await getFunc(seachterm, take, skip, grupoUsuario);
      if(error){
          AlertDialog({message});
      }else if(data.length) {
          result = data;
      }
      return result;
    }

    const ds = new DataSource({
      pageSize: DEFAULT_PAGE_SIZE_CMB,
      store: new CustomStore({
          key:"id",
          load: (loadOptions) => {
              if(loadOptions.searchValue)
                  return fetchFunc({seachterm:loadOptions.searchValue}, loadOptions.take, loadOptions.skip);     
              else 
                  return fetchFunc({}, loadOptions.take, loadOptions.skip);             
          },
          byKey: (key) => {
              return key;
          }
        })
    });
    return ds;
  }
  
  const eventoDatasource = generarDataSource(getEventoBySearchTerm);
  //#endregion

  //#region eventos interfaz
  const searchFilter = () =>{
    dataSource.reload();
  }

  const cleanFilter = async() =>{
      formRef.current.instance.updateData('evento_id',null);
      dataSource.reload();
    
  }
  //#endregion

  //#region render
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Entrega realizada - Nivel puesto</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
            <form>
                <Form
                    ref={formRef}
                    formData={evento}
                    readOnly={false}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                    validationGroup="eventoData"
                >
                    <GroupItem colCount={8}>
                      <Item
                        colSpan={3}
                        dataField="evento_id"
                        editorType="dxSelectBox"
                        editorOptions={{
                            valueExpr:"id",
                            searchEnabled:true,
                            searchMode:"contains",
                            displayExpr: (item) => item && item.nombre,                     
                            searchExpr:(item) => item && item.nombre,
                            dataSource: eventoDatasource,
                        }}
                      >
                        <Label text={"Evento"}/>
                      </Item>
                        <Item colSpan={4}>
                        <Button text={"Buscar"} type="success" onClick={()=>{searchFilter()}}/>
                        <Button style={{marginLeft:"5px"}}  text={"Limpiar"} type="default" onClick={()=>{cleanFilter()}}/>
                        </Item>
                    </GroupItem>
                </Form>
            </form>
        
          <Box width="100%" direction="row">
            <Item ratio={12}>
              <DataGrid 
                key="id"
                onRowPrepared={(e)=>{if('data' in e) e.rowElement.title = e.data.nombre_puesto}}
                rowAlternationEnabled={true}
                dataSource={dataSource}
                rowAlternationEnabled={true}                
                showBorders={true}
                columnAutoWidth={true}
                width={"100%"}>
                <FilterRow visible={true} showOperationChooser={false}/>
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                        <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                        showInfo={true} />
                <Editing
                  mode="row"
                  useIcons={false}
                  allowDeleting={false}
                  allowUpdating={false}
                >
                  </Editing>
                  <Export enabled={true} allowExportSelectedData={false} fileName={'Entrega realizada - Puesto'}/>
                <Column dataField = "ente" caption="Ente"/>
                <Column dataField = "unidad_administrativa" caption="Unidad administrativa" alignment={'center'}/>
                <Column dataField = "puesto" caption="Puestos" alignment={'center'}/>


                 <Column dataField = "estatus_entrega_recepcion" caption="Estatus Entrega recepcion:" alignment={'center'}/>
                <Column dataField = "termino_nota" caption="Razon de inconformidad" alignment={'center'} cellRender={labelCellRender}/>

                <Column dataField = "ciere_automatico" caption="Cierre Automático Sistema" alignment={'center'} dataType={'boolean'}/>


                <Column dataField = "anexos_aplicables" caption="Anexo aplicable" alignment={'center'}/>

                <Column dataField = "anexos_observaciones_pendientes" caption="Anexos observados no atendidos" alignment={'center'}/>

                <Column dataField = "fecha_firma" caption="Fecha firma acta" alignment={'center'}/>
                <Column dataField = "fecha_observacion" caption="Fecha ultima observacion" alignment={'center'}/>

                <Column dataField = "fecha_cierre" caption="Fecha limite de cierre entrega recepción" alignment={'center'}/>
                <Column dataField = "fecha_cierre_real" caption="Fecha real de cierre entrega recepción" alignment={'center'}/>

                <Column dataField = "anexos_en_proceso" caption="Anexos en proceso" alignment={'center'}/>
                <Column dataField = "anexo_observaciones_pendientes" caption="Anexos Observaciones pendientes" alignment={'center'}/>
                <Column dataField = "anexo_observaciones_atendidas" caption="Anexos Observaciones atendidas" alignment={'center'}/>
                
                <Column dataField = "clave_puesto" caption="Puesto clave" alignment={'center'}/>

                <Column dataField = "titular" caption="Titular" alignment={'center'}/>
                <Column dataField = "jerarquia" caption="Jerarquia" alignment={'center'}/>
                <Column dataField = "funcionario_saliente" caption="Funcionario saliente" alignment={'center'}/>
                <Column dataField = "correo_saliente" caption="Correo saliente" alignment={'center'}/>
                <Column dataField = "funcionario_entrante" caption="Funcionario entrante" alignment={'center'}/>
                <Column dataField = "downloading" caption="Anexos" alignment={'center'} cellRender={IconRenderanexos}/>
                <Column dataField = "estatus" caption="Estatus seguimiento" alignment={'center'}/>
              </DataGrid>
            </Item>
          </Box>
        </div>
      </div>
    </React.Fragment>
  );
  }