import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import './dx-styles.scss';
import './menu.scss';
import './scrollStyles.scss';
import React from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { RoutesProvider } from './contexts/routes';
import { GlobalProvider, useGlobal } from './contexts/global';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import NotAuthenticatedContent from './NotAuthenticatedContent';
import Verify from './route/layout/verify'
import utils from "utils/session";

function App() {
  const { loading } = useGlobal();
  if (loading) {
    return <LoadPanel visible={true} />;
  }
  return utils.doesHttpOnlyCookieExist()? 
    (<Content/>) : (<Redirect to={'/login'} />)
  
}

const AppWrapper = function () {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <GlobalProvider>
        <RoutesProvider>
        <NavigationProvider>
        <div className={`app ${screenSizeClass}`}>
          <Switch>
            <Route key={'r_l_admin'} path={'/admin'} component={()=><App />}/>
            <Route key={'r_l_catalogos'} path={'/catalogos'} component={()=><App />}/>
            <Route key={'r_l_eventos'} path={'/eventos'} component={()=><App />}/>
            <Route key={'r_l_consultas'} path={'/consultas'} component={()=><App />}/>
            <Route key={'r_l_verify'} path={'/verify'} component={()=><Verify />}/>
            <Route key={'r_l_login'} path={'/'} component={()=> utils.doesHttpOnlyCookieExist()? <Redirect to={'/admin'} />:<NotAuthenticatedContent/>}/>
            <Redirect to={'/admin/home'} />
          </Switch>
          </div>
        </NavigationProvider>
        </RoutesProvider>
        
      </GlobalProvider>
    </Router>
  );
}

export default AppWrapper;