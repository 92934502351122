import React, { useState,useEffect } from 'react';
import DataGrid, { Column, Editing, Pager, Paging, RequiredRule,Texts, StringLengthRule } from 'devextreme-react/data-grid';
import Form, {
    ButtonItem,
    GroupItem,
    Item,
    Label
  } from 'devextreme-react/form';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import 'devextreme-react/autocomplete';
import AlertDialog from 'components/alertDialog/alertDialog'
import {getDiasAsueto, postDiaAsueto, deleteDiaAsueto, getDiasHabiles, postDiasHabiles} from 'api';
import { LoadPanel } from 'devextreme-react/load-panel';
import {getDataSourceFilter, getDataSourceSort} from '../../../utils/utility';
import moment from 'moment';
import {
    STRING_ALERTDIALOG_CREATE_SUCCESS,
    STRING_DATAGRID_CONFIRM_DELETE_TITLE,
    STRING_DATAGRID_CONFIRM_DELETE_MESSAGE,
    STRING_DATAGRID_ADD_ROW,
    DATASOURCE_DIAS_HABILES_ANO
} from '../../../constants/defaultValues.js';

const position = { of: '#delegacion' }; //TODO: CSS - Se utiliza para posicionar el panel de loading

export default function (props) {
    //#region hook de control
    const [isLoading, setIsLoading] = useState(false);
    //#endregion

    //#region hook de estado
    const [diaLaboral,setDiaLaboral] = useState({
        "ano": new Date().getFullYear(),
        "domingo" : false,
        "lunes" : false,
        "martes": false,
        "miercoles":false,
        "jueves": false,
        "viernes": false,
        "sabado" : false,
        "tamanoArchivoNombre":null,
        "tamanoArchivo":null
    });
    //#endregion

    //#region hook de montado de la vista
    useEffect(() => {
        let mounted = true; 
        async function fetchData(){
            if (mounted) { 
                //aqui se manda a llamar los fetchs iniciales
                setDiaLaboral(await fetchDiaLaboral());
                setIsLoading(false);
            }
        }
        fetchData();
        return () => { mounted = false; };
      },[]); 
    //#endregion

    //#region fetch
    const fetchDiaAsueto = async (take=0, skip=0, filter={}, sort={})=>{
        let result= {data:[], totalCount:0};
        let ano = diaLaboral.ano;
        const {data:{error,message,data}} = await getDiasAsueto(take, skip, filter, sort, ano);
        if(error){
            AlertDialog({message: message});
        }else if(data.totalCount)
            result = data;
        
        return result;
    }

    const fetchDiaLaboral = async()=>{
        let result = {...diaLaboral};
        const {data:{error,message,data}} = await getDiasHabiles();
        if(error){
            AlertDialog({message: message});
        }else if(data.length){
            let dato = data[0].row;
            let datoArchivos = data[1].row;
            console.log('datoArchivos', datoArchivos);
            result.domingo = dato.domingo;
            result.lunes = dato.lunes;
            result.martes = dato.martes;
            result.miercoles = dato.miercoles;
            result.jueves = dato.jueves;
            result.viernes = dato.viernes;
            result.sabado = dato.sabado;
            result.tamanoArchivoNombre = datoArchivos.tamanoNombre;
            result.tamanoArchivo = datoArchivos.tamano;
        }
        return result;
    }

    const saveDiaAsueto = async (values, key = null, ano = diaLaboral.ano )=>{ 
        let result = [];
        if(values.fecha_inicio){
            let date = new Date(values.fecha_inicio);
            let day = date.getDate();
            let month = date.getMonth();
            values.fecha_inicio  = new Date(ano, month, day);
        }
        if(values.fecha_final){
            let date = new Date(values.fecha_final);
            let day = date.getDate();
            let month = date.getMonth();
            values.fecha_final  = new Date(ano, month, day);
        }
        const {data:{error,message,data}} = await postDiaAsueto(values, key, ano);
        if(error)
            AlertDialog({message});
        else if(data.length)
        {
            result = data;
            AlertDialog({message:STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true})
        }
            
        return result;
    }

    const wipeOutDiaAsueto = async (key)=>{
        let result = [];
        const {data:{error,message,data}} =  await deleteDiaAsueto(key);
        if(error)
            AlertDialog({message});
        else if(data.length)
            result = data;
        return result;
    }
    //#endregion fetch

    //#region dataSource
    const diasLaboralesDataSource = new DataSource({
        store: new CustomStore({
            key: "id",
            load: (loadOptions) => {               
                return fetchDiaAsueto(loadOptions.take, loadOptions.skip, getDataSourceFilter(loadOptions), getDataSourceSort(loadOptions));             
            },
            insert: (values) => {
                return saveDiaAsueto(values);
            },
            update: (key, values) => {
                return saveDiaAsueto(values, key);
            },
            remove: (key) => {
                return wipeOutDiaAsueto(key);
            }
        })
    });
    //#endregion datasource

    //#region eventos interfaz
    const onValueChangeAno =()=>{
        diasLaboralesDataSource.reload();
    }
    //#endregion

    //#region eventos CRUD
    const handleSubmit = (async(e) => {
        e.preventDefault();
        setIsLoading(true);
        let values = {...diaLaboral};
        let valor = {
            "lunes": values.lunes, 
            "martes": values.martes, 
            "miercoles": values.miercoles,
            "jueves": values.jueves,
            "viernes": values.viernes,
            "sabado": values.sabado,
            "domingo": values.domingo,
            "ano": values.ano,
        };
        let valorArchivo ={
            "tamano":values.tamanoArchivo,
            "tamanoNombre":values.tamanoArchivoNombre
        };
        console.log('valorArchivo guardar', valorArchivo)
        const {data:{error,message}} = await postDiasHabiles(valor, valorArchivo);
        if(error){
            AlertDialog({message});
             setIsLoading(false);
        } else {
            AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true});
            setIsLoading(false);
        }
    });
    //#endregion

    const validationRules ={ 
        archivo:[
            {type:"required",message:"Tamaño de carga maximo es obligatorio"},
        ],
        nombre:[
            {type:"required",message:"Tamaño de nombre maximo"},
        ],
    }

    //#region render
    return (
    <React.Fragment >
            <h2 className={'content-block'}>Configuración de sistema</h2>
            <div id="diasLaborales" className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                    <form  onSubmit={handleSubmit}>
                        <Form
                            formData={diaLaboral}
                            readOnly={false}
                            showColonAfterLabel={true}
                            showValidationSummary={true}
                        >
                            <GroupItem caption="Carga de archivos" colCount={12}>
                                <Item 
                                    colSpan={6}
                                    dataField="tamanoArchivo"
                                    stylingMode="underlined"
                                    editorType="dxNumberBox"
                                    editorOptions={{
                                        format:"#",
                                        step: 0,
                                        min:0,
                                        max:9999 
                                    }}
                                    validationRules={validationRules.archivo}>
                                        <Label text={'Tamaño de carga maximo (mb)'}></Label>
                                </Item>
                                <Item 
                                    colSpan={6}
                                    dataField="tamanoArchivoNombre"
                                    stylingMode="underlined"
                                    stylingMode="underlined"
                                    editorType="dxNumberBox"
                                    editorOptions={{
                                        format:"#",
                                        step: 0,
                                        min:0,
                                        max:9999 
                                    }}
                                    validationRules={validationRules.nombre}>
                                     <Label text={'Tamaño de nombre maximo (Caracteres)'}></Label>
                                </Item>
                            </GroupItem>
                            <GroupItem caption="Dias de asueto" colCount={12}>
                            <Item
                                colSpan={12}
                                dataField="ano"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    valueExpr:"ano",
                                    searchEnabled:true,
                                    searchMode:"contains",
                                    displayExpr: function(item) {
                                        return item && item.ano;      
                                    },                        
                                    searchExpr:function(item) {
                                        return item && item.ano;      
                                    },                 
                                    dataSource:DATASOURCE_DIAS_HABILES_ANO,
                                    onValueChanged:onValueChangeAno
                                }}>
                                <Label text="Año"/>
                            </Item>      
                            </GroupItem>
                            <GroupItem colCount={12}>
                                <DataGrid 
                                    dataSource={diasLaboralesDataSource}
                                    remoteOperations={{paging:true, sorting:true, filtering: true}}
                                    showBorders={true}
                                    width={"100%"}
                                    columnAutoWidth={true}
                                    repaintChangesOnly={true}
                                    rowAlternationEnabled={true}>

                                    <Paging defaultPageSize={5} enabled={true}/>
                                        <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={[5]}
                                        showInfo={true} />
                                    <Editing
                                             mode="row"
                                             useIcons={true}
                                            allowAdding={true}
                                            allowDeleting={true}
                                            allowUpdating={true}
                                    >
                                        <Texts
                                        confirmDeleteTitle={STRING_DATAGRID_CONFIRM_DELETE_TITLE}
                                        confirmDeleteMessage={STRING_DATAGRID_CONFIRM_DELETE_MESSAGE}
                                        addRow={STRING_DATAGRID_ADD_ROW}
                                    />
                                    </Editing>
                                    
                                    <Column type="buttons" width={110}
                                        buttons={[
                                            {hint:'Eliminar',icon:'trash',name:'delete'},
                                            {hint:'Editar',icon:'edit',name:'edit',}
                                        ]} 
                                        
                                    />
                                      <Column dataField="fecha_inicio" caption="Fecha de inicio" dataType="date" format="dd-MM-yyyy" width="250" >
                                        <RequiredRule/>
                                    </Column>
                                    <Column dataField="fecha_final" caption="Fecha final" dataType="date" format="dd-MM-yyyy" width="250" >
                                        <RequiredRule/>
                                    </Column>  
                                    <Column dataField="dia_descripcion" caption="Descripción">
                                        <RequiredRule/>
                                        <StringLengthRule message="Máximo 150 caracteres" max={150} />
                                    </Column>
                                </DataGrid>
                            </GroupItem>
                            <GroupItem caption="Días laborales" colCount={12}>
                                <Item colSpan={3} dataField={"lunes"} className={"dx-field"} editorType="dxCheckBox">
                                    <Label text = "Lunes" location="left"/>
                                </Item>
                                <Item colSpan={3} dataField={"martes"} className={"dx-field"} editorType="dxCheckBox">
                                    <Label text = "Martes" location="left"/>
                                </Item>
                                <Item colSpan={3} dataField={"miercoles"} className={"dx-field"} editorType="dxCheckBox">
                                    <Label text="Miércoles" location="left"/>
                                </Item>
                                <Item colSpan={3} dataField={"jueves"} className={"dx-field"} editorType="dxCheckBox">
                                    <Label text = "Jueves" location="left"/>
                                </Item>
                                <Item colSpan={3} dataField={"viernes"} className={"dx-field"} editorType="dxCheckBox">
                                    <Label text = "Viernes" location="left"/>
                                </Item>
                                <Item colSpan={3} dataField={"sabado"} className={"dx-field"} editorType="dxCheckBox">
                                    <Label text = "Sábado" location="left"/>
                                </Item>
                                <Item colSpan={3} dataField={"domingo"} className={"dx-field"} editorType="dxCheckBox">
                                    <Label text = "Domingo" location="left"/>
                                </Item>
                            </GroupItem>
                            <ButtonItem horizontalAlignment="left"
                            buttonOptions={{text:'Guardar configuración',type:'success',useSubmitBehavior:true, disabled: isLoading}}
                            />
                        </Form>
                    </form>
                </div>
                
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={isLoading}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
    </React.Fragment>
    );
    //#endregion
}
