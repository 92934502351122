import './commentArea.scss';
import React from 'react';
import moment from 'moment';

export default function CommentArea({nombre=null,comentario=null,comentario_revisor=false,fecha=null}){
    const name = nombre ? nombre :'N/A';
    const comment = comentario ?  comentario : 'Sin comentarios'; 
    const petitioner = comentario_revisor ? true : false;
    const date = fecha ? moment(fecha).format('L h:mm:ss a') : moment(new Date()).format('L');

    return(
        <div class={petitioner ? "coment blue" :"comentresponse green"}>
            <div class="row name">
                <h2 class="user_name">{name}  <span className="fas fa-calendar-alt" style={{color:"#C1DBD6",marginRight:"5px",marginLeft:"5px"}}></span>&nbsp;{date}</h2>
            </div>
            <div class="row">
                <p className="comment">{comment}</p>
            </div>
        </div>
      );
}

/*
let comentarios =[
        {
          id: 1,
          name: "Germán Alejandro Velázquez Fajardo",
          petitioner: false,
          comment: "Esto es una prueba de un comentario pequeño. Tiene la finalida de probar como se veria los comentarios.",
          date: new Date() 
        },]

        const dataSource = new ArrayStore({
        key: 'id',
        data: comentarios 
      });

<div className={'dx-card responsive-paddings'}>
                <List
                    dataSource={dataSource}
                    height="100%"
                    width="100%"
                    itemRender={CommentArea}
                    selectionMode={'none'} />
                </div>
*/