import axiossail from './../components/axiossail';

export const instance = axiossail;


//#region EXCEL
export const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//#endregion

//#region DEFAULTS
export const GRUPO_SISTEMA_ADMINISTRADOR = 1;
export const GRUPO_SISTEMA_REVISOR = 2;
export const GRUPO_ENTE_ADMINISTRADOR = 3;
export const GRUPO_ENTE_REVISOR_INTERNO = 4;
export const GRUPO_ENTE_CAPTURA = 5;
export const GRUPO_ENTE_REGISTRO_LIMITADO = 6;
export const GRUPO_ENTE_REVISOR_ENTRANTE = 7;

export const DEFAULT_PAGE_SIZE_CMB = 10;
export const DEFAULT_PAGE_SIZE_GRID = 10;
export const DEFAULT_PAGE_SIZE_ONE_GRID = 10;
export const DEFAULT_PAGE_SIZE_TWO_GRID = 20;
export const DEFAULT_PAGE_SIZE_THREE_GRID = 50;
export const DEFAULT_PAGE_SIZE_FOUR_GRID = 100;
export const DEFAULT_PAGE_SIZES = [DEFAULT_PAGE_SIZE_ONE_GRID, DEFAULT_PAGE_SIZE_TWO_GRID, DEFAULT_PAGE_SIZE_THREE_GRID, DEFAULT_PAGE_SIZE_FOUR_GRID];
//#endregion

//#region Strings de mensajes para el eliminar en el datagrid
export const STRING_DATAGRID_CONFIRM_DELETE_TITLE = 'Eliminar elemento';
export const STRING_DATAGRID_CONFIRM_DELETE_MESSAGE = '¿Está seguro que desea eliminar el elemento?' ;
export const STRING_DATAGRID_ADD_ROW = 'Añadir elemento';
export const STRING_ALERTDIALOG_CREATE_SUCCESS = 'Registro guardado correctamente';

export const STRING_ALERTDIALOG_DAR_BAJA = '¿Esta seguro que quiere dar de baja esta clave catastral?'
export const STRING_ALERTDIALOG_EDICION = '¿Esta seguro que quiere editar esta clave catastral?'
//#endregion 

//#region diseño del datagrid
export const STRING_FORMATO_FECHA_GRID = 'dd/MM/yyyy';//FECHA COMPLETA dd/MM/yyyy hh:mm aa
export const ESTATUS_SOURCE_CONFIG = {
    store: {
        type: 'array',
        data: [
            { id: 1, name: 'Activo'},
            { id: 2, name: 'Cancelado'},
        ],
        key: 'id'
    }
}
export const DATASOURCE_FUNCIONARIO_CAPTURA = {
    store: {
        type: 'array',
        data: [
            { id: 1, nombre: 'Titular'},
            { id: 2, nombre: 'Staff'},
        ],
        key: 'id'
    }
}


//#endregion

//#region PATTERNS
export const PATTERN_NUMERIC_10_2 = /(^[0-9]{1,10})+(\.[0-9]{0,2})?$/;
export const PATTERN_INTEGER_10 = /^[0-9]{1,10}?$/;
export const PATTERN_INTEGER_20 = /^[0-9]{1,20}?$/;
export const PATTERN_INTEGER_3 = /^[0-9]{1,3}?$/;
export const PATTERN_INTEGER_3_2 = /(^[0-9]{1,3})+(\.[0-9]{0,2})?$/;
export const PATTERN_RFC_FISICO = /^([a-zA-Z]{1,4})([0-9]{1,6})([a-zA-Z0-9]{1,3})?$/;
export const PATTERN_RFC = /^([a-zA-Z]{1,4})([0-9]{1,6})([a-zA-Z0-9]{1,3})?$/;
export const PATTERN_RFC_MORAL = /^([a-zA-Z]{1,4})([0-9]{1,6})([a-zA-Z0-9]{1,2})?$/;
export const PATTERN_CURP = /^([a-zA-Z]{1,4})([0-9]{1,6})([a-zA-Z]{1,6})([a-zA-Z0-9]{1,2})?$/;
export const PATTERN_CELULAR = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
export const PATTERN_PHONE = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
//export const PATTERN_CELULAR = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
//export const PATTERN_PHONE = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
export const PATTERN_LESS_THAN_100 = /^([0-9]|[1-9][0-9]|100)$/;
//#endregion

//#region 
export const MASK_CELULAR = { mask: '(000) 000-0000' };
export const MASK_CURP = { mask: 'AAAAAAAAAAAAAAAAAA' ,maskChar: ' '};
export const MASK_RFC_FISICA = { mask: 'AAAAAAAAAAAAA' ,maskChar: ' '};
export const MASK_RFC_MORAL = { mask: 'AAAAAAAAAAAA' ,maskChar: ' '};
export const MASK_IP = { mask: '000.000.000.000'};
export const MASK_CLAVE = { mask: '000'};

//#endregion

//#region DATASOURCES
export const DATASOURCE_DIAS_HABILES_ANO = [
    {ano:2018},
    {ano:2019},
    {ano:2020},
    {ano:2021},
    {ano:2022},
    {ano:2023},
    {ano:2024},
    {ano:2025},
    {ano:2026},
    {ano:2027},
    {ano:2028},
    {ano:2029},
    {ano:2030},
    {ano:2031},
    {ano:2032},
    {ano:2033},
    {ano:2034},
    {ano:2035},
    {ano:2036},
    {ano:2037},
    {ano:2038},
    {ano:2039},
    {ano:2040},
]

export const DATASOURCE_EVENTOS_TIPO_TERMINO=[
    {
        id_estatus:1,
        estatus_nombre:'Favorable'
    },
    {
        id_estatus:0,
        estatus_nombre:'No favorable'
    }
];
export const DATASOURCE_TIPO_EVENTO =[
    {
        id_evento_tipo:1,
        evento_tipo_nombre: "Final"
    },
    {
        id_evento_tipo:2,
        evento_tipo_nombre: "Intermedio"
    }
];

export const DATASOURCE_TIPOS_USUARIO = [
    {
        usuario_tipo_id:1,
        descripcion:"Interno"
    },
    {
        usuario_tipo_id:2,
        descripcion:"Externo"
    }
]

export const DATASOURCE_TIPOS_CONTRIBUYENTE = [
    {
        contribuyente_tipo_id     :1,
        descripcion:"Persona Fisica"
    },
    {
        contribuyente_tipo_id     :2,
        descripcion:"Persona Moral"
    }
]

export const DATASOURCE_TIPOS_ACCION = [
    {
        accion_id:1,
        accion:"Edicion"
    },
    {
        accion_id:2,
        accion:"Baja"
    }
]

export const DATASOURCE_DURACION_SESION = [
    ...Array(24).fill().map((element, index) => {return { duracion_sesion:index + 1 }})
]

export const DATASOURCE_ANOS = [
    ...Array(50).fill().map((element, index) => {return { ano:index + 2018 }})
]

//#endregion


export const DATASOURCE_CAT_ANEXO_COLUMNAS_TIPOS_DATOS = [
    {"id":1,"nombre":"Texto","descripcion":"abc","ejemplo":"CRE801003RF6","es_lista":0}, 
    {"id":2,"nombre":"Número c/2 decimales","descripcion":"#,###.##","ejemplo":"1,235.40","es_lista":0}, 
    {"id":3,"nombre":"Número c/4 decimales","descripcion":"#,###.####","ejemplo":"21.3628","es_lista":0}, 
    {"id":4,"nombre":"Entero c/coma","descripcion":"#,###","ejemplo":"5,250","es_lista":0}, 
    {"id":5,"nombre":"Entero s/coma","descripcion":"####","ejemplo":"2021","es_lista":0}, 
    {"id":6,"nombre":"Fecha","descripcion":"dd/MM/yyyy","ejemplo":"21/01/2021","es_lista":0}, 
    {"id":7,"nombre":"Hora","descripcion":"hh:mm","ejemplo":"21:00","es_lista":0}, 
    {"id":8,"nombre":"Lista","descripcion":"Lista de elementos para selección","ejemplo":"Sinaloa, Sonora, Durango","es_lista":1}, 
    {"id":9,"nombre":"Porcentaje c/2 decimales","descripcion":"###.##%","ejemplo":"34.56%","es_lista":0}
]



