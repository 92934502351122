import {instance} from './../constants/defaultValues';
import axios from 'axios';

export function getUsuarioById(usuarioId, take, skip){
    return instance.post(`/api/read`,{
        "VC":"seg_usuario",
        "VA":"byid",
        "page":skip/take,
        "pagesize":take,
        "usuario_id":usuarioId,
        "searchterm":''
    })
}

export function getUsuarioLoged(){
    return instance.post(`/api/read`,{
        "VC":"seg_usuario",
        "VA":"logedUser",
        "grupo_id":localStorage.getItem('grupo_id'),
    })
}

export function getUsuariosBySearchTerm(searchTerm, take, skip){
    return instance.post(`/api/read`,{
        "VC":"seg_usuario",
        "VA":"byidandsearchterm",
        "page":skip/take,
        "pagesize":take,
        "searchterm":searchTerm
    })
}



export function getGrupoById(perfilId, take, skip){
    return instance.post(`/api/read`,{
        "VC":"seg_usuario_grupo",
        "VA":"byidandsearchterm",
        "page":skip/take,
        "pagesize":take,
        "perfil_id":perfilId,
        "searchterm":''
    })
}
export function getGrupoBySearchTerm(seachTerm, take, skip){
    return instance.post(`/api/read`,{
        "VC":"seg_usuario_grupo",
        "VA":"byidandsearchterm",
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm
    })
}

export function getActaBySearchTerm(seachTerm, take, skip){
    return instance.post(`/api/read`,{
        "VC":"cat_acta",
        "VA":"byidandsearchterm",
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm
    })
}

export function getAnexoRubroBySearchTerm(seachTerm, take, skip){
    return instance.post(`/api/read`,{
        "VC":"cat_anexo_rubro",
        "VA":"byidandsearchterm",
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm
    })
}

//#region eventos
export function getEventoByidandsearchtermandidusuario(seachTerm, take, skip){
    return instance.post(`/api/read`,{
        "VC":"eventos",
        "VA":"byidandsearchtermandidusuario",
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm,
        "grupo_permiso_id":localStorage.getItem('grupo_id')
    })
}

export function getEventoBySearchTerm(seachTerm, take, skip, grupo_permiso_id=null){
    return instance.post(`/api/read`,{
        "VC":"eventos",
        "VA":"byidandsearchterm",
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm,
        "grupo_permiso_id":grupo_permiso_id
    })
}  

export function getEventoBySearchTerm_captura(seachTerm, take, skip){
    return instance.post(`/api/read`,{
        "VC":"eventos",
        "VA":"byidandsearchterm_captura",
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm
    })
}

export function getEventoBySearchTermPuestoEnte(eventoId,puestoEnteId, seachTerm, take, skip, cargaContinua = false){
    return instance.post(`/api/read`,{
        "VC":"eventos",
        "VA":"byidandsearchtermpuestoente",
        "puestoente_id":puestoEnteId,
        "evento_id":eventoId,
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm,
        "carga_continua":cargaContinua
    })
}

export function getEventoBySearchTermAnexo(eventoId, puestoEnteId, seachTerm, take, skip){
    return instance.post(`/api/read`,{
        "VC":"eventos",
        "VA":"byidandsearchtermanexo",
        "puestoente_id":puestoEnteId,
        "evento_id":eventoId,
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm
    })
}

export function getEventoPuestoAnexoBySearchTerm(
    evento_id = 0, ente_id = 0, unidad_id = 0, puesto_id = 0,
    seachTerm, take, skip){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto_anexo",
        "VA":"byidandsearchterm",
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm,
        "evento_id":evento_id,
        "ente_id":ente_id,
        "unidad_id":unidad_id,
        "puesto_id":puesto_id
    })
}
//#endregion

export function getEnteBySearchTerm(seachTerm, take, skip, grupo_id=null, flag_consulta_puesto = false){



    return instance.post(`/api/read`,{
        "VC":"cat_ente",
        "VA":"bysearchterm",
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm,
        "grupo_id":grupo_id,
        "flag_consulta_puesto":flag_consulta_puesto
    })
}

export function getEntePuestoBySearchTerm(seachTerm, take, skip, id_a_excluir=0, id_ente_publico = null, id=null){
    return instance.post(`/api/read`,{
        "VC":"cat_ente_publico_puesto",
        "VA":"byidandsearchterm",
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm,
        "id_a_excluir":id_a_excluir,
        "id_ente_publico": id_ente_publico,
        'id':id ? id : null
    })
}

export function getEntePuestoBySearchTermByIdUnidadAdministrativa(seachTerm, take, skip,  id_unidad_administrativa = null){
    return instance.post(`/api/read`,{
        "VC":"cat_ente_publico_puesto",
        "VA":"byidandsearchtermByUnidadAdministrativa",
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm,
        "id_unidad_administrativa": id_unidad_administrativa
    })
}

export function getEntePuestoJerarquiaBySearchTerm(seachTerm, take, skip){
    return instance.post(`/api/read`,{
        "VC":"cat_ente_publico_puesto_jerarquia",
        "VA":"byidandsearchterm",
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm
    })
}
export function getEnteUnidadAdministrativaBySearchTerm(seachTerm, take, skip){
    return instance.post(`/api/read`,{
        "VC":"cat_ente_publico_unidad_administrativa",
        "VA":"byidandsearchterm",
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm
    })
}

export function getEnteUnidadAdministrativaBySearchTermByEnte(seachTerm, take, skip, id_ente_publico){
    return instance.post(`/api/read`,{
        "VC":"cat_ente_publico_unidad_administrativa",
        "VA":"byidandsearchtermbyente",
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm,
        "id_ente_publico": id_ente_publico
    })
}

export function getEnteTipoBySearchTerm(seachTerm, take, skip){
    return instance.post(`/api/read`,{
        "VC":"cat_ente_tipo",
        "VA":"byidandsearchterm",
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm
    })
}
export function getEnteSectorBySearchTerm(seachTerm, take, skip){
    return instance.post(`/api/read`,{
        "VC":"cat_ente_sector",
        "VA":"byidandsearchterm",
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm
    })
}
export function getExtensionesById(extensionId, take, skip){
    return instance.post(`/api/read`,{
        "VC":"sys_extensiones",
        "VA":"byid",
        //"page":skip/take,
        //"pagesize":take,
        "id_extension":extensionId,
        "searchterm":''
    })
}
export function getExtensionesByIds(extensionId, take, skip){
    return instance.post(`/api/read`,{
        "VC":"sys_extensiones",
        "VA":"byids",
        //"page":skip/take,
        //"pagesize":take,
        "ids_extension":extensionId,
        "searchterm":''
    })
}
export function getExtensionesBySearchTerm(seachTerm, take, skip){
    return instance.post(`/api/read`,{
        "VC":"sys_extensiones",
        "VA":"bysearchterm",
        //"page":skip/take,
        //"pagesize":take,
        "searchterm":seachTerm
    })
}
export function getUsuarioBySearchTermAndDelegacion(delegacionId,searchTerm,take,skip){
    return instance.post(`/api/read`,{
        "VC":"seg_usuario",
        "VA":"searchtermdelegacion",
        "delegacion_id":delegacionId,
        "page":skip/take,
        "pagesize":take,
        "searchterm":searchTerm
    })
}
export function getEventoByIdAndSearchTerm(eventoId,seachTerm, take, skip){
    return instance.post(`/api/read`,{
        "VC":"eventos",
        "VA":"byidandsearchterm",
        "id":eventoId,
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm
    })
}
export function getFuncionarioByIdAndSearchTerm(funcionarioId,seachTerm, take, skip){
    return instance.post(`/api/read`,{
        "VC":"cat_funcionario",
        "VA":"byidandsearchterm",
        "id":funcionarioId,
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm
    })
}





export function postFingerPrint(isValidating,params){
    //console.log("par",params);
    return axios({
        url: !isValidating ? 'http://localhost:8888/Registrar/' : 'http://localhost:8888/Verificar/',
        withCredentials:true,
        method:"post",
        data:params    
    }); 
}
export function verifyUser(usuario, password){
    return instance.post(`/api/read`,{
        "VC":"seg_usuario",
        "VA":"verifyUser",
        "usuario":usuario,
        "password":password,
    });
}