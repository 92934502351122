import React,{useRef, useState, useEffect } from 'react';
import {
    DEFAULT_PAGE_SIZE_TWO_GRID,
    DEFAULT_PAGE_SIZES,
} from 'constants/defaultValues';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {getEventoEntesUnidadesPuestosBloqueo,postBlockAnexosEventoPuestoMasivo} from 'api';
import AlertDialog from 'components/alertDialog/alertDialog';
import DataGrid, { Column, Pager, Paging,Selection, FilterRow, } from 'devextreme-react/data-grid';

export default function(props){
    const grdRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [eventoPuestosArray, setEventoPuestosArray] = useState([]);
    const [onSelectionChangedFirstTime, setOnSelectionChangedFirstTime] = useState(true);
    const [flagOnLoaded, setFlagOnLoaded] = useState(true);

    useEffect(() => {
        let mounted = true;
        async function fetchData() {
            if (mounted) {
                await setEventoPuestosArray(await fetchPuestos());
                
            }
        }
        fetchData();
        return () => { mounted = false; };
    }, []);

    const fetchPuestos = async () =>{
        const {data:{error,message,data}} = await getEventoEntesUnidadesPuestosBloqueo({eventoId:props.eventoId});
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            return data;
        }
        return [];
    }

    const datasource = new DataSource({
        store: new CustomStore({
            key: "evento_puesto_id",
            load: (loadOptions) => eventoPuestosArray,
            onLoaded:async(result) => {
                    if(flagOnLoaded && eventoPuestosArray.length){
                       await loadSelectedRows()
                        setFlagOnLoaded(!flagOnLoaded)
                    }
                }
        })
    });

    const onSelectionChanged = async (e) =>{
        console.log('onSelectionChange', eventoPuestosArray);

        //1er caso cuando no trae nada
        //2do cuando trae algo
        if(onSelectionChangedFirstTime &&  eventoPuestosArray.filter((eventoPuesto)=>(eventoPuesto.flag_bloqueo_anexos===true)).length){
            setOnSelectionChangedFirstTime(false);   
            return;
        }
            
        setOnSelectionChangedFirstTime(false);  
        handleSubmit();
        
    }

    const loadSelectedRows = () => {
        ///solo debe de entrar al cargar el datasource
        console.log('loadSelectedRows', eventoPuestosArray);
        
        let selectedRows = eventoPuestosArray.filter((eventoPuesto)=>(eventoPuesto.flag_bloqueo_anexos===true)).map(eventoPuesto => {return eventoPuesto.evento_puesto_id; });
        
        if(selectedRows.length)
            grdRef.current.instance.selectRows(selectedRows, false);
    }

   const handleSubmit  = async () =>{
        console.log('handleSubmit');
        setIsLoading(true);
        let selectedRowsData = grdRef.current.instance.getSelectedRowsData(); 

        const {data:{error,message,data}} = await postBlockAnexosEventoPuestoMasivo(selectedRowsData,props.eventoId);
        if(error){
            AlertDialog({message});
            return;
        }else if(data.length) {
            AlertDialog({message: "Cambio realizado con éxito.",isNotification:true});
        }
        
        await setEventoPuestosArray(await fetchPuestos());
        setIsLoading(false);
   }

    return(
        <div>
            <DataGrid 
                key="evento_puesto_id"
                ref={grdRef}
                dataSource={datasource}
                showBorders={true}
                columnAutoWidth={true}
                rowAlternationEnabled={true}
                onSelectionChanged={onSelectionChanged}
                disabled={isLoading}
                >
                <FilterRow visible={true} showOperationChooser={false}/>
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE_TWO_GRID} />
                <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                        showInfo={true} />
                <Selection
                    mode="multiple"
                    selectAllMode={'page'}
                    showCheckBoxesMode={'always'}
                />
                    <Column dataField="evento_puesto_id" caption="Id" visible={false} />
                    <Column dataField="ente_clave" caption="Clave" />
                    <Column dataField="ente_nombre" caption="Ente público" />
                    <Column dataField="unidad_administrativa_nombre" caption="Unidad administratíva" />
                    <Column dataField="puesto_nombre" caption="Puesto" />
            </DataGrid>
        </div>
    )
}