import React,{useRef } from 'react';
import {
    DEFAULT_PAGE_SIZE_ONE_GRID,
    DEFAULT_PAGE_SIZES,
    ESTATUS_SOURCE_CONFIG,
} from 'constants/defaultValues';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { getDataSourceFilter, getDataSourceSort } from 'utils/utility'
import {getEnteEvento} from 'api';
import AlertDialog from 'components/alertDialog/alertDialog';
import lang from 'lang';
import { Button } from 'devextreme-react/button';
import DataGrid, { Column, Editing, Pager, Paging,Selection, FilterRow, Lookup} from 'devextreme-react/data-grid';

export default function({id,onAccept=()=>{}}){

    const grdRef = useRef();

    const fetchEntes = (async (take, skip, filter, sort) =>{
        let result= {data:[], totalCount:0};
        let evento_id = id;
        const {data:{error,message,data}} = await getEnteEvento({take, skip, filter, sort,evento_id});
        if(error){
            AlertDialog({message});
        }else if(data.length && data[0].totalCount) {
            result = data[0];
        }
        return result;
    })

    const enteDatasource = new DataSource({
        store: new CustomStore({
            key: "id",
            load: (loadOptions) => {
                return fetchEntes(
                    loadOptions.take, 
                    loadOptions.skip, 
                    getDataSourceFilter(loadOptions), 
                    getDataSourceSort(loadOptions));             
            }
        })
    });

   const handleSubmit  = ()=>{
        let selectedKeys = grdRef.current.instance.getSelectedRowKeys();
        if(selectedKeys.length > 0){
            let selectedRowsData = grdRef.current.instance.getSelectedRowsData();
            onAccept && onAccept(selectedRowsData, grdRef);
        }else{
            AlertDialog({message: "No se ha seleccionado ningun elemento.",isNotification:true, type:"error"})
        }
        
   }

    return(
        <div>
            <Button
                type="default"
                text="Añadir ente al evento"
                onClick={()=>{handleSubmit()}}
            />
            <div style={{marginBottom:"15px"}}/>
            <DataGrid 
                key="id"
                ref={grdRef}
                dataSource={enteDatasource}
                remoteOperations={{ paging: true, sorting: true, filtering: true }}
                showBorders={true}
                columnAutoWidth={true}
                rowAlternationEnabled={true}>
                <FilterRow visible={true} showOperationChooser={false}/>
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                        <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                        showInfo={true} />
                <Editing
                    mode="row"
                    useIcons={true}
                    allowDeleting={false}
                    allowUpdating={false}
                />
                <Selection
                    mode="multiple"
                    selectAllMode={'page'}
                    showCheckBoxesMode={'onClick'}
                />
                <Column dataField="id" caption="Id" visible={false} />
                    <Column dataField="orden" caption="Orden" allowFiltering={false} />
                    <Column dataField="clave" caption="Clave" width={100} /> 
                    <Column dataField="nombre" caption="Ente" />
                    <Column dataField="nombre_corto" caption="Nombre corto" />                                       
                    <Column dataField="tipo" caption="Tipo Ente" allowSorting={false} allowFiltering={false} />
                    <Column dataField="sector" caption="Sector" allowSorting={false} allowFiltering={false} />
                    <Column dataField="administrador" caption="Administrador" allowSorting={false} allowFiltering={false} />

                    <Column dataField="estatus" allowSorting={false}
                        caption={lang.getString("catalogo.caption.estatus")}
                        allowFiltering={false}>
                        <Lookup dataSource={ESTATUS_SOURCE_CONFIG} valueExpr="id" displayExpr="name"/>
                    </Column>
            </DataGrid>
            
            
        </div>
    )
}