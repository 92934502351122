import React from 'react';
import { DateBox } from 'devextreme-react/date-box';
import { Template } from 'devextreme-react/core/template';
import { Button } from 'devextreme-react/button';

export default function DatePicker(props) {
    const type =props.type ? props.type : 'datetime';
    const stylingMode =props.stylingMode ? props.stylingMode : 'underlined';
    const value = props.value ? props.value : null;
    const showClearButton = props.showClearButton ? props.showClearButton : false;
    const displayFormat =  props.onlyYear ? "yyyy" : (props.type && props.type === 'time'  ? "HH:mm" : (props.type && props.type == 'date') ? 'dd/MM/yyyy' :  'dd/MM/yyyy HH:mm');
    const defaultValue = (props.defaultValue ? props.defaultValue  : new Date());
    const min = (props.min ? props.min  : null);
    const max = (props.max ? props.max  : null);
    const disabledDates = (props.disabledDates ? props.disabledDates  : []);
    const inputAttr = (props.inputAttr ? props.inputAttr  : {});
    const calendarOptions = props.onlyYear ? {maxZoomLevel:'decade'}:{};
    const name = props.name ? props.name : "datepicker"
    const onOpened = (e) =>  {
        if( type === 'time'){
            e.component.content().getElementsByClassName("dx-box-item")[0].style.display = "none";
            if(e.component._popup.option('toolbarItems').length === 3)
                e.component._popup.option('toolbarItems', e.component._popup.option('toolbarItems').slice(1))  
        }
        else if( type === 'date')
            e.component.content().getElementsByClassName("dx-box-item")[1].style.display = "none";
    };

  return (
    <DateBox
        name = {name}
        icon = "save"
        stylingMode={stylingMode}
        calendarOptions={calendarOptions}
        value={value} 
        defaultValue={defaultValue} 
        dropDownButtonTemplate="buttonRender"
        min={min}
        max={max}
        disabledDates= {disabledDates}
        showClearButton={showClearButton}
        displayFormat={displayFormat}
        onContentReady={(e)=> e.component.option('inputAttr',inputAttr)}
        onOpened={onOpened}
        onValueChanged={(e)=>props.onValueChange && props.onValueChange(e)}
        type="datetime"
        >
            <Template name="buttonRender" render={()=> <Button style={{backgroundColor:"unset",marginLeft:"-13px"}} icon={type === "time" ? "clock" : "event"} />}/>
    </DateBox>
  );
}