import {instance} from './../constants/defaultValues';

export function getUsuarios({usuarioId = 0, take = 1, skip = 0, filter = {}, sort = {}}){
    if(usuarioId){
        return instance.post(`/api/read`,{
            "VC":"seg_usuario",
            "VA":"byid",
            "usuario_id":usuarioId
          })
    }
    else{
        return instance.post(`/api/read`,{
            "VC":"seg_usuario",
            "VA":"list",
            "page":skip/take,
            "pagesize":take,
            ...filter,
            "sortby": sort.selector,
            "sortdesc": sort.desc
        })
    }
}

export function changeCurrentPassword(current_password,usuario_password){
    return instance.post(`/api/write`,{
        "VC":"seg_usuario",
        "VA":"changeCurrentPassword",
        current_password,
         usuario_password
    })
}


export function getLoggedUserId(){
    return instance.post(`/api/read`,{
        "VC":"seg_usuario",
        "VA":"id_funcionario_logged_user",
    })
}

export function postUsuario(usuario){
    return instance.post(`/api/write`,{
        "VC":"seg_usuario",
        "VA":"bysystem",
        "usuario_id":usuario.id,
        "usuario" : usuario.usuario,
        "usuario_correo" : usuario.correoElectronico,
        "usuario_password" : usuario.contraseña,
        "duracion_sesion":usuario.duracionSesion,
        "usuario_hora_inicio" : usuario.horaInicio ,
        "usuario_hora_fin" : usuario.horaFin,
        "usuario_tipo_id":usuario.tipoUsuario,
        "usuario_ip" : usuario.usuarioIp,
        "fecha_ultima_sesion" : usuario.fechaUltimaSesion,
        "grupo_id" : usuario.grupoId,
        "usuario_departamento_id":usuario.departamentoId,
        "usuario_prefijo":usuario.prefijo,
        "usuario_nombre":usuario.nombre,
        "usuario_apellido_paterno":usuario.apellidoPaterno,
        "usuario_apellido_materno":usuario.apellidoMaterno,
        "usuario_rfc":usuario.rfc,
        "usuario_celular":usuario.celular,
        "usuario_departamento":usuario.departamentoId,
        "usuario_puesto_id":usuario.puestoId,
        "usuario_grupo_id":usuario.grupo_id,
        "usuario_permiso":usuario.grupoPermiso,
        "usuario_tramite_clasificador":usuario.tramiteClasificador,
        "delegacion_id":usuario.delegacionId,
        "municipio_id":usuario.municipioId,
        "incidencia_municipio":usuario.incidenciaMunicipio,
        "huella_dactilar": usuario.huella_dactilar,
        "usuario_serial_number":usuario.usuario_serial_number,
        "perito_valuador_numero": usuario.numero_perito,
	    "folio":usuario.folio_perito,
	    "vigencia":usuario.vigencia,
	    "especialidad": usuario.especialidad,
	    "domicilio": usuario.domicilio,
	    "telefono": usuario.telefono_perito,
	    "ciudad": usuario.ciudad,
        "zona": usuario.zona,
        "curp":usuario.curp
    })
}

export function getPermisos(){
    return instance.post(`/api/read`,{
        "VC":"seg_permiso",
        "VA":"list",
        "page":0, //TODO:falta implementacion de page
        "pagesize":100 //TODO:falta implementacion del pagesize
    })
}

export function getGrupoPermisos(grupoId){
    return instance.post(`/api/read`,{
        "VC":"seg_usuario_grupo_permiso",
        "VA":"bygrupoid",
        "grupo_id":grupoId
    })
}

    
export function deleteUsuario(usuarioId){
    return instance.post(`/api/delete`,{
        "VC":"seg_usuario",
        "VA":"bysystem",
        "usuario_id" : usuarioId
    });
}

export function getFlagCmb(){
    return instance.post(`/api/read`,{
        "VC":"seg_usuario",
        "VA":"permisoasignarbuzon",
      })
}

export function getUsuariobySearchTermAndFuncionario(seachTerm, take, skip){
    return instance.post(`/api/read`,{
        "VC":"seg_usuario",
        "VA":"byidandfuncionarioandsearchterm",
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm
    })
}