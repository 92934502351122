import React,{ useState, useEffect,useReducer, useCallback } from 'react';
import AlertDialog from 'components/alertDialog/alertDialog';
import {uploadFiles} from 'api';

import { 
      downloadFile,
} from 'api'

const  isObject = function(object) {
    return object != null && typeof object === 'object';
  }

export const getDataSourceFilter = (loadOptions, convertIdToName = false) => {
    //TODO: Mejora - Cambiar el ciclo por el metodo filter
    //TODO: Cambiar a recibir un objeto de {[{attr,newAttr}]} para cambiar el replabe _id a _nombre
    let filter = {}
    if(loadOptions.filter){
        if(loadOptions.filter.length === 3 && loadOptions.filter[1] !== "and" && loadOptions.filter[1] !== "notAnd"
        && loadOptions.filter[1] !== "or" && loadOptions.filter[1] !== "notOr" ){
            if(convertIdToName &&  typeof loadOptions.filter["filterValue"] == "string" && loadOptions.filter[0].endsWith("_id")){
                filter[[loadOptions.filter[0].replace("_id", "_nombre")]] =  loadOptions.filter[2];
            } else {
                filter[[loadOptions.filter[0]]] = loadOptions.filter[2];
            }
        } else {
            for(let i = 0; i < loadOptions.filter.length; i++){
                if(loadOptions.filter[i] !== "and" && loadOptions.filter[i] !== "notAnd"
                && loadOptions.filter[i] !== "or" && loadOptions.filter[i] !== "notOr" ){
                    if(convertIdToName &&  typeof loadOptions.filter[i]["filterValue"] == "string" && loadOptions.filter[i][0].endsWith("_id")){
                        filter[[loadOptions.filter[i][0].replace("_id", "_nombre")]] =  loadOptions.filter[i][2];
                    } else {
                        if(filter[[loadOptions.filter[i][0]]] !== undefined && filter[[loadOptions.filter[i][0]]] !== null){
                            if(Array.isArray(filter[[loadOptions.filter[i][0]]])) filter[[loadOptions.filter[i][0]]].push(loadOptions.filter[i][2]);
                            else filter[[loadOptions.filter[i][0]]] = [filter[[loadOptions.filter[i][0]]], loadOptions.filter[i][2]];
                        }else{
                            filter[[loadOptions.filter[i][0]]] = loadOptions.filter[i][2];
                        }
                    }
                }
            }
        }
    }
    //console.log("Filters", filter);
    return filter;
}

export const getDataSourceSort = (loadOptions) =>{
    let sort={selector:null, desc:1};
    if(loadOptions.sort){
        sort = loadOptions.sort[0];
        sort.selector = sort.selector ? sort.selector : null;
        sort.desc = sort.desc ? 1 : 0
    }
    return sort;
}

export const useFormState = (initialData,formRef) =>{
    const [data] = useDxState(initialData);
    const [fRef] = useState(formRef);
    
    useEffect(() => {
        
    },[]);
    const setData = useCallback ((newData) =>{
        return new Promise((resolve)=>{
            if(fRef && fRef.current){
                fRef.current.instance.updateData({...data,...newData});
            }
            setTimeout(()=>resolve(),100)
        });
        

    },[formRef])
    const getData = (elememnt) =>{
        if(fRef && fRef.current){
            return elememnt === undefined? data: data[elememnt];
        }
        return null;
    }
    return [data,setData,getData];
}

export const useComponentState = (value,Component = ()=>{return null}) =>{
    const [data,setData] = useReducer((state,newState)=>{
        //console.log('useComponentState',typeof state === 'object',state,newState)
        if(typeof state === 'object') return {...state,...newState};
        return newState;
    },value);
    useEffect(() => {
        //setData(data);
    },[]);
    const render = (props)=>{  return <Component data={data} setData={setData} {...props}></Component> };
    const render2 = (props)=>{  return <Component data={value} setData={setData} {...props}></Component> };
    return [data,setData,render,render2];
}
export const useDxState = (value) =>{
    const [data,setData] = useReducer((state,newState)=>{
        if(typeof state === 'object') return newState;
        return {...state,...newState};
    },value);
    return [data,setData];
}

export const  deepEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
  
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        areObjects && !deepEqual(val1, val2) ||
        !areObjects && val1 !== val2
      ) {
        return false;
      }
    }
  
    return true;
  }
  
export async function descargarArchivo(idAdjunto,setIsLoading){
    if (!idAdjunto) return;
    setIsLoading && setIsLoading(true);
    const resultado = await downloadFile(idAdjunto,(p)=>{});
    console.log("descargarFile> archivo",resultado)
    if (resultado.data.byteLength>0){ 
        const downloadUrl = window.URL.createObjectURL(new Blob([resultado.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', resultado.filename);
        document.body.appendChild(link);
        link.click();
        link.remove();     
    }
    else{
        AlertDialog({message:"No es posible descargar este archivo."});
    }
    setIsLoading && setIsLoading(false);
};

export const cargarArchivo =  function(tipo,alCargarArchivo,setIsLoading) {  
    let dataarray = []

    const submitFiles = (async()=>{
        setIsLoading && setIsLoading(true)
        try{
            const {data:{error,message,data:files}} = await uploadFiles(dataarray,
                (pregress)=>{ console.log('Subiendo archivo...'+pregress+"%");
            });
            if(error) AlertDialog({message});
            else {   
                alCargarArchivo(files[0].idrow_file);    
            } 
        }catch(err){
            console.log("Error", err)
        }
        setIsLoading && setIsLoading(false)
    });

    const handleFileChange = function()  {
        dataarray = Array.from(this.files);
        submitFiles();
    };

        var inputElement = document.createElement("input");
        inputElement.type = "file";
        inputElement.accept = tipo;//'*.xlsx';
        inputElement.addEventListener("change", handleFileChange)
        inputElement.dispatchEvent(new MouseEvent("click")); 
};


  

