import React from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { observer } from "mobx-react-lite"
import DataGrid, {Column,Pager, Paging} from 'devextreme-react/data-grid';

export default observer(({data,...props}) =>{

    return <Popup
          visible={data.popupVisible}
          onHiding={()=> data.popupVisible = false} 
          dragEnabled={false}
          closeOnOutsideClick={false}
          showCloseButton={true}
          showTitle={true}
          title="Archivos a importar"
          container=".dx-viewport"
          width={500}
          height={650}
        >
          {data.filesImportar.length > 0 && <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: 'Importar archivos',
                onClick: props.onImportar
              }}
          />}
          <p>
            Listado de renglones asociados a la importación masiva.<br/>
            <b>Los archivos seleccionados deberá contener la siguiente nomenclatura [numero de renglón]_[nombre del archivo].pdf, ejemplo 01_estado de cuenta Banamex.pdf</b>
          </p>
          <DataGrid key="id_archivos_importar" colSpan={12}
            dataSource={data.filesImportar}
            columnAutoWidth={true}
            repaintChangesOnly={true}
            showBorders={true} width={"100%"}
            height={400}
            rowAlternationEnabled={true}
            > 
            <Paging defaultPageSize={10000} /> 
                <Pager
                showPageSizeSelector={false}
                allowedPageSizes={10000}
                showInfo={false} />
             <Column dataField={'numero_renglon'} caption={'Número de renglón'} alignment='center' width={150} />
             <Column dataField={'archivo'} caption={'Archivo asociado'} />
            </DataGrid>
            <p>
              Se importarán <big><b>{data.filesImportar.length}</b></big> archivos de <big><b>{data.totalFiles}</b></big>.
            </p>
        </Popup>
})