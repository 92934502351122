import {instance} from '../constants/defaultValues';

export function getEntePublicoPuestoJerarquia({entePublicoPuestoJerarquiaId = 0, take = 1, skip = 0, filter = {}, sort = {}}){
    if(entePublicoPuestoJerarquiaId){
        let ent = instance.post(`/api/read`,{
            "VC":"cat_ente_publico_puesto_jerarquia",
            "VA":"byid",
            "id":entePublicoPuestoJerarquiaId
          })
       
        return ent;
    }
    else{
        return instance.post(`/api/read`,{
            "VC":"cat_ente_publico_puesto_jerarquia",
            "VA":"listdata",
            "page":skip/take,
            "pagesize":take,
            ...filter,
            "sortby": sort.selector,
            "sortdesc": sort.desc
        })
    }
}


export function postEntePublicoPuestoJerarquia(entePublicoPuestoJerarquia){

    return instance.post(`/api/write`,{
        "VC":"cat_ente_publico_puesto_jerarquia",
        "VA":"bysystem",
        "id":entePublicoPuestoJerarquia.id,
        "nombre": entePublicoPuestoJerarquia.nombre,
        "nivel" : entePublicoPuestoJerarquia.nivel,
        "orden" : entePublicoPuestoJerarquia.orden,
        "estatus": 1
    })
}

export function deleteEntePublicoPuestoJerarquia(entePublicoPuestoJerarquiaId){
    return instance.post(`/api/delete`,{
        "VC":"cat_ente_publico_puesto_jerarquia",
        "VA":"bysystem",
        "id" : entePublicoPuestoJerarquiaId
    });
}

export function activateEntePublicoPuestoJerarquia(entePublicoPuestoJerarquiaId){
    return instance.post(`/api/write`,{
        "VC":"cat_ente_publico_puesto_jerarquia",
        "VA":"activate",
        "id" : entePublicoPuestoJerarquiaId
    });
}
