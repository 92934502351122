import {instance} from '../constants/defaultValues';

export function getEnte({enteId = 0, take = 1, skip = 0, filter = {}, sort = {}, grupoPermiso = null,evento_id=null}){
    if(enteId){
        return instance.post(`/api/read`,{
            "VC":"cat_ente",
            "VA":"byid",
            "id":enteId
        })
    }
    else{
        return instance.post(`/api/read`,{
            "VC":"cat_ente",
            "VA":"listdata",
            "page":skip/take,
            "pagesize":take,
            ...filter,
            "sortby": sort.selector,
            "sortdesc": sort.desc,
            "grupo_permiso": grupoPermiso,
            evento_id
        })
    }
}

export function getEnteEvento({take = 1, skip = 0, filter = {}, sort = {}, grupoPermiso = null,evento_id=null}){
    return instance.post(`/api/read`,{
        "VC":"cat_ente",
        "VA":"listdata_evento",
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc,
        "grupo_permiso": grupoPermiso,
        evento_id
    })
}

export function postEnte(ente){
    return instance.post(`/api/write`,{
        "VC":"cat_ente",
        "VA":"bysystem",
        "id":               ente.id,
        "nombre":           ente.nombre,
        "nombre_corto":     ente.nombre_corto,
        "clave":            ente.clave,
        "orden":            ente.orden,
        "id_tipo":          ente.id_tipo,
        "id_sector":        ente.id_sector,
        "columnas":         ente.columnas,
        "usuarioai_id":     ente.usuarioai_id,
        "usuarioai":        ente.usuarioai,
        "usuarioai_email":  ente.usuarioai_email
    })
}

export function deleteEnte(enteId){
    return instance.post(`/api/delete`,{
        "VC":"cat_ente",
        "VA":"bysystem",
        "id" : enteId
    });
}

export function activateEnte(enteId){
    return instance.post(`/api/write`,{
        "VC":"cat_ente",
        "VA":"activate_ente",
        "id" : enteId
    });
}
export function cambiarPasswordEnte(ente){
    return instance.post(`/api/write`,{
        "VC":"cat_ente",
        "VA":"cambiar_password",
        "id": ente.id,
        "usuarioai": ente.usuarioai,
        "usuarioai_id": ente.usuarioai_id,
        "usuarioai_email": ente.usuarioai_email,
        "nombre": ente.nombre,
    });
}

export function identebyusuarioid(){
    return instance.post(`/api/read`,{
        "VC":"cat_ente",
        "VA":"identebyusuarioid"
    });
}