import React, { useState,useEffect,useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Form, {
    ButtonItem,
    GroupItem,
    Item,
    Label,
  } from 'devextreme-react/form';
import DataGrid, {
    Column,
    Editing,
    Lookup,
    FilterRow
} from 'devextreme-react/data-grid';
import AuthCheck from '../../../components/authCheck/authCheck.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { 
    postEntePublicoUnidadAdministrativa,
    getPuestoByUnidadAdministrativa,
    deleteEntePublicoPuesto,
    activatePuesto,
    postEntregaParcial
} 
from 'api';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import 'devextreme-react/autocomplete';
import {
    ESTATUS_SOURCE_CONFIG
} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog';
import { custom } from 'devextreme/ui/dialog';
import lang from '../../../lang';
const position = { of: '#usuario' }; //TODO: CSS - Se utiliza para posicionar el panel de loading

export default function (props) {
    //#region hook de control
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);

    const formRef = useRef(null);

    const permCanEdit = AuthCheck({permission: "PUESTO_EDIT", onlyValidator: true});
    const permCanDelete = AuthCheck({permission: "PUESTO_DELETE", onlyValidator: true});
    //#endregion

    //#region hook de estado
    const [unidadAdministrativa,setUnidadAdministrativa] = useState({
        "ente_publico_id": props.location.state && props.location.state.ente_publico_id? props.location.state.ente_publico_id: 0,
        "ente_publico_nombre": props.location.state && props.location.state.ente_publico_nombre? props.location.state.ente_publico_nombre: '',
        "ente_publico_nombre_corto": props.location.state && props.location.state.ente_publico_nombre_corto? props.location.state.ente_publico_nombre_corto: '',

        "id": props.location.state && props.location.state.unidad_administrativa_id? props.location.state.unidad_administrativa_id: 0,
        "orden": props.location.state && props.location.state.unidad_administrativa_orden? props.location.state.unidad_administrativa_orden: 0,
        "nombre": props.location.state && props.location.state.unidad_administrativa_nombre? props.location.state.unidad_administrativa_nombre: '',
    });
    //#endregion

    //#region hook de montado de la vista, se utiliza para generar el estado inicial
    useEffect(() => {
        let mounted = true;   
        if (mounted) {                 
            setIsLoading(false);
        }
        setIsLoading(false);      
        return () => { mounted = false; };
    },[]); 
    //#endregion

    //#region fetch
    const fetchUnidadAdministrativaPuestos = async(unidadAdministrativaId) =>{
        const {data:{error,message,data}} = await getPuestoByUnidadAdministrativa(unidadAdministrativaId);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            return data;
        }
        return [];
    }

    const deleteData = async (key) =>{
        const {data:{error,message}} = await deleteEntePublicoPuesto(key);
        if(error){
            AlertDialog({message});
        }
    }

    const activateData = async (key) =>{
        const {data:{error,message}} = await activatePuesto(key);
        if(error){
            AlertDialog({message});
        }
    }

    const activateRecord = (e) =>{
        let activateDialog = custom({
            showTitle: false,
            messageHtml: "¿Está seguro que desea activar este registro?",
            buttons: [
                {
                    text: "SÍ",
                    onClick: () => {
                        return true;
                    }
                }, 
                {
                    text: "NO",
                    onClick: () => {
                        return false;
                    }
                }, 
            ]
        });

        activateDialog.show().then(async (dialogResult) => {
            if(dialogResult){
                await activateData(e.row.data.id);
                dataSource.reload();
            }
        });
    }
    //#endregion

    //#region dataSource
    const dataSource = new DataSource({
        store: new CustomStore({
            key: "id",
            load: (options) => { 
                return fetchUnidadAdministrativaPuestos(unidadAdministrativa.id);                   
            },
            remove: (key) => {
                return deleteData(key);
            }
        })
    });
    //#endregion

    //#region eventos interfaz
    const goToPuesto = (puesto) =>{
        history.push({pathname:'/catalogos/entePuesto/detalle',state:{
            id:puesto? puesto.id: null,
            unidad_administrativa: unidadAdministrativa,
            ente_publico_id: unidadAdministrativa.ente_publico_id
        }})
    }

    const goToEntePublico = () =>{
        history.replace({pathname:'/catalogos/ente/detalle', state:{id:unidadAdministrativa.ente_publico_id}});
    }

    const createEventoParcial = async (dato) =>{
        const {data:{data,message,error}} = await postEntregaParcial(dato.id);
        if(error){
            AlertDialog({message});
        }else{
            dataSource.reload();
            AlertDialog({message:'Evento parcial para este puesto creado'});
        }
    }
    //#endregion

    //#region eventos CRUD
    const handleSubmit = (async (e) => {    
        e.preventDefault();
        setIsLoading(true);
        const {data:{error,message,data}} = await postEntePublicoUnidadAdministrativa(unidadAdministrativa);
        setIsLoading(false);
        if(error) AlertDialog({message: message});  
        else {
            if(unidadAdministrativa.id == 0){
                setUnidadAdministrativa({
                    ...unidadAdministrativa,
                    id: data[0].id,
                    orden: data[0].orden,
                    nombre: data[0].nombre
                });
            } else {
                goToEntePublico();
            }
        };
    });
    //#endregion

    //#region validaciones
    function canDoEntregaParcial(e){
        return e.row.data.flag_entrega_parcial;
    }
    function canEdit(e){
        return e.row.data.estatus == 1 && permCanEdit;
    }

    function canDelete(e){
        return e.row.data.estatus == 1 && permCanDelete;
    }

    function canActivate(e){
        return e.row.data.estatus != 1;
    }

    const validationRules ={
        nombre:[
            {type:"required",message:"Nombre es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:1000}
        ],
        orden:[
            {type:"required",message:"Orden es obligatorieo"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:10}
        ]
    }
    //#endregion

    //#region render
    return (
        <React.Fragment >
            <h2 className={'content-block'}>{lang.getString("unidad.administrativa.title")}</h2>
            <div id="usuario" className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                <form onSubmit={handleSubmit}>
                    <Form
                        ref={formRef}
                        formData={unidadAdministrativa}
                        readOnly={false}
                        showColonAfterLabel={true}
                        showValidationSummary={true}
                        validationGroup="validationRules"
                    >
                        <GroupItem colCount={2}>
                            <Item 
                                colSpan={4}
                                dataField="ente_publico_nombre"
                                stylingMode="underlined"
                                disabled={true}>
                                <Label text={lang.getString("unidad.administrativa.detail.ente_nombre")}></Label>
                            </Item>
                            <Item 
                                colSpan={4}
                                dataField="ente_publico_nombre_corto"
                                stylingMode="underlined"
                                disabled={true}>
                                <Label text={lang.getString("unidad.administrativa.detail.ente_nombre_corte")}></Label>
                            </Item>
                        </GroupItem>

                        <GroupItem colCount={2}>   
                            <Item 
                                colSpan={4}
                                dataField="orden"
                                stylingMode="underlined"
                                editorType="dxNumberBox"
                                validationRules={validationRules.orden}>
                                    <Label text={lang.getString("ente.detail.unidad.orden")}></Label>
                            </Item>  
                            <Item 
                                colSpan={4}
                                dataField="nombre"
                                stylingMode="underlined"
                                validationRules={validationRules.nombre}>
                                    <Label text={lang.getString("ente.detail.unidad.nombre")}></Label>
                            </Item>                      
                        </GroupItem> 

                        {
                            unidadAdministrativa.id > 0 &&
                            <GroupItem colCount={1} >
                            <GroupItem caption={lang.getString("Puestos")} colCount={1}> 
                                <ButtonItem horizontalAlignment="right" colSpan={10}
                                    buttonOptions={{text:'Agregar puesto',onClick: () => goToPuesto(null)}}
                                />
                            </GroupItem>
                        
                            <GroupItem colCount={1}> 
                                <DataGrid key="orden" colSpan={12}
                                    dataSource={dataSource}
                                    columnAutoWidth={true}
                                    repaintChangesOnly={true}
                                    showBorders={true} width={"100%"}
                                    rowAlternationEnabled={true}>
                                    <FilterRow visible={true} showOperationChooser={false}/>
                                    <Editing
                                        mode="row"
                                        useIcons={true}
                                        allowDeleting={AuthCheck({
                                            permission:"PUESTO_DELETE",
                                            onlyValidator: true
                                        })}
                                        allowUpdating={AuthCheck({
                                            permission:"PUESTO_EDIT",
                                            onlyValidator: true
                                        })}
                                    />
                                    <Column caption="Acción" type="buttons" width={110}
                                        buttons={[
                                            {hint:'Editar',icon:'edit',name:'edit', visible:canEdit, onClick:(event) => goToPuesto(event.row.data)},
                                            {hint:'Entrega parcial' ,icon:'newfolder',name:'Entrega parcial', visible:canDoEntregaParcial, onClick:(e)=>createEventoParcial(e.row.data)},
                                            {hint:'Eliminar',icon:'trash',name:'delete', visible:canDelete},
                                            {hint:'Activar' ,icon:'check',name:'activate', visible:canActivate, onClick:activateRecord},      
                                        ]}
                                    />
                                    <Column dataField="id" caption="Id" visible={false} />
                                    <Column dataField="clave" caption={lang.getString("ente.publico.puesto.caption.clave")} />    
                                    <Column dataField="nombre" caption={lang.getString("ente.publico.puesto.caption.nombre")} />
                                    <Column dataField="padre" caption={lang.getString("ente.publico.puesto.caption.id_padre")} />
                                    <Column dataField="jerarquia" caption={lang.getString("ente.publico.puesto.caption.id_jerarquia")} />
                                    <Column dataField="unidad_administrativa" caption={lang.getString("ente.publico.puesto.caption.id_unidad_administrativa")} />
                                    <Column dataField="nombre_funcionario" caption={lang.getString("ente.publico.puesto.caption.id_funcionario")} />
                                    <Column dataField="nombramiento" caption={lang.getString("ente.publico.puesto.caption.nombramiento")} />

                                    <Column dataField="estatus" width={120} caption={lang.getString("catalogo.caption.estatus")} >
                                        <Lookup dataSource={ESTATUS_SOURCE_CONFIG} valueExpr="id" displayExpr="name"/>
                                    </Column>
                                    <Column dataField="registro_creacion_fecha" alignment="center" dataType="date" format="dd/MM/yyyy" caption={lang.getString("catalogo.caption.registro_creacion_fecha")} />
                                    <Column dataField="registro_modificacion_fecha" alignment="center"  dataType="date" format="dd/MM/yyyy" caption={lang.getString("catalogo.caption.registro_modificacion_fecha")} /> 
                                </DataGrid>

                            </GroupItem>
                        
                        </GroupItem>
                        }
                       
                        <GroupItem colSpan={4}>
                            <ButtonItem horizontalAlignment="left" colSpan={2}
                                buttonOptions={{text:'Guardar',type:'success',useSubmitBehavior:true}}
                            />
                            <ButtonItem horizontalAlignment="left" colSpan={2}
                                buttonOptions={{text:'Regresar',type:'default',onClick:()=> goToEntePublico()}}
                            />
                        </GroupItem>
                    </Form>
                </form>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={isLoading}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
      </React.Fragment>);
    //#endregion
}