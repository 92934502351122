import React, { useState } from 'react';
import { Button } from 'devextreme-react/button';
import { useHistory } from 'react-router-dom';
import DataGrid, { Column, Editing, Pager, Paging, FilterRow } from 'devextreme-react/data-grid';
import Box, {
    Item
  } from 'devextreme-react/box';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {DEFAULT_PAGE_SIZE_ONE_GRID, DEFAULT_PAGE_SIZES} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog'
import {getUsuarios,deleteUsuario} from './../../../api';
import {getDataSourceFilter, getDataSourceSort} from 'utils/utility'
import lang from '../../../lang';
import AuthCheck from '../../../components/authCheck/authCheck.js';

export default function() {
    //#region hook de control
    const history = useHistory();
    const[isPopUpOpen, setIsPopUpOpen] = useState(false);
    //#endregion

    //#region fetch
    const fetchData = (async (take, skip, filter, sort) =>{
        let result= {data:[], totalCount:0};
        const {data:{error,message,data}} = await getUsuarios({take, skip, filter, sort});
        if(error){
            AlertDialog({message});
        }else if(data.totalCount) {
            result = data
        }
        console.log('result', result)
        return result;
    })

    const deleteData = (async (key) =>{
        const {data:{error,message}} = await deleteUsuario(key);
        if(error){
            AlertDialog({message});
        }
    })
    //#endregion

    //#region dataSource
    const dataSource = new DataSource({
        store: new CustomStore({
            key: "usuario_id",
            load: (loadOptions) => {
                return fetchData(loadOptions.take, loadOptions.skip, getDataSourceFilter(loadOptions), getDataSourceSort(loadOptions));
            },
            remove: (key) => {
                return deleteData(key);
            }
        })
    });
    //#endregion
    
    //#region eventos interfaz
    const goToDetail = (id = null) =>{
        history.push({pathname:'/admin/usuarios/detalle',state:{id}})
    }
   
 
    
    const openModal = () =>{
        setIsPopUpOpen(!isPopUpOpen);
    }
    //#endregion

    /*
<div style={{ float:"right", marginLeft:"5px", display:"inline-block"}}>
             <Button 
                    type="default"
                    text="Imprimir listado de usuarios"
                    onClick={()=>{
                        //goToPrintUsers()
                        openModal();
                    }}/>
             </div>
    */

    //#region render
    return(
        <React.Fragment>
    <h2 className={'content-block'}>{lang.getString("user.index.title")}</h2>
            <div className={'content-block'}>
            <div className={'dx-card responsive-paddings'}>
            <div className="dx-field-value" style={{marginBottom:"20px"}}>
             
                 
            <div style={{display:"inline-block", float:"right"}}>
            {
                        AuthCheck({
                            permission:"SEG_USU_003",
                            component: <Button icon="plus"
                            type="default"
                            text="Agregar usuario"
                            onClick={()=>{goToDetail()}}/>
                        })
                    }
            </div>
                    
             </div>
            <Box width="100%" direction="row">
                <Item ratio={12}>
                <DataGrid key="id"
                dataSource={dataSource}
                remoteOperations={{paging:true, sorting:true, filtering: true}}
                columnAutoWidth={true}
                repaintChangesOnly={true}
                showBorders={true} width={"100%"}
                rowAlternationEnabled={true}>
                    <FilterRow visible={true} showOperationChooser={false}/>
                    <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                        <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                        showInfo={true} />
                    <Editing
                        mode="row"
                        useIcons={true}
                        allowDeleting={AuthCheck({
                            permission:"SEG_USU_004",
                            onlyValidator: true
                        })}
                        allowUpdating={AuthCheck({
                            permission:"SEG_USU_002",
                            onlyValidator: true
                        })}
                    />
                    <Column type="buttons" width={110}
                    buttons={[
                        {hint:'Editar',icon:'edit',name:'edit',onClick:(event) => goToDetail(event.row.key)},
                        {hint:'Eliminar',icon:'trash',name:'delete'},
                    ]}
                    />
                    <Column dataField="usuario_id" caption="Id" visible={false} />
                    <Column dataField="usuario" caption="Usuario" />
                    <Column dataField="usuario_nombre_completo" caption="Nombre del usuario" />
                    <Column dataField="departamento_nombre" caption="Departamento"/>
                    <Column dataField="delegacion_nombre" caption="Delegación"/>
                    <Column dataField="usuario_correo" caption="Correo" />
                    <Column dataField="usuario_rfc" caption="RFC" />
                </DataGrid>
                </Item>
            </Box>

            </div>
            
            </div>
        </React.Fragment>
    );
    //#endregion
};
