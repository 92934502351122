import React, { useState, useEffect,useRef } from 'react';
import { useHistory } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem
} from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useGlobal } from '../../contexts/global';
import Utils from '../../utils/session';
import notify from 'devextreme/ui/notify';

import './login-form.scss';
import AlertDialog from '../alertDialog/alertDialog'
import {sysInfo, getGrupoPermisoByUsuarioId} from 'api/index'
import Swal from 'sweetalert2';

const LoginForm = function () {

  //#region hook de control
  const { signIn , setUser, signOut } = useGlobal();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isPopUpRecuperarOpen, setIsPopUpRecuperarOpen] = useState(false);
  
  const recuperarRef = useRef(null);
  //#endregion

  //#region hook de estado
  const [formData,setFormData] = useState ({
    user:"",
    rememberMe:false,
    password:""
  });
  const recuperar = useState ({
    user:""
  });
//#endregion

useEffect(() => {
  const load = async function () {
      setFormData(await getLocalStorageData());
  };
  load();
}, []);



  const getLocalStorageData = async()=>{
    let rememberMe = localStorage.getItem(Utils.getSpaceNameProperty("recuerdaUsuario")) === 'true';
    let eUser = localStorage.getItem(Utils.getSpaceNameProperty("usuarioCuenta"));
    let user = await Utils.Decrypt(eUser);
    let form = {
      "rememberMe": rememberMe,
      "user": user
    };
    return form;
  }

  const onSubmit = async (e) => {
    try{
      e.preventDefault();
      const { user, password,rememberMe } = formData;
      setLoading(true);
      //todo: Incluir rutas involucrados en el inicio de sesion
      let ePassword = await Utils.Encript(password);
      let eUser = await Utils.Encript(user);
  
      if(!localStorage.getItem(Utils.getSpaceNameProperty("recuerdaUsuario")) || localStorage.getItem(Utils.getSpaceNameProperty("recuerdaUsuario")) !== rememberMe)
          localStorage.setItem(Utils.getSpaceNameProperty("recuerdaUsuario"),rememberMe);
  
      if(rememberMe)
          localStorage.setItem(Utils.getSpaceNameProperty("usuarioCuenta"),eUser);
  
      let session = {};
      const {data:{error,message,data}} = await signIn(user, ePassword);
      const {data:{error:SysError,data:SysData,accion:SysAccion}} = await sysInfo();
      if(!SysError && SysData !== null){   
        data.sysinfo = SysData[0];
        session = data;
      }  
      else if (SysAccion === "signout") {
        window.location.reload();
        return;
      }
      
      if (error) AlertDialog({message, isNotification:true, type:'error'});
      else {
        const {data:{error,message,data}} = await getGrupoPermisoByUsuarioId();
        if (error) AlertDialog({message, isNotification:true, type:'error'});
        else {
          setLoading(false);
          if(data.length > 0){
            let eNombre = await Utils.Encript(data[0].nombre);
            localStorage.setItem(Utils.getSpaceNameProperty("nombre_seer"),eNombre);
            if(data.length > 1){
              const { value: index } = await Swal.fire({
                heightAuto: false,
                title: `<span style='font-size:30px;font-weight:bold'>`+ '¿Con qué perfil deseas ingresar?' +`</span>`,
                input: 'select',
                inputOptions: data.map(grupo => grupo.grupo_nombre),
                confirmButtonText: 'Ingresar',
                showCancelButton: true,
              })
              
              if (index) {
                localStorage.setItem('grupo_id', data[index].grupo_id);
                setUser(session);
                window.location.reload();
              } else {
                signOut();
              }
            } else {
              localStorage.setItem('grupo_id', data[0].grupo_id);
              setUser(session);
              window.location.reload();
            }
          } else {
            AlertDialog({message: "El usuario no tiene permisos asignados.", isNotification:true, type:'error'});
          }
        }
      }
      setLoading(false);
    }catch(error){
      setLoading(false);
    }
  }
  const abrirRecuperacion=()=>{
    //setIsPopUpRecuperarOpen(true);
    history.push('/reset-password');
  }

  const handleSubmitRecuperacion =(e)=>{
    let validacion = recuperarRef.current.instance.validate();
    if (!validacion.isValid) return;
    //enviar correo y verificar que el usuario exista
    let correo = "example@domain.com";//esto se debe de obtener despues del usuario
    notify({message: "Se ha enviado un enlace para restablecer su contraseña al correo "+correo+"."});
    setIsPopUpRecuperarOpen(false);
  }

  const goToLogin=()=>{
    setIsPopUpRecuperarOpen(false);
  }

  //#region validaciones
  const recuperarValidationRules = {         
    user:[
      {type:"required",message:"Usuario es obligatorio"},
      {type:"stringLength",message:"Máximo de caracteres excedido",max:250},
    ],  
  }
//#endregion

  return (
    <React.Fragment>
    <form className={'login-form'} onSubmit={onSubmit}>
      
      <Form formData={formData} disabled={loading}>
        <Item
          dataField={'user'}
          onOptionChanged={(e)=>{console.log("vaina",e)}}
          editorType={'dxTextBox'}
          editorOptions={userEditorOptions}
        >
          <RequiredRule message="Usuario es requerido" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Contraseña es requerida" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'rememberMe'}
          editorType={'dxCheckBox'}
          editorOptions={rememberMeEditorOptions}
        >
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Entrar'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        <ButtonItem 
          buttonOptions={{ width:'100%',type:'normal',stylingMode:"text"
            ,text:"Olvidé mi contraseña", onClick: abrirRecuperacion}}
        />

          <Item>
          {/*<div className={'link'}>
            <Link to={'/reset-password'}>Olvidé mi contraseña</Link>
            </div>*/}
        </Item>
      </Form>
    </form>
    <Popup
        visible={isPopUpRecuperarOpen}
        onHiding={() => {setIsPopUpRecuperarOpen(false)} }
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title={"Recuperando Acceso"}
        width="600"
        height="360">
          
        <Form
          ref={recuperarRef}
          formData={recuperar}
          readOnly={false}
          showColonAfterLabel={true}
          validationGroup="recuperarValidationRules" >
            <GroupItem colCount={1}>    
              <div className='titulo'>
               
                  La recuperación de contraseña se puede realizar capturando su usuario del sistema. 
                  Posteriormente, se enviará un correo electrónico con un link de recuperación, 
                  el cual le permitirá restablecer la contraseña.
                
              </div> 
            </GroupItem> 
            <GroupItem colCount={1} horizontalAlignment="left">      
              <Item horizontalAlignment="left" colSpan={1} 
                dataField="user"
                editorOptions={{width:'100%', stylingMode: 'filled', placeholder: 'Usuario'}}
                validationRules={recuperarValidationRules.user}>
                <Label visible={false} />
              </Item>
            </GroupItem> 
            <ButtonItem horizontalAlignment="center" colSpan={1}
              editorOptions={{width:'60%',text:'RECUPERAR CONTRASEÑA',type:'success',onClick:(e) => handleSubmitRecuperacion(e)}}
            />    
            <ButtonItem horizontalAlignment="center" colSpan={1}
              editorOptions={{width:'60%',text:"Volver a autentificación",type:'normal',stylingMode:"text", onClick:goToLogin}}
            />  
          </Form>       
        </Popup>      
    </React.Fragment>
  );
}

const userEditorOptions = { stylingMode: 'filled', placeholder: 'Usuario', mode: 'text' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Contraseña', mode: 'password' };
const rememberMeEditorOptions = { text: 'Recordar usuario', elementAttr: { class: 'form-text' } };
export default LoginForm;