import React,{useState} from "react"
import ContentLoader from "react-content-loader"
import { LoadPanel } from 'devextreme-react/load-panel';

const MyLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={'100%'}
    height={460}
    viewBox="0 0 95% 460"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="18" rx="2" ry="2" width="95%" height="10" /> 
    <rect x="0" y="34" rx="2" ry="2" width="95%" height="10" /> 
    <rect x="0" y="60" rx="2" ry="2" width="95%" height="400" />
  </ContentLoader>
)

export const GridLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={'100%'}
    height={300}
    viewBox="0 0 95% 360"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="34" rx="2" ry="2" width="95%" height="10" /> 
    <rect x="0" y="60" rx="2" ry="2" width="95%" height="300" />
  </ContentLoader>
)
export const Lines3 = (props) => (
  <ContentLoader 
    speed={2}
    width={'100%'}
    height={170}
    viewBox="0 0 95% 360"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="34" rx="2" ry="2" width="95%" height="10" /> 
    <rect x="0" y="60" rx="2" ry="2" width="95%" height="10" />
    <rect x="0" y="60" rx="2" ry="2" width="95%" height="150" />
  </ContentLoader>
)
  export const Loading = ({isLoading,children}) => {
    const [serial] = useState("loading_"+makeid(5))
    return <div id={serial}>
        {children}
        <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={{ of: `#${serial}` }}
            visible={isLoading!==null && !!isLoading}
            showIndicator={true}
            shading={true}
            showPane={true}
            closeOnOutsideClick={false}
        />
    </div>
  }

  function makeid(length) {
    var result           = [];
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result.push(characters.charAt(Math.floor(Math.random() * 
    charactersLength)));
   }
   return result.join('');
}
export default MyLoader