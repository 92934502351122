import CryptoJS from 'crypto-js';
const cookiename = "SIC_UserSettings";
const Fingerprint = require('fingerprintjs2');
const bFPOptions = {
    NOT_AVAILABLE: 'not available',
    ERROR: 'error',
    EXCLUDED: 'excluded',
  }
  

Fingerprint.get(bFPOptions, function (components) {
    var values = components.map(function (component) { return component.value })
    _SESSION.browserFingerPrint = Fingerprint.x64hash128(values.join(''), 31)
  })

const _SESSION = {
    browserFingerPrint:  undefined,
    doesHttpOnlyCookieExist:()=>{
      var d = new Date();
      d.setTime(d.getTime() + (1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cookiename + "=new_value;path=/;" + expires;
      if (document.cookie.indexOf(cookiename + '=') === -1) {
          return true;
       } else {
          return false;
       }
    },
    getSpaceNameProperty:(property) => encodeURI(btoa(`${property}`)).replace(/=/g, ""),
    Decrypt: async(text)=>{
      return await new Promise((resolver)=>{
        let callBack =()=>{
         if(_SESSION.browserFingerPrint === undefined) setTimeout(callBack,100);
            else { 
              try {
                var key = CryptoJS.enc.Utf8.parse(_SESSION.browserFingerPrint.substring(0,32));
                var iv = CryptoJS.enc.Utf8.parse(_SESSION.browserFingerPrint.split("").reverse().join("").substring(0,16));
                var decrypted = CryptoJS.AES.decrypt(text, key,
                    {
                        keySize: 256 / 8,
                        iv: iv,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    }).toString(CryptoJS.enc.Utf8);
               //let sDecrypted = CryptoJS.enc.Base64.stringify(decrypted.ciphertext); 
               resolver(decrypted);
            }
            catch (err) {
              resolver(null);
            }
            }
           }
            
            callBack();
          })
    },
    Encript: async (text) => { 
        return await new Promise((resolver)=>{
           let callBack = ()=>{
             if(_SESSION.browserFingerPrint === undefined) setTimeout(callBack,100);
             else { 
               try {
                 var key = CryptoJS.enc.Utf8.parse(_SESSION.browserFingerPrint.substring(0,32));
                 var iv = CryptoJS.enc.Utf8.parse(_SESSION.browserFingerPrint.split("").reverse().join("").substring(0,16));
                 var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key,
                     {
                         keySize: 256 / 8,
                         iv: iv,
                         mode: CryptoJS.mode.CBC,
                         padding: CryptoJS.pad.Pkcs7
                     });
                let sEncrypted = CryptoJS.enc.Base64.stringify(encrypted.ciphertext); 
                 resolver(sEncrypted);
             }
             catch (err) {
               resolver( "");
             }
             }
           }
           callBack();
       })
     },
}

export default _SESSION;