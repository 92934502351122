import React,{useEffect, useState} from 'react';
import './index.scss';
import {getInfoEvento} from 'api';
import AlertDialog from 'components/alertDialog/alertDialog';

export default function(props){
    const[data, setData]= useState({
        "ente":'',
        "puesto": '',
        "funcionario":'',
        "anexo_pendientes":'',
        "anexo_terminados": '',
        "porcentaje":'',
        "estatus_aceptacion":'',
        "cerrado_fecha":'',
        "termino_tipo":'',
        "termino_nota":''
    });

    useEffect(() => {
        let mounted = true;
        async function fetchData() {
            if (mounted) {
                if(props.puestoenteId && props.eventoId){
                    setData(await fetchDato(props.eventoId, props.puestoenteId))
                }
            }
        }
        fetchData();
        return () => { mounted = false; };
    }, [props.puestoenteId, props.eventoId]);

    const fetchDato = (async (eventoId, puestoEnteId) =>{
        let result = [];
        const {data:{error,message,data}} =  await getInfoEvento(puestoEnteId, eventoId);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result.ente = data[0].ente_publico;
            result.puesto = data[0].puesto;
            result.funcionario = data[0].funcionario;
            result.anexo_pendientes = data[0].anexo_pendientes;
            result.anexo_terminados = data[0].anexo_terminados;
            result.porcentaje = data[0].porcentaje;
            result.estatus_aceptacion =data[0].estatus_aceptacion;
            result.cerrado_fecha =data[0].cerrado_fecha;
            result.termino_tipo =data[0].termino_tipo;
            result.termino_nota =data[0].termino_nota;
        }
        console.log('info', result);
        return result;
    })

    return(
        <div >
            <div >
                <div className="titleCardInfo">
                    <div className="titleCard-element">
                        <b>{data.ente}</b>
                    </div>
                    <h6 className="titleCard-element">
                        {data.puesto} : {data.funcionario}
                    </h6>
                </div>
                <div className="titleCardInfo">
                    <div className="titleCard-element-container">
                        <div className="titleCard-element-container-title">
                            <b>Anexo pendientes</b>
                        </div>
                        <h6 className="titleCard-element-container-content">{data.anexo_pendientes}</h6>
                    </div>
                    <div className="titleCard-element-container">
                        <div className="titleCard-element-container-title">
                            <b>Anexo terminados</b>
                        </div>
                        <h6 className="titleCard-element-container-content">{data.anexo_terminados}</h6>
                    </div>
                    <div className="titleCard-element-container">
                        <div className="titleCard-element-container-title">
                            <b>% Anexos terminados</b>
                        </div>
                        <h6 className="titleCard-element-container-content">{data.porcentaje}</h6>
                    </div>
                </div>
               {data.estatus_aceptacion === 2 && 
                    <div className="titleCardInfo">
                        <div className="titleCard-element-container">
                            <div className="titleCard-element-container-title">
                                <b>Validacion oficial concluida</b>
                            </div>
                            <h6 className="titleCard-element-container-content">{data.cerrado_fecha}</h6>
                        </div>
                        <div className="titleCard-element-container">
                            <div className="titleCard-element-container-title">
                                <b>Estatus de termino</b>
                            </div>
                            <h6 className="titleCard-element-container-content">{data.termino_tipo}</h6>
                        </div>
                        <div className="titleCard-element-container">
                            <div className="titleCard-element-container-title">
                                <b>Nota</b>
                            </div>
                            <h6 className="titleCard-element-container-content">{data.termino_nota}</h6>
                        </div>
                    </div>
                }
            </div>
        </div>
    );

}