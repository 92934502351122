import {instance} from './../constants/defaultValues';

export const permisionSet = ["NAV_HOM_000"]


export async function signIn(user, password) {
    // Send request
    return instance.post(`/api/seguridad/signin`,{
      "VA":"login",
      "usuario":user,
      "password":password,
    })
}

export async function getLogedUser(){
  return instance.post(`/api/seguridad/getUser`,{})
}

export async function signOut(){
  return instance.post(`/api/seguridad/signout`,{})
}
export async function sysInfo(){
  return instance.post(`/api/security`,{"VC":"version","VA":"get"})
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(pwd, recoveryCode) {
  return instance.post(`/api/security`,{
    "VC":"Password",
    "VA":"changepassword",
    "password":pwd,
    "token":recoveryCode,
  })
}

export async function resetPassword(usuario) {
  //autentificacionController
  return instance.post(`/api/security`,{
    "VC":"Password",
    "VA":"resetpassword",
    "usuario":usuario,
  })
}
