import React,{useRef,useEffect} from 'react';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { observer } from "mobx-react-lite"
import List from 'devextreme-react/list';
import CommentSeccion from 'components/commentSeccion';
import CommentArea from 'components/commentArea';
import Form, {
    GroupItem,
    Item
  } from 'devextreme-react/form';
import ScrollView from 'devextreme-react/scroll-view';
import {Loading} from 'components/loader'
import AlertDialog from 'components/alertDialog/alertDialog'
import {postAddRevisionObservacionComentario,postAddAceptacionObservacionComentario} from 'api';
import AuthCheck from 'components/authCheck/authCheck.js';
import { 
    GRUPO_ENTE_CAPTURA,
    GRUPO_ENTE_REGISTRO_LIMITADO,
    GRUPO_ENTE_REVISOR_ENTRANTE 
} from 'constants/defaultValues';
import { GRUPO_SISTEMA_REVISOR } from 'constants/defaultValues';
import { GRUPO_ENTE_REVISOR_INTERNO } from 'constants/defaultValues';

export default observer(({data,...props}) =>{
const formRef = useRef();
const listRef = useRef();
const grupoUsuario = parseInt(localStorage.getItem('grupo_id'));
let isRevision = data.isRevision;
let puedeDescargar = AuthCheck({permission:isRevision ? "CAPTURA_OBSERV_REVI_DESCARGA" : "CAPTURA_OBSERV_ACEPT_DESCARGA",onlyValidator: true});
let puedeFinalizar = AuthCheck({permission: isRevision ? "CAPTURA_OBSERV_FINALIZAR_REVI":"CAPTURA_OBSERV_FINALIZAR_ACEPT",onlyValidator: true});
let puedeAtender = AuthCheck({permission:  isRevision ? "CAPTURA_OBSERV_ATEND":"CAPTURA_OBSERV_ACEPT_ATEND",onlyValidator: true});


useEffect(() => {
    let mounted = true;
   function listRefInterval() {
        if (mounted) {
            if (listRef.current != null && data.id_evento_puesto_anexo_observacion_revision) {
                listRef.current.instance.$element().find("dx-item-content").css("padding", "5px 0px 0px !important");  
            }
            else setTimeout(listRefInterval,400);
        }
    }
    listRefInterval();
    return () => { mounted = false; };
}, []);

const handleSubmit = ()=>{
    if(formRef.current.instance.validate().isValid){
        props.callback && props.callback(data);
    }
}

const agregarComentario = async(comentario) => {
  data.fromJS({isSavingComment:true});
  let params = {id:data.id_evento_puesto_anexo_observacion_revision,comentario,grupo_id:parseInt(localStorage.getItem("grupo_id"))};
  const {data:{error,message,data:response}} = isRevision ? await postAddRevisionObservacionComentario(params):await postAddAceptacionObservacionComentario(params);
  data.fromJS({isSavingComment:false});
  if(error) AlertDialog({message});   
  else if(response.length) {
      data.fromJS({comentario:null,isSavingComment:false,comentarios:response[0].comentarios});
      props.commentAdded && props.commentAdded();
    }
  
}

const handleFinishRevision = async()=>{
    AlertDialog({title:isRevision?"Finalizar Revisión":"Finalizar aceptación",message:isRevision ?"¿Esta seguro que desea finalizar revisión?":"¿Esta seguro que desea finalizar aceptación?",showCancelButton:true,onActionCallback:async(resp)=>{
        if(resp.value){
            props.finishRevaluacionCb && props.finishRevaluacionCb();
        }
    }})
   
}

const goBack = ()=>{
    data.clear();
}

const canChangeAttention = ()=>{
    // Condiciones limitadoras
    if(isRevision && data.puesto_cerrado) return false;
    if(!puedeAtender) return false;
    if(!data.id_evento_puesto_anexo_observacion_revision) return false;
    // Condiciones de seguimiento
    if(isRevision && [GRUPO_ENTE_CAPTURA, GRUPO_ENTE_REGISTRO_LIMITADO].includes(grupoUsuario) && [4].includes(data.estatus_anexo) && [4].includes(data.estatus)) return true;
    // Condiciones de aceptacion
    if(!isRevision && data.estatus_aceptacion === 2 && data.estatus === 4 && [GRUPO_ENTE_CAPTURA, GRUPO_ENTE_REGISTRO_LIMITADO, GRUPO_ENTE_REVISOR_ENTRANTE].includes(grupoUsuario)) return true;
    return false;
}

const canComment = ()=>{
    // Condiciones limitadoras
    if(isRevision && data.puesto_cerrado) return false;
    if(!data.id_evento_puesto_anexo_observacion_revision) return false;
    // Condiciones de seguimiento
    if(isRevision && [GRUPO_ENTE_CAPTURA, GRUPO_ENTE_REGISTRO_LIMITADO, GRUPO_SISTEMA_REVISOR, GRUPO_ENTE_REVISOR_INTERNO, GRUPO_ENTE_REVISOR_ENTRANTE].includes(grupoUsuario) && [3,4,5].includes(data.estatus_anexo) && [4,5].includes(data.estatus)) return true;
    // Condiciones de aceptacion
    if(!isRevision && ![4,5].includes(data.estatus_aceptacion) && [GRUPO_ENTE_CAPTURA, GRUPO_ENTE_REGISTRO_LIMITADO, GRUPO_ENTE_REVISOR_ENTRANTE].includes(grupoUsuario)) return true;
    return false;
}

const validationRules ={ 
    titulo:[
        {type:"required",message:"El título es requerido"} 
    ],
    descripcion:[
        {type:"required",message:"La descripción es requerida"} 
    ],
}

    return <Popup
          visible={data.visible}
          onHiding={()=> data.visible = false} 
          dragEnabled={false}
          closeOnOutsideClick={false}
          showCloseButton={true}
          showTitle={true}
          title="Agregar Observación"
          container=".dx-viewport"
          width={"65%"}
          height={"auto"}
        >
            <ScrollView id="always" 
                scrollByContent={true}
                bounceEnabled={false}
                showScrollbar={"always"}
                height="500px"
                scrollByThumb={true}>
                <Form
                        ref={formRef}
                        id="form"
                        formData={data}
                        readOnly={false}
                        showColonAfterLabel={true}
                        labelLocation={'top'}
                        minColWidth={300}
                        colCount={3}
                        width={'auto'}
                    >
                        <GroupItem colSpan={!data.id_evento_puesto_anexo_observacion_revision ? 3 :1}>
                    
                                <Item 
                                colSpan={3}
                                label={{text:"Título",location:"top"}}
                                editorOptions={{
                                    readOnly:data.id_evento_puesto_anexo_observacion_revision
                                }}
                                dataField="titulo"
                                stylingMode="outlined"
                                editorType="dxTextArea"
                                validationRules={validationRules.titulo}
                            >
                            </Item>
                            <Item 
                                colSpan={3}
                                label={{text:"Descripción",location:"top"}}
                                dataField="descripcion"
                                editorOptions={{
                                    //disabled:data.id_evento_puesto_anexo_observacion_revision
                                    readOnly:data.id_evento_puesto_anexo_observacion_revision
                                }}
                                editorType="dxTextArea"
                                stylingMode="outlined"
                                validationRules={validationRules.descripcion}
                            />
                            <Item
                            colSpan={3}
                            >
                                <span>{data.info_creacion}</span>
                            </Item>
                            <Item colSpan={3}>
                                <div style={{display:"inline-block"}}>
                                    <Button visible={!data.id_evento_puesto_anexo_observacion_revision} style={{marginRight:"5px"}} width={100} text="Guardar" type="default" onClick={()=>{handleSubmit()}} />
                                    <Button disabled={data.isSaving} visible={data.id_evento_puesto_anexo_observacion_revision && puedeFinalizar && data.estatus !==6 && (grupoUsuario != 4 && data.estatus_anexo != 6)} style={{marginRight:"5px",marginTop:"5px"}} width={220} text={isRevision ? 'Finalizar revisión' : 'Finalizar aceptación'} type='default' onClick={()=>{handleFinishRevision()}}/>
                                    <Button disabled={data.isSaving} visible={canChangeAttention()} style={{marginRight:"5px",marginTop:"5px"}} width={220} text="Marcar como atendida" type='default' onClick={()=>{props.changeEstatus && props.changeEstatus(data.id_evento_puesto_anexo_observacion_revision,5)}}/>
                                    <Button visible={data.id_evento_puesto_anexo_observacion_revision} style={{marginRight:"5px",marginTop:"5px"}}  width={130} text='Regresar' type='default' onClick={()=>{goBack()}}/>
                                    <Button visible={false} width={200} text='Descargar revisión' type='default' onClick={()=>{handleSubmit()}}/>
                                </div>
                                   
                            </Item>
                        </GroupItem>
                        <GroupItem colSpan={2} visible={data.id_evento_puesto_anexo_observacion_revision?true:false}>
                    
                            <Item colSpan={2} visible={data.id_evento_puesto_anexo_observacion_revision?true:false}>
                                <List
                                        ref={listRef}
                                        focusStateEnabled={false}
                                        hoverStateEnabled={false}
                                        dataSource={data.comentarios}
                                        height="350px"
                                        style={{padding:"0"}}
                                        itemRender={(item)=>
                                            <CommentArea {...item}></CommentArea>
                                        }
                                    /> 
                            </Item>
                            {
                            AuthCheck({
                                permission: isRevision ? "CAPTURA_OBSERV_ADD_COMENT" : "CAPTURA_OBSERV_ACEPT_ADD_COMENT",
                                permission:null,
                                component: <Item colSpan={2} visible={canComment()}>
                                    <Loading isLoading ={data.isSavingComment}>
                                    <CommentSeccion data={data} callback={agregarComentario} />
                                </Loading>
                                </Item>
                            })
                            }
                        </GroupItem>
                        </Form>
            </ScrollView>
         
        </Popup>
})