import React, { useState, createContext, useContext, useEffect } from 'react';
import Routes from 'app-routes.js';

const RoutesContext = createContext({});
const useRoutes = () => useContext(RoutesContext);

function RoutesProvider(props) {
  const [routes, setRoutes] = useState(Routes);

  return (
    <RoutesContext.Provider
      value={{ routes, setRoutes }}
      {...props}
    />
  );
}

function withRoutesWatcher(Component) {
  return function (props) {
    const { routes } = props.routes;
    const { setRoutes } = useRoutes();

    useEffect(() => {
      setRoutes(routes);
    }, [routes, setRoutes]);

    return React.createElement(Component, props);
  }
}

export {
  RoutesProvider,
  useRoutes,
  withRoutesWatcher
}
