import React from 'react';
import { Popup} from 'devextreme-react/popup';
import { observer } from "mobx-react-lite"
import DataGrid, {Column,Pager, Paging} from 'devextreme-react/data-grid';
import { toJS } from 'mobx';

export default observer(({data,...props}) =>{

  const obligatoriocellrender = function(e){
    console.log('obligatoriocellrender',e);
    return <div style={{color: (!!e.data.countobligatorio)?"red":'green' }}>
        {(toJS( e.data.countobligatorio) > 0) && <span >{''+toJS(e.data.countobligatorio)+' Errores'}</span>}
        {(toJS( e.data.countobligatorio) > 0) && <span>{", Fila "+toJS(e.data.filaobligatorio)}</span>}
        {!!!e.data.countobligatorio && <i class="dx-icon-check"  style={{fontWeight: 'bold',fontSize: 'x-large'}}></i>}
      </div>
  }
  const longitudcellrender = function(e){
    console.log('obligatoriocellrender',e);
    return <div style={{color: (!!e.data.countlongitud)?"red":'green' }}> 
        {(toJS( e.data.countlongitud) > 0) && <span >{''+toJS(e.data.countlongitud)+' Errores'}</span>}
        {(toJS( e.data.countlongitud) > 0) && <span>{", Fila "+toJS(e.data.filalongitud)}</span>}
        {!!!e.data.countlongitud && <i class="dx-icon-check"  style={{fontWeight: 'bold',fontSize: 'x-large'}}></i>}
      </div>
  }
  const tipocellrender = function(e){
    console.log('obligatoriocellrender',e.data);
      return <div style={{color: (!!e.data.counttipo)?"red":'green' }} >
        {(toJS( e.data.counttipo) > 0) && <span >{''+toJS(e.data.counttipo)+' Errores'}</span>}
        {(toJS( e.data.counttipo) > 0) && <span>{", Fila "+toJS(e.data.filatipo)}</span>}
        {!!!e.data.counttipo && <i class="dx-icon-check"  style={{fontWeight: 'bold',fontSize: 'x-large'}}></i>}
        
      </div>
  }
    return <Popup
          visible={data.popupImportarErroresVisible}
          onHiding={()=> data.popupImportarErroresVisible = false} 
          dragEnabled={false}
          closeOnOutsideClick={false}
          showCloseButton={true}
          showTitle={true}
          title="Errores al importar"
          container=".dx-viewport"
          width={1000}
          height={600}
        >
          {/*data.filesImportar.length > 0 && <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: 'Importar archivos',
                onClick: props.onImportar
              }}
            />*/}
          <p>
            Errores asociados a la importación masiva.
          </p>
          <DataGrid key="id_archivos_importar" colSpan={12}
            dataSource={data.importarErrores}
            columnAutoWidth={true}
            repaintChangesOnly={true}
            showBorders={true} width={"100%"}
            height={400}
            rowAlternationEnabled={true}
            > 
            <Paging defaultPageSize={10000} /> 
                <Pager
                showPageSizeSelector={false}
                allowedPageSizes={10000}
                showInfo={false} />
             <Column dataField={'columna'} caption={'Columna'} alignment='left' width={350} />
             <Column dataField={'filaobligatorio'} caption={'Obligatorio'} alignment='center' width={150}  cellRender={obligatoriocellrender}  />
             <Column dataField={'filalongitud'} caption={'Longitud'} alignment='center' width={150}  cellRender={longitudcellrender}  />
             <Column dataField={'filatipo'} caption={'Tipo'} alignment='center' width={150}  cellRender={tipocellrender} />
            </DataGrid>
        </Popup>
})