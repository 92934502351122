import {instance} from '../constants/defaultValues';

export function getEventoEnte(parameters){
    return instance.post(`/api/read`,{
        "VC":"evento_ente_publico",
        "VA":"byid_evento",
        ...parameters
    });
}

export function postAddEventoEnte(parameters){
    return instance.post(`/api/write`,{
        "VC":"evento_ente_publico",
        "VA":"add_ente",
        ...parameters
    })
}


export function deleteEventoEnte(parameters){
    return instance.post(`/api/delete`,{
        "VC":"evento_ente_publico",
        "VA":"bysystem",
        ...parameters
    });
}

export function postBlockEventoEnte(params){
    return instance.post(`/api/write`,{
        "VC":"evento_ente_publico",
        "VA":"bloquear",
        ...params
    })
}