import React from 'react';
import { Popup} from 'devextreme-react/popup';
import { observer } from "mobx-react-lite"
import EventoRegistro from "./eventoRegistro"

export default observer(({data,...props}) =>{

    return data.registroPopupVisible && <Popup
          visible={data.registroPopupVisible}
          onHiding={()=> data.registroPopupVisible = false} 
          dragEnabled={false}
          closeOnOutsideClick={false}
          showCloseButton={true}
          showTitle={true}
          title="Agregar Registro"
          container=".dx-viewport"
          width={"80%"}
          height={"80%"}
        >
          <EventoRegistro data={data} {...props}></EventoRegistro>
        </Popup>
})