import React, { useState,useEffect } from 'react';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
 //TODO: CSS - Se utiliza para posicionar el panel de loading

const CustomPopUp =  ({width = null,height = null,visible=false,title = "",render = null,onClose = ()=> {}}) => {
  const [modalTitle,setModalTitle] = useState(title);
  const [isVisible,setIsVisible] = useState(visible);
  const [mRender, setmRender] = useState(render);
  
  const vw = Math.max(document.documentElement.clientWidth -50 || 0, window.innerWidth -50 || 0, 600)
  const vh = Math.max(document.documentElement.clientHeight -50 || 0, window.innerHeight -50 || 0, 500)

  
  useEffect(() => {
    let mounted = true;
    async function fetchData() {
      if (mounted) {
        setIsVisible(visible);
        setModalTitle(title);
        setmRender(render);
      }
    }
    fetchData();
    return () => { mounted = false; };
  }, [visible,title,render]);

  const onHiding = ()=>{
    onClose && onClose();
  }
   

  return ( <div>{isVisible && <Popup
          visible={isVisible}
          onHiding={onHiding}
          dragEnabled={false}
          closeOnOutsideClick={false}
          showTitle={true}
          title={modalTitle}
          width={width ? width : vw}
          height={height? height:vh}
        >
          <ScrollView width='100%' height='100%'>
            {mRender}
          </ScrollView>
        </Popup>}</div>
  );
}


export default class CustomPopUpWrapper extends React.Component{
  constructor(props){
    super(props);
    this.state={
      visible: !!props.visible,
      title: props.title,
      onHide: props.onClose,
      height: props.height,
      width: props.width,
      children: props.children
    }
    this.setParams = this.setParams.bind(this);
    this.hide = this.hide.bind(this);
  }

  setParams = (params) => {
    this.setState({...this.state,...params})
  }
  
  componentWillUpdate = (nextProps) => {
    if(nextProps.children !== this.state.children){
      this.setState({children:nextProps.children});
    }
  }

  onHiding = ()=>{
    this.setState({visible:false})
    this.state.onHide && this.state.onHide();
  }
  hide = ()=>{this.onHiding()}
  show = ()=>{this.setState({visible:true})}
  render(){
    const {visible,title,children,height,width} = this.state;
    return <CustomPopUp title={title} width={width} height={height} visible={visible} render={children} onClose={this.onHiding}></CustomPopUp>
  }
}
