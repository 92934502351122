import React, { useState,useEffect,useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Form, {ButtonItem, GroupItem, Item, Label} from 'devextreme-react/form';
import ReportViwer from 'components/reportviewer';
import { LoadPanel } from 'devextreme-react/load-panel';
import {getEntePublicoPuesto, postEntePublicoPuesto,
    getEntePuestoBySearchTerm, getEntePuestoJerarquiaBySearchTerm,
    getEnteUnidadAdministrativaBySearchTermByEnte
} from 'api'
import 'devextreme-react/autocomplete';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import AlertDialog from 'components/alertDialog/alertDialog'
import lang from 'lang';
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {DEFAULT_PAGE_SIZE_CMB} from 'constants/defaultValues';
import DataGrid, {Column, FilterRow } from 'devextreme-react/data-grid';

const position = { of: '#usuario' }; //TODO: CSS - Se utiliza para posicionar el panel de loading



export default function (props) {
    //#region hook de control
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [isPopUpOpen,setIsPopupOpen] = useState(false);
    const formRef = useRef(null);
    const reportVieweRef = useRef();
    //#endregion

    //#region hook de estado
    const [entePublicoPuesto,setEntePublicoPuesto] = useStateWithCallbackLazy({
        "id":0,
        "id_padre":0,
        "nombre":'',
        "clave":'',
        "id_unidad_administrativa": props.location.state && props.location.state.unidad_administrativa? props.location.state.unidad_administrativa.id: 0,
        "id_jerarquia":null,
        "id_funcionario":0,
        "nombramiento":'',
        'id_ente_publico': props.location.state && props.location.state.ente_publico_id ? props.location.state.ente_publico_id : null,

        "padre":'',
        "unidad_administrativa":'',
        "jerarquia":'',
        "funcionario":'',
        "funcionario_nombre":'',
        "": false,
        "cmb_padre":{"id":0,"nombre":''},
        "cmb_unidad_administrativa":{
            "id": props.location.state && props.location.state.unidad_administrativa? props.location.state.unidad_administrativa.id: 0,
            "nombre": props.location.state && props.location.state.unidad_administrativa? props.location.state.unidad_administrativa.nombre: ''
        },
        "cmb_jerarquia":{"id":0,"nombre":''},
        "cmb_funcionario":{"id":0,"nombre":''},

        "ente_publico_nombre": props.location.state && props.location.state.unidad_administrativa? props.location.state.unidad_administrativa.ente_publico_nombre: '',
        "unidad_administrativa_nombre": props.location.state && props.location.state.unidad_administrativa? props.location.state.unidad_administrativa.nombre: '',
        "historial":[],
    });

    const [isEdit, setIsEdit] = useState(false);
    const [visible, setVisible] = useState(false);
    
    //#endregion
    
    //#Cargar datos iniciales
    useEffect(() => {
        let mounted = true; 
        async function fetchData(){
            let ent = {...entePublicoPuesto};
            if (mounted) { 
                if(props.location.state && props.location.state.id){   
                    setIsEdit(true);
                    ent = await fetchEntePublicoPuesto(props.location.state.id); 
                    setEntePublicoPuesto(ent);    
                }   
                setIsLoading(false);
            }
        }
        fetchData();
        return () => { mounted = false; };
      },[]); 
    //#endregion

    //#region fetch
    const fetchEntePublicoPuesto = (async(entePublicoPuestoId) =>{
        let result = {...entePublicoPuesto};
        if(entePublicoPuestoId){
           
            const {data:{error,message,data}} = await getEntePublicoPuesto({entePublicoPuestoId});
            console.log('fetchEntePublicoPuesto', data)
           
            if(error){
                AlertDialog({message: message});
            }else if(data.length){
                result.id = data[0].id;
                result.id_padre = data[0].id_padre;
                result.nombre = data[0].nombre;
                result.clave = data[0].clave;
                result.id_unidad_administrativa = data[0].id_unidad_administrativa;
                result.id_jerarquia = data[0].id_jerarquia;
                result.id_funcionario = data[0].id_funcionario;
                result.nombramiento = data[0].nombramiento;
                result.clave = data[0].clave;

                result.padre = data[0].padre;
                result.unidad_administrativa = data[0].unidad_administrativa;
                result.jerarquia = data[0].jerarquia;
                result.funcionario = data[0].funcionario;
                result.funcionario_nombre = data[0].funcionario_nombre;

                result.cmb_padre = data[0].cmb_padre;
                result.cmb_unidad_administrativa = data[0].cmb_unidad_administrativa;
                result.cmb_jerarquia = data[0].cmb_jerarquia;
                result.cmb_funcionario = data[0].cmb_funcionario;
                result.id_ente_publico = data[0].id_ente_publico;
                result.historial =data[0].historial;

                result.flag_titular = data[0].flag_titular === "1" ? true : false;
            }
        }
        return result;
    })

    const fetchPadre = (async ({seachterm = ''}, take = 1, skip = 0, id_a_excluir=0) =>{
        let result = [];
        const {data:{error,message,data}} =  await getEntePuestoBySearchTerm(seachterm, take, skip, id_a_excluir, entePublicoPuesto.id_ente_publico, entePublicoPuesto.id);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    })

    const fetchUnidadAdministrativa = (async ({seachterm = ''}, take = 1, skip = 0) =>{
        let result = [];
        const {data:{error,message,data}} =  await getEnteUnidadAdministrativaBySearchTermByEnte(seachterm, take, skip, entePublicoPuesto.id_ente_publico);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    })
    const fetchJerarquia = (async ({seachterm = ''}, take = 1, skip = 0) =>{
        let result = [];
        const {data:{error,message,data}} =  await getEntePuestoJerarquiaBySearchTerm(seachterm, take, skip);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    })
    //#endregion

    //#region origen de datos
    const dataSourcePadre = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["id"],
            load: (options) => {
                if(options.filter)  return fetchPadre({}, options.take, options.skip, entePublicoPuesto.id);   
                else if(options.searchValue)  return fetchPadre({seachterm:options.searchValue},
                                                         options.take, options.skip, entePublicoPuesto.id);    
                else return fetchPadre({}, options.take, options.skip, entePublicoPuesto.id);                 
            },
            byKey: (key) => {
                return entePublicoPuesto.cmb_padre ? entePublicoPuesto.cmb_padre : [] ;
            }
        })
    });
    const dataSourceUnidadAdministrativa= new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["id"],
            load: (options) => {
                if(options.filter)  return fetchUnidadAdministrativa({}, options.take, options.skip);   
                else if(options.searchValue)  return fetchUnidadAdministrativa({seachterm:options.searchValue}, options.take, options.skip);    
                else return fetchUnidadAdministrativa({}, options.take, options.skip);                 
            },
            byKey: (key) => {
                console.log("byKey", props.location.state);
                return entePublicoPuesto.cmb_unidad_administrativa ? entePublicoPuesto.cmb_unidad_administrativa : [] ;
            }
        })
    });
    const dataSourceJerarquia = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["id"],
            load: (options) => {
                if(options.filter)  return fetchJerarquia({}, options.take, options.skip);   
                else if(options.searchValue)  return fetchJerarquia({seachterm:options.searchValue}, options.take, options.skip);    
                else return fetchJerarquia({}, options.take, options.skip);                 
            },
            byKey: (key) => {
                return entePublicoPuesto.cmb_jerarquia ? entePublicoPuesto.cmb_jerarquia : [] ;
            }
        })
    });
    //#endregion
    //#region eventos interfaz
    const goToUnidadAdministrativa = () =>{
        console.log("goToUnidadAdministrativa", props);
        history.replace({pathname:'/catalogos/entePublicoUnidadAdministrativa/detalle', 
            state: {
                ente_publico_id: props.location.state.unidad_administrativa.ente_publico_id,
                ente_publico_nombre: props.location.state.unidad_administrativa.ente_publico_nombre,
                ente_publico_nombre_corto: props.location.state.unidad_administrativa.ente_publico_nombre_corto,
                unidad_administrativa_id: props.location.state.unidad_administrativa.id,
                unidad_administrativa_orden: props.location.state.unidad_administrativa.orden,
                unidad_administrativa_nombre: props.location.state.unidad_administrativa.nombre
            }
        });
      }
      const verNombramiento = () =>{
        AlertDialog({message:"El funcionario no cuenta con un nombramiento cargardo."});
      }
    //#region eventos CRUD
    const handleSubmit = (async (e) => {    
        e.preventDefault();
        setIsLoading(true);
        const {data:{error,message}} = await postEntePublicoPuesto(entePublicoPuesto);
        if(error) AlertDialog({message: message});  
        else goToUnidadAdministrativa();
        
        setIsLoading(false);
    });
    //#endregion

    //#region validaciones
    const customCallback =(e) => {
        if (visible) 
            return true;
        else
            return false;
    }

    const validationRules ={ 
        nombre:[
            {type:"required",message:"Nombre es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:1000}
        ],
        id_unidad_administrativa:[ {type:"required",message:"Unidad administrativa es obligatorio"}, ],
        id_jerarquia:[ {type:"required",message:"Jerarquia es obligatorio"}, ],
    }
    //#endregion


    //#region render
    return (
        <React.Fragment >
            <h2 className={'content-block'}>{lang.getString("ente.publico.puesto.detail.title")}</h2>
            <div id="entePublicoPuesto" className={'content-block'}>
                <ReportViwer ref={reportVieweRef}/>
                <div className={'dx-card responsive-paddings'}>
                
                <form  onSubmit={handleSubmit}>
                    <Form
                        ref={formRef}
                       formData={entePublicoPuesto}
                       readOnly={false}
                       showColonAfterLabel={true}
                       showValidationSummary={true}
                       validationGroup="validationRules"
                    >
                        <GroupItem colCount={2}>
                            <Item 
                                colSpan={4}
                                dataField="ente_publico_nombre"
                                stylingMode="underlined"
                                disabled={true}>
                                <Label text={lang.getString("unidad.administrativa.detail.ente_nombre")}></Label>
                            </Item>

                            <Item
                                colSpan={4}
                                dataField="id_unidad_administrativa"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    //placeholder: "",
                                    searchEnabled:true,
                                    searchMode:"contains",
                                    displayExpr: (item) => item && item.nombre,                        
                                    searchExpr: (item) =>  item && item.nombre,                 
                                    valueExpr: "id",
                                    dataSource: dataSourceUnidadAdministrativa
                                }}
                                validationRules={ validationRules.id_unidad_administrativa } >
                                <Label text={lang.getString("ente.publico.puesto.caption.id_unidad_administrativa")}></Label>
                            </Item> 
                        </GroupItem>
                         <GroupItem  colCount={1}>  
                            <Item
                                colSpan={4}
                                dataField="id_padre"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    //placeholder: "",
                                    searchEnabled:true,
                                    searchMode:"contains",
                                    displayExpr: (item) => item && item.nombre,                        
                                    searchExpr: (item) =>  item && item.nombre,                 
                                    valueExpr: "id",
                                    dataSource: dataSourcePadre
                                }}
                                validationRules={ validationRules.id_padre } >
                                <Label text={lang.getString("ente.publico.puesto.caption.id_padre")}></Label>
                            </Item> 
                        </GroupItem>
                        <GroupItem  colCount={2}>  
                            <Item 
                                colSpan={4}
                                dataField="nombre"
                                stylingMode="underlined"
                                validationRules={validationRules.nombre}>
                                    <Label text={lang.getString("ente.publico.puesto.caption.nombre")}></Label>
                            </Item>       
                            <Item 
                                colSpan={4}
                                dataField="clave"
                                stylingMode="underlined"
                                editorOptions={{ readOnly:false,}}>
                                    <Label text={lang.getString("ente.publico.puesto.caption.clave")}></Label>
                            </Item>             
                        </GroupItem>

                        <GroupItem  colCount={2}>  
                            <Item
                                colSpan={4}
                                dataField="id_jerarquia"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    searchEnabled:true,
                                    searchMode:"contains",
                                    displayExpr: (item) => item && item.nombre,                        
                                    searchExpr: (item) =>  item && item.nombre,                 
                                    valueExpr: "id",
                                    dataSource: dataSourceJerarquia
                                }}
                                validationRules={ validationRules.id_jerarquia } >
                                <Label text={lang.getString("ente.publico.puesto.caption.id_jerarquia")}></Label>
                            </Item>      
                        </GroupItem>
                        <GroupItem  colCount={2}>  
                            <Item 
                                colSpan={4}
                                dataField="funcionario_nombre"
                                stylingMode="underlined"
                                editorOptions={{ readOnly:true,}}>
                                    <Label text={lang.getString("ente.publico.puesto.caption.id_funcionario")}></Label>
                            </Item>   

                            <ButtonItem horizontalAlignment="right" verticalAlignment="bottom" colSpan={2}
                                readOnly={true}
                                buttonOptions={{text:lang.getString("ente.publico.puesto.caption.vernombramiento"),
                                     type:'success',onClick: () => verNombramiento()}}
                            />
                            <Item 
                            colSpan={2}
                            dataField="flag_titular"
                            editorType="dxCheckBox"
                            >
                                <Label text="Titular"/>
                        </Item>           
                        </GroupItem>

                        <GroupItem colSpan={12} caption={'Historial de funcionarios'} visible={entePublicoPuesto.id > 0 ? true : false}>
                        <DataGrid key="id" colSpan={12}
                            dataSource={entePublicoPuesto.historial}
                            columnAutoWidth={true}
                            repaintChangesOnly={true}
                            showBorders={true} width={"100%"}
                            rowAlternationEnabled={true}>
                            <FilterRow visible={true} showOperationChooser={false}/>
                            <Column dataField="nombre_evento" caption={'Evento'} />    
                            <Column dataField="rfc_entrante" caption={'RFC Entrante'} />    
                            <Column dataField="nombre_entrante" caption={'Nombre Entrante'}/>
                            <Column dataField="fecha_firma" caption={'Fecha firma'}/>
                            <Column dataField="rfc_saliente" caption={'RFC Saliente'}/>
                            <Column dataField="nombre_saliente" caption={'Nombre saliente'}/>
                        </DataGrid>
                        </GroupItem>
                        <GroupItem colSpan={12}>
                            <ButtonItem horizontalAlignment="left" colSpan={2}
                                buttonOptions={{text:'Guardar',type:'success',useSubmitBehavior:true}}
                            />
                            <ButtonItem horizontalAlignment="left" colSpan={10}
                                buttonOptions={{text:'Regresar',type:'default',onClick:()=>{goToUnidadAdministrativa()}}}
                            />
                        </GroupItem>

                    </Form>
                    </form>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={isLoading}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
                
            </div>
      </React.Fragment>
        );
    //#endregion
}


