import React, { useState,useEffect,useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Form, {
    ButtonItem,
    GroupItem,
    Item,
    Label,
  } from 'devextreme-react/form';
import ReportViwer from 'components/reportviewer';
import { LoadPanel } from 'devextreme-react/load-panel';
import { postEnteSector,getEnteSector } from 'api';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import 'devextreme-react/autocomplete';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {DEFAULT_PAGE_SIZE_CMB,} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog'
import CustomPopUp from 'components/custompopup';
import ComponentWrapper from 'components/componentWrapper';
import lang from 'lang';
const position = { of: '#usuario' }; //TODO: CSS - Se utiliza para posicionar el panel de loading

export default function (props) {
    //#region hook de control
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const formRef = useRef(null);
    const viewerRefFiel = useRef();
    const reportVieweRef = useRef();
    //#endregion

    //#region hook de estado
    const [entidad,setEntidad] = useStateWithCallbackLazy({
        "id":0,
        "nombre":'',
        "clave":'',
        "orden": 0
    });
    const [isEdit, setIsEdit] = useState(false);
    const [visible, setVisible] = useState(false);
    //#endregion

    //#region hook de montado de la vista, se utiliza para generar el estado inicial
    useEffect(() => {
        let mounted = true; 
        async function fetchData(){
            let sector = {...entidad};
            if (mounted) { 
                //aqui se manda a llamar los fetchs iniciales
                if(props.location.state && props.location.state.id){
                    setIsEdit(true);
                    sector = await fetchEnteSector(props.location.state.id);
                    setEntidad(sector);
                }                
                setIsLoading(false);
            }
        }
        fetchData();
        return () => { mounted = false; };
      },[]); 
    //#endregion

    //#region fetch
    const fetchEnteSector = (async(enteSectorId) =>{
        let result = {...entidad};
        if(enteSectorId){
            const {data:{error,message,data}} = await getEnteSector({enteSectorId});
            if(error){
                AlertDialog({message: message});
            }else if(data.length){
                result.id = data[0].id;
                result.nombre = data[0].nombre;
                result.clave = data[0].clave;
                result.orden = data[0].orden;
            }
        }
        return result;
    })
    //#endregion    
    
    //#region dataSource
    const rubroDataSource = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: "id",
            load: (options) => {               
                return fetchEnteSector(options.id, options.take, options.skip);                    
            },
            byKey: (key) => {
                return [];
            }
        })
    });
    //#endregion

    //#region eventos interfaz
    const goToList = () =>{
      history.push({pathname:'/catalogos/enteSector'});
    }
    //#endregion

    //#region eventos CRUD
    const handleSubmit = (async (e) => {    
        e.preventDefault();
        setIsLoading(true);

        const {data:{error,message}} = await postEnteSector(entidad);
        if(error) AlertDialog({message: message});  
        else      goToList();
        
        setIsLoading(false);
    });
    //#endregion

    //#region validaciones
    const customCallback =(e) => {
        if (visible) 
            return true;
        else
            return false;
    }

    const validationRules ={ 
        nombre:[
            {type:"required",message:"Nombre es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:1000}
        ],
        clave:[
            {type:"required",message:"Clave es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:1000},
        ],
    }
    //#endregion

    //#region render
    return (
        <React.Fragment >
            <ComponentWrapper ref={viewerRefFiel}  component={(props)=> {
                return(
                props.visible ? 
                <CustomPopUp width={600} height={600} visible={true} title={"Registrar fiel"} onClose={()=> props && props.setProps({visible:false})}>
                
                </CustomPopUp>
                :null);
                }
            } 
            />
            <h2 className={'content-block'}>{lang.getString("ente.sector.detail.title")}</h2>
            <div id="usuario" className={'content-block'}>
                <ReportViwer ref={reportVieweRef}/>
                <div className={'dx-card responsive-paddings'}>
                
                <form  onSubmit={handleSubmit}>
                    <Form
                        ref={formRef}
                        formData={entidad}
                        readOnly={false}
                        showColonAfterLabel={true}
                        showValidationSummary={true}
                        validationGroup="usuarioData"
                    >
                        <GroupItem colCount={2}>
                            <Item 
                                colSpan={4}
                                dataField="nombre"
                                stylingMode="underlined"
                                validationRules={validationRules.nombre}>
                                    <Label text={lang.getString("ente.sector.detail.nombre")}></Label>
                            </Item>
                            <Item 
                                colSpan={4}
                                dataField="clave"
                                stylingMode="underlined"
                                validationRules={validationRules.clave}>
                                    <Label text={lang.getString("ente.sector.detail.clave")}></Label>
                            </Item>
                            <Item 
                                colSpan={4}
                                dataField="orden"
                                stylingMode="underlined"
                                editorType="dxNumberBox"
                                editorOptions={{
                                    min:0,format:"##",step: 0,
                                    inputAttr:{style:"text-align:right"}
                                }}
                                validationRules={validationRules.orden}>
                                    <Label text={lang.getString("ente.sector.detail.orden")}></Label>
                            </Item>
                            <Item itemType="empty"/>
                        </GroupItem>
                       
                        <GroupItem colSpan={12}>
                            <ButtonItem horizontalAlignment="left" colSpan={2}
                                buttonOptions={{text:'Guardar',type:'success',useSubmitBehavior:true}}
                            />
                            <ButtonItem horizontalAlignment="left" colSpan={10}
                                buttonOptions={{text:'Regresar',type:'default',onClick:()=>{goToList()}}}
                            />
                        </GroupItem>
                            
                    </Form>
                    </form>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={isLoading}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
                
            </div>
      </React.Fragment>
        );
    //#endregion
}