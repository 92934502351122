import React from "react";
import { Button } from 'devextreme-react/button';
import AlertDialog from 'components/alertDialog/alertDialog'
import XLSX from 'xlsx';
import AuthCheck from '../../components/authCheck/authCheck.js';
import { 
    GRUPO_ENTE_CAPTURA,
    GRUPO_ENTE_REGISTRO_LIMITADO
} from 'constants/defaultValues';
export default function(props){
    const grupoUsuario = parseInt(localStorage.getItem('grupo_id'));
    const handleFileChange = e => {
        e.preventDefault();
        //console.log('E componente importar', e);
        let reader = new FileReader();
        //console.log('Reader componente importar', reader);
        let newFile = e.target.files[0];
        //console.log('New files', newFile);
        if (newFile)
        {
            //this.setState({isLoading:true,isFileSelected:true,file:newFile,missingColumns:''},()=>{
                reader.onloadend = () => {
                    //IsDebug && 
                    let workbook = XLSX.read(new Uint8Array(reader.result), { type: "array",dateNF:"DD/MM/YYYY" });
                    //console.log("wb",workbook);
                    let sheet = workbook.Sheets[workbook.SheetNames[0]];
                    //console.log("sh",sheet);
                    //let data = XLSX.utils.sheet_to_json(sheet, { raw: false, header: 1, range: props.headerPosition!= undefined && props.headerPosition!= null?(props.headerPosition):0 });
                    let data = XLSX.utils.sheet_to_json(sheet, { raw: false, header: 1 });
                    //console.log("da",data);
                    //let columns = data.shift();

                    let mapper = data[props.headerPosition];
                    if(props.headerPosition > data.length || !mapper)
                    {
                        AlertDialog({message:"El archivo seleccionado no cuenta con un formato valido o no contiene datos."})
                        return;
                    }

                    let columns = data[props.headerPosition];// .map(() => 'C');                    
                    let newColumns = [];
                    let counts = {};// {'C': 1};

                    columns.forEach(elem=>{
                        if(newColumns.indexOf(elem)<0)
                        {
                            newColumns.push(elem)
                            counts[elem] = 2;
                        }
                        else{
                            newColumns.push(elem) //+''+counts[elem] +'')
                            counts[elem] += 1;
                        }
                    });
                    
                    columns = newColumns;
                    //columns[0] = 'C1';
                    let missingColumns = [];
                    if(props.headers){
                        props.headers.forEach(elem =>{
                            if(columns.indexOf(elem) < 0 )
                            {
                                missingColumns.push(elem);
                            }
                        })
                        
                    }
                    if( missingColumns.length>0 )
                    {
                        AlertDialog({message:"Faltan las siguientes columnas: "+missingColumns.join()})
                        return;
                    }

                    if(props.headerCount !== undefined && props.headerCount !== null)
                    {
                        newColumns = [];
                        for(let i = 0; i < props.headerCount; i++)
                        {
                            newColumns.push(columns[i])//"C"+(i+1))
                        }
                        columns = newColumns;
                    }
                    
                    data = data.map((item,indD) => {
                        let isRowValid = false;
                        let obj = {};
                        columns.forEach((element, index) => {
                            //if(index >= props.headerCount){
                                
                            //    if(item[index] && item[index].toString().trim() != '')
                            //        isRowValid = true;
                            //}
                            //else {
                                
                                if(item[index] && item[index].toString().trim() != '')
                                    isRowValid = true;
                            //};
                            if(isRowValid)
                                obj[element] = item[index] ? item[index]:"";
                                
                               // console.log("valor", item[index], "tipo", typeof(item[index]));
                        });
                        
                        return obj;
                    });

                    if(Object.keys(data[data.length - 1]).length === 0 && data[data.length - 1].constructor === Object)
                         data = data.filter((val,ind) => Object.keys(val).length > 0 && val.constructor === Object).map((value) => {                                
                             return value;
                         });

                    //console.log("kekefilter",data.filter(elem=> data.indexOf(elem) >= props.headerPosition ));
                    props.fileData( data.filter(elem=> data.indexOf(elem) >= props.headerPosition ));
                };
                reader.readAsArrayBuffer(newFile);
        }
    };

    const canImport = () =>{
        if (props.capturaContinua) return true;
        if(props.puesto_cerrado) return false;
        if([GRUPO_ENTE_CAPTURA, GRUPO_ENTE_REGISTRO_LIMITADO].includes(grupoUsuario) && [1,4].includes(props.estatus_anexo)) return true;
        return false;
    }

    return(
        <div style={{float:"left"}}>
        <input
            accept="*/*"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={handleFileChange}//this.handleFileChange}
            onClick={event => event.target.value = ''}
            
        />
            {                       
                AuthCheck({
                    permission:"CAPTURA_ANEXO_PLANTILLA",
                    component: <Button style={{marginLeft:"5px", marginRight:"5px"}} icon="download" type="default" 
                        text="Descargar plantilla"  onClick={props.descargarPlantilla}/>
                })
            }
            
            
            <label htmlFor="raised-button-file">
                {                          
                    AuthCheck({
                        permission:"CAPTURA_ANEXO_IMPORT",
                        component: canImport()? <Button icon="upload" text="Importar plantilla" type="default" visible={props.flag_disable ? false : true}/>:null
                    })
                }                
            </label>      
        </div>
    );
}