import React, { useState,useEffect,useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Form, {
    ButtonItem,
    GroupItem,
    Item,
    Label,
  } from 'devextreme-react/form';
import ReportViwer from 'components/reportviewer';
import { LoadPanel } from 'devextreme-react/load-panel';
import {getAnexoRubro,postAnexoRubro} from 'api'
import 'devextreme-react/autocomplete';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import AlertDialog from 'components/alertDialog/alertDialog'
import CustomPopUp from 'components/custompopup';
import ComponentWrapper from 'components/componentWrapper';
import lang from 'lang';
const position = { of: '#usuario' }; //TODO: CSS - Se utiliza para posicionar el panel de loading


export default function (props) {
    //#region hook de control
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);;
    const formRef = useRef(null);
    const viewerRefFiel = useRef();
    const reportVieweRef = useRef();
    //#endregion

    //#region hook de estado
    const [anexoRubro,setAnexoRubro] = useStateWithCallbackLazy({
        "id":0,
        "nombre":'',
        "clave":'',
        "orden":0,
    });

    const [isEdit, setIsEdit] = useState(false);
    
    //#endregion
    
    //#Cargar datos iniciales
    useEffect(() => {
        let mounted = true; 
        async function fetchData(){
            let rubro = {...anexoRubro};
            if (mounted) { 
                if(props.location.state && props.location.state.id){   
                    setIsEdit(true);
                    rubro = await fetchAnexoRubro(props.location.state.id); 
                    setAnexoRubro(rubro);    
                }   
                setIsLoading(false);
            }
        }
        fetchData();
        return () => { mounted = false; };
      },[]); 
    //#endregion

    //#region fetch
    const fetchAnexoRubro = (async(anexoRubroId) =>{
        let result = {...anexoRubro};
        if(anexoRubroId){
           
            const {data:{error,message,data}} = await getAnexoRubro({anexoRubroId});
           
            if(error){
                AlertDialog({message: message});
            }else if(data.length){
                result.id = data[0].id;
                result.nombre = data[0].nombre;
                result.clave = data[0].clave;
                result.orden = data[0].orden;
            }
        }
        return result;
    })
    //#endregion
    
    //#region eventos interfaz
    const goToList = () =>{
        history.push({pathname:'/catalogos/anexosRubros'});
      }
    //#region eventos CRUD
    const handleSubmit = (async (e) => {    
        e.preventDefault();
        setIsLoading(true);

        const {data:{error,message}} = await postAnexoRubro(anexoRubro);
        if(error) AlertDialog({message: message});  
        else      goToList();
        
        setIsLoading(false);
    });
    //#endregion

    //#region validaciones  

    const validationRules ={ 
        orden:[
            {type:"required",message:"Nombre es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:50}
        ],
        nombre:[
            {type:"required",message:"Nombre es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:1000}
        ],
        clave:[
            {type:"required",message:"Clave es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:50},
        ],
    }
    //#endregion


    //#region render
    return (
        <React.Fragment >
            <ComponentWrapper ref={viewerRefFiel}  component={(props)=> {
                return(
                props.visible ? 
                <CustomPopUp width={600} height={600} visible={true} title={"Registrar fiel"} onClose={()=> props && props.setProps({visible:false})}>
                
                </CustomPopUp>
                :null);
                }
            } 
            />
            <h2 className={'content-block'}>{lang.getString("anexo.rubro.detail.title")}</h2>
            <div id="anexoRubro" className={'content-block'}>
                <ReportViwer ref={reportVieweRef}/>
                <div className={'dx-card responsive-paddings'}>
                
                <form  onSubmit={handleSubmit}>
                    <Form
                        ref={formRef}
                       formData={anexoRubro}
                       readOnly={false}
                       showColonAfterLabel={true}
                       showValidationSummary={true}
                       validationGroup="anexoRubroData"
                    >
                        <GroupItem  colCount={3}>
                            <Item 
                                colSpan={4}
                                dataField="nombre"
                                stylingMode="underlined"
                                validationRules={validationRules.nombre}>
                                    <Label text={lang.getString("anexo.rubro.caption.nombre")}></Label>
                            </Item>
                            <Item 
                                colSpan={4}
                                dataField="clave"
                                stylingMode="underlined"
                                validationRules={validationRules.clave}>
                                    <Label text={lang.getString("anexo.rubro.caption.clave")}></Label>
                            </Item>    
                            <Item 
                                colSpan={4}
                                dataField="orden"
                                stylingMode="underlined"
                                validationRules={validationRules.orden}>
                                    <Label text={lang.getString("anexo.rubro.caption.orden")}></Label>
                            </Item>               
                            <Item itemType="empty"/>
                        </GroupItem>
                       
                        <GroupItem colSpan={12}>
                            <ButtonItem horizontalAlignment="left" colSpan={2}
                                buttonOptions={{text:'Guardar',type:'success',useSubmitBehavior:true}}
                            />
                            <ButtonItem horizontalAlignment="left" colSpan={10}
                                buttonOptions={{text:'regresar',type:'default',onClick:()=>{goToList()}}}
                            />
                        </GroupItem>

                    </Form>
                    </form>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={isLoading}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
                
            </div>
      </React.Fragment>
        );
    //#endregion
}


