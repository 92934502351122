import {instance} from '../constants/defaultValues';

export function getEventoPuesto({id = 0}){
    
    return instance.post(`/api/read`,{
        "VC":"evento_puesto",
        "VA":"byid",
        "id":id,
    })
    
}

export function getEventosPuestos({take = 1, skip = 0, filter = {}, sort = {}}){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto",
        "VA":"listeventospuestos",
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc
    })
    
}

export function postBlockEventoPuesto(parameters){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto",
        "VA":"bloquear",
       ...parameters
    })
}

export function postBlockAnexosEventoPuesto(id_evento_puesto, flag){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto",
        "VA":"bloquear_anexos",
       "id_evento_puesto":id_evento_puesto,
       "bloqueo": flag ? 1 : 0 
    })
}

export function postBlockAnexosEventoPuestoMasivo(puestos, id_evento){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto",
        "VA":"bloquear_anexos_masivo",
        "puestos":puestos,
        "id_evento":id_evento
    })
}

export function postActaPuestoEventoJson(acta, id_evento_puesto){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto",
        "VA":"acta_json",
        "id_evento_puesto": id_evento_puesto,
        "acta_json": acta
    })
}

export function postClosePuesto(id_evento_puesto){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto",
        "VA":"cerrar",
         id_evento_puesto
    })
}

export function postReOpenPuesto(id_evento_puesto){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto",
        "VA":"reabrir",
         id_evento_puesto
    })
}

export function deleteEventoPuesto(idEventoPuesto){
    return instance.post(`/api/delete`,{
        "VC":"evento_puesto",
        "VA":"bysystem",
        "id" : idEventoPuesto
    });
}

export function postAddEventoPuesto(parameters){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto",
        "VA":"addeventopuestoandanexo",
        ...parameters
    })
}

export function getPuestosByUnidadEnteEvento(parameters){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto",
        "VA":"byenteunidadandevento",
        ...parameters
    })
}

export function getMissingPuestoUnidadEnteEvento({parameters, take = 1, skip = 0, filter = {}, sort = {}}){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto",
        "VA":"missingunidadenteandevento",
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc,
        ...parameters
    });
}

export function getEventoEntesUnidadesPuestosBloqueo({eventoId}){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto",
        "VA":"evento_ente_unidad_puesto_list_bloqueo",
        "id_evento":eventoId
    });
}

export function getEventoEntesUnidadesPuestos({eventoId}){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto",
        "VA":"evento_ente_unidad_puesto_list",
        "id_evento":eventoId
    });
}

export function getEventoEntesUnidadesPuestosByUnidad({eventoId, entePublicoId, unidadAdministrativaId}){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto",
        "VA":"evento_ente_unidad_puesto_list_byunidad",
        "id_evento":eventoId,
        "id_ente_publico":entePublicoId,
        "id_unidad_administrativa":unidadAdministrativaId
    })
}

export function getEventoEntesUnidadesPuestosByUnidad_funcionarios(id_evento_puesto, revisorInterno){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto",
        "VA":"evento_ente_unidad_puesto_list_byunidad_funcionarios",
        "id_evento_puesto":id_evento_puesto,
        "type": revisorInterno? 1: 2
    })
}

export function getCreacionActaInfo(id_evento_puesto){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto",
        "VA":"creacion_acta_data",
        id_evento_puesto:id_evento_puesto
    })
}


export function adjuntoActaFirmada(id_evento_puesto, id_adjunto, fecha_firma){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto",
        "VA":"adjunto_acta_firmada",
        "id_evento_puesto": id_evento_puesto,
        "id_adjunto": id_adjunto,
        'fecha_firma':fecha_firma
    })
}

export function postAddEventoPuestoRevisorAdministrador(parameters){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto",
        "VA":"add_evento_puesto_revisor_administrador",
        ...parameters
    })
}

export function postAddEventoPuestoRevisorInterno(parameters){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto",
        "VA":"add_evento_puesto_revisor_interno",
        ...parameters
    })
}

export function postAsignarEntranteSaliente(parameters){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto",
        "VA":"add_entrante_saliente",
        ...parameters
    })
}

export function generacionActaDoc(parameters,id_evento_puesto){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto",
        "VA":"generacionActa",
        id_evento_puesto,
        ...parameters
    }, {responseType: 'arraybuffer',})
}

export function terminoEventoPuesto(evento_puesto_id, terminoObj){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto",
        "VA":"termino",
        "evento_puesto_id" : evento_puesto_id,
        ...terminoObj
    })
}

export function deleteFuncionariorRevisor(id, evento_puesto_id, revisorInterno){
    return instance.post(`/api/delete`,{
        "VC":"evento_puesto",
        "VA":"funcionario_revisor_interno",
        'funcionario_id':id,
        'evento_puesto_id':evento_puesto_id,
        'type': revisorInterno? 1: 2
    })
}


export function getDescendants(evento_id, puesto_id){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto",
        "VA":"descendants",
        "evento_id": evento_id, 
        "puesto_id": puesto_id
    })
}

export function replicarPuesto(rows, id_evento, id_puesto){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto",
        "VA":"replicar_puestos",
        "id_puesto_titular":id_puesto,
	    "ids":rows, 
	    "id_evento" :id_evento
    })
}
