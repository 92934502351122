import {instance} from '../constants/defaultValues';

export function readRPTseguimientoTitulares(evento_id){
    return instance.post(`/api/read`,{
        "VC":"rpt_reportes",
        "VA":"rpt_seguimiento_titulares",
        "evento_id" : evento_id
    });
}

export function readRPTseguientoPuestos(evento_id){
    return instance.post(`/api/read`,{
        "VC":"rpt_reportes",
        "VA":"rpt_seguimiento_puestos",
        "evento_id" : evento_id
    });
}