import React, { useState,useEffect,useRef } from 'react';
import { useHistory } from 'react-router-dom';
import TreeList, { Column,Selection,Paging,Pager, ColumnChooser } from 'devextreme-react/tree-list';
import Form, {
    ButtonItem,
    GroupItem,
    Item,
    Label,
    EmptyItem
    
  } from 'devextreme-react/form';
import ReportViwer from 'components/reportviewer';
import { LoadPanel } from 'devextreme-react/load-panel';
import {getUsuarios,
    postUsuario,
    getGrupoById,
    getGrupoBySearchTerm,
    getPermisos,
    getGrupoPermisos,
    getUsuariosBySearchTerm
} from 'api'
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import 'devextreme-react/autocomplete';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {DEFAULT_PAGE_SIZE_CMB,
    DATASOURCE_TIPOS_USUARIO,
    DATASOURCE_DURACION_SESION,
    PATTERN_RFC,
    PATTERN_CELULAR,
    MASK_IP,
    PATTERN_CURP,
} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog'
import { Popup } from 'devextreme-react/popup';
import DatePicker from 'components/datePicker';
import { Button} from 'devextreme-react';
import DatePickerItem from 'components/datePicker/datePickerItem';
import moment from 'moment';
import FingerPrintAction from 'components/digitalPersona/';
import CustomPopUp from 'components/custompopup';
import ComponentWrapper from 'components/componentWrapper';
import lang from 'lang';
//const usuarioId = 1; //TODO: quitar esta variable por que se debe obtener del props
const position = { of: '#usuario' }; //TODO: CSS - Se utiliza para posicionar el panel de loading



export default function (props) {
    //#region hook de control
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [isPopUpOpen,setIsPopupOpen] = useState(false);
    const [fingerPrintFlags,setFingerPrintFlags] = useState({
        showFingerComponent:false,
        isValidating:false
    });
    const formRef = useRef(null);
    const viewerRefFiel = useRef();
    const reportVieweRef = useRef();;
    const permisoRef = useRef();
    const permisoRefTramite = useRef();
    const getDataFormRef = () => formRef.current.instance;
    //#endregion

    //#region hook de estado
    const [usuario,setUsuario] = useStateWithCallbackLazy({
        "id":0,
        "usuario" : '',
        "correoElectronico" : '',
        "contraseña" : '',
        "tipoUsuario" : null,
        "duracionSesion":null,
        "horaInicio": null,
        "horaFin": null,
        "usuarioIp":'',
        "fechaUltimaSesion":null,
        "prefijo":'',
        "nombre":'',
        "apellidoPaterno":'',
        "apellidoMaterno":'',
        "rfc":'',
        "celular":'',
        "departamentoId":null,
        "departamento":null,
        "puestoId":null,
        "puesto":null,
        "grupoId":null,
        "grupo":null,
        "delegacionId":null,
        "municipioId":null,
        "incidenciaMunicipio":false,
        "municipio":null,
        "delegacion":null,
        "huella_dactilar":null,
        "grupoPermiso":[],
        "tramiteClasificador":[],
        "clon_id": null,
        "verConstraseña":false,
        "usuario_serial_number":null,
        "masked_serial_number":null,
        "numero_perito": null,
        "folio_perito": null,
        "vigencia": new Date(),
        "especialidad": null,
        "domicilio": null,
        "telefono_perito": null,
        "ciudad":null,
        "zona": null,
        "curp": null,
    });
    const [verConstraseña,setVerConstraseña] = useState(false);
    const [permisosLista,setPermisosLista] = useStateWithCallbackLazy({});
    const [tramiteClasificador,setTramiteClasificador] = useStateWithCallbackLazy([]);
    const [isEdit, setIsEdit] = useState(false);
    const [visible, setVisible] = useState(false);
    
    const treeListGrupoRef = useRef(null);
    const treeListClasificadorRef = useRef(null);
    //#endregion

    //#region hook de montado de la vista, se utiliza para generar el estado inicial
    useEffect(() => {
        let mounted = true; 
        async function fetchData(){
            let user = {...usuario};
            if (mounted) { 
                setPermisosLista(await fetchPermisosLista());
                //aqui se manda a llamar los fetchs iniciales
                if(props.location.state && props.location.state.id){
                    setIsEdit(true);
                    //setUsuario(await fetchUsuario(props.location.state.id));
                    user = await fetchUsuario(props.location.state.id);
                    setUsuario(user);

                    //console.log("00")        
                    //console.log(user)      
                    //console.log("11")  

                }else{

                    let grupoPermisoa=[];
                    let permisoTramitea=[];
                    grupoPermisoa.push(999999999);
                    permisoTramitea.push(999999999);
                    setUsuario({"grupoPermiso": grupoPermisoa })
                }
                setIsLoading(false);
            }
        }
        fetchData();
        return () => { mounted = false; };
      },[]); 
    //#endregion

    //#region fetch
    const fetchUsuario = (async(usuarioId) =>{
        let result = {...usuario};
        if(usuarioId){
            const {data:{error,message,data}} = await getUsuarios({usuarioId});
            if(error){
                AlertDialog({message: message});
            }else if(data.length){
                result.id = data[0].usuario_id;
                result.usuario = data[0].usuario;
                result.correoElectronico = data[0].usuario_correo;
                result.contraseña = data[0].usuario_password;
                result.duracionSesion = data[0].duracion_session;
                result.tipoUsuario = data[0].usuario_tipo_id;
               result.usuarioIp = data[0].usuario_ip;
                result.departamentoId = data[0].usuario_departamento_id;
                result.departamento = data[0].departamento;
                result.puestoId = data[0].usuario_puesto_id;
                result.puesto = data[0].puesto;
                result.grupoId = data[0].grupo_id;
                result.grupo = data[0].grupo;
                result.horaInicio =  data[0].usuario_hora_inicio ? moment(data[0].usuario_hora_inicio, "HH:mm:ss") : null ;
                result.horaFin =  data[0].usuario_hora_fin  ? moment(data[0].usuario_hora_fin, "HH:mm:ss"): null;
                result.fechaUltimaSesion = data[0].fecha_ultima_sesion;
                result.prefijo = data[0].usuario_prefijo;
                result.nombre = data[0].usuario_nombre;
                result.apellidoPaterno = data[0].usuario_apellido_paterno;
                result.apellidoMaterno = data[0].usuario_apellido_materno;
                result.rfc = data[0].usuario_rfc;
                result.municipio = data[0].municipio;
                result.delegacion = data[0].delegacion;
                result.celular = data[0].usuario_celular;
                result.delegacionId = data[0].delegacion_id;
                result.municipioId = data[0].municipio_id;
                result.incidenciaMunicipio =  data[0].incidencia_municipio == 1 ? true: false;
                result.huella_dactilar = data[0].huella_dactilar;
                result.usuario_serial_number = data[0].usuario_serial_number;
                result.masked_serial_number = data[0].usuario_serial_number != null ? data[0].usuario_serial_number.replace(/\d(?=\d{4})/g, "*"):null
                result.numero_perito = data[0].perito_valuador_numero;
				result.folio_perito = data[0].folio;
				result.vigencia = data[0].vigencia;
				result.especialidad = data[0].especialidad;
				result.domicilio = data[0].domicilio;
				result.telefono_perito = data[0].telefono;
				result.ciudad = data[0].ciudad;
                result.zona = data[0].zona;
                result.curp = data[0].usuario_curp;
                result.grupoPermiso = castFromPermisos(data[0].permisos_usuario);
            }
        }
        return result;
    })

    const fetchUsuarios = (async ({seachterm = ''}, take = 1, skip = 0) =>{
        const {data:{error,message,data}} = await getUsuariosBySearchTerm(seachterm, take, skip);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            return data;
        }
        return [];
    })

    const fetchPermisosLista = (async () =>{
        let result = [];
        const {data:{error,message,data}} = await getPermisos();
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    })

    

    const fetchGrupos = (async ({grupoId = 0 ,seachterm = ''}, take = 1, skip = 0) =>{
        let result = [];
        const {data:{error,message,data}} =  grupoId !== 0 ? await getGrupoById(grupoId, take, skip) : await getGrupoBySearchTerm(seachterm, take, skip);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    })
    //#endregion
    
    //#region operaciones
    const castFromPermisos = (permisos) =>{
        return permisos ? permisos.map((val)=>val.permiso_id) : null;
    }

    const castFromClasificadores = (clasificadores) =>{
        let result = [];
        if(clasificadores)
            for(let i = 0; i < clasificadores.length; i++){
                result.push(clasificadores[i].tramite_clasificador_id);
            }
        return result;
    }

    const castToPermisos = (permisosIds) =>{
        let result = [];
        for(let i = 0; i < permisosIds.length; i++){
            result.push({permiso_id: permisosIds[i]});
        }
        return result;
    }

    const castToClasificadores = (clasificadores) =>{
        let result = [];
        for(let i = 0; i < clasificadores.length; i++){
            result.push({tramite_clasificador_id: clasificadores[i]});
        }
        return result;
    }
    //#endregion
    
    //#region dataSource


    const usuarioDataSource = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: "usuario_id",
            load: (options) => {
                if(options.filter)
                    return fetchUsuarios({usuarioId:options.filter[2]}, options.take, options.skip);   
                else if(options.searchValue)
                    return fetchUsuarios({seachterm:options.searchValue}, options.take, options.skip);     
                else 
                    return fetchUsuarios({}, options.take, options.skip);                    
            },
            byKey: (key) => {
                return [];
            }
        })
    });



    const gruposDataSource = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["grupo_id", "grupo_nombre"],
            load: (options) => {
                if(options.filter)
                    return fetchGrupos({grupoId:options.filter[2]}, options.take, options.skip);   
                else if(options.searchValue)
                    return fetchGrupos({seachterm:options.searchValue}, options.take, options.skip);     
                else 
                    return fetchGrupos({}, options.take, options.skip);                    
            },
            byKey: (key) => {
                if(key.grupo_id && key.grupo_nombre)
                    return key;
                else
                    return[];
            }
        })
    });
    //#endregion
    
    //#region eventos interfaz
    const goToPrintCredencial = () =>{
        reportVieweRef.current.setParams({
            visible:true,
            title:"Credicial de perito",
            parameters:{ 
                VN:"rpt_credencial_perito.repx",
                VDS:[
                    {key:"usuario_id",value:usuario.id}
                ],
                generateqr:true 
            }
        })
    }

    const goToPrintCertificado = () =>{
        reportVieweRef.current.setParams({
            visible:true,
            title:"Certificado de perito",
            parameters:{ 
                VN:"rpt_certificado_perito.repx",
                VDS:[
                    {key:"usuario_id",value:usuario.id}
                ],
                generatewm:true
            }
        })
    }

    

    const onValueChangedIncidenciaMunicipio = (e) =>{
        if(e.previousValue){
            //setUsuario({...usuario,incidenciaMunicipio:e.value});
            formRef.current.instance.updateData('incidenciaMunicipio', e.value);
        }
    }

    const onValueChangeFechaUltimaSesion = ({value}) =>{
        //setUsuario({...usuario,fechaUltimaSesion:value});
        formRef.current.instance.updateData('fechaUltimaSesion',value);
    }

    const onSelectionChangedPermisosGrupo = (e) =>{
        if(treeListGrupoRef.current && treeListGrupoRef.current.instance)
       { 
           let result = []
            result = treeListGrupoRef.current.instance.getSelectedRowKeys('all');
            result = result.length === 0 ? usuario.grupoPermiso : result;
            result = result.filter(function(item, pos) { //TODO: se quitan lo elementos duplicados no sabemos por que al cargar la referencia te trae el doble de elementos iniciales
                
                return result.indexOf(item) == pos;
            })
            formRef.current.instance.updateData('grupoPermiso', result);
            permisoRef.current && permisoRef.current.setProps({grupoPermiso:result});
            //formRef.current.instance.updateData('grupoPermiso', result);
            //formRef.current.instance.option("formData").grupoPermiso = result;
            //setUsuario({...usuario,grupoPermiso:result})
    }
}

const onSelectionChangedTramiteClasificador= (e) =>{

    if(treeListClasificadorRef.current && treeListClasificadorRef.current.instance)
    { 
       let result = []
        result = treeListClasificadorRef.current.instance.getSelectedRowKeys('all');
        result = result.length ===  0 ? usuario.tramiteClasificador : result;
        result = result.filter(function(item, pos) { //TODO: se quitan lo elementos duplicados no sabemos por que al cargar la referencia te trae el doble de elementos iniciales
            return result.indexOf(item) == pos;
    })
        //setUsuario({...usuario,tramiteClasificador:result})
        formRef.current.instance.updateData('tramiteClasificador', result);
}
}

    const onValueChangedGrupo = (async (e) =>{
        if(e.value && !isEdit) { 
            const {data:{data}} = await getGrupoPermisos(e.value.grupo_id);
            const result = castFromPermisos(data);
            formRef.current.instance.updateData('grupoPermiso', result);
            //setUsuario({...usuario,grupoPermiso:result})
        }

        if(isEdit)
            setIsEdit(false);

        let user = {...usuario};
        permisoRef.current && permisoRef.current.setProps({visiblePermiso:true,grupoPermiso:user.grupoPermiso,onSelectionChangedPermisosGrupo});
    })



    const cloneUsuarioPermiso = (async () =>{
        if(usuario.clon_id) { 
            let usuarioId = usuario.clon_id;
            const {data:{data}} = await getUsuarios({usuarioId});
            const result = castFromPermisos(data[0].permisos_usuario);
            formRef.current.instance.updateData('grupoPermiso', result);
            setIsPopupOpen(false);
            let user = {...usuario};
            permisoRef.current && permisoRef.current.setProps({visiblePermiso:true,grupoPermiso:user.grupoPermiso,onSelectionChangedPermisosGrupo});
        }
    })

    const handlePopupVisible = () =>{
        setIsPopupOpen(!isPopUpOpen);
        let user = {...usuario};
        permisoRef.current && permisoRef.current.setProps({visiblePermiso:true,grupoPermiso:user.grupoPermiso,onSelectionChangedPermisosGrupo});
    }


    const goToList = () =>{
        history.push({pathname:'/admin/usuarios'});
      }

    const fingerprintFailed = (message)=>{
        AlertDialog({message});
        setFingerPrintFlags({showFingerComponent:false,isValidating:false})
    }

    const fingerPrintSuccess = (data) =>{
        if(!fingerPrintFlags.isValidating) setUsuario({...usuario,huella_dactilar:data});
        else AlertDialog({message:data});

        setFingerPrintFlags({showFingerComponent:false,isValidating:false})
    }

    const setDatosPermisos = ()=>{
        let user = usuario;
        user.grupoPermiso = castFromPermisos(user.grupoPermiso);
        user.tramiteClasificador = castFromClasificadores(user.tramiteClasificador);
        permisoRef.current &&  permisoRef.current.setProps({visiblePermiso:true,grupoPermiso:user.grupoPermiso,onSelectionChangedPermisosGrupo});
    }
    //#endregion

    //#region eventos CRUD
    const handleSubmit = (async (e) => {
        e.preventDefault();
        setIsLoading(true);
        let resultGrupoPermiso =  castToPermisos(usuario.grupoPermiso)
        let resultClasificadores= usuario.tramiteClasificador ? castToClasificadores(usuario.tramiteClasificador) : [];
        usuario.grupoPermiso = resultGrupoPermiso;
        usuario.tramiteClasificador = resultClasificadores;
        usuario.incidenciaMunicipio = usuario.incidenciaMunicipio ? 1 : 0;
        usuario.delegacionId = usuario.delegacion.delegacion_id;
        usuario.departamentoId = usuario.departamento.departamento_id;
        usuario.municipioId = usuario.municipio && usuario.municipio.municipio_id ? usuario.municipio.municipio_id : undefined;
        usuario.grupoId = usuario.grupo.grupo_id;
        usuario.puestoId = usuario.puesto.puesto_id;
        usuario.horaInicio =  usuario.horaInicio ? moment(usuario.horaInicio).format('YYYY-MM-DD HH:mm:ss') :undefined;
        usuario.horaFin = usuario.horaFin ? moment(usuario.horaFin).format('YYYY-MM-DD HH:mm:ss') :undefined;
        if(visible){
            usuario.vigencia = usuario.vigencia === null || usuario.vigencia === undefined ? moment(new Date()).format('YYYY-MM-DD HH:mm:ss') :   moment(usuario.vigencia).format('YYYY-MM-DD HH:mm:ss')
            usuario.folio_perito = parseInt(usuario.folio_perito)
        }
        const {data:{error,message}} = await postUsuario(usuario);
        if(error){
            AlertDialog({message: message});
            setTimeout(() => {
                setDatosPermisos();
            }, 300);
        }
        else{
            //Navegacion
            history.push({pathname:'/admin/usuarios'});
        }
        setIsLoading(false);

    });
    //#endregion

    //#region validaciones
    const customCallback =(e) => {
        if (visible) 
            return true;
        else
            return false;
    }

    const validationRules ={ 
        usuario:[
            {type:"required",message:"Usuario es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:50}
        ],
        correoElectronico:[
            //{type:"required",message:"Correo es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:150},
            {type:"email",message:"Formato de correo electrónico incorrecto"}
        ],
        contraseña:[
            {type:"required",message:"Contraseña es obligatoria"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:250},
        ],
        usuarioIp:[
            {type:"stringLength",message:"Máximo de caracteres excedido",max:50},
        ],
        prefijo:[
            {type:"stringLength",message:"Máximo de caracteres excedido",max:20},
        ],
        nombre:[
            {type:"required",message:"Nombre es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:250},
        ],
        apellidoPaterno:[
            {type:"required",message:"Apellido paterno es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:250},
        ],
        apellidoMaterno:[
            {type:"required",message:"Apellido materno es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:250},
        ],
        
        rfc:[
            //{type:"required",message:"RFC es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:13},
            {type:"pattern",message:"Formato de RFC incorrecto",pattern:PATTERN_RFC}
        ],
        celular:[
            //{type:"required",message:"Celular es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:10},
            {type:"pattern",message:"Formato de celular incorrecto.",pattern:PATTERN_CELULAR}
        ],
        grupo:[
            {type:"required",message:"Grupo es obligatorio."}
        ],
        delegacion:[
            {type:"required",message:"Delegación es obligatorio."}
        ],
        municipio:[
            //{type:"required",message:"Delegacion es obligatorio."}
        ],
        numero_perito:[
            {type:"custom",message:"Número de perito valuador es obligatorio.", reevaluate:true ,validationCallback:customCallback},
        ],
        folio_perito:[
            {type:"custom",message:"folio de perito es obligatorio..", reevaluate:true ,validationCallback:customCallback},
        ],
        especialidad:[
            {type:"custom",message:"Especialidad de perito es obligatorio.", reevaluate:true ,validationCallback:customCallback},
        ],
        domicilio:[
            {type:"custom",message:"Domicilio de perito es obligatorio.", reevaluate:true ,validationCallback:customCallback},    
        ],
        telefono_perito:[
            {type:"stringLength",message:"Máximo de caracteres excedido",max:10},
            {type:"pattern",message:"Formato de telefono incorrecto.",pattern:PATTERN_CELULAR}
        ],
        ciudad:[
            {type:"custom",message:"Ciudad de perito es obligatorio.", reevaluate:true ,validationCallback:customCallback}, 
        ],
        zona:[
            {type:"custom",message:"Zona de perito es obligatorio.", reevaluate:true ,validationCallback:customCallback}, 

        ],
        curp:[
            {type:"stringLength",message:"Máximo de caracteres excedido",max:18},
            {type:"pattern",message:"Formato de CURP incorrecto",pattern:PATTERN_CURP}
        ],
    }
    //#endregion


    //#region render
    return (
        <React.Fragment >
            <ComponentWrapper ref={viewerRefFiel}  component={(props)=> {
                return(
                props.visible ? 
                <CustomPopUp width={600} height={600} visible={true} title={"Registrar fiel"} onClose={()=> props && props.setProps({visible:false})}>
                
                </CustomPopUp>
                :null);
                }
            } 
            />
            <h2 className={'content-block'}>Usuarios</h2>
            <div id="usuario" className={'content-block'}>
                <ReportViwer ref={reportVieweRef}/>
                <div className={'dx-card responsive-paddings'}>
                {fingerPrintFlags.showFingerComponent && 
                    <FingerPrintAction 
                        isValidating={fingerPrintFlags.isValidating} 
                        onSuccess={fingerPrintSuccess} 
                        onFailed={fingerprintFailed}
                        {...usuario}
                        />
                }
                <form  onSubmit={handleSubmit}>
                    <Form
                        ref={formRef}
                       formData={usuario}
                       readOnly={false}
                       showColonAfterLabel={true}
                       showValidationSummary={true}
                       validationGroup="usuarioData"
                    >
                        <GroupItem caption={lang.getString("usuarios.detail.datos-generales")} colCount={12}>
                            <Item 
                                colSpan={4}
                                dataField="usuario"
                                stylingMode="underlined"
                                validationRules={validationRules.usuario}>
                            </Item>
                            <Item 
                                colSpan={4}
                                dataField="correoElectronico"
                                stylingMode="underlined"
                                validationRules={validationRules.correoElectronico}>
                                    <Label text={lang.getString("usuarios.detail.correo")}></Label>
                            </Item>
                           
                            <Item 
                                colSpan={2}
                                dataField="contraseña"
                                stylingMode="underlined"
                                editorType="dxTextBox"
                                editorOptions={{
                                    mode: verConstraseña ? 'text':'password'
                                    
                                }}
                                validationRules={validationRules.contraseña}
                            >
                            </Item>
                            <ButtonItem horizontalAlignment="right" colSpan={1} 
                                        buttonOptions={{
                                            text:'ver',
                                            type:'success',onClick:()=>{ 
                                                setVerConstraseña(!verConstraseña);
                                            }
                                        }}
                                    />
                            <Item itemType="empty"/>
                            <Item
                                colSpan={4}
                                dataField="duracionSesion"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    valueExpr:"duracion_sesion",
                                    searchEnabled:true,
                                    searchMode:"contains",
                                    displayExpr: (item) => item && item.duracion_sesion,                       
                                    searchExpr: (item) => item && item.duracion_sesion,                 
                                    value:usuario.duracionSesion,
                                    dataSource:DATASOURCE_DURACION_SESION
                                }}
                                validationRules={[
                                    {type:"required",message:"La duración de la sesión es obligatoria"}
                                ]}>
                                    <Label text={lang.getString("usuarios.detail.duracion-sesion")}></Label>
                            </Item> 
                            
                            <Item 
                                colSpan={4}
                                dataField="fechaUltimaSesion"
                                disabled = {true}
                                label={{text:"Fecha de última sesión"}}
                                validationRules={[
                                    {type:"required",message:"Fecha de última sesión es obligatoria"}
                                ]}
                                >
                                <DatePicker value={usuario.fechaUltimaSesion} onValueChange={onValueChangeFechaUltimaSesion} type="dateTime" />{//TODO: cambiar a datepickeritem
                                }
                               
                            </Item>
                                
                                {DatePickerItem({dataField:"horaInicio", colSpan:4, type:"time", inputAttr:{readOnly:true}, label:{text:lang.getString("usuarios.detail.hora-inicio")}})}                
                                {DatePickerItem({dataField:"horaFin", colSpan:4, type:"time", inputAttr:{readOnly:true}, label:{text:lang.getString("usuarios.detail.hora-fin")}})}
                            <Item 
                                colSpan={4}
                                dataField="usuarioIp"
                                stylingMode="underlined"
                                editorOptions={MASK_IP}
                                validationRules={validationRules.usuarioIp}
                            />
                            
                            <Item
                                colSpan={4}
                                dataField="tipoUsuario"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    valueExpr:"usuario_tipo_id",
                                    searchEnabled:true,
                                    searchMode:"contains",
                                    displayExpr: (item) => item && item.descripcion,                     
                                    searchExpr:(item) => item && item.descripcion,                
                                    value:usuario.tipoUsuario,
                                    dataSource:DATASOURCE_TIPOS_USUARIO,
                                    onValueChanged: (e) => {
                                        if(e.value && e.value === 3)
                                            setVisible(true)
                                        else if (e.value && e.value !== 3)
                                            setVisible(false)
                                    }
                                }}
                                validationRules={[
                                    {type:"required",message:"Tipo de usuario es obligatorio"}
                                ]}>
                                <Label text={lang.getString("usuarios.detail.tipo-usuario")} />
                            </Item> 
                           
                        </GroupItem>
                       
                        <GroupItem caption="Permisos de usuario" colCount={12}>
                        <Item
                                colSpan={6}
                                dataField="grupo"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    searchEnabled:true,
                                    searchMode:"contains",
                                    displayExpr: (item) => item && item.grupo_nombre,                        
                                    searchExpr: (item) =>  item && item.grupo_nombre,                 
                                    //value:usuario.grupoId,
                                    dataSource:gruposDataSource,
                                    onValueChanged:(e) =>onValueChangedGrupo(e)
                                }}
                                validationRules={validationRules.grupo}>
                                    <Label text="Grupo"/>
                            </Item> 
                            <ButtonItem horizontalAlignment="left" colSpan={6}
                                        buttonOptions={{text:'Clonar permisos de usuario',type:'success',onClick:()=>{handlePopupVisible()}}}
                                    />
                        </GroupItem>
                        <GroupItem colCount={12}>
                        <ComponentWrapper ref={permisoRef}  component={(props)=> {
                            return(
                                <TreeList
                                    dataSource={permisosLista}
                                    showBorders={true}
                                    ref={treeListGrupoRef}
                                    columnAutoWidth={true}
                                    wordWrapEnabled={true}
                                    selectedRowKeys={props.grupoPermiso}
                                    keyExpr="permiso_id"
                                    parentIdExpr="padre_id"
                                    onSelectionChanged={props.onSelectionChangedPermisosGrupo}
                                    id="permisosLista">   
                                    <Paging
                                    enabled={true}
                                    defaultPageSize={1000} />
                                    {/* <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={20}
                                    showInfo={true} /> */}
                                    <ColumnChooser enabled={false} /> 
                                    <Selection recursive = {true}mode="multiple" />
                                    <Column dataField="permiso_modulo"  />
                                    <Column dataField="permiso_elemento"  />
                                    <Column dataField="permiso_tipo"  />
                                    <Column dataField="permiso_clave"  />
                                    <Column dataField="permiso_desrcipcion" caption="Permiso descripción" />
                                </TreeList>
                                
                                );
                                }
                            } 
                        />
                        </GroupItem>
                        <GroupItem colSpan={12}>
                            <ButtonItem horizontalAlignment="left" colSpan={2}
                                buttonOptions={{text:'Guardar',type:'success',useSubmitBehavior:true}}
                            />
                            <ButtonItem horizontalAlignment="left" colSpan={10}
                                buttonOptions={{text:'regresar',type:'default',onClick:()=>{goToList()}}}
                            />
                        </GroupItem>
                            
                    </Form>
                    </form>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={isLoading}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
                 <Popup
                    visible={isPopUpOpen}
                    onHiding={() => {handlePopupVisible()} }
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Clonar permisos"
                    width={420}
                    height={250}>
                        <Form
                             ref={formRef}
                             formData={usuario}
                             readOnly={false}
                             showColonAfterLabel={true}
                             showValidationSummary={true}
                             validationGroup="usuarioData"
                        >
                            <Item
                                    colSpan={6}
                                    dataField="clon_id"
                                    editorType="dxSelectBox"
                                    editorOptions={{
                                        valueExpr:"usuario_id",
                                        searchEnabled:true,
                                        searchMode:"contains",
                                        displayExpr:function(item) {
                                            return item && item.usuario_nombre +' '+ item.usuario_apellido_paterno +' '+ item.usuario_apellido_materno;       
                                        }, 
                                        searchExpr:function(item) {
                                            return item && item.usuario_nombre +' '+ item.usuario_apellido_paterno +' '+ item.usuario_apellido_materno;       
                                        },               
                                        //value:usuario.grupoId,
                                        dataSource:usuarioDataSource,
                                        //onValueChanged:(e)=>onValueChangedUsuario(e)
                                    }}>
                                        <Label text="Usuario"></Label>
                                </Item> 
                        </Form> 
                    <br></br>
                    <br></br>
                    <Button onClick={() =>{cloneUsuarioPermiso()}}>Clonar los permisos</Button>
                </Popup>
                
            </div>
      </React.Fragment>
        );
    //#endregion
}


