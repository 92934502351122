import React  from 'react';
import './comentarioModal.scss';
import TextArea from 'devextreme-react/text-area';

export default function(props){

    return(
        <React.Fragment >
            <div>
                <TextArea
                    value={props.data}
                    readOnly={true}
                    width={"auto"}
                    height={"auto"}
                />
            </div>
        </React.Fragment>
        
    )
}