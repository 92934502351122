import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SingleCard } from './layouts';
import { LoginForm, ResetPasswordForm, ChangePasswordForm, CreateAccountForm } from './components';
import logo from 'components/login-form/logotipo.png'


const NoAutenticationContent =  function () {
  return (
    <Switch>
      <Route exact path='/login' >
        <SingleCard title="Iniciar sesión" logo={logo}>
          <LoginForm />
        </SingleCard>
      </Route>
      <Route exact path='/create-account' >
        <SingleCard title="Sign Up">
          <CreateAccountForm />
        </SingleCard>
      </Route>
      <Route exact path='/reset-password' >
        <SingleCard
          title="Recuperando acceso"
          description="La recuperación de contraseña se puede realizar capturando su usuario del sistema. Posteriormente, se enviará un correo electrónico con un link de recuperación, el cual le permitirá restablecer la contraseña."
        >
          <ResetPasswordForm />
        </SingleCard>
      </Route>
      <Route exact path='/change-password/:recoveryCode' >
        <SingleCard title="Cambiar contraseña">
          <ChangePasswordForm />
        </SingleCard>
      </Route>
      <Redirect to={'/login'} />
    </Switch>
  );
}


export default NoAutenticationContent;