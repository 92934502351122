/* Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
05.Validations
06.Catalogo Delegaciones
07.Grupos de servicio
08.Grupos de usos
09.Unidades
10.Demerito de terreno
11.Municipios
12.Poblaciones
13.Cuarteles
14.Manzanas
15.Predios
16.Zonas
17.Regimenes
18.Calles
19.Construccion categorias
20.Impuestos
21.Contribuyentes
22.Tipo terrenos
23.Tramites
24.Usuarios
25.Dias habiles
*/

const lang = {
        "getString":(text, parameter = "") => {
        let auxText = lang[text] ? lang[text] : text;
        return parameter ? auxText + " " + parameter : auxText
    },
    /* 01.General */
    "general.copyright": "© Todos los derechos reservados.",
    "general.search": "Buscar...",
    "general.save": "Guardar",
    "general.delete": "Eliminar",
    "general.back": "Regresar",
    "A connection attempt failed because the connected party did not properly respond after a period of time, or established connection failed because connected host has failed to respond.":"No fue posible conectarse al servicio de consulta de adeudo municipio",
  
    /* 02.Inicio de sesión de usuario, cierre de sesión, registro */
    "user.login-title": "Iniciar sesión",
    "user.register": "Registro",
    "user.forgot-password": "Olvidó su contraseña",
    "user.email": "Correo electrónico",
    "user.password": "Contraseña",
    "user.forgot-password-question": "¿Olvidó su contraseña?",
    "user.fullname": "Nombre completo",
    "user.login-button": "INICIAR SESIÓN",
    "user.register-button": "REGISTRO",
    "user.reset-password-button": "REINICIAR",
    "user.buy": "COMPRAR",
    "user.username": "Nombre de usuario",
    "user.index.title": "Usuarios",
  
    /* 03.Menú */
    "menu.app": "Inicio",
    "menu.catalogos": "Catálogos",
    "menu.catalogos.delegaciones": "Delegaciones",
    "menu.catalogos.grupos-de-servicio": "Grupos de servicio",

    /* 03.1.Catalogos en general */
    "catalogo.caption.estatus": "Estatus",
    "catalogo.caption.registro_creacion_usuario": "Usuario creación",
    "catalogo.caption.registro_creacion_fecha": "Fecha de creación",
    "catalogo.caption.registro_modificacion_usuario": "Usuario modificación",
    "catalogo.caption.registro_modificacion_fecha": "Fecha de modificación",
    "catalogo.caption.registro_cancelacion_usuario": "Usuario cancelación",
    "catalogo.caption.registro_cancelacion_fecha": "Fecha de cancelación",
    "cat.anexo.clave.alreadyexists":"Clave de anexo ya existe.Intente con una diferente.",


    /* 04.Anexos  */
    "anexo.index.title": "Catálogo de anexos",
    "anexo.index.agregar": "AGREGAR ANEXO",
    "anexo.index.imprimirlistado": "IMPRIMIR LISTADO DE ANEXOS",

    "anexo.detail.datosgenerales": "Anexo",
    "anexo.detail.columnas": "Columnas del anexo",
    "anexo.detail.title": "Anexos",
    "anexo.caption.id_rubro": "Rubro",
    "anexo.caption.clave": "Clave",
    "anexo.caption.nombre": "Nombre del anexo",
    "anexo.caption.descripcion": "Descripción",
    "anexo.caption.soporte_anexo": "Soporte anexo",
    "anexo.caption.soporte_registro": "Soporte registro",
    "anexo.caption.orden": "Orden",

    "anexo.columnas.agregar": "Agregar columna",
    "anexo.columnas.acciones": "Acciones",
    "anexo.columnas.orden": "Orden",
    "anexo.columnas.nombre": "Nombre",
    "anexo.columnas.descripcion": "Descripción",
    "anexo.columnas.id_tipo_dato": "Tipo de dato",
    "anexo.columnas.tipo_dato": "Tipo de dato",
    "anexo.columnas.obligatorio": "Obligatorio",
    "anexo.columnas.dato_longitud": "Dato longitud",
    "anexo.columnas.columna_longitud": "Columna longitud",
    "anexo.columnas.lista.clave": "Clave",
    "anexo.columnas.lista.nombre": "Nombre",
    "anexo.columnas.lista.descripcion": "Descripción",
    "anexo.columnas.lista.orden": "Orden",
    "eventos.error.delete.anexo.revisado": "El puesto contiene capturas realizadas por lo cual no se puede eliminar.",
    "eventos_puestos_anexos_registros.error.notexists":"El registro que desea eliminar no se encuentra en los registros",
    "evento.puesto.cerrar.without.funcionario.entrante" :"No se puede cerrar el puesto, falta asignar el funcionario entrante.",
    
    /* 04.1.rubros */
    "anexo.rubro.index.title": "Catálogo de rubros",
    "anexo.rubro.index.agregar": "AGREGAR RUBRO",
    "anexo.rubro.index.imprimirlistado": "IMPRIMIR LISTADO DE RUBROS",

    "anexo.rubro.detail.title": "Rubro",
    "anexo.rubro.caption.nombre": "Nombre",
    "anexo.rubro.caption.clave": "Clave",
    "anexo.rubro.caption.orden": "Orden",
    "anexo.rubro.caption.orden": "Orden",

    /* 04.Entes */
    "ente.index.title": "Catálogo de entes públicos",
    "ente.index.agregar": "AGREGAR ENTE",
    "ente.index.imprimirlistado": "IMPRIMIR LISTADO DE ENTES",

    "ente.detail.title":            "Ente público",
    "ente.detail.nombre":           "Nombre",
    "ente.detail.clave":            "Clave",
    "ente.detail.orden":            "Orden",
    "ente.detail.nombre_corto":     "Nombre corto",
    "ente.detail.id_tipo":          "Tipo de ente",
    "ente.detail.id_sector":        "Sector",
    "ente.detail.usuarioai":        "Usuario administrador interno",
    "ente.detail.usuarioai_email":  "Usuario administrador interno e-mail",
    "ente.detail.unidad.nombre":    "Nombre",
    "ente.detail.unidad.orden":     "Orden", 

    "ente.detail.columnas":     "Unidades administrativas",
    "ente.detail.columnas.orden":     "Orden",
    "ente.detail.columnas.nombre":    "Nombre de Unidad Administrativa",
    
    /* 04a.Entes.UnidadAdministrativa*/
    "unidad.administrativa.title": "Catálogo de unidades administrativas",
    "unidad.administrativa.detail.ente_nombre": "Ente público",
    "unidad.administrativa.detail.ente_nombre_corte": "Ente público nombre corto",

    /* 04.Entes.Sector */
    "ente.sector.index.title": "Catálogo de sectores",
    "ente.sector.index.agregar": "AGREGAR SECTOR",
    "ente.sector.index.imprimirlistado": "IMPRIMIR LISTADO DE SECTORES",

    "ente.sector.detail.title": "Sectores",
    "ente.sector.detail.nombre": "Nombre",
    "ente.sector.detail.clave": "Clave",
    "ente.sector.detail.orden": "Orden",

    /* 04.Entes.Tipo */
    "ente.tipo.index.title": "Catálogo de tipos de entes públicos",
    "ente.tipo.index.agregar": "AGREGAR TIPO",
    "ente.tipo.index.imprimirlistado": "IMPRIMIR LISTADO DE TIPOS",

    "ente.tipo.detail.title": "Tipo de ente público",
    "ente.tipo.detail.nombre": "Nombre",
    "ente.tipo.detail.clave": "Clave",
    "ente.tipo.detail.orden": "Orden",

     /* 05.Entes.Jerarquia */
     "ente.publico.puesto.jerarquia.index.title": "Jerarquías de puestos",
     "ente.publico.puesto.jerarquia.index.agregar": "AGREGAR JERARQUÍA",
     "ente.publico.puesto.jerarquia.index.imprimirlistado": "IMPRIMIR LISTADO DE JERARQUÍAS",
     "ente.publico.puesto.jerarquia.detail.title": "Jerarquía",
     "ente.publico.puesto.jerarquia.caption.nombre": "Nombre de jerarquía",
     "ente.publico.puesto.jerarquia.caption.nivel": "Nivel",
     "ente.publico.puesto.jerarquia.caption.orden": "Orden",

      /* 06.Entes.Puesto */
      "ente.publico.puesto.index.title": "Puestos de entes públicos",
      "ente.publico.puesto.index.agregar": "AGREGAR PUESTO DE ENTE PÚBLICO",
      "ente.publico.puesto.index.imprimirlistado": "IMPRIMIR LISTADO DE PUESTOS",
      "ente.publico.puesto.detail.title": "Puesto de ente público",
      "ente.publico.puesto.caption.clave": "Clave",
      "ente.publico.puesto.caption.nombre": "Nombre del puesto",
      "ente.publico.puesto.caption.id_padre": "Puesto del que depende",
      "ente.publico.puesto.caption.id_unidad_administrativa": "Unidad administrativa",
      "ente.publico.puesto.caption.id_jerarquia": "Jerarquia",
      "ente.publico.puesto.caption.id_funcionario": "Funcionario",
      "ente.publico.puesto.caption.nombramiento": "Nombramiento",
      "ente.publico.puesto.caption.vernombramiento": "Ver nombramiento",

      /* 07.captura */
      "captura.index.title": "Mis capturas",
      "captura.detail.title": "Captura de anexos",
      "captura.caption.ente_publico": "Ente público",
      "captura.caption.unidad_administrativa": "Unidad administrativa",
      "captura.caption.puesto": "Puesto",
      "captura.caption.funcionario_saliente": "Funcionario saliente",
      "captura.caption.funcionario_entrante": "Funcionario entrante",
      "captura.caption.estatus_anexo": "Estatus anexo",
      "captura.caption.estatus_aceptacion": "Estatus aceptación",
      "captura.caption.anexo": "Anexo",
      "captura.caption.anexo_clave": "Anexo clave",
      "captura.caption.rubro": "Rubro",
      "captura.caption.aplica": "Aplica",
      "captura.caption.justificacion": "Justificación",
      "captura.caption.bloqueo": "Bloqueo",

      "captura.caption.revisor": "Revisor",
      "captura.caption.evento": "Evento",
      "eventos_puestos_anexos.error.notexists.rows":"No hay registros agregados",

      /* 05.funcionario */
      "funcionario.index.title": "Listado de funcionarios",
      "funcionario.index.agregar": "AGREGAR FUNCIONARIO",
      "funcionario.index.imprimirlistado": "IMPRIMIR LISTADO DE FUNCIONARIOS",
      "funcionario.detail.title": "Funcionario",
      "funcionario.caption.prefijo": "Prefijo",
      "funcionario.caption.nombre": "Nombre",
      "funcionario.caption.apellido_paterno": "Apellido paterno",
      "funcionario.caption.apellido_materno": "Apellido materno",
      "funcionario.caption.rfc": "RFC",
      "funcionario.caption.curp": "CURP",
      "funcionario.caption.correo": "Correo",
      "funcionario.caption.fecha_nacimiento": "Fecha nacimiento",
      "funcionario.caption.flag_permiso_admin": "Administrador de sistema",
      "funcionario.caption.flag_permiso_revisor": "Revisor",
      "funcionario.caption.orden": "Orden",

      "funcionario.caption.funcionario": "Funcionario",
      "funcionario.caption.usuario": "Usuario",
      "funcionario.caption.unidad_administrativa": "Unidad administrativa",
      "funcionario.caption.ente_publico": "Ente público",
      "funcionario.caption.puesto": "Puesto",
      "funcionario.caption.puestos_asignados": "# Puestos asignados",

    /* 08.acta */
    "acta.index.title": "Listado de actas",
    "acta.index.agregar": "AGREGAR ACTA",
    "acta.index.imprimirlistado": "IMPRIMIR LISTADO DE ACTAS",

    "acta.detail.title" : "Acta",
    "acta.caption.nombre": "Acta",
    "acta.caption.clave" : "Clave",
    "acta.caption.orden" : "Orden",
    "acta.caption.observaciones" : "Observaciones",

    /* 09.biblioteca.documento */
    "biblioteca.documento.index.title": "Biblioteca de documentos",
    "biblioteca.documento.index.agregar": "AGREGAR DOCUMENTO",
    "biblioteca.documento.index.imprimirlistado": "IMPRIMIR LISTADO DE DOCUMENTOS",
    "biblioteca.documento.detail.title" : "Documento",
    "biblioteca.documento.caption.nombre": "Nombre del formato",
    "biblioteca.documento.caption.id_adjunto": "Documento digital",
    "biblioteca.documento.caption.fecha_adjunto": "Fecha subida",
    "biblioteca.documento.caption.orden" : "Orden",

    /* 10.usuario.registro.limitado */
    "usuario.registro.limitado.index.title": "Usuarios de registro limitado",
    "usuario.registro.limitado.index.agregar": "AGREGAR USUARIO",
    "usuario.registro.limitado.detail.title" : "Usuario",
    "usuario.registro.limitado.caption.nombre": "Nombre",
    "usuario.registro.limitado.caption.usuario": "Usuario",
    "usuario.registro.limitado.caption.usuario_password": "Contraseña",
    "usuario.registro.limitado.caption.usuario_password_confirm": "Confirmar contraseña",
    
    /* 11.eventos.revisiones */
    "eventos.revisiones.index.title": "Mis revisiones",
    "eventos.revisiones.puestos.index.title": "Mis revisiones - Puestos",

    /* 12.consultas.revisiones */
    "consultas.revisiones.index.title": "Buscador avanzado de revisiones",

    /*VALIDACIONES */
    "cat.ente.publico.unidad.administrativa.deleteevento_has_puestos":"No se puede eliminar la unidad adminsitrativa ya que cuenta con puestos asignados",
    "cat.ente.publico.delete_evento_has_unidades_administrativas":"No se puede eliminar el ente público ya que cuenta con unidad adminsitrativas asignadas",
    "cat.anexo.clave.alreadyexists": "La clave ya existe, por favor cambie la clave.",
    "cat.entes.puestos.jerarquias.nombre.alreadyexists": "El nombre de la jerarquía ya existe, favor de cambiar el nombre.",
    "validation.seg_usuario.changeCurrentPassword.passwordInvalid":"No es posible cambiar la contraseña, verificar la contraseña actual.",
    "sys.validateRevisorSistema":"El funcionario no tiene permiso para ser revisor a nivel de sistema."
  };
  
export default lang;
