import { withNavigationWatcher } from './contexts/navigation';
import UsuarioDetail from './pages/catalogos/usuarios/UsuarioDetail';
import Usuario from './pages/catalogos/usuarios/index.js';

import AnexoRubro from './pages/catalogos/anexosRubros/index.js';
import AnexoRubroDetail from './pages/catalogos/anexosRubros/AnexoRubroDetail.js';
import Anexo from './pages/catalogos/anexos/index.js';
import AnexoDetail from './pages/catalogos/anexos/AnexoDetail.js';

import enteSector from 'pages/catalogos/enteSector/index.js';
import enteSectorDetail from 'pages/catalogos/enteSector/enteSectorDetail.js';
import enteTipo from 'pages/catalogos/enteTipo/index.js';
import enteTipoDetail from 'pages/catalogos/enteTipo/enteTipoDetail.js';
import ente from 'pages/catalogos/ente/index.js';
import enteDetail from 'pages/catalogos/ente/enteDetail.js';
import entePublicoUnidadAdministrativaDetail from 'pages/catalogos/entePublicoUnidadAdministrativa/entePublicoUnidadAdministrativaDetail.js';
import entePublicoPuesto from 'pages/catalogos/entePublicoPuesto/index.js';
import entePublicoPuestoDetail from 'pages/catalogos/entePublicoPuesto/entePublicoPuestoDetail.js';
import entePublicoPuestoJerarquia from 'pages/catalogos/entePublicoPuestoJerarquia/index.js';
import entePublicoPuestoJerarquiaDetail from 'pages/catalogos/entePublicoPuestoJerarquia/entePublicoPuestoJerarquiaDetail.js';

import funcionario from 'pages/catalogos/funcionario/index.js';
import funcionarioDetail from 'pages/catalogos/funcionario/funcionarioDetail.js';
import acta from 'pages/catalogos/acta/index.js';
import actaDetail from 'pages/catalogos/acta/actaDetail.js';
import bibliotecaDocumento from 'pages/catalogos/bibliotecaDocumento/index.js';
import bibliotecaDocumentoDetail from 'pages/catalogos/bibliotecaDocumento/bibliotecaDocumentoDetail.js';

import grupoPermiso from  'pages/catalogos/grupoPermiso/index.js';
import grupoPermisoDetail from 'pages/catalogos/grupoPermiso/grupoPermisoDetail.js';
import CatalogoEvento from './pages/catalogos/eventos/index.js';
import CatalogoEventoDetail from './pages/catalogos/eventos/eventoDetail.js';
import ConsultaPuestos from 'pages/catalogos/eventos/puestos.js'

import eventos from  './pages/eventos/capturas/index.js';
import eventosDetail from  './pages/eventos/capturas/eventosDetail.js';
import eventosDetailContinuo from './pages/eventos/capturas/eventosDetailContinuo.js';
import usuarioRegistroLimitado       from  './pages/eventos/usuarioRegistroLimitado/index.js';
import revisiones from  './pages/eventos/revisiones/index.js';
import revisionesPuestos from  './pages/eventos/revisionesPuestos/index.js';
import diasAsueto from './pages/configuracion/diasLaborales/index.js';
import profile from  './pages/profile/profile';


import ConsultaEventos from './pages/consultas/eventos/index.js';
import ConsultaRevisiones from './pages/consultas/revisiones/index.js';
import ConsultaProgresoEntregaNivel from  './pages/consultas/ente/progresoEntrega/index.js';
import ConsultaEntregaRealizadaEnte from './pages/consultas/ente/entregaRealizada/index.js';
import ConsultaProgresoUnidadAdministrativas from './pages/consultas/unidadAdministrativa/progresoEntrega/index.js';
import ConsultaEntregaUnidadAdministrativa from './pages/consultas/unidadAdministrativa/entregaRealizada/index.js';

import ConsultaProgresoPuesto from './pages/consultas/puesto/progresoEntrega/index.js'; 
import ConsultaEntregaPuesto from './pages/consultas/puesto/entregaRealizada/index.js';
import ConsultaHistorial from 'pages/consultas/puesto/historial/index.js';

import BitacoraSistema from './pages/bitacora/index.js';
import Reportes from './pages/reportes/index.js';
//#endregion
const allRoutes = [
  /*
  {
      hidden: false,
      path: "/home",
      name: "casa",
      icon:"mediumiconslayout",
      mini: "Home",
      component: HomePage,
      layout: "/admin",
      permissionset: [],
  },
  {
    hidden: false,
    name: "SubMenu",
    icon: "paste",
    permissionset: [],
    views: [
      {
        hidden: false,
        path: "/buzonUsuario",
        name: "Buzón de entrada",
        icon:"",
        mini: "BU",
        component: HomePage,
        layout: "/admin",
        permissionset: []
      }
    ]
  },
  */
  //#region Seguridad 
  {
    hidden: true,
    name: "Seguridad",
    icon: "key",
    permissionset: [],
    views: [
      {
        hidden: true,
        path: "/usuarios",
        name: "Usuarios",
        icon:"",
        mini: "usuario",
        component: Usuario,
        layout: "/admin",
        permissionset: ["SEG_USU_001"],
      },
      {
        hidden: true,
        path: "/usuarios/detalle",
        name: "Usuarios detalles",
        icon:"",
        mini: "usuario",
        component: UsuarioDetail,
        layout: "/admin",
        permissionset: ["SEG_USU_001"],
      },
      {
        hidden: false,
        path: "/grupoPermisos",
        name: "Grupo de permisos",
        icon:"",
        mini: "GP",
        component: grupoPermiso,
        layout: "/admin",
        permissionset: ['SEG_GPR_001'],
      },
      {
        hidden: true,
        path: "/grupoPermisos/detalle",
        name: "Grupo de permisos",
        icon:"",
        mini: "GP",
        component: grupoPermisoDetail,
        layout: "/admin",
        permissionset: ['SEG_GPR_001'],
      }
      
    ]
  },
  //#endregion Seguridad
  //#region Configuración
  /*
  {
    hidden: false,
    name: "Configuración",
    icon: "preferences",
    permissionset: [],
    views: [
      {
        hidden: false,
        path: "/configuracionSistema",
        name: "Configuración del sistema",
        icon:"",
        mini: "CS",
        component: ConfiguracionSistema,
        layout: "/admin",
        permissionset: ["CNG_SIS_001"],
      },
    ]
  },
  */
  //#endregion Configuración
  {
    hidden: false,
    name: "Eventos E-R",
    icon: "detailslayout",
    permissionset: [],
    
    views:[
      {
        hidden: false,
        path: "/eventos",
        name: "Eventos",
        icon:"",
        mini: "",
        component: CatalogoEvento,
        layout: "/catalogos",
        permissionset: ["EVENT_ACCESS"],
      },
      {
        hidden:false,
        path:"/eventos/puestos",
        name:"Consulta de puestos",
        icon:"",
        mini:"",
        component:ConsultaPuestos,
        layout:"/catalogos",
        permissionset:["EVENT_PUESTOS_ACCESS"]
      },
      {
        hidden: true,
        path: "/eventos/detalle",
        name: "Eventos",
        icon:"",
        mini: "",
        component: CatalogoEventoDetail,
        layout: "/catalogos",
        permissionset: ["EVENT_ACCESS"],
      },
      {
        hidden: false,
        path: "/capturas",
        name: "Mis capturas",
        icon:"",
        mini: "capturas",
        component: eventos,
        layout: "/eventos",
        permissionset: ["MISCAP_ACCESS"],
      },
      {
        hidden: true,
        path: "/capturas/detalle",
        name: "Captura de anexos",
        icon:"",
        mini: "capturas",
        component: eventosDetail,
        layout: "/eventos",
        permissionset: ["MISCAP_ACCESS"],
      },
      {
        hidden: true,
        path: "/capturas/detalleContinuo",
        name: "Captura de anexos",
        icon:"",
        mini: "capturas",
        component: eventosDetailContinuo,
        layout: "/eventos",
        permissionset: ["MISCAP_ACCESS"],
      },
      {
        hidden: true,
        path: "/capturas/detalle",
        name: "Captura de anexos",
        icon:"",
        mini: "capturas",
        component: eventosDetail,
        layout: "/eventos",
        permissionset: ["CAPTURA_ANEXO_ACCESS"],
      },
	    {
        hidden: true,
        path: "/usuarioRegistroLimitado",
        name: "Usuarios de registro limitado",
        icon:"",
        mini: "usuarioRegistroLimitado",
        component: usuarioRegistroLimitado,
        layout: "/eventos",
        permissionset: ["MISCAP_REGIS_LIMIT"],
      },
      {
        hidden: false,
        path: "/revisionesPuestos",
        name: "Mis revisiones - Puestos",
        icon:"",
        mini: "revisiones",
        component: revisionesPuestos,
        layout: "/eventos",
        permissionset: ["REVISION_ACCESS"],
      },
      {
        hidden: true,
        path: "/revisiones",
        name: "Mis revisiones",
        icon:"",
        mini: "revisiones",
        component: revisiones,
        layout: "/eventos",
        permissionset: ["REVISION_ACCESS"],
      },
    ]
    
  },
  //#region Catalogos
  {
    hidden: false,
    name: "Catálogos",
    icon: "paste",
    permissionset: [],
    views: [
      {
        hidden: false,
        path: "/acta",
        name: "Actas",
        icon:"",
        mini: "usuario",
        component: acta,
        layout: "/catalogos",
        permissionset: ["ACTA_ACCESS"],
      },
      {
        hidden: true,
        path: "/acta/detalle",
        name: "Acta detalles",
        icon:"",
        mini: "usuario",
        component: actaDetail,
        layout: "/catalogos",
        permissionset: ["ACTA_ACCESS"],
      },
      
      // {
      //   hidden: false,
      //   path: "/catalogos/eventos",
      //   name: "Eventos",
      //   icon:"",
      //   mini: "",
      //   component: CatalogoEvento,
      //   layout: "/catalogos",
      //   permissionset: [],
      // },
      // {
      //   hidden: true,
      //   path: "/eventos/detalle",
      //   name: "Eventos",
      //   icon:"",
      //   mini: "",
      //   component: CatalogoEventoDetail,
      //   layout: "/catalogos",
      //   permissionset: [],
      // },

      {
        hidden: false,
        path: "/anexosRubros",
        name: "Anexo - Rubros",
        icon:"",
        mini: "anexoRubro",
        component: AnexoRubro,
        layout: "/catalogos",
        permissionset: ["RUBRO_ACCESS"],
      },
      {
        hidden: true,
        path: "/anexosRubros/detalle",
        name: "Rubros detalles",
        icon:"",
        mini: "anexoRubro",
        component: AnexoRubroDetail,
        layout: "/catalogos",
        permissionset: ["RUBRO_ACCESS"],
      },
      {
        hidden: false,
        path: "/anexos",
        name: "Anexos",
        icon:"",
        mini: "anexo",
        component: Anexo,
        layout: "/catalogos",
        permissionset: ["ANEXO_ACCESS"],
      },
      {
        hidden: true,
        path: "/anexos/detalle",
        name: "Anexos detalles",
        icon:"",
        mini: "anexo",
        component: AnexoDetail,
        layout: "/catalogos",
        permissionset: ["ANEXO_ACCESS"],
      },
      /*{
        hidden: false,
        path: "/anexosmobx",
        name: "Anexos Mobx",
        icon:"",
        mini: "anexo",
        component: AnexoMobx,
        layout: "/catalogos",
        permissionset: ["SEG_USU_001"],
      },
      {
        hidden: false,
        path: "/anexosmobx/detalle",
        name: "Anexos detalles Mobx",
        icon:"",
        mini: "anexo",
        component: AnexoDetailMobx,
        layout: "/catalogos",
        permissionset: ["SEG_USU_001"],
      },
      {
        hidden: false,
        path: "/anexosmobx/columnas",
        name: "Anexos columnas",
        icon:"",
        mini: "anexo",
        component: AnexoColumnas,
        layout: "/catalogos",
        permissionset: ["SEG_USU_001"],
      },*/
      {
        hidden: false,
        path: "/enteSector",
        name: "Ente - Sectores",
        icon:"",
        mini: "usuario",
        component: enteSector,
        layout: "/catalogos",
        permissionset: ["SECTOR_ACCESS"],
      },
      {
        hidden: true,
        path: "/enteSector/detalle",
        name: "Sectores detalles",
        icon:"",
        mini: "usuario",
        component: enteSectorDetail,
        layout: "/catalogos",
        permissionset: ["SECTOR_ACCESS"],
      },

      {
        hidden: false,
        path: "/enteTipo",
        name: "Ente - Tipos",
        icon:"",
        mini: "usuario",
        component: enteTipo,
        layout: "/catalogos",
        permissionset: ["TIPO_ACCESS"],
      },
      {
        hidden: true,
        path: "/enteTipo/detalle",
        name: "Tipo detalles",
        icon:"",
        mini: "usuario",
        component: enteTipoDetail,
        layout: "/catalogos",
        permissionset: ["TIPO_ACCESS"],
      },
      {
        hidden: false,
        path: "/enteJerarquia",
        name: "Jerarquías",
        icon:"",
        mini: "usuario",
        component: entePublicoPuestoJerarquia,
        layout: "/catalogos",
        permissionset: ["JERARQ_ACCESS"],
      },
      {
        hidden: true,
        path: "/enteJerarquia/detalle",
        name: "Jerarquia detalles",
        icon:"",
        mini: "usuario",
        component: entePublicoPuestoJerarquiaDetail,
        layout: "/catalogos",
        permissionset: ["JERARQ_ACCESS"],
      },
      {
        hidden: true,
        path: "/entePuesto",
        name: "Puestos",
        icon:"",
        mini: "usuario",
        component: entePublicoPuesto,
        layout: "/catalogos",
        permissionset: ["PUESTO_ACCESS"],
      },
      {
        hidden: true,
        path: "/entePuesto/detalle",
        name: "Puesto detalles",
        icon:"",
        mini: "usuario",
        component: entePublicoPuestoDetail,
        layout: "/catalogos",
        permissionset: ["PUESTO_ACCESS"],
      },

      {
        hidden: false,
        path: "/ente",
        name: "Entes públicos",
        icon:"",
        mini: "usuario",
        component: ente,
        layout: "/catalogos",
        permissionset: ["ENTE_ACCESS"],
      },
      {
        hidden: true,
        path: "/ente/detalle",
        name: "Ente detalles",
        icon:"",
        mini: "usuario",
        component: enteDetail,
        layout: "/catalogos",
        permissionset: ["ENTE_ACCESS"],
      },
      {
        hidden: true,
        path: "/entePublicoUnidadAdministrativa/detalle",
        name: "Ente Unidad Administrativa detalles",
        icon:"",
        mini: "usuario",
        component: entePublicoUnidadAdministrativaDetail,
        layout: "/catalogos",
        permissionset: ["ENTE_ACCESS"],
      },
      {
        hidden: false,
        path: "/funcionario",
        name: "Funcionarios",
        icon:"",
        mini: "usuario",
        component: funcionario,
        layout: "/catalogos",
        permissionset: ["FUNC_ACCESS"],
      },
      {
        hidden: true,
        path: "/funcionario/detalle",
        name: "Funcionario detalles",
        icon:"",
        mini: "usuario",
        component: funcionarioDetail,
        layout: "/catalogos",
        permissionset: [],
      },
      {
        hidden: false,
        path: "/bibliotecaDocumento",
        name: "Biblioteca de documentos",
        icon:"",
        mini: "usuario",
        component: bibliotecaDocumento,
        layout: "/catalogos",
        permissionset: ["BBLIO_ACCESS"],
      },
      {
        hidden: true,
        path: "/bibliotecaDocumento/detalle",
        name: "Biblioteca de documentos detalles",
        icon:"",
        mini: "usuario",
        component: bibliotecaDocumentoDetail,
        layout: "/catalogos",
        permissionset: ["BBLIO_ACCESS"],
      },
      

    ]
  },
  //#endregion
  //#region Eventos
  {
    hidden: true,
    name: "Consultas",
    icon: "folder",
    permissionset: [],
    views:[
        {
          hidden: false,
          path: "/busquedaAvanzadaEventos",
          name: "Busqueda - Eventos",
          icon:"",
          mini: "",
          component: ConsultaEventos,
          layout: "/consultas",
          permissionset: ["BUQ_PUESTO_ACCESS"],
        },
        {
          hidden: false,
          path: "/busquedaAvanzadaRevisiones",
          name: "Busqueda - Revisiones",
          icon:"",
          mini: "",
          component: ConsultaRevisiones,
          layout: "/consultas",
          permissionset: ["BUQ_REVISION_ACCESS"],
        },
    ]
  },


  {
    hidden: false,
    name: "Consultas y reportes",
    icon: "activefolder",
    permissionset: [],
    views:[
      {
        hidden: false,
        name: "Nivel de ente",
        icon: "",
        permissionset: [],
        views:[
          {
            hidden: false,
            path: "/progresoEntregaEnte",
            name: "Progreso de entrega",
            icon:"chevronnext",
            mini: "",
            component: ConsultaProgresoEntregaNivel,
            layout: "/consultas",
            permissionset: ["CONSULT_ENTEPUBLICO_PROCS_ENT"],
          },
          {
            hidden: false,
            path: "/entregaRealizadaEnte",
            name: "Entrega realizada",
            icon:"chevronnext",
            mini: "",
            component: ConsultaEntregaRealizadaEnte,
            layout: "/consultas",
            permissionset: ["CONSULT_ENTEPUBLICO_ENT_REALIZADA"],
          },
        ]
      },
      {
        hidden: false,
        name: "Nivel de unidad",
        icon: "",
        permissionset: [],
        views:[
          {
            hidden: false,
            path: "/progresoEntregaUnidadAdministrativa",
            name: "Progreso de entrega",
            icon:"chevronnext",
            mini: "",
            component: ConsultaProgresoUnidadAdministrativas,
            layout: "/consultas",
            permissionset: ["CONSULT_UNIDAD_PROCS_ENT"],
          },
          {
            hidden: false,
            path: "/entregaRealizadaUnidadAdministrativa",
            name: "Entrega realizada",
            icon:"chevronnext",
            mini: "",
            component: ConsultaEntregaUnidadAdministrativa,
            layout: "/consultas",
            permissionset: ["CONSULT_UNIDAD_ENT_REALIZADA"],
          },
        ]
      },
      {
        hidden: false,
        name: "Nivel de puesto",
        icon: "",
        permissionset: [],
        views:[
          {
            hidden: false,
            path: "/progresoEntregaPuesto",
            name: "Progreso de entrega",
            icon:"chevronnext",
            mini: "",
            component: ConsultaProgresoPuesto,
            layout: "/consultas",
            permissionset: ["CONSULT_PUESTO_PROCS_ENT"],
          },
          {
            hidden: false,
            path: "/entregaRealizadaPuesto",
            name: "Entrega realizada",
            icon:"chevronnext",
            mini: "",
            component: ConsultaEntregaPuesto,
            layout: "/consultas",
            permissionset: ["CONSULT_PUESTO_ENT_REALIZADA"],
          },
          {
            hidden: false,
            path: "/historialPuestos",
            name: "Historial",
            icon:"chevronnext",
            mini: "",
            component: ConsultaHistorial,
            layout: "/consultas",
            permissionset: ["CONSULT_PUESTO_HIST"],
          },
        ]
      },
      
      {
        hidden: false,
        path: "/reportes",
        name: "Reportes de sistema",
        icon:"",
        mini: "",
        component: Reportes,
        layout: "/admin",
        permissionset: ["REP_SYS_ACCESS"],
      },
      
      
    ]
  },
  {
    hidden: false,
    name: "Bitacora",
    icon: "floppy",
    permissionset: [],
    views:[
      {
        hidden: false,
        path: "/bitacoraSistema",
        name: "Bitacora de sistema",
        icon:"",
        mini: "",
        component: BitacoraSistema,
        layout: "/admin",
        permissionset: ["BIT_SYS_ACCESS"],
      },
      ]
  },
  {
    hidden: false,
    name: "Configuración",
    icon: "preferences",
    permissionset: [],
    views:[
        {
          hidden: false,
          path: "/configuracionSistema",
          name: "Configuración de sistema",
          icon:"",
          mini: "",
          component: diasAsueto,
          layout: "/admin",
          permissionset: ["CONFIGURACION_DIAS_ACCESS"],
        }
    ]
  }
  ,{
    hidden: true,
    name: "Profile",
    icon: "folder",
    permissionset: [],
    views:[
        {
          hidden: false,
          path: "/user/profile",
          name: "profile",
          icon:"",
          mini: "",
          component: profile,
          layout: "/verify",
          permissionset: [],
        },
    ]
  }
  //#endregion
]


//#region Exports
export const buildRoutes = (routesBase,permissionset) => {
  let routes = [];
  routesBase.forEach(element => {
    if(element.component){
      if(!element.permissionset || element.permissionset.length === 0 || !permissionset) 
        routes.push({ layout: element.layout, path: element.layout + element.path, component: withNavigationWatcher(element.component) });
      else if(Array.isArray(permissionset) && element.permissionset && element.permissionset.length  && element.permissionset.find(permission => permissionset.includes(permission)))
        routes.push({ layout: element.layout, path: element.layout + element.path, component: withNavigationWatcher(element.component) });
    }
    if(element.views) {
      let childRoutes = buildRoutes(element.views,permissionset);
      routes = routes.concat(childRoutes);
    }
  });
  return routes;
}

export const buildTree = (routesBase,permissionset) => {
  
  let tree = [];
  if(!Array.isArray(permissionset)) return tree;

  routesBase.forEach(element => {
    let treeItem ;
      if( (
        (!element.permissionset || (element.permissionset && element.permissionset.length === 0) || !permissionset)
          || ( Array.isArray(permissionset) && element.permissionset && element.permissionset.length  && element.permissionset.find(permission => permissionset.includes(permission)))
          )
            && !element.hidden
          ) 
      treeItem = { 
          text: element.name,
          path: element.layout + element.path,
          icon: element.icon
        };
    if(treeItem && element.views) {
      treeItem.items = buildTree(element.views,permissionset);
    }
    treeItem && (!treeItem.items || treeItem.items.length > 0) && tree.push(treeItem);
  });
  return tree;
}


export default allRoutes;