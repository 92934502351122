import {instance} from 'constants/defaultValues';
export function getBusquedaAvanzadaEvento({take = 1, skip = 0, filter = {}, sort = {}, evento_id = null, ente_id = null, unidad_administrativa_id = null, puesto_id = null}){
    return instance.post(`/api/read`,{
        "VC":"busqueda_avanzada_evento",
        "VA":"list",
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc,
        "evento_id": evento_id,
        "ente_id": ente_id,
        "unidad_administrativa_id": unidad_administrativa_id,
        "puesto_id": puesto_id,
    })
}

export function getuuids(array_ids){
    return instance.post(`/api/read`,{
        "VC":"busqueda_avanzada_evento",
        "VA":"getuuids",
        "array_ids": array_ids
    })
}