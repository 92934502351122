import React ,{useState, useRef} from 'react';
import { DataGrid, Column,Editing,MasterDetail,Texts } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import AlertDialog from 'components/alertDialog/alertDialog';
import {descargarArchivo} from 'utils/utility';
import {
    getPuestosByUnidadEnteEvento,
    postAddEventoPuesto,
    getuuids,
    deleteEventoPuesto,
    postBlockEventoPuesto,
    handleDownloadPlantilla,
    postClosePuesto,
    getEventoAnexoArchivo,
    postBlockAnexosEventoPuesto
} from 'api';
import {
  STRING_ALERTDIALOG_CREATE_SUCCESS,
  STRING_DATAGRID_ADD_ROW
} from 'constants/defaultValues';
import CustomPopUp from 'components/custompopup';
import PuestosModal from './puestosModal.js';
import ReviewersModal from './reviewersModal.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import PuestosAnexosDetail from './PuestoAnexoDetail.js';
import ModalEntranteSaliente from 'components/asignarFuncionarioDialog/asignarEntranteSalienteDialog';
import AuthCheck from '../../../components/authCheck/authCheck.js';
import GeneralActaModal from  'components/generarActaModal/index.js';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import swal from 'sweetalert2';
const position = { of: '#masterDetail' };



const getUUIDs = async(adjuntos_ids)=>{
  let result = [];
  const { data: { error, message, data } } = await getuuids(adjuntos_ids);
    if (error) {
      AlertDialog({ message: message });
    } else if (data) {
      result = data;
    }
    return result;
}

const descargarActa = (async(id_adjunto)=>{
  if (id_adjunto) descargarArchivo(id_adjunto,  ()=>{})
});

  export default function(props){

    function IconRenderActaFirmada(data){
      let flag = permActaFirmada ? true : false;
      return <div key={`dgrid_puesto_acta_firmada_download_${data.data.id_evento_puesto}`}>
          {
              !flag ? null : !data.data.downloading_actasFirmadas ?
                     (<i style={{fontSize:"18px",cursor:"pointer"}} onClick={()=> downloadArchivo(data)} class="far fa-file-pdf"></i>)
                     : <div className={"dx-link dx-link-icon"}><LoadIndicator  height={20} width={20}  /></div>
                      
          }
      </div>
  }
  const downloadArchivo=async(data)=>{
    dataGridRef.current.instance.getDataSource().store().push(
      [{
          type: 'update',
          key: data.key,
          data: {
              ...data.data, downloading_actasFirmadas: 1
          }
      }]
  );
  console.log('descargarArchivo', data.data.acta_firmada_descarga_id);
  await descargarArchivo(data.data.acta_firmada_descarga_id)
  dataGridRef.current.instance.getDataSource().store().push(
    [{
        type: 'update',
        key: data.key,
        data: {
            ...data.data, downloading_actasFirmadas: 0
        }
    }]
);
  }

    const dataGridRef = useRef();
    const [key] = useState(props.data.key);
    const [unidadBloqueada] = useState(props.data.data.bloqueo)
    const [enteBloqueado] = useState(props.data.data.bloqueo_ente)
    const [openModal, setOpenModal] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [isOpenModalEntranteSaliente, setIsOpenModalEntranteSaliente] = useState(false);
    const [openReviewersModal, setOpenReviewersModal] = useState(false);
    const [selectedEventoPuesto, setSelectedEventoPuesto] = useState(null);
    const [isOpenGenerarActaModal, setIsOpenGenerarActaModal] = useState(false);
    const [eventoPuesto, setEventoPuesto] = useState({
      "id_evento_puesto": null,
      "acta_json":null,
      "acta_firmada_descarga_id": null,
      "filename":  null,
      "flag_acta_cargada" : null,
    });

    //const permRevisorInterno    = AuthCheck({permission: "EVENT_PUESTO_REVISORINTERNO", onlyValidator: true});
    //const permBloquear    = AuthCheck({permission: "EVENT_PUESTO_ADD", onlyValidator: true});
    const permFuncionarios    = AuthCheck({permission: "EVENT_PUESTO_FUNCIONARIOS", onlyValidator: true});
    const permBloquear    = AuthCheck({permission: "EVENT_PUESTO_BLOQUEO", onlyValidator: true});
    const permDelete    = AuthCheck({permission: "EVENT_PUESTO_DELETE", onlyValidator: true});
    const permActa    = AuthCheck({permission: "EVENT_PUESTO_ACTA", onlyValidator: true});
    const permActaFirmada    = AuthCheck({permission: "EVENT_PUESTO_ACTAFIRMADA", onlyValidator: true});
    const permAnexo    = AuthCheck({permission: "EVENT_PUESTO_ANEXO", onlyValidator: true});
    const puesto_close = AuthCheck({permission: "EVENT_PUESTO_CLOSED", onlyValidator: true});

    const IconRenderAnexo= function(cellData){
      let flag = permAnexo ? true : false;//cellData.data.anexos_capturados > 0;

      return <div key={`dgrid_puesto_anexo_download_${cellData.data.id_evento_puesto}`}>
          {
              !flag ? null : !cellData.data.downloading ?
                     (<i style={{fontSize:"18px",cursor:"pointer"}} onClick={()=> handleDownloadAnexos(cellData)} class="far fa-file-pdf"></i>)
                     : <div className={"dx-link dx-link-icon"}><LoadIndicator  height={20} width={20}  /></div>
                      
          }
      </div>
    }

    
    const handleDownloadAnexos=async(dato)=>{
    swal.fire({
      title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
      text: "Por favor espere.",
      imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
      showConfirmButton: false,
      allowOutsideClick: false
    });
    let progress = 0;
    setTimeout(() => {
      if(progress===0){
          swal.update({
              html: "Recopilando información...",
              title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
              //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
              showConfirmButton: false,
              allowOutsideClick: false
          });
          swal.showLoading();
          setTimeout(() => {
              if(progress===0){
                  swal.update({
                      html: "Creando archivo comprimido...",
                      title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                      //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                      showConfirmButton: false,
                      allowOutsideClick: false
                  });
                  swal.showLoading();
              }
          },2000);
      }
  }, 200);

  const {data:{data, message, error}} = await getEventoAnexoArchivo({eventoId : props.data.key.id_evento,
    enteId : props.data.key.id_ente_publico,
    id_unidad_administrativa : props.data.key.id_unidad_administrativa,
    evento_puesto_id : dato.data.id_evento_puesto,
    //puesto_id: dato.data.id_puesto,
  },
    (pregress)=>{ 
        progress = pregress;
        console.log('Descargando archivo...'+pregress+"%");
    swal.update({
            html: 'Descargando archivo...'+pregress+"%",
            title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
            //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
            showConfirmButton: false,
            allowOutsideClick: false
        });
        swal.showLoading();
    });
    if(error){
      AlertDialog({message});
      }else{
          swal.fire({
              title: `<span style='font-size:30px;font-weight:bold'>`+  "Descarga finalizada" +`</span>`,
              showConfirmButton: false,
              icon:'success',
              timer: 1000
          });
      }
    }


    const fetchData = (async () => {
        let parameters = props.data.key;
        let result = [];
        const { data: { error, message, data } } = await getPuestosByUnidadEnteEvento(parameters);
        if (error) {
          AlertDialog({ message: message });
        } else if (data.length) {
          console.log('getPuestosByUnidadEnteEvento', data);
          result = data;
        }
        return result;
      })

      const dataSource = new DataSource({
        store: new CustomStore({
          key: "id_evento_puesto",
          load: async(loadOptions) => {
            return (await fetchData()).map(item=>{item.downloading=0; item.downloading_actasFirmadas = 0; return item});
          },
          onLoaded: async () =>{
            if(localStorage.getItem("SEER_PTO_CLV") != null){
              let puestoKey = parseInt(localStorage.getItem("SEER_PTO_CLV"));
              dataGridRef.current && dataGridRef.current.instance.expandRow(puestoKey);
            }
          },
          remove:async(key)=>{
            const {data:{error,message}} = await deleteEventoPuesto(key);
                if(error) AlertDialog({message});
                else AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true });
            
          }
        })
      });

      const handleBlock = async(data,bloqueo)=>{
        AlertDialog({showCancelButton:true,message: bloqueo === 0 ? "¿Seguro que desea des-bloquear este puesto?" : "¿Seguro que desea bloquear este puesto?",isNotification:false, type:"warning",onActionCallback:async(resp)=>{
          if(resp.value){
            try{
              setIsLoading(true);
              let params = {id_evento_puesto:data.id_evento_puesto,bloqueo};
              const {data:{error,message}} = await postBlockEventoPuesto(params);
              setIsLoading(false);
                  if(error) AlertDialog({message});
                  else AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true });
                  
            }catch(error){
              setIsLoading(false);
              AlertDialog({message:"Ha ocurrido un error al tratar de bloquear el puesto, comuniquese con el adminsitrador"})
            }
            
          }
      }});
      }

      const handleDownloadActas=async(actas)=>{
        console.log("actas");
        let files = ["00963cfa-6058-4e99-ab57-0aaf11831de2","00963cfa-6058-4e99-ab57-0aaf11831de2"];
        //let files = actas;
        await handleDownloadPlantilla({uid:files},"actas.zip");
      }

      const handleDownloadAnexoss=async(anexos)=>{
        let files = ["00963cfa-6058-4e99-ab57-0aaf11831de2","00963cfa-6058-4e99-ab57-0aaf11831de2"];
        //let files = anexos;
        await handleDownloadPlantilla({uid:files},"anexos.zip");
      }

      

      const handleClose = async(data)=>{
        AlertDialog({showCancelButton:true,message:"¿Seguro que desea cerrar el puesto?",isNotification:false, type:"warning",onActionCallback:async(resp)=>{
          if(resp.value){
            try{
              setIsLoading(true);
              const {data:{error,message}} = await postClosePuesto(data.id_evento_puesto);
              setIsLoading(false);
                  if(error) AlertDialog({message});
                  else AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true });
                  
            }catch(error){
              setIsLoading(false);
              AlertDialog({message:"Ha ocurrido un error al tratar de cerrar el puesto, comuniquese con el adminsitrador"})
            }
            
          }
      }});
        
      }

      const handleAdd = async (selectedRowsData, gridModalRef)=>{
        setIsLoading(true);
        let params = {...key,puestos:selectedRowsData};
        const {data:{error,message}} = await postAddEventoPuesto(params);
        setIsLoading(false);
            if(error) AlertDialog({message});
            else {
                gridModalRef.current.instance.clearSelection();
                AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true});
            } 
        setOpenModal(false);
    }

    const handleOpenReviewersModal = () =>{
      setOpenReviewersModal(!openReviewersModal);
    }

    const ChageStateModalEntranteSaliente=(e)=>{
      if(e){
        setSelectedEventoPuesto({
          eventoPuestoId: e.row.data.id_evento_puesto,
          funcionarioSalienteId: e.row.data.id_funcionario_saliente,
          funcionarioEntranteId: e.row.data.id_funcionario_entrante
        });
      }
      setIsOpenModalEntranteSaliente(!isOpenModalEntranteSaliente)
    }

    const changeStateModalGenerarActa=(e)=>{
      if(e)
        setEventoPuesto({
          "id_evento_puesto": e.row.data.id_evento_puesto,
          "acta_json":e.row.data.acta_json,
          "acta_firmada_descarga_id": e.row.data.acta_firmada_descarga_id,
          "filename":  e.row.data.filename,
          "flag_acta_cargada" :e.row.data.flag_acta_cargada
        });
      else
        setEventoPuesto({
          "id_evento_puesto": null,
          "acta_json":null,
          "acta_firmada_descarga_id": null,
          "filename":  null,
          "flag_acta_cargada" : null
        });

      setIsOpenGenerarActaModal(!isOpenGenerarActaModal);
    }

    const handleLockEventoPuesto = async(e)=>{
      let flag = !e.flag_bloqueo_anexos;
      try{
        setIsLoading(true);
        const {data:{data, message, error}} = await postBlockAnexosEventoPuesto(e.id_evento_puesto, flag);
        if(error){
          AlertDialog({message});
        }else{
          setIsLoading(false);
          AlertDialog({message: flag ? 'Captura desbloqueada' : 'Captura bloqueada', isNotification: true });
        }
      }
      catch(error){
        setIsLoading(false);
        AlertDialog({message:"Ha ocurrido un error al tratar bloquear las capturas de puesto, comuniquese con el adminsitrador"})
      }
      
    }

      return (
        <React.Fragment id="masterDetail">
          {isOpenGenerarActaModal && 
            <CustomPopUp width={'90%'} visible={true} onClose={()=>changeStateModalGenerarActa && changeStateModalGenerarActa()} title={"Generar Acta"}>
                <GeneralActaModal id_evento_puesto={eventoPuesto.id_evento_puesto} acta_json={eventoPuesto.acta_json} file_id={eventoPuesto.acta_firmada_descarga_id} filename={eventoPuesto.filename} flag_acta_cargada ={eventoPuesto.flag_acta_cargada} onAccept={()=>changeStateModalGenerarActa && changeStateModalGenerarActa()}/>
            </CustomPopUp>
          }
            {openModal &&
                <CustomPopUp height={"85%"} width={"85%"} visible={true} onClose={()=>setOpenModal(false)} title={"Seleccion de puesto"}>
                    <PuestosModal parameters={key} onAccept={(selectedRowsData,gridModalRef)=>{handleAdd(selectedRowsData, gridModalRef)}}/>
                </CustomPopUp>
            }
            {openReviewersModal && 
                <CustomPopUp height={"85%"} width={"85%"} visible={true} onClose={()=>handleOpenReviewersModal && handleOpenReviewersModal()} title={"ASIGNAR REVISORES"}>
                    <ReviewersModal 
                      eventoId={props.data.key.id_evento}
                      entePublicoId={props.data.key.id_ente_publico}
                      unidadAdministrativaId={props.data.key.id_unidad_administrativa}
                      onAccept={(selectedRowsData,gridModalRef)=>{handleOpenReviewersModal()}}/>
                </CustomPopUp>
            }

          <div className="master-detail-caption" style={{marginBottom:"20px"}}>

            {
              AuthCheck({
                permission:"EVENT_PUESTO_ADD",
                component:    
                  <Button
                    style={{marginRight:"5px"}}
                    icon="plus"
                    type="default"
                    text="Agregar puesto"
                    disabled={unidadBloqueada || enteBloqueado}
                    onClick={(e)=>{setOpenModal(!openModal)}}
                  />            
              })
            }
                               
          </div>
          <DataGrid
            ref={dataGridRef}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            rowAlternationEnabled={true}
            onRowExpanding={ async (e)=>{
              localStorage.setItem('SEER_PTO_CLV', e.key);
            }}
          >
              <Editing
                mode="row"
                useIcons={true}
                allowDeleting={true}
                >
                    <Texts
                      confirmDeleteTitle={'Eliminar puesto'}
                      confirmDeleteMessage={'Esta seguro de eliminar el puesto, esto eliminara todas las capturas realizadas en el anexo, al aceptar se eliminara los anexos y el puesto, ¿Desea continuar?'}
                      addRow={STRING_DATAGRID_ADD_ROW}
                  />

                </Editing>
                <MasterDetail
                    enabled={true}
                    component={PuestosAnexosDetail}
                /> 
            <Column type="buttons" width={190}
            buttons={[
                { hint: "Funcionario saliente", icon: 'far fa-user',visible:(e)=> permFuncionarios,onClick:(e)=>ChageStateModalEntranteSaliente(e) },
                { hint: "Bloquear", icon: 'fas fa-unlock', visible:(e)=> !e.row.data.bloqueo && permBloquear, onClick:(e)=>handleBlock(e.row.data,1) }, //estatus 1 es bloqueado en BD
                { hint: "Desbloquear", icon: 'fas fa-lock',visible:(e)=> e.row.data.bloqueo && permBloquear,onClick:(e)=>handleBlock(e.row.data,0) }, //estatus 0 es desbloqueado en BD
                { hint: "Cerrar puesto", icon: 'fas fa-ban',visible:(e)=> !e.row.data.cerrado && puesto_close ,onClick:(e)=>handleClose(e.row.data) },
                { hint: "Generar acta", icon: 'far fa-file-alt',visible:(e) => e.row.data.cerrado && localStorage.getItem('grupo_id') != 9,
                onClick:(e)=>changeStateModalGenerarActa(e) }, //estatus 1 es desbloqueado en BD
                { hint: 'Eliminar', icon: 'fas fa-trash-alt', name: 'delete',visible: !unidadBloqueada && !enteBloqueado && permDelete},
                { hint: "Bloquear captura", icon: 'far fa-times-circle',visible:(e)=> !e.row.data.flag_bloqueo_anexos && localStorage.getItem('grupo_id') === '1' ,onClick:(e)=>handleLockEventoPuesto(e.row.data) },
                { hint: "Desbloquear captura", icon: 'far fa-check-circle',visible:(e)=> e.row.data.flag_bloqueo_anexos && localStorage.getItem('grupo_id') === '1' ,onClick:(e)=>handleLockEventoPuesto(e.row.data) },
            ]}/>
            
            <Column dataField="clave" caption="Clave" width={100} />
            <Column dataField="id_evento_puesto" visible={false} />
            <Column dataField="nombre_puesto" caption="Nombre del puesto" width={500}/>
            <Column dataField="jerarquia" caption="Jerarquía" width={200} alignment="center"/>
            <Column dataField="titular" caption="Titular" alignment="center" width={100} dataType="boolean"/>
            <Column dataField="funcionario_saliente" caption="Funcionario saliente" alignment="center" width={300} />
            <Column dataField="funcionario_entrante" caption="Funcionario entrante" alignment="center" width={300} />
            <Column dataField="estatus_seguimiento" caption="Estatus seguimiento" alignment="center" width={150}/>
            <Column dataField="estatus_aceptacion" caption="Estatus aceptación" alignment="center" width={150}/>
            { 
             // permActa?
             // <Column dataField="acta" caption="Acta" alignment="center" width={150} visible= {false} cellRender={(e)=> e.data.anexos_capturados >= 0 ? <i style={{fontSize:"18px",cursor:"pointer"}} onClick={()=>alert('sin implementar')} class="far fa-file-word"></i> : <span></span>} />
             // :
             // null
            }
              <Column dataField="downloading_actasFirmadas" caption="Acta firmada" alignment="center" 
                width={150} 
                cellRender={IconRenderActaFirmada}/> 
              <Column dataField="downloading" caption="Anexos" alignment="center" width={150} cellRender={IconRenderAnexo}/>
              
            <Column dataField="fecha_firma" caption="Fecha firma" dataType="date" alignment="center" width={150}/>
            <Column dataField="captura_anexos" caption="Captura de anexos" alignment="center" width={150}/>
            <Column dataField="anexos_capturados" caption="Anexos capturados" alignment="center" width={150}/>
            <Column dataField="anexos_validados" caption="Anexos validados" alignment="center" width={150}/>
            <Column dataField="porcentaje_captura" caption="% captura" alignment="center" width={150}/>
            <Column dataField="avance_captura" caption="Avance de captura" alignment="center" width={150}/>
            
            
            <Column dataField="dias_post_firma" caption="Días post-firma" alignment="center" width={150}/>
            <Column dataField="bloqueo" caption="Bloqueo" dataType="boolean" alignment="center" width={150}/>
            <Column dataField="cerrado" caption="Cerrado" alignment="center" width={150}/>
            <Column dataField="anexos_x_revisar" caption="Anexos por revisar" alignment="center" width={150}/>
            <Column dataField="revisiones_favorables" caption="Revisiones favorables" alignment="center" width={150}/>
            <Column dataField="revisiones_no_favorables" caption="Revisiones no favorables" alignment="center" width={250}/>
            <Column dataField="porcentaje_revision" caption="% Revisión" alignment="center" width={150}/>
            <Column dataField="avance_revision" caption="Avance de revisión" alignment="center" width={150}/>
            <Column dataField="avance_global" caption="Avance global" alignment="center" width={350}/>
          </DataGrid>
          <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={position}
                visible={isLoading}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false}
                />
          {isOpenModalEntranteSaliente && 
            <CustomPopUp height={600} visible={true} onClose={()=>ChageStateModalEntranteSaliente()} title={"Asignar funcionarios"}>
                <ModalEntranteSaliente eventoPuesto={selectedEventoPuesto} onSave={(result)=>{ChageStateModalEntranteSaliente()}} />
            </CustomPopUp>
          }
        </React.Fragment>
      );
  }


