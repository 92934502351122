import React from 'react';
import { Template } from 'devextreme-react/core/template';
import { Button } from 'devextreme-react/button';
import {Item} from 'devextreme-react/form';

export default function DatePickerItem(props) {
    const dataField=props.dataField? props.dataField: '';
    const colSpan=props.colSpan? props.colSpan: 0;
    const type =props.type ? props.type : 'datetime';
    const stylingMode =props.stylingMode ? props.stylingMode : 'underlined';
    const showClearButton = props.showClearButton ? props.showClearButton : false;
    const displayFormat =  (props.type && props.type === 'time'  ? "HH:mm" : (props.type && props.type == 'date' ? 'dd/MM/yyy' : 'dd/MM/yyyy HH:mm'));
    const min = (props.min ? props.min  : null);
    const max = (props.max ? props.max  : null);
    const disabledDates = (props.disabledDates ? props.disabledDates  : []);
    const inputAttr = (props.inputAttr ? props.inputAttr  : {});
    const label = (props.label  ? props.label : {});
    const validationRules = (props.validationRules  ? props.validationRules : {});
    const onOpened = (e) =>  {
        if( type === 'time'){
            e.component.content().getElementsByClassName("dx-box-item")[0].style.display = "none";
            if(e.component._popup.option('toolbarItems').length === 3)
              e.component._popup.option('toolbarItems', e.component._popup.option('toolbarItems').slice(1))  
        }
        else if( type === 'date')
            e.component.content().getElementsByClassName("dx-box-item")[1].style.display = "none";
    };

  return (
    <Item
        dataField={dataField}
        colSpan={colSpan}
        label={label}
        validationRules={validationRules}
        editorType="dxDateBox"
        editorOptions={{
          icon: "save",
          stylingMode,
          dropDownButtonTemplate:"buttonRender",
          min,
          max,
          disabledDates,
          showClearButton,
          displayFormat,
          onContentReady:(e)=> e.component.option('inputAttr',inputAttr),
          onOpened,
          type:"datetime"
      }}>
        <Template name="buttonRender" render={()=> <Button style={{backgroundColor:"unset",marginLeft:"-13px"}} icon={type === "time" ? "clock" : "event"} />}/>
    </Item>
  );
}