import React,{ useRef, useEffect, useState} from 'react';
import { Button } from 'devextreme-react/button';
import DataGrid, { Column, Editing, Pager, Paging, FilterRow,Export} from 'devextreme-react/data-grid';
import Box, {
  Item
} from 'devextreme-react/box';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import AlertDialog from 'components/alertDialog/alertDialog'
import {
  getBitacora,
  getUsuariobySearchTermAndFuncionario,
} from 'api';
import { Template } from 'devextreme-react/core/template';
import { DEFAULT_PAGE_SIZE_ONE_GRID, DEFAULT_PAGE_SIZES, DEFAULT_PAGE_SIZE_CMB } from 'constants/defaultValues';
import { getDataSourceFilter, getDataSourceSort, descargarArchivo} from 'utils/utility';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Form, {
    GroupItem,
    Label
  } from 'devextreme-react/form';
import CustomPopUp from 'components/custompopup';
import ModalBitacora from './modalBitacora';



  export default function () {
    function solicitudCellRender(cellData){
      let nota = cellData.data.solicitud.toString();
      return(
        <div>
          {nota !== null ? <Button icon="info" onClick={()=>{ changeStateModal(nota, 'Solicitud') }}/> : null} 
         </div>
      )
    }
  
    function respuestaCellRender(cellData){
      let nota = cellData.data.respuesta.toString();
      return(
        <div>
          {nota !== null ? <Button icon="info" onClick={()=>{ changeStateModal(nota, 'Respuesta') }}/> : null} 
         </div>
      )
    }
  
    function headersCellRender(cellData){
      let nota = cellData.data.solicitud.toString();
      return(
        <div>
          {nota !== null ? <Button icon="info" onClick={()=>{ changeStateModal(nota, 'Encabezado') }}/> : null} 
         </div>
      )
  }


    //#region hook de control
        const formRef = useRef();
    //#endregion
    //#region hook de estado
    const [bitacora,setBitacora] = useStateWithCallbackLazy({
        "usuario":null,
        "fecha":null
  })
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({data:null, title:null});
  //#endregion
   //#region carga de datos iniciales 
   useEffect(() => {
    let mounted = true; 
    async function fetchData(){
        if (mounted) { 
        }
    }
    fetchData();
    return () => { mounted = false; };
  },[]); 
  //#endregion

  //#region fetch
  const fetchData = (async (take, skip, filter, sort) => {
    let result = { data: [], totalCount: 0 };
    let bit = {...bitacora};
    let usuario_id = bit.usuario;
    let fecha = bit.fecha;

    const { data: { error, message, data } } = await getBitacora({take, skip, filter, sort, usuario_id, fecha});
    if (error) {
      AlertDialog({ message: message });
    } else if (data.totalCount) {
      result = data;
    }
    return result; 
  });
  //#endregion

  //#region dataSource
  const dataSource = new DataSource({
    pageSize: 10,
    store: new CustomStore({
      key: "log_id",
      load: (loadOptions) => {
        return fetchData(loadOptions.take, loadOptions.skip, getDataSourceFilter(loadOptions), getDataSourceSort(loadOptions));
      }
    })
  });

  const generarDataSource = (getFunc)=>{
    const fetchFunc = async ({seachterm = ''}, take = 1, skip = 0, ) =>{
      let result = []
      const {data:{error,message,data}} = await getFunc(seachterm, take, skip);
      if(error){
          AlertDialog({message});
      }else if(data.length) {
          result = data;
      }
      return result;
    }

    const ds = new DataSource({
      pageSize: DEFAULT_PAGE_SIZE_CMB,
      store: new CustomStore({
          key:"id",
          load: (loadOptions) => {
              if(loadOptions.searchValue)
                  return fetchFunc({seachterm:loadOptions.searchValue}, loadOptions.take, loadOptions.skip);     
              else 
                  return fetchFunc({}, loadOptions.take, loadOptions.skip);             
          },
          byKey: (key) => {
              return key;
          }
        })
    });
    return ds;
  }
  
  const usuariosDatasource = generarDataSource(getUsuariobySearchTermAndFuncionario);
  //#endregion

  //#region eventos interfaz
  const searchFilter = () =>{
    dataSource.reload();
  }

  const cleanFilter = async() =>{
      formRef.current.instance.updateData('usuario',null);
      formRef.current.instance.updateData('fecha',null);
      dataSource.reload();
    
  }



  const changeStateModal = async(dato = null, titulo = null) =>{
    console.log('changeStateModal ',dato)
    if(data.data !== null)
    await setData({data:null, title:null});
      else
    await setData({data:dato, title:titulo})

    setOpenModal(!openModal);
    
  }
  //#endregion

  //#region render
  return (
    <React.Fragment>
    {
      openModal && <CustomPopUp height={'180px'} width={'65%'} visible={true} onClose={()=>changeStateModal && changeStateModal()} title={data.title}>
              <ModalBitacora data={data.data}/>
        </CustomPopUp>
    }

      <h2 className={'content-block'}>Bitacora de sistema</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
            <form>
                <Form
                    ref={formRef}
                    formData={bitacora}
                    readOnly={false}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                    validationGroup="eventoData"
                >
                    <GroupItem colCount={12}>
                      <Item
                        colSpan={6}
                        dataField="usuario"
                        editorType="dxSelectBox"
                        editorOptions={{
                            valueExpr:"usuario_id",
                            searchEnabled:true,
                            searchMode:"contains",
                            displayExpr: (item) => item && item.nombre_completo + ' - ' + item.rfc,                     
                            searchExpr:(item) => item && item.nombre_completo + ' - ' + item.rfc,
                            dataSource: usuariosDatasource,
                        }}
                      >
                        <Label text={"Usuario"}/>
                      </Item>
                        <Item 
                              colSpan={6}
                              dataField="fecha"
                              stylingMode="underlined"
                              editorType="dxDateBox" 
                              editorOptions={{
                                  type:"date",
                                  width: '100%', 
                                  inputAttr:{readOnly:false},
                                  dropDownButtonTemplate:"buttonRender",
                              }}
                          >
                              <Label text={'Fecha'}/>
                              <Template name="buttonRender" render={()=> <Button style={{fontSize:'3px', backgroundColor:"unset",marginLeft:"-13px"}} icon={"event"} />}/>
                          </Item>
                        <Item colSpan={4}>
                        <Button text={"Buscar"} type="success" onClick={()=>{searchFilter()}}/>
                        <Button style={{marginLeft:"5px"}}  text={"Limpiar"} type="default" onClick={()=>{cleanFilter()}}/>
                        </Item>
                    </GroupItem>
                </Form>
            </form>
        
          <Box width="100%" direction="row">
            <Item ratio={12}>
              <DataGrid 
                key="id"
                rowAlternationEnabled={true}
                dataSource={dataSource}
                rowAlternationEnabled={true}                
                showBorders={true}
                columnAutoWidth={true}
                remoteOperations={{ paging: true, sorting: true, filtering: true }}
                width={"100%"}>
                <FilterRow visible={true} showOperationChooser={false}/>
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                        <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                        showInfo={true} />
                <Editing
                  mode="row"
                  useIcons={false}
                  allowDeleting={false}
                  allowUpdating={false}
                >
                  </Editing>
                <Column dataField = "fecha_solucitud" caption="Fecha de solicitud"/>
                <Column dataField = "usuario_nombre" caption="Usuario" alignment={'center'}/>
                <Column dataField = "controlador" caption="Controlador" alignment={'center'}/>
                 <Column dataField = "opcion" caption="Opcion" alignment={'center'}/>
                <Column dataField = "accion" caption="Accion" alignment={'center'}/>

                <Column dataField = "solicitud" caption="Solicitud" alignment={'center'} cellRender={solicitudCellRender} allowSorting={false} allowFiltering={false}/>
                <Column dataField = "respuesta" caption="Respuesta" alignment={'center'} cellRender ={respuestaCellRender} allowSorting={false} allowFiltering={false}/>
                <Column dataField = "encabezados" caption="Encabezado" alignment={'center'} cellRender ={headersCellRender} allowSorting={false} allowFiltering={false}/>
                <Column dataField = "tiempo_respuesta" caption="Tiempo de respuesta" alignment={'center'}/>
              </DataGrid>
            </Item>
          </Box>
        </div>
      </div>
    </React.Fragment>
  );
  }