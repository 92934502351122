import {instance} from '../constants/defaultValues';

export function getUsuarioLimitadoPuesto({id = 0, id_evento_puesto = 0, take = 1, skip = 0, filter = {}, sort = {}}){
    if(id && id>0){
        let ent = instance.post(`/api/read`,{
            "VC":"rel_usuario_limitado_puesto",
            "VA":"byid",
            "id":id
          })
       
        return ent;
    }
    else{
        return instance.post(`/api/read`,{
            "VC":"rel_usuario_limitado_puesto",
            "VA":"listdata",
            "id_evento_puesto": id_evento_puesto,
            "page":skip/take,
            "pagesize":take,
            ...filter,
            "sortby": sort.selector,
            "sortdesc": sort.desc
        })
    }
}


export function postUsuarioLimitadoPuesto(usuarioLimitadoPuesto){

    return instance.post(`/api/write`,{
        "VC":"rel_usuario_limitado_puesto",
        "VA":"bysystem",
        "id":usuarioLimitadoPuesto.id,
        "id_usuario":usuarioLimitadoPuesto.id_usuario,
        "id_evento_puesto":usuarioLimitadoPuesto.id_evento_puesto,
        "nombre": usuarioLimitadoPuesto.nombre,
        "usuario" : usuarioLimitadoPuesto.usuario,
        "usuario_password": usuarioLimitadoPuesto.usuario_password,
        "usuario_password_confirm": usuarioLimitadoPuesto.usuario_password_confirm,
        "anexos": usuarioLimitadoPuesto.anexos,
        "anexos_seleccionados": usuarioLimitadoPuesto.anexos_seleccionados,
    })
}

export function deleteUsuarioLimitadoPuesto(id){
    return instance.post(`/api/delete`,{
        "VC":"rel_usuario_limitado_puesto",
        "VA":"bysystem",
        "id" : id
    });
}

export function activateUsuarioLimitadoPuesto(id){
    return instance.post(`/api/write`,{
        "VC":"rel_usuario_limitado_puesto",
        "VA":"activate",
        "id" : id
    });
}
