import {instance} from '../constants/defaultValues';

export function getObservacionesRevision({id_puesto_anexo,take = 1, skip = 0, filter = {}, sort = {}}){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto_anexo_observacion_revision",
        "VA":"listdata",
        id_puesto_anexo,
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc
    })
    
}

export function getObservacionesAceptacion({id_puesto_anexo,take = 1, skip = 0, filter = {}, sort = {}}){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto_anexo_observacion_aceptacion",
        "VA":"listdata",
        id_puesto_anexo,
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc
    })
    
}

export function postAddRevisionObservacion(parameters){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto_anexo_observacion_revision",
        "VA":"bysystem",
        ...parameters
    })
}

export function postAddAceptacionObservacion(parameters){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto_anexo_observacion_aceptacion",
        "VA":"bysystem",
        ...parameters
    })
}

export function postAddRevisionObservacionComentario(parameters){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto_anexo_observacion_revision",
        "VA":"addcomment",
        ...parameters
    })
}

export function postAddAceptacionObservacionComentario(parameters){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto_anexo_observacion_aceptacion",
        "VA":"addcomment",
        ...parameters
    })
}

export function postFinishRevisionObservacion(id,estatus){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto_anexo_observacion_revision",
        "VA":"changerevisionestatus",
        id,
        estatus
    })
}

export function postFinishAceptacionObservacion(id,estatus){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto_anexo_observacion_aceptacion",
        "VA":"changerevisionestatus",
        id,
        estatus
    })
}

