import React, { useState } from 'react';
import { Button } from 'devextreme-react/button';
import { useHistory } from 'react-router-dom';
import DataGrid, { Column, Editing, Pager, Paging, FilterRow, Lookup } from 'devextreme-react/data-grid';
import Box, { Item } from 'devextreme-react/box';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {DEFAULT_PAGE_SIZE_ONE_GRID,
    DEFAULT_PAGE_SIZES,
    ESTATUS_SOURCE_CONFIG
} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog'
import { getBibliotecaDocumento, deleteBibliotecaDocumento, activateBibliotecaDocumento, adjuntoBibliotecaDocumento,} from '../../../api';
import {cargarArchivo,descargarArchivo} from 'utils/utility.js';
import { getDataSourceFilter, getDataSourceSort } from 'utils/utility'
import lang from '../../../lang';
import AuthCheck from '../../../components/authCheck/authCheck.js';
import { custom } from 'devextreme/ui/dialog';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import PopUpEntidadInfo from '../../../components/PopUpEntidadInfo';

export default function() {
    //#region hook de control
    const history = useHistory();
    const[isPopUpOpen, setIsPopUpOpen] = useState(false);
    const[isPopUpInfoOpen, setIsPopUpInfoOpen] = useState(false);
    const permCanEdit = AuthCheck({permission: "BBLIO_EDIT", onlyValidator: true});
    const permCanDelete = AuthCheck({permission: "BBLIO_DELETE", onlyValidator: true});
    //#endregion

    //#region hook de estado
    const [entidadInfo,setEntidadInfo] = useStateWithCallbackLazy({
        "id":0,
        'registro_creacion_usuario':'',
        'registro_creacion_fecha':'',
        'registro_modificacion_usuario':'',
        'registro_modificacion_fecha':'',
        'registro_cancelacion_usuario':'',
        'registro_cancelacion_fecha':'',
    })
    //#endregion

    //#region fetch
    const fetchEntidadInfo = (async(id) =>{
        let result = {...entidadInfo};
        if(id){     
            const {data:{error,message,data}} = await getBibliotecaDocumento({bibliotecaDocumentoId:id});
            
            if(error){
                AlertDialog({message: message});
            }else if(data.length){
                result.id = data[0].id;
                result.registro_creacion_usuario = data[0].registro_creacion_usuario;
                result.registro_creacion_fecha = data[0].registro_creacion_fecha;
                result.registro_modificacion_usuario = data[0].registro_modificacion_usuario;
                result.registro_modificacion_fecha = data[0].registro_modificacion_fecha;
                result.registro_cancelacion_usuario = data[0].registro_cancelacion_usuario;
                result.registro_cancelacion_fecha = data[0].registro_cancelacion_fecha;
            }
        }
        return result;
    })
    const fetchData = (async (take, skip, filter, sort) =>{
        let result= {data:[], totalCount:0};
        const {data:{error,message,data}} = await getBibliotecaDocumento({take, skip, filter, sort});
        if(error){
            AlertDialog({message});
        }else if(data.length && data[0].totalCount) {
            result = data[0];
        }
        return result;
    })
    const deleteData = (async (key) =>{
        const {data:{error,message}} = await deleteBibliotecaDocumento(key);
        if(error){
            AlertDialog({message});
        }
    })
    const activateData = (async (key) =>{
        const {data:{error,message}} = await activateBibliotecaDocumento(key);
        if(error){
            AlertDialog({message});
        }
    })
    //#endregion

    //#region dataSource
    const dataSource = new DataSource({
        store: new CustomStore({
            key: "id",
            load: (loadOptions) => {
                return fetchData(
                    loadOptions.take, 
                    loadOptions.skip, 
                    getDataSourceFilter(loadOptions), 
                    getDataSourceSort(loadOptions));             
            },
            remove: (key) => {
                return deleteData(key);
            }
        })
    });
    //#endregion
    
    //#region eventos interfaz
    const goToDetail = (id = null) =>{
        history.push({pathname:'/catalogos/bibliotecaDocumento/detalle', state:{id} })
    }
    
    const openModal = () =>{
        setIsPopUpOpen(!isPopUpOpen);
    }
    const openModalInfo = async(id) =>{    
        let ent = await fetchEntidadInfo(id); 
        setEntidadInfo(ent);    
        setIsPopUpInfoOpen(!isPopUpInfoOpen);
    }

    const  activateRecord = (e) =>{
        let activateDialog = custom({
            showTitle: false,
            messageHtml: "¿Está seguro que desea activar este registro?",
            buttons: [
                {
                    text: "SÍ",
                    onClick: () => {
                        return true;
                    }
                }, 
                {
                    text: "NO",
                    onClick: () => {
                        return false;
                    }
                }, 
            ]
        });

        activateDialog.show().then(async (dialogResult) => {
            if(dialogResult){
                await activateData(e.row.data.id);
                dataSource.reload();
            }
        });
    }

    function canUpload(e){
        return e.row.data.estatus == 1 && permCanEdit;
    }
    function canDownload(e){
        return e.row.data.id_adjunto;
    }

    function canEdit(e){
        return e.row.data.estatus == 1 && permCanEdit;
    }

    function canDelete(e){
        return e.row.data.estatus == 1 && permCanDelete;
    }

    function canActivate(e){
        return e.row.data.estatus != 1 && permCanDelete;
    }
    //#endregion

    //#region archivo
    const cargar =  function(data) {
        const alCargarArchivo = (async(idAdjunto)=>{   
            data.id_adjunto = idAdjunto;
        
            const {data:{error,message}} = await adjuntoBibliotecaDocumento(data)
            if(error){
                AlertDialog({message});
                data.id_adjunto = null; 
            } 
            
            dataSource.reload();
        });

        cargarArchivo('*', alCargarArchivo, ()=>{});
    }  
    const descargar = (async(data)=>{
        if (data.id_adjunto) descargarArchivo(data.id_adjunto,  ()=>{})
    });
    //#endregion

    //#region render
    return(
        <React.Fragment>
            <h2 className={'content-block'}>{lang.getString("biblioteca.documento.index.title")}</h2>
            <div className={'content-block'}>
            <div className={'dx-card responsive-paddings'}>
            <div className="dx-field-value" style={{marginBottom:"20px"}}>
                        
            <div style={{display:"inline-block", float:"right"}}>
            {
                AuthCheck({
                    permission:"BBLIO_ADD",
                    component: <Button icon="plus"
                    type="default"
                    text={lang.getString("biblioteca.documento.index.agregar")}
                    onClick={()=>{goToDetail()}}/>
                })
            }
            </div>
                    
             </div>
            <Box width="100%" direction="row">
                <Item ratio={12}>
                <DataGrid key="id"
                dataSource={dataSource}
                remoteOperations={{paging:true, sorting:true, filtering: true}}
                columnAutoWidth={true}
                repaintChangesOnly={true}
                showBorders={true} width={"100%"}
                rowAlternationEnabled={true}>
                    <FilterRow visible={true} showOperationChooser={false}/>
                    <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={DEFAULT_PAGE_SIZES}
                            showInfo={true} />
                    <Editing
                        mode="row"
                        useIcons={true}
                        allowDeleting={AuthCheck({
                            permission:"BBLIO_DELETE",
                            onlyValidator: true
                        })}
                        allowUpdating={AuthCheck({
                            permission:"BBLIO_ADD",
                            onlyValidator: true
                        })}
                    />
                    <Column caption="Acción" type="buttons" width={120}
                    buttons={[
                        {hint:'Subir documento',icon:'upload',name:'upload', visible:canUpload, onClick:(event) => cargar(event.row.data)},
                        {hint:'Editar',icon:'edit',name:'edit', visible:canEdit, onClick:(event) => goToDetail(event.row.key)},
                        {hint:'Eliminar',icon:'trash',name:'delete', visible:canDelete},
                        {hint:'Activar',icon:'check',name:'activate', visible:canActivate, onClick:activateRecord},
                        {hint:'Ver',icon:'info',name:'info',onClick:(event) => openModalInfo(event.row.key)}, 
                    ]}
                    />
                    <Column dataField="id" visible={false} />
                    <Column dataField="orden" caption={lang.getString("biblioteca.documento.caption.orden")} />
                    <Column dataField="nombre" caption={lang.getString("biblioteca.documento.caption.nombre")} />
                    <Column caption={lang.getString("biblioteca.documento.caption.id_adjunto")} type="buttons"
                        buttons={[
                            {hint:'Bajar documento',icon:'file',name:'download', visible:canDownload, onClick:(event) => descargar(event.row.data)},
                        ]}
                    />
                    <Column dataField="fecha_adjunto" caption={lang.getString("biblioteca.documento.caption.fecha_adjunto")} />
                    <Column dataField="estatus" width={120} caption={lang.getString("catalogo.caption.estatus")} >
                        <Lookup dataSource={ESTATUS_SOURCE_CONFIG} valueExpr="id" displayExpr="name"/>
                    </Column>
                    <Column dataField="fecha_creacion" caption={lang.getString("catalogo.caption.registro_creacion_fecha")} />
                    <Column dataField="fecha_modificacion" caption={lang.getString("catalogo.caption.registro_modificacion_fecha")} />  
                </DataGrid>
                </Item>
            </Box>
            <PopUpEntidadInfo
                    isPopUpInfoOpen={isPopUpInfoOpen}
                    setIsPopUpInfoOpen={setIsPopUpInfoOpen}
                    entidadInfo={entidadInfo}
                    titulo={"Documento"}/>
            </div>
            
            </div>
        </React.Fragment>
    );
    //#endregion
}