import React, { useState,useEffect } from 'react';
import {handleReportDownloadAndMakeUrlPost} from './util';
import { Popup } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/load-panel';

const position = { of: '#loading' }; //TODO: CSS - Se utiliza para posicionar el panel de loading

const Viewer =  ({visible=false,title = "",parameters =  {},onClose = ()=> {},ref}) => {
  const [url, setUrl] = useState(null);
  const [modalTitle,setModalTitle] = useState(title);

  const [isLoading,setIsLoading] = useState(true);
  const [isVisible,setIsVisible] = useState(visible);
  
  const vw = Math.max(document.documentElement.clientWidth -50 || 0, window.innerWidth -50 || 0, 600)
  const vh = Math.max(document.documentElement.clientHeight -50 || 0, window.innerHeight -50 || 0, 500)

  
  useEffect(() => {
    let mounted = true;
    async function fetchData() {
      if (mounted) {
        console.log('ref',parameters)
        setIsVisible(visible);
        setModalTitle(title);
        if(visible){
            setIsLoading(true)
            let url = await handleReportDownloadAndMakeUrlPost(parameters);
            setUrl(url);
            setTimeout(()=> setIsLoading(false),100);
        }
      }
    }
    fetchData();
    return () => { mounted = false; };
  }, [visible,title,parameters]);

  const onHiding = ()=>{

    //isVisible && setIsVisible(false);
    setUrl(null);
    onClose && onClose();
  }
   

  return ( <div ref={ref}><Popup
          visible={isVisible}
          onHiding={onHiding}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showTitle={true}
          title={modalTitle}
          width={vw}
          height={vh}
        >
        <div><iframe  src={url} width={"100%"} height={vh-100}></iframe></div>
        <div id={"loading"} style={{top:'50vh',left:'45%',position:'fixed',textAlign:"center"}}>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={position}
                visible={isVisible && isLoading}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false}
                />
        </div> 
    </Popup></div>
  );
}


export default class ViewerWrapper extends React.Component{
  constructor(props){
    super(props);
    this.state={
      visible:false,
      title:"",
      parameters:{}
    }
    this.setParams = this.setParams.bind(this);
  }

  setParams = (params) => {
    this.setState({...params})
  }

  onPrintParametersRequest = ()=>{
    return this.state.parameters;
  }
  onHiding = ()=>{
    this.setState({visible:false})
    this.props.onClose && this.props.onClose();
  }

  render(){
    const {visible,title,parameters} = this.state;
    return <Viewer title={title} visible={visible} parameters={parameters} onClose={this.onHiding}></Viewer>
  }
}
