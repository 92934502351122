import lang from "lang";
import swal from 'sweetalert2';

export default function AlertDialog({
    type = '',
    title = '',
    message = undefined,
    content = undefined,
    showDenyButton = false,
    showCancelButton = false,
    confirmButtonText = 'Aceptar',
    denyButtonText = 'Rechazar',
    cancelButtonText = 'Cancelar',
    isNotification = false,
    onActionCallback = () => {},
    onWillClose = () => {}
    }) {
  
  let auxType = type? type: ''
  let auxTitle =  title? `<span style='font-size:30px;font-weight:bold'>`+ title +`</span>`: '';
  let auxMessage =  content? undefined: message ? message: '';

  if(!isNotification)
    return (
      swal.fire({
          icon: auxType? auxType: 'warning',
          title: auxTitle,
          html: lang.getString(auxMessage),
          content: content,
          confirmButtonText: confirmButtonText,
          showDenyButton: showDenyButton,
          denyButtonText: denyButtonText,
          showCancelButton: showCancelButton,
          cancelButtonText: cancelButtonText,
      }).then((result) => {
          onActionCallback(result);
      })
    );
  else
    return (swal.fire({
        icon: auxType? auxType: 'success',
        title: lang.getString(auxMessage),
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        willClose: () => {
          onWillClose();
        }
      })
    );  
}
