import React,{useRef, useState, useEffect } from 'react';
import {
    DEFAULT_PAGE_SIZE_TWO_GRID,
    DEFAULT_PAGE_SIZES,
} from 'constants/defaultValues';
import CustomStore from "devextreme/data/custom_store";
import { Button } from 'devextreme-react/button';
import DataSource from "devextreme/data/data_source";
import {getEventoEntesUnidadesPuestos, getEventoEntesUnidadesPuestosByUnidad ,postAddEventoPuestoRevisorAdministrador, postAddEventoPuestoRevisorInterno} from 'api';
import AlertDialog from 'components/alertDialog/alertDialog';
import SearchFuncionario from 'components/searchFuncionario/searchFuncionario.js'
import DataGrid, { Column, Pager, Paging,Selection, FilterRow, } from 'devextreme-react/data-grid';
import CustomPopUp from 'components/custompopup';
import ModalRevisoresList from './modalRevisoresList';

export default function(props){
    const grdRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [funcionarioId, setFuncionarioId] = useState(null);
    const [eventoPuestosArray, setEventoPuestosArray] = useState([]);
    const [onSelectionChangedFirstTime, setOnSelectionChangedFirstTime] = useState(true);
    const [modal, setModal] = useState({
        'open':false,
        'info':null
    });

    function revisoresCellRender(cellData){
        let flag = cellData.data.flag_modal;
        console.log('revisoresCellRender flag', flag);
        console.log('revisoresCellRender cellData.data', cellData.data);
        return(
          <div>
            {flag ? <Button icon="info" onClick={()=>{ changeModalState(cellData.data.evento_puesto_id) }}/> : null} 
           </div>
        )
      }

    useEffect(() => {
        let mounted = true;
        async function fetchData() {
            if (mounted) {
                await setEventoPuestosArray(await fetchPuestos());
            }
        }
        fetchData();
        return () => { mounted = false; };
    }, []);

    const fetchPuestos = async () =>{
        if(props.revisorInterno){
            const {data:{error,message,data}} = await getEventoEntesUnidadesPuestosByUnidad({eventoId:props.eventoId, entePublicoId:props.entePublicoId, unidadAdministrativaId:props.unidadAdministrativaId});
            if(error){ 
                AlertDialog({message});
            }else if(data.length) {
                console.log('getEventoEntesUnidadesPuestosByUnidad',data);
                return data;
            }
            return [];
        }

        const {data:{error,message,data}} = await getEventoEntesUnidadesPuestos({eventoId:props.eventoId});
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            return data;
        }
        return [];
    }

    const datasource = new DataSource({
        store: new CustomStore({
            key: "evento_puesto_id",
            load: (loadOptions) => eventoPuestosArray
        })
    });

    const onSelectionChanged = async (e) =>{
        if(funcionarioId == null){
            e.component.deselectRows([e.selectedRowKeys[0]]); 
            AlertDialog({message: "Antes de seleccionar puestos, tienes que cargar un funcionario.", type:"warning"});
            return;  
        }

        if(onSelectionChangedFirstTime){
            setOnSelectionChangedFirstTime(false);
            return;
        }

        handleSubmit();
    }

    const loadSelectedRows = (funcionarioId) => {
        if(funcionarioId == null) return;
        let selectedRows = eventoPuestosArray.filter((eventoPuesto)=>(eventoPuesto.funcionario_id == funcionarioId)).map(eventoPuesto => {return eventoPuesto.evento_puesto_id; });
        grdRef.current.instance.selectRows(selectedRows, false);
        if(!selectedRows.length)
            setOnSelectionChangedFirstTime(false);
    }

   const handleSubmit  = async () =>{
        setIsLoading(true);
        let selectedRowsData = grdRef.current.instance.getSelectedRowsData(); 
        if(props.revisorInterno){
            const {data:{error,message,data}} = await postAddEventoPuestoRevisorInterno({funcionario_id:funcionarioId,puestos:selectedRowsData, id_evento: props.eventoId, id_ente_publico: props.entePublicoId});
            if(error){
                AlertDialog({message});
                return;
            }else if(data.length) {
                AlertDialog({message: "Cambio realizado con éxito.",isNotification:true});
            }
        } else {
            const {data:{error,message,data}} = await postAddEventoPuestoRevisorAdministrador({funcionario_id:funcionarioId,puestos:selectedRowsData, id_evento: props.eventoId});
            if(error){
                AlertDialog({message});
                return;
            }else if(data.length) {
                AlertDialog({message: "Cambio realizado con éxito.",isNotification:true});
            }
        }
        await setEventoPuestosArray(await fetchPuestos());
        setIsLoading(false);
   }

   const changeModalState = async(data = null) =>{
        if(data !== null ) 
            await setModal({'open':true,'info':data});
        else {
            await setModal({'open':false,'info':null});
            setIsLoading(true);
            await setEventoPuestosArray(await fetchPuestos());
            setIsLoading(false);
        }
   }

    return(
        <div>
            {modal.open && <CustomPopUp height={'35%'} width={'35%'} visible={true} onClose={()=>{changeModalState && changeModalState()}} title={'Funcionarios asignados'}>
                <ModalRevisoresList data={modal.info} revisorInterno={props.revisorInterno}/>
                </CustomPopUp> 
            }
            {SearchFuncionario({onlyForm: true, funcionarioId: funcionarioId, canCreate:true, validateRevisorSistema:props.validateRevisorSistema, onFound: (funcionarioId) =>{setFuncionarioId(funcionarioId); loadSelectedRows(funcionarioId);}})}
            <DataGrid 
                key="evento_puesto_id"
                ref={grdRef}
                dataSource={datasource}
                showBorders={true}
                columnAutoWidth={true}
                rowAlternationEnabled={true}
                onSelectionChanged={onSelectionChanged}
                disabled={isLoading}
                >
                <FilterRow visible={true} showOperationChooser={false}/>
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE_TWO_GRID} />
                <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                        showInfo={true} />
                <Selection
                    mode="multiple"
                    selectAllMode={'page'}
                    showCheckBoxesMode={'always'}
                />
                    <Column dataField="evento_puesto_id" caption="Id" visible={false} />
                    <Column dataField="ente_clave" caption="Clave" />
                    <Column dataField="ente_nombre" caption="Ente público" />
                    <Column dataField="unidad_administrativa_nombre" caption="Unidad administratíva" />
                    <Column dataField="puesto_nombre" caption="Puesto" />
                    <Column dataField="funcionario_nombre" caption="Revisor asignado" />
                    <Column dataField="flag_modal" visible={props.revisorInterno} caption="Revisores" cellRender={revisoresCellRender} allowFiltering={false} allowSorting={false}/>
            </DataGrid>
        </div>
    )
}