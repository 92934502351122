import React from 'react';
import DataGrid, { Column, Pager, Paging,FilterRow,Editing } from 'devextreme-react/data-grid';
import {
    DEFAULT_PAGE_SIZE_TWO_GRID,
    DEFAULT_PAGE_SIZES,
} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog';
import {getEventoEntesUnidadesPuestosByUnidad_funcionarios, deleteFuncionariorRevisor} from 'api';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

export default function(props){

    const getDataSource = async() =>{
        let result = [];
        const {data:{error,message,data}} = await getEventoEntesUnidadesPuestosByUnidad_funcionarios(props.data, props.revisorInterno);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    }

        const deleteData = (async (key) => {
            const { data: { error, message } } = await deleteFuncionariorRevisor(key, props.data, props.revisorInterno);
            if (error) {
              AlertDialog({ message: message });
            }else{
                AlertDialog({ message: 'Funcionario eliminado', isNotificatio:true, type:'success'});
            }
          })

    const datasource = new DataSource({
        store: new CustomStore({
            key:"id",
            load: (loadOptions) => {
                    return getDataSource({seachterm:loadOptions.searchValue}, loadOptions.take, loadOptions.skip);     
            },
            remove: (key) => {
                return deleteData(key);
            },
            byKey: (key) => {
                return key;
            }
          })
      });


    return(
        <div>
            <DataGrid 
                key="id"
                dataSource={datasource}
                showBorders={true}
                columnAutoWidth={true}
                rowAlternationEnabled={true}
                >
                <FilterRow visible={true} showOperationChooser={false}/>
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE_TWO_GRID} />
                <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                        showInfo={true} />

                <Editing
                                mode="row"
                                useIcons={true}
                                allowDeleting={true}
                                >
                     </Editing>

                    <Column type="buttons" width={100}
                        buttons={[
                            { hint: 'Eliminar', icon: 'trash', name: 'delete',visible:true}
                        ]}
                    />
                    <Column dataField="id" caption="Id" visible={false} />
                    <Column dataField="nombre_funcionario" caption="Funcionario" />
            </DataGrid>
        </div>
    )
}