import {instance} from '../constants/defaultValues';

export function getActa({actaId = 0, take = 10, skip = 0, filter = {}, sort = {}}){
    if(actaId && actaId > 0){
        return instance.post(`/api/read`,{
            "VC":"cat_acta",
            "VA":"byid",
            "id":actaId
        })
    }
    else{
        return instance.post(`/api/read`,{
            "VC":"cat_acta",
            "VA":"listdata",
            "page":skip/take,
            "pagesize":take,
            ...filter,
            "sortby": sort.selector,
            "sortdesc": sort.desc
        })
    }
}

export function postActa(acta){
    return instance.post(`/api/write`,{
        "VC":"cat_acta",
        "VA":"bysystem",
        "id":     acta.id,
        "adj_acta_docx" : acta.adj_acta_docx,
        "observaciones" : acta.observaciones,
        "clave" : acta.clave,
        "orden" : acta.orden,
        "id_adjunto" : acta.id_adjunto,
        "nombre": acta.nombre,

    })
}

export function deleteActa(actaId){
    return instance.post(`/api/delete`,{
        "VC":"cat_acta",
        "VA":"bysystem",
        "id" : actaId
    });
}

export function activateActa(actaId){
    return instance.post(`/api/write`,{
        "VC":"cat_acta",
        "VA":"activate",
        "id" : actaId
    });
}

export function adjuntoActa(acta){
    return instance.post(`/api/write`,{
        "VC":"cat_acta",
        "VA":"adjunto",
        "id":acta.id,
        "id_adjunto":acta.id_adjunto,
    })
}