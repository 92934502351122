import React,{useState,useRef} from 'react';
import { Button } from 'devextreme-react/button';
import { useHistory } from 'react-router-dom';
import DataGrid, { Column, Editing, Pager, Paging, FilterRow,Texts } from 'devextreme-react/data-grid';
import Box, {
  Item
} from 'devextreme-react/box';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import AlertDialog from 'components/alertDialog/alertDialog'
import {
  getEventosPuestos, 
  getEventoAnexoArchivo,
  postBlockEventoPuesto,
  postClosePuesto,
  postBlockAnexosEventoPuesto
} from 'api';
import { DEFAULT_PAGE_SIZE_ONE_GRID, DEFAULT_PAGE_SIZES, STRING_DATAGRID_ADD_ROW } from 'constants/defaultValues';
import { getDataSourceFilter, getDataSourceSort, descargarArchivo } from 'utils/utility'
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import EventoInfo from './eventoInfo.js';
import AuthCheck from '../../../components/authCheck/authCheck.js';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { LoadPanel } from 'devextreme-react/load-panel';
import swal from 'sweetalert2';
import CustomPopUp from 'components/custompopup';
import {
    STRING_ALERTDIALOG_CREATE_SUCCESS
  } from 'constants/defaultValues';
import GeneralActaModal from  'components/generarActaModal/index.js';
import ModalEntranteSaliente from 'components/asignarFuncionarioDialog/asignarEntranteSalienteDialog';

const position = { of: '#frgConsultaPuesto' };


export default function () {

 
  //#region hook de control
  const history = useHistory();
  const dataGridRef = useRef();
  const[isPopUpInfoOpen, setIsPopUpInfoOpen] = useState(false);
  const permDownloadActa = AuthCheck({permission: "EVENT_DOWNLOAD_ACTA", onlyValidator: true});
  const [isOpenModalEntranteSaliente, setIsOpenModalEntranteSaliente] = useState(false);
  const [selectedEventoPuesto, setSelectedEventoPuesto] = useState(null);
  const [isOpenGenerarActaModal, setIsOpenGenerarActaModal] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [eventoPuesto, setEventoPuesto] = useState({
    "id_evento_puesto": null,
    "acta_json":null,
    "acta_firmada_descarga_id": null,
    "filename":  null,
    "flag_acta_cargada" : null,
  });
  //#endregion

  //#region hook de estado
  const [eventoInfo,setEventoInfo] = useStateWithCallbackLazy({
    "id":0,
    'registro_creacion_usuario':'',
    'registro_creacion_fecha':'',
    'registro_modificacion_usuario':'',
    'registro_modificacion_fecha':'',
    'registro_cancelacion_usuario':'',
    'registro_cancelacion_fecha':'',
});

const permFuncionarios    = AuthCheck({permission: "EVENT_PUESTO_FUNCIONARIOS", onlyValidator: true});
const permBloquear    = AuthCheck({permission: "EVENT_PUESTO_BLOQUEO", onlyValidator: true});
const permDelete    = AuthCheck({permission: "EVENT_PUESTO_DELETE", onlyValidator: true});
const puesto_close = AuthCheck({permission: "EVENT_PUESTO_CLOSED", onlyValidator: true});
const permAnexo    = AuthCheck({permission: "EVENT_PUESTO_ANEXO", onlyValidator: true});
//#endregion


function IconRenderActaFirmadas(data){
  let flag = permDownloadActa ? true : false;
      return <div key={`dgrid_eventomain_actas_firmadas_download_${data.data.id}`}>
          {
              !flag ? null : !data.data.downloading_actas_firmadas  ? (
                  <i className="dx-link dx-icon-file dx-link-icon" title="Descargar archivo" style={{fontSize:'22px',textDecoration:'unset'}} onClick={()=>{downloadArchivo(data)}}></i>) 
                  : <div className={"dx-link dx-link-icon"}><LoadIndicator  height={20} width={20}  /></div>
          }
      </div>
}

const IconRenderAnexo= function(cellData){
  let flag = permAnexo ? true : false;//cellData.data.anexos_capturados > 0;

  return <div key={`dgrid_puesto_anexo_download_${cellData.data.id_evento_puesto}`}>
      {
          !flag ? null : !cellData.data.downloading ?
                 (<i style={{fontSize:"18px",cursor:"pointer"}} onClick={()=> handleDownloadAnexos(cellData)} class="far fa-file-pdf"></i>)
                 : <div className={"dx-link dx-link-icon"}><LoadIndicator  height={20} width={20}  /></div>
                  
      }
  </div>
}

const handleDownloadAnexos=async(dato)=>{
  console.log("dato",dato);
  swal.fire({
    title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
    text: "Por favor espere.",
    imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
    showConfirmButton: false,
    allowOutsideClick: false
  });
  let progress = 0;
  setTimeout(() => {
    if(progress===0){
        swal.update({
            html: "Recopilando información...",
            title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
            //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
            showConfirmButton: false,
            allowOutsideClick: false
        });
        swal.showLoading();
        setTimeout(() => {
            if(progress===0){
                swal.update({
                    html: "Creando archivo comprimido...",
                    title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                    //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                    showConfirmButton: false,
                    allowOutsideClick: false
                });
                swal.showLoading();
            }
        },2000);
    }
}, 200);

const {data:{data, message, error}} = await getEventoAnexoArchivo({eventoId : dato.data.id_evento,
  enteId : dato.data.id_ente_publico,
  id_unidad_administrativa : dato.data.id_unidad_administrativa,
  evento_puesto_id : dato.data.id_evento_puesto,
  //puesto_id: dato.data.id_puesto,
},
  (pregress)=>{ 
      progress = pregress;
      console.log('Descargando archivo...'+pregress+"%");
  swal.update({
          html: 'Descargando archivo...'+pregress+"%",
          title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
          //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
          showConfirmButton: false,
          allowOutsideClick: false
      });
      swal.showLoading();
  });
  if(error){
    AlertDialog({message});
    }else{
        swal.fire({
            title: `<span style='font-size:30px;font-weight:bold'>`+  "Descarga finalizada" +`</span>`,
            showConfirmButton: false,
            icon:'success',
            timer: 1000
        });
    }
  }


const downloadArchivo=async(data)=>{
  dataGridRef.current.instance.getDataSource().store().push(
    [{
        type: 'update',
        key: data.key,
        data: {
            ...data.data, downloading_actasFirmadas: 1
        }
    }]
);
console.log('descargarArchivo', data.data.acta_firmada_descarga_id);
await descargarArchivo(data.data.acta_firmada_descarga_id)
dataGridRef.current.instance.getDataSource().store().push(
  [{
      type: 'update',
      key: data.key,
      data: {
          ...data.data, downloading_actasFirmadas: 0
      }
  }]
);
}


  //#region fetch
  const fetchData = (async (take, skip, filter, sort) => {
    let result = { data: [], totalCount: 0 };
    const { data: { error, message, data } } = await getEventosPuestos({take, skip, filter, sort });
    if (error) {
      AlertDialog({ message: message });
    } else if (data[0].totalCount) {
      result = data[0];

      console.log('datax', result)
    }
    return result;
  })


 
  //#endregion

  //#region dataSource
  const dataSource = new DataSource({
    store: new CustomStore({
      key: "id_evento_puesto",
      load: async(loadOptions) => {
        return (
          await fetchData(loadOptions.take, loadOptions.skip, getDataSourceFilter(loadOptions), getDataSourceSort(loadOptions))
          )
      }
    })
  });
  //#endregion

  //#region eventos interfaz

  const ChageStateModalEntranteSaliente=(e)=>{
    if(e){
      setSelectedEventoPuesto({
        eventoPuestoId: e.row.data.id_evento_puesto,
        funcionarioSalienteId: e.row.data.id_funcionario_saliente,
        funcionarioEntranteId: e.row.data.id_funcionario_entrante
      });
    }
    setIsOpenModalEntranteSaliente(!isOpenModalEntranteSaliente)
  }

  const handleBlock = async(data,bloqueo)=>{
    AlertDialog({showCancelButton:true,message: bloqueo === 0 ? "¿Seguro que desea des-bloquear este puesto?" : "¿Seguro que desea bloquear este puesto?",isNotification:false, type:"warning",onActionCallback:async(resp)=>{
      if(resp.value){
        try{
          setIsLoading(true);
          let params = {id_evento_puesto:data.id_evento_puesto,bloqueo};
          const {data:{error,message}} = await postBlockEventoPuesto(params);
          setIsLoading(false);
              if(error) AlertDialog({message});
              else AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true });
              
        }catch(error){
          setIsLoading(false);
          AlertDialog({message:"Ha ocurrido un error al tratar de bloquear el puesto, comuniquese con el adminsitrador"})
        }
        
      }
  }});
  }

  const handleClose = async(data)=>{
    AlertDialog({showCancelButton:true,message:"¿Seguro que desea cerrar el puesto?",isNotification:false, type:"warning",onActionCallback:async(resp)=>{
      if(resp.value){
        try{
          setIsLoading(true);
          const {data:{error,message}} = await postClosePuesto(data.id_evento_puesto);
          setIsLoading(false);
              if(error) AlertDialog({message});
              else AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true });
              
        }catch(error){
          setIsLoading(false);
          AlertDialog({message:"Ha ocurrido un error al tratar de cerrar el puesto, comuniquese con el adminsitrador"})
        }
        
      }
  }});
    
  }

  const changeStateModalGenerarActa=(e)=>{
    if(e)
      setEventoPuesto({
        "id_evento_puesto": e.row.data.id_evento_puesto,
        "acta_json":e.row.data.acta_json,
        "acta_firmada_descarga_id": e.row.data.acta_firmada_descarga_id,
        "filename":  e.row.data.filename,
        "flag_acta_cargada" :e.row.data.flag_acta_cargada
      });
    else
      setEventoPuesto({
        "id_evento_puesto": null,
        "acta_json":null,
        "acta_firmada_descarga_id": null,
        "filename":  null,
        "flag_acta_cargada" : null
      });

    setIsOpenGenerarActaModal(!isOpenGenerarActaModal);
  }

  const handleLockEventoPuesto = async(e)=>{
    let flag = !e.flag_bloqueo_anexos;
    try{
      setIsLoading(true);
      const {data:{data, message, error}} = await postBlockAnexosEventoPuesto(e.id_evento_puesto, flag);
      if(error){
        AlertDialog({message});
      }else{
        setIsLoading(false);
        AlertDialog({message: flag ? 'Captura desbloqueada' : 'Captura bloqueada', isNotification: true });
      }
    }
    catch(error){
      setIsLoading(false);
      AlertDialog({message:"Ha ocurrido un error al tratar bloquear las capturas de puesto, comuniquese con el adminsitrador"})
    }
    
  }

  //#endregion

  //#region render
  return (
    <React.Fragment id="frgConsultaPuesto">
      <h2 className={'content-block'}>Consulta de puestos</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
        <EventoInfo
            isPopUpInfoOpen={isPopUpInfoOpen}
            setIsPopUpInfoOpen={setIsPopUpInfoOpen}
            eventoInfo={eventoInfo}
            titulo={"Eventos"}
        />
          
          <Box width="100%" direction="row">
            
            <Item ratio={12}>
              <DataGrid key="id"
                ref={dataGridRef}
                dataSource={dataSource}
                remoteOperations={{ paging: true, sorting: true, filtering: true }}
                showBorders={true}
                columnAutoWidth={true}
                width={"100%"}
                allowColumnResizing={true}
                rowAlternationEnabled={true}>
                <FilterRow visible={true} showOperationChooser={false}/>
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                        <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                        showInfo={true} />
           
                  <Column type="buttons" width={190}
                    buttons={[
                        { hint: "Funcionario saliente", icon: 'far fa-user',visible:(e)=> permFuncionarios,onClick:(e)=>ChageStateModalEntranteSaliente(e) },
                        { hint: "Bloquear", icon: 'fas fa-unlock', visible:(e)=> !e.row.data.bloqueo && permBloquear, onClick:(e)=>handleBlock(e.row.data,1) }, //estatus 1 es bloqueado en BD
                        { hint: "Desbloquear", icon: 'fas fa-lock',visible:(e)=> e.row.data.bloqueo && permBloquear,onClick:(e)=>handleBlock(e.row.data,0) }, //estatus 0 es desbloqueado en BD
                        { hint: "Cerrar puesto", icon: 'fas fa-ban',visible:(e)=> !e.row.data.cerrado && puesto_close ,onClick:(e)=>handleClose(e.row.data) },
                        { hint: "Generar acta", icon: 'far fa-file-alt',visible:(e) => e.row.data.cerrado && localStorage.getItem('grupo_id') != 9,
                        onClick:(e)=>changeStateModalGenerarActa(e) }, //estatus 1 es desbloqueado en BD
                        { hint: 'Eliminar', icon: 'fas fa-trash-alt', name: 'delete',visible:(e)=> !e.row.data.unidadBloqueada && !e.row.data.enteBloqueado && permDelete},
                        { hint: "Bloquear captura", icon: 'far fa-times-circle',visible:(e)=> !e.row.data.flag_bloqueo_anexos && localStorage.getItem('grupo_id') === '1' ,onClick:(e)=>handleLockEventoPuesto(e.row.data) },
                        { hint: "Desbloquear captura", icon: 'far fa-check-circle',visible:(e)=> e.row.data.flag_bloqueo_anexos && localStorage.getItem('grupo_id') === '1' ,onClick:(e)=>handleLockEventoPuesto(e.row.data) },
                 ]}/>
                <Column dataField="evento" caption="Evento"/>
                <Column dataField="ente_nombre" caption="Ente"/>
                <Column dataField="unidad_administrativa" caption="Unidad administrativa"/>
                <Column dataField="clave" caption="Clave" width={100}/>
                <Column dataField="nombre_puesto" caption="Nombre del puesto" width={500}/> 
                <Column dataField="funcionario_saliente" caption="Funcionario saliente" width={300}/>  
                <Column dataField="funcionario_entrante" caption="Funcionario entrante" width={300}/>
                <Column dataField="estatus_seguimiento" caption="Estatus seguimiento" width={150}/>
                <Column dataField="estatus_aceptacion" caption="Estatus aceptación" width={150}/> 
                <Column dataField = "downloading_actas_firmadas" caption="Actas firmadas" cellRender={IconRenderActaFirmadas} allowFiltering={false} allowSorting={false}/>
                <Column dataField = "dowloading_anexos" caption="Anexos" cellRender={IconRenderAnexo} allowFiltering={false} allowSorting={false}/>
              </DataGrid>
            </Item>
          </Box>
        </div>
      </div>
      <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={position}
                visible={isLoading}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false}
                />
      {isOpenModalEntranteSaliente && 
            <CustomPopUp height={600} visible={true} onClose={()=>ChageStateModalEntranteSaliente()} title={"Asignar funcionarios"}>
                <ModalEntranteSaliente eventoPuesto={selectedEventoPuesto} onSave={(result)=>{ChageStateModalEntranteSaliente()}} />
            </CustomPopUp>
    }
    {isOpenGenerarActaModal && 
            <CustomPopUp width={'90%'} visible={true} onClose={()=>changeStateModalGenerarActa && changeStateModalGenerarActa()} title={"Generar Acta"}>
                <GeneralActaModal id_evento_puesto={eventoPuesto.id_evento_puesto} acta_json={eventoPuesto.acta_json} file_id={eventoPuesto.acta_firmada_descarga_id} filename={eventoPuesto.filename} flag_acta_cargada ={eventoPuesto.flag_acta_cargada} onAccept={()=>changeStateModalGenerarActa && changeStateModalGenerarActa()}/>
            </CustomPopUp>
          }
    </React.Fragment>
  );
  //#endregion
};