import React,{useState, useRef, useEffect} from 'react';
import Form, { Item,ButtonItem, Label} from 'devextreme-react/form';
import { GroupItem } from 'devextreme-react/tree-list';
import {PATTERN_RFC, PATTERN_CURP} from 'constants/defaultValues';
import {getFuncionario, getFuncionarioByRFC} from 'api';
import AlertDialog from 'components/alertDialog/alertDialog.js';
import ModalFuncionarios from 'components/altaFuncionarios';
import CustomPopUp from 'components/custompopup';
import { custom } from 'devextreme/ui/dialog';

/*
INSTRUCCIONES SUGERIDAS DE IMPLEMENTACION:
---IMPORTS NECESARIOS
import ModalFuncionarios from 'components/altaFuncionarios';
import CustomPopUp from 'components/custompopup';

---VARIABLES DE ESTADO NECESAROS
const [isOpen, setIsOpen] = useState(false);

---METODOS PARA CAMBIAR LA VARIABLE DE ESTADO(ESTO PARA ABRIR O CERRAR EL MODAL)
const ChageStateModalFuncionarios=()=>{
        setIsOpen(!isOpen)
    }
    
---IMPLEMENTACION DE COMPONENT
---DENTRO DEL onAccept del componentes se implementaria el metodo que se desee. La variable objetosIds retornara el id de funcionario que se inserto 
---y el id de usuario
{isOpen && 
    <CustomPopUp height={420} visible={true} onClose={()=>ChageStateModalFuncionarios && ChageStateModalFuncionarios()} title={"Alta de funcionarios"}>
        <ModalFuncionarios onAccept={(objetoIds)=>{}} />
    </CustomPopUp>
}
*/

export default function(props){
    const formRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const [funcionario, setFuncionario] = useState({
    "id": 0,
    "rfc":null,
    "nombre": null,
    "apellido_paterno": null,
    "apellido_paterno": null,
    "nombre_completo": null,
    "correo": null,
    "curp":null,
    "fecha_nacimiento":null,
    "visible": false
    });
    
    useEffect(() => {
        let mounted = true;
        async function fetchData() {
            if (mounted) {
                if (props.funcionarioId) 
                    cargarFuncionarioById(props.funcionarioId);
            }
        }
        fetchData();
        return () => { mounted = false; };
    }, [props.funcionarioId]);

    const cargarFuncionarioById = async(funcionarioId)=>{
        setIsLoading(true);
        const {data:{error,message,data}} = await getFuncionario({funcionarioId});
            if(error){
                AlertDialog({message: message});
            }else if(data.length){
                setFuncionario({...funcionario,
                    id: data[0].id,
                    rfc: data[0].rfc,
                    nombre: data[0].nombre,
                    apellido_paterno: data[0].apellido_paterno,
                    apellido_materno: data[0].apellido_materno,
                    nombre_completo: data[0].nombre + ' ' + data[0].apellido_paterno + ' ' + data[0].apellido_materno,
                    visible:true});
                if(props.onFound)
                    props.onFound(data[0].id);
            } 
        setIsLoading(false);
    }

    const cargarFuncionarioByRfc = async(rfc)=>{
        // Validar que el RFC no este vacio
        if(!rfc){
            cleanForm();
            // Si deja el RFC en vacio regresar null
            props.onFound && props.onFound(null);
            return;
        }

        // Validar el formato del RFC
        if(!formRef.current.instance.validate().isValid){
            return;
        }

        setIsLoading(true);
        let validateRevisorSistema = props.validateRevisorSistema? props.validateRevisorSistema: false;
        const {data:{error,message, data}} = await getFuncionarioByRFC({rfc, validateRevisorSistema});
        if(error){
            AlertDialog({message});
        } else if(data.length){
            setFuncionario({...funcionario,
                id: data[0].id,
                nombre: data[0].nombre,
                apellido_paterno: data[0].apellido_paterno,
                apellido_materno: data[0].apellido_materno,
                nombre_completo: data[0].nombre + ' ' + data[0].apellido_paterno + ' ' + data[0].apellido_materno,
                visible:true});
            // Si lo encuentra regresa el funcionarioId
            props.onFound && props.onFound(data[0].id);
        } else {
            cleanForm();
            // Si no lo encuentra regresa null
            props.onFound && props.onFound(null);
            // Validando si puede dar de alta funcionarios
            if(props.canCreate){
                confirmDialog();
            }
        }
        setIsLoading(false);
    }

    const cleanForm = () =>{
        setFuncionario({...funcionario,
            id: 0,
            nombre: null,
            apellido_paterno: null,
            apellido_materno: null,
            nombre_completo: null,
            visible:false});
    }

    const chageStateModalFuncionarios=()=>{
        setIsOpen(!isOpen)
    }

    const  confirmDialog = () =>{
        let dialog = custom({
            showTitle: false,
            messageHtml: "El RFC no está dado de alta, ¿desea darlo de alta?",
            buttons: [
                {
                    text: "SÍ",
                    onClick: () => {
                        return true;
                    }
                }, 
                {
                    text: "NO",
                    onClick: () => {
                        return false;
                    }
                }, 
            ]
        });

        dialog.show().then(async (result) => {
            if(result){
                chageStateModalFuncionarios();
            } 
        });
    }

    const onKeyDown = (e) => {
        if (e.event.keyCode == 13) {
            e.event.preventDefault();
            formRef.current.instance.getEditor("rfc").blur()
            cargarFuncionarioByRfc(funcionario.rfc)
        }
    }

   const validationRules ={
        rfc:[
            {type:"stringLength",message:"Máximo de caracteres excedido",max:13},
            {type:"pattern",message:"Formato de RFC incorrecto",pattern:PATTERN_RFC}
        ]
    };

    const formVar = <div>
        <form>
        <Form id="frmClaveCatastral" colCount={1} ref={formRef} formData={funcionario} disabled={isLoading}>
            <GroupItem colCount={12} caption={props.title}>
                <Item 
                    colSpan={4}
                    editorType="dxTextBox"
                    dataField="rfc"
                   
                    validationRules={validationRules.rfc}
                    editorOptions={{
                        onKeyDown: (e) => onKeyDown(e)
                    }}
                >
                    <Label text="RFC"/>
                    </Item>
                <ButtonItem
                    colSpan={2}
                    buttonOptions={{
                        text: "Cargar",
                        type:'default',
                        width:"200",
                        onClick: (e) => cargarFuncionarioByRfc(funcionario.rfc)
                    }}
                />
                <Item 
                    colSpan={4}
                    editorType="dxTextBox"
                    dataField="nombre_completo"
                    label = "Funcionario"
                    visible = {funcionario.visible}
                    disabled = {true}
                />
            </GroupItem>
            {isOpen && 
                <CustomPopUp height={420} visible={true} onClose={()=>chageStateModalFuncionarios()} title={"Alta de funcionarios"}>
                    <ModalFuncionarios 
                        rfc= {funcionario.rfc}
                        onAccept={(result)=>{
                                chageStateModalFuncionarios();
                                result.funcionario_id && cargarFuncionarioById(result.funcionario_id);
                            }} />
                </CustomPopUp>
            }
        </Form>
        </form>
    </div>

    return (props.onlyForm ? formVar : <Item render={() => { return formVar }} /> );
}