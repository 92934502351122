import {instance} from '../constants/defaultValues';

export function postAnexoColumnaLista(anexoColumnaLista){

    return instance.post(`/api/write`,{
        "VC":"cat_anexo_columna_lista",
        "VA":"bysystem",
        "id":anexoColumnaLista.id,
        "id_anexo_columna":anexoColumnaLista.id_anexo_columna,
        "clave":anexoColumnaLista.clave,
        "nombre": anexoColumnaLista.nombre,
        "descripcion": anexoColumnaLista.descripcion,
        "orden" : anexoColumnaLista.orden
    })
}

export function deleteAnexoColumnaLista(anexoColumnaListaId){
    return instance.post(`/api/delete`,{
        "VC":"cat_anexo_columna_lista",
        "VA":"bysystem",
        "id" : anexoColumnaListaId
    });
}