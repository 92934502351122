import {instance} from '../constants/defaultValues';

export function postEntePublicoUnidadAdministrativa(entePublicoUnidadAdministrativa){
    return instance.post(`/api/write`,{
        "VC":"cat_ente_publico_unidad_administrativa",
        "VA":"bysystem",
        "id": entePublicoUnidadAdministrativa.id,
        "nombre": entePublicoUnidadAdministrativa.nombre,
        "orden": entePublicoUnidadAdministrativa.orden,
        "id_ente_publico": entePublicoUnidadAdministrativa.ente_publico_id,
    })
}

export function deleteEntePublicoUnidadAdministrativa(entePublicoUnidadAdministrativaId){
    return instance.post(`/api/delete`,{
        "VC":"cat_ente_publico_unidad_administrativa",
        "VA":"bysystem",
        "id" : entePublicoUnidadAdministrativaId
    });
}

export function activateEntePublicoUnidadAdministrativa(entePublicoUnidadAdministrativaId){
    return instance.post(`/api/write`,{
        "VC":"cat_ente_publico_unidad_administrativa",
        "VA":"activate",
        "id" : entePublicoUnidadAdministrativaId
    });
}


export function getMissingUnidadesAdministrativasByEnte({id_ente = 0,id_evento=0, take = 10, skip = 0, filter = {}, sort = {}}){
    return instance.post(`/api/read`,{
        "VC":"cat_ente_publico_unidad_administrativa",
        "VA":"missingbyidente",
        "id" : id_ente,
        id_evento,
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc
    });
}