
import {getReport} from './../../api';

export const handleReportDownload = (parameters,name)=>{
    getReport(parameters).then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', name); 
        document.body.appendChild(link);
        link.click();
        link.remove();
    });   
}

export const handleReportDownloadAndMakeUrlPost = async (parameters,type)=>{
    if(!type) type = 'application/pdf';
   const { data } = await getReport(parameters);
   const downloadUrl = window.URL.createObjectURL(new Blob([data],{type: type}));
    return downloadUrl;
}
export const handleReportDownloadUrl = (downloadUrl,name) =>{
    const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', name); 
        document.body.appendChild(link);
        link.click();
        link.remove();
}

export default  {
    handleReportDownload,
    handleReportDownloadAndMakeUrlPost,
    handleReportDownloadUrl
}