import React,{useState} from 'react';
import Form, { ButtonItem} from 'devextreme-react/form';
import {postAsignarEntranteSaliente} from 'api';
import AlertDialog from 'components/alertDialog/alertDialog.js';
import SearchFuncionario from 'components/searchFuncionario/searchFuncionario.js'


export default function(props){
    const [isLoading, setIsLoading] = useState(false);
    const [funcionarios, setFuncionarios] = useState({
        "evento_puesto_id":props.eventoPuesto && props.eventoPuesto.eventoPuestoId? props.eventoPuesto.eventoPuestoId:null,
        "saliente_funcionario_id":props.eventoPuesto && props.eventoPuesto.funcionarioSalienteId? props.eventoPuesto.funcionarioSalienteId:null,
        "entrante_funcionario_id":props.eventoPuesto && props.eventoPuesto.funcionarioEntranteId? props.eventoPuesto.funcionarioEntranteId:null
    });

    const handleSubmit  = async(e)=>{
        setIsLoading(true);
        // Validando que los por lo menos haya un funcionario elegido
        if(funcionarios.saliente_funcionario_id == null && funcionarios.entrante_funcionario_id == null){
            AlertDialog({message:"Debe cargar por lo menos un funcionario", isNotification: true, type:"error"});
            return;
        } 

        const {data:{error,message, data}} = await postAsignarEntranteSaliente(funcionarios);
        if(error){
            AlertDialog({message});
        } else if(data.length){
            props.onSave && props.onSave();
        }
        setIsLoading(false);
    }    

    return(
        <div>
         <form onSubmit={handleSubmit} disabled={isLoading} style={{marginTop:"20px"}}>
                <Form
                    readOnly={false}
                    showColonAfterLabel={false}
                    showValidationSummary={false}
                    formData={funcionarios}
                    >
                    {SearchFuncionario({title: "Funcionario saliente", funcionarioId: funcionarios.saliente_funcionario_id, canCreate:true, onFound: (funcionarioId) =>{setFuncionarios({...funcionarios, saliente_funcionario_id: funcionarioId})}})}
                    {SearchFuncionario({title: "Funcionario entrante", funcionarioId: funcionarios.entrante_funcionario_id, canCreate:true, onFound: (funcionarioId) =>{setFuncionarios({...funcionarios, entrante_funcionario_id: funcionarioId})}})}
                    <ButtonItem
                        buttonOptions={{
                            text: "GUARDAR",
                            type:'default',
                            width:"200",
                            onClick: (e) => handleSubmit()
                        }}
                    />
                </Form>
            </form>
        </div>
    )
}