import React, { useEffect,useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Form, {
    ButtonItem,
    GroupItem,
    Item,
    Label,
  } from 'devextreme-react/form';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { LoadPanel } from 'devextreme-react/load-panel';
import { 
    getAnexo,
     getEventoPuestoAnexo, 
     getEventoPuestoAnexoBySearchTerm,
      postEventoPuestoAnexoRegistro, 
      getEventoPuestoAnexoRegistro,
      postEventoPuestoAnexoIdAdjunto,
      postEventoPuestoAnexoRegistroIdAdjunto,
      uploadFiles,
      downloadFile,
      getEventoPuestoAnexoRegistroPlantilla, 
      postEventoPuestoAnexoEstatus,
      postEventoPuestoAnexoEstatusAceptacion,
      deleteEventoPuestoAnexoRegistro,
      getUUIDSAdjuntosRegistroAnexo,
      adjuntoAclaracion,
      handleDownloadPlantilla,
      getAdjuntoIdPlantillaAnexo
    } from 'api'
import {cargarArchivo,descargarArchivo} from 'utils/utility.js';
import 'devextreme-react/autocomplete';
import AlertDialog from 'components/alertDialog/alertDialog'
import lang from 'lang';
import { Button } from 'devextreme-react/button';

import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { 
    DEFAULT_PAGE_SIZE_CMB, 
    GRUPO_SISTEMA_ADMINISTRADOR,
    GRUPO_SISTEMA_REVISOR,
    GRUPO_ENTE_ADMINISTRADOR,
    GRUPO_ENTE_REVISOR_INTERNO,
    GRUPO_ENTE_CAPTURA,
    GRUPO_ENTE_REGISTRO_LIMITADO,
    GRUPO_ENTE_REVISOR_ENTRANTE 
} from 'constants/defaultValues';

import BoxImportar from '../../../components/boxImportar';
import AuthCheck from '../../../components/authCheck/authCheck.js';
import DataGrid, {Column,Editing, Scrolling, Sorting, Button as GridButton} from 'devextreme-react/data-grid';
import ModalArchivosImportar from './modalArchivosImportar'
import ModalError from './modalErrores'
import ModalRegistro from './modalRegistro'
import ModalObservaciones from './modalObservaciones'
import ModalProgressBar from './modalProgresBar'

import { makeAutoObservable ,autorun, intercept, toJS} from "mobx";
import { observer, useLocalObservable } from "mobx-react-lite"
const position = { of: '#captura' }; //TODO: CSS - Se utiliza para posicionar el panel de loading
const grupoUsuario = parseInt(localStorage.getItem('grupo_id'));

class AnexoPlantillaDetailModel {
    id = 0
    id_rubro = null
    clave = ''
    nombre = ''
    descripcion = ''
    soporte_anexo = false
    soporte_registro = false
    orden = null
    columnas =[]
    cmbrubro = {"id":null,"cmbrubro":''}
    data = []
    refresh = null
    popupVisible = false
    filesImportar = []
    totalFiles = 0
    id_adjunto = null
    popupImportarErroresVisible = false
    registroPopupVisible = false
    importarErrores = []
    constructor() { 
        makeAutoObservable(this);
    }
    fromJS(json){
        Object.keys(json).forEach(key => {
            if (this.hasOwnProperty(key)) {
                this[key] = json[key]; 
            }
        });
    }
    fromJson(json){
        let data = json;
        if(typeof json === 'string')
            data = JSON.parse(json);
        this.fromJS(data)
    }
    clear(){
        this.id=0
        this.id_rubro=null
        this.clave=''
        this.nombre=''
        this.descripcion=''
        this.soporte_anexo=false
        this.soporte_registro=false
        this.orden=null
        this.columnas=[]
        this.cmbrubro={"id":null,"cmbrubro":''}
        this.refresh = null
        this.popupVisible = false
        this.filesImportar = []
        this.totalFiles = 0
        this.id_adjunto = null
        this.popupImportarErroresVisible = false
        this.importarErrores = []
        this.registroPopupVisible = false
    }
    forceRefresh(){
        this.refresh = true;
    } 
}

const anexo = new AnexoPlantillaDetailModel()


export default function (props) {
    //#region hook de control
    const history = useHistory();
    //const [isLoading, setIsLoading] = useState(true);
    const gridRef = useRef(null);
    const formRef = useRef(null);
    const permCanLoad = AuthCheck({permission: "CAPTURA_REGISTRO_ADJUNTO", onlyValidator: true});
    const permCanDownLoad = AuthCheck({permission: "CAPTURA_REGISTRO_ADJUNTO_DESCARGA", onlyValidator: true});
    const permCapturaAdjunto= AuthCheck({permission: "CAPTURA_ADJUNTO", onlyValidator: true});

    //#endregion

    //#region hook de estado
    /*const anexoPlantilla = 
    {
        "id":0,
        "id_rubro":null,
        "clave":'',
        "nombre":'',
        "descripcion":'',
        "soporte_anexo":false,
        "soporte_registro":false,
        "orden":null,
        "columnas":[],
        "cmbrubro":{"id":null,"cmbrubro":''},
    };*/
    //const [anexo,setAnexo] = useStateWithCallbackLazy(anexoPlantilla);

    const isLoading = useLocalObservable(()=>{return {value: true}})
    const setIsLoading = (newValue) => { isLoading.value = newValue };
    const progress = useLocalObservable(()=>{
        return{
            progress : 0,
            progressActivo : false,
            fromJS:function(json){
                Object.keys(json).forEach(key => {
                    if (this.hasOwnProperty(key)) {
                        this[key] = json[key];
                    }
                });
            },
    refreshFromModal:function(id){
        recargarCaptura(id);
    }
}});
    const captura = useLocalObservable(()=>{return{
        "evento_id": null,
        "evento": '',
        "ente_id": null,
        "entepublico": '',
        "unidad_id": null,
        "unidadadministrativa": '',
        "puesto_id": null,
        "puesto": '',
        "anexo_id": {id:null, id_puesto_anexo:null},
        "anexo": '',
        "funcionariosaliente": '',
        "funcionarioentrante": '',
        "estatus_anexo": 0,
        "estatus_anexo_descripcion": '',
        "estatusaceptacion":"",
        "estatus_aceptacion": null,
        "tiene_observaciones":false,
        "tiene_observaciones_pendientes":false,
        "tiene_observaciones_pendientes_revision":false,
        "tiene_observaciones_pendientes_revision_usuario_revisor":false,
        "puesto_cerrado":false,
        "tiene_observaciones_no_revisadas":false,
        "cmbanexo":{"id": null,"cmbanexo": ''},
        "btnCaptura":{"activo": false,"texto": ""},
        "btnRevision":{"activo": false,"texto": ""},
        "btnObservaciones":{"activo": false,"texto": ""},
        "btnConcluir":{"activo": false,"texto": ""},
        "btnAceptacion":{"activo": false,"texto": ""},
        "btnBitacora":{"activo": false,"texto": ""},
        "btnRevisionObservaciones":true,
        "comentarioRevisionPopupVisible": false,
        "comentarioAceptacionPopupVisible":false,
        "adjunto":null,
        "id_adjunto":null,
        "nombre_adjunto":"Sin documento adjunto.",
        "id_adjunto_aclaracion":null,
        "nombre_adjunto_aclaracion":null,
        "visible_btn_descarga_soporte":null,
        "flag_disable": false, 
        "estatus_evento":null,
        refresh:null,
        fromJS:function(json){
            Object.keys(json).forEach(key => {
                if (this.hasOwnProperty(key)) {
                    this[key] = json[key];
                }
            });
        },
        refreshFromModal:function(id){
            recargarCaptura(id);
        }
    }});
  
    //#endregion
    
    //#region Cargar datos iniciales
     const recargarCaptura =async (id_anexo) =>{
            let _captura = await fetchCaptura(
                /*48,
                89,
                129,
                129,
                id_anexo*/
                props.location.state.data.id_evento,
                props.location.state.data.id_ente_publico,
                props.location.state.data.id_unidad_administrativa,
                props.location.state.data.id_puesto,
                id_anexo
                ); 
            captura.fromJS(_captura);   
            captura.refresh = true; 
            console.log('refresh captura', captura);
        }

    useEffect(() => {        
        //id_puesto_anexo = props.location.state.id_puesto_anexo
        let mounted = true; 
        const  fetchData = async()=>{
            let result = {...anexo};
            let ent = {...captura};   
            if(props.location.state && props.location.state.data){  
                
                ent = await fetchCaptura(
                    // 48,
                    // 89,
                    // 129,
                    // 129,
                    // 101
                    props.location.state.data.id_evento,
                    props.location.state.data.id_ente_publico,
                    props.location.state.data.id_unidad_administrativa,
                    props.location.state.data.id_puesto,
                    props.location.state.data.id
                ); 
                captura.fromJS(ent);   
                captura.refresh = true;
                setIsLoading(false); 
                return true;              
            }
            return false;
        }
        fetchData()
            .then((seCargo)=>{
                if(!seCargo) history.push({pathname:'/eventos/capturas/'});
            });

        //#region  reactions
        const capturaAr = autorun(()=>{
            if(captura.refresh){
                setTimeout(() => {
                    captura.refresh = false
                }, 25);
            }
        })
        /*const anexoAr = autorun(()=>{
            if(anexo.refresh){
                setTimeout(() => {
                    anexo.refresh = false
                }, 25);
            }
        })*/
       
        const setAnexoId = intercept(captura, "anexo_id", change => {
            if (change.newValue.id !== captura.anexo_id.id) {
                (async function(){
                    setIsLoading(true);
                    
                    await recargarCaptura(change.newValue.id);

                    anexo.refresh = true; 
                    let result = await fetchAnexo(change.newValue.id);
                    let registros = await fetchEventoPuestoAnexoRegistro(change.newValue.id_puesto_anexo)
                    if(captura.estatus_anexo === 6){
                        let columns = registros[0];
                        let newColumns = [];
                        for(var key in columns){
                            if(key !== 'id_adjunto' && key !== 'id' &&  key !== 'renglon_numero')
                                newColumns.push({'nombre':key});
                        }
                        result.columnas = newColumns
                    }
                    
                    anexo.fromJS({...result,data:registros.sort((a,b) => a.renglon_numero-b.renglon_numero), refresh:false });
                    
                    setIsLoading(false);    
                })();
            }
            return change;
        })

        //#endregion
        return () => { 
            mounted = false; 
            anexo.clear();
            capturaAr(); 
            //anexoAr();
            setAnexoId();
        };
      },[]); 
    //#endregion

    //#region fetch
    const fetchCaptura = (async(evento_id, ente_id, unidad_id, puesto_id, anexo_id) =>{        
        let result = {...captura};
           
        const { data: { error, message, data } } = await getEventoPuestoAnexo({
            evento_id, ente_id, unidad_id, puesto_id, anexo_id 
        });
        if(error){
            AlertDialog({message: message});
        }else if(data.length){
            console.log('fetchCaptura', data);
            result.evento_id = data[0].evento_id;
            result.evento = data[0].evento;
            result.ente_id = data[0].ente_id;
            result.entepublico = data[0].entepublico;
            result.unidad_id = data[0].unidad_id;
            result.unidadadministrativa = data[0].unidadadministrativa;
            result.puesto_id = data[0].puesto_id;
            result.puesto = data[0].puesto;
            result.anexo_id = {id:data[0].anexo_id, id_puesto_anexo : data[0].id_puesto_anexo } ;
            result.anexo = data[0].anexo;
            result.funcionariosaliente = data[0].funcionariosaliente;
            result.funcionarioentrante = data[0].funcionarioentrante;
            result.estatus_anexo = data[0].estatus_anexo;
            result.estatus_anexo_descripcion = data[0].estatus_anexo_descripcion;
            result.estatusaceptacion = data[0].estatusaceptacion;
            result.estatus_aceptacion = data[0].estatus_aceptacion;
            result.tiene_observaciones = data[0].tiene_observaciones;
            result.tiene_observaciones_pendientes = data[0].tiene_observaciones_pendientes;
            result.tiene_observaciones_pendientes_revision = data[0].tiene_observaciones_pendientes_revision;
            result.tiene_observaciones_no_revisadas = data[0].tiene_observaciones_no_revisadas;
            result.tiene_observaciones_pendientes_revision_usuario_revisor = data[0].tiene_observaciones_pendientes_revision_usuario_revisor;
            result.cmbanexo = data[0].cmbanexo;
            result.id_adjunto = data[0].id_adjunto;
            result.nombre_adjunto = data[0].nombre_adjunto;
            result.puesto_cerrado = data[0].puesto_cerrado;
            result.id_adjunto_aclaracion = data[0].id_adjunto_aclaracion;
            result.nombre_adjunto_aclaracion = data[0].nombre_adjunto_aclaracion;
            result.visible_btn_descarga_soporte =data[0].visible_btn_descarga_soporte;
            result.flag_disable = data[0].flag_disable;
            result.estatus_evento = data[0].estatus_evento;
            actualizarBotonesCaptura(result);
        }
        return result;
    })
    const fetchAnexo = (async(anexoId) =>{
        let result = {...anexo};
        if(anexoId){           
            const {data:{error,message,data}} = await getAnexo({anexoId});
           console.log('FETCH DEL ANEXO PERRON', data);
            if(error){
                AlertDialog({message: message});
            }else if(data.length){            
                result.id = data[0].id;
                result.id_rubro = data[0].id_rubro;
                result.clave = data[0].clave;
                result.nombre = data[0].nombre;
                result.descripcion = data[0].descripcion;
                result.soporte_anexo = (data[0].soporte_anexo == "1") ;
                result.soporte_registro = (data[0].soporte_registro == "1") ;
                result.orden = data[0].orden;
                result.columnas = data[0].columnas;
                result.cmbrubro = data[0].cmbrubro;
                result.id_adjunto = data[0].id_adjunto;
            }
        }
        
        return result;
    })
    
    const fetchEventoPuestoAnexoRegistro = (async(id_puesto_anexo) =>{
        let result = [];
        if(id_puesto_anexo){           
            const {data:{error,message,data}} = await getEventoPuestoAnexoRegistro(id_puesto_anexo);
           
            if(error){
                AlertDialog({message: message});
            }
            result = data;
        }
        return result;
    })
    //#endregion

    //#region origen de datos 
    //#endregion

    //#region origen de datos combo anexo
    const fetchCmbAnexo = (async ({anexo_id = 0, seachterm = ''}, take = 10, skip = 0) =>{
        let result = [];
        let evento_id = props.location.state.data.id_evento;
        let ente_id = props.location.state.data.id_ente_publico;
        let unidad_id = props.location.state.data.id_unidad_administrativa;
        let puesto_id = props.location.state.data.id_puesto;
        /*let evento_id = 48;
        let ente_id = 89;
        let unidad_id = 129;
        let puesto_id = 129;*/
        const {data:{error,message,data}} =  await getEventoPuestoAnexoBySearchTerm(
            evento_id, 
            ente_id, 
            unidad_id, 
            puesto_id,  
            seachterm, take, skip);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    })
    const dataSourceAnexo  = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["id"],
            load: (options) => {
                if(options.filter)
                    return fetchCmbAnexo({anexo_id: options.filter[2]}, options.take, options.skip);   
                else if(options.searchValue)
                    return fetchCmbAnexo({seachterm: options.searchValue}, options.take, options.skip);     
                else 
                    return fetchCmbAnexo({}, options.take, options.skip);                    
            },
            byKey: (key) => {
                return captura.cmbanexo ? captura.cmbanexo : [];
            }
        })
    });
    
    //#endregion

    //#region eventos interfaz
    const goToList = () =>{
        var auxData = props.location.state.data;
        if(auxData.isEvento)
            history.replace({ pathname: '/catalogos/eventos/detalle', state: { id:captura.evento_id } })
        if(auxData.isMisRevisiones){
            let params = {
                id_evento_puesto: auxData.id_evento_puesto,
                id_evento: auxData.id_evento,
                evento_nombre: auxData.evento_nombre,
                id_ente_publico :auxData.id_ente_publico,
                id_unidad_administrativa:auxData.id_unidad_administrativa,
                unidad_administrativa_nombre:auxData.unidad_administrativa_nombre,
                nombre_puesto:auxData.nombre_puesto,
                ev_nom:auxData.ev_nom
            }
            history.push({pathname:'/eventos/revisiones',state:{data:params}})
        }
        else
            history.goBack();
    }
    function canDelete(e){
        if(!permCanLoad) return false;

        if([GRUPO_ENTE_CAPTURA,GRUPO_ENTE_REGISTRO_LIMITADO].includes(grupoUsuario) && [1,4].includes(captura.estatus_anexo)) return true;
        return false;
    }
    function canLoad(e){
        if(!permCanLoad) return false;
        if(!anexo.soporte_registro) return false;

        if([GRUPO_ENTE_CAPTURA,GRUPO_ENTE_REGISTRO_LIMITADO].includes(grupoUsuario) && [1,4].includes(captura.estatus_anexo)) return true;
        return false;
    }

    function candownLoad(e){
        return e.row.data.id_adjunto && e.row.data.id_adjunto > 0 && permCanDownLoad && anexo.soporte_registro;
    }

    const cargarAclaracion=async ()=>{
        const alCargarArchivo = (async(idAdjunto)=>{   
            let cap = {...captura}

                const {data:{error,message}} = await adjuntoAclaracion(cap.anexo_id.id_puesto_anexo, idAdjunto);
                if(error){
                    AlertDialog({message});
                    return;
                }else{
                   AlertDialog({message:'Aclaracion cargada de manera exitosa.', isNotification:true, type:'success'});
                   await recargarCaptura(cap.anexo_id.id);
                }
            
        });
    
        await cargarArchivo('.pdf', alCargarArchivo, setIsLoading);
       }

       const descargar = (async()=>{
        if (captura.id_adjunto_aclaracion) descargarArchivo(captura.id_adjunto_aclaracion, setIsLoading)
        });
    
    const downloadAnexosRegistros = async ()=>{
        let cap = {...captura}
        const {data:{data, message, error}} = await getUUIDSAdjuntosRegistroAnexo(cap.anexo_id.id_puesto_anexo)
        if(error){
            AlertDialog({message});
            return;
        }else if(data.length){
            let files = [];
            data.forEach((val)=>{
                files.push(val.sysfilename);
            });
            await handleDownloadPlantilla({uid:files},"Registros_anexos.zip");
        }else{
            AlertDialog({message:'No existen documentos adjuntos en los registros del anexo.', type:'warning'});
        }
    }

    //#endregion

    //#region eventos columna
    const quitarColumna = (e) => {
        let columnas = anexo.columnas.filter(c => c.id != e.id);
        anexo.columnas = columnas;  
    }
    //#endregion

    //#region eventos CRUD
    const handleSubmit = (async (e) => {    
        e.preventDefault();
        setIsLoading(true);
        /*
        const {data:{error,message}} = await postEntePublicoPuesto(entePublicoPuesto);
        if(error) AlertDialog({message: message});  
        else      goToList();
        */
        setIsLoading(false);
    });
    //#endregion

    //#region validaciones
    const validationRules ={ 
        nombre:[
            {type:"required",message:"Nombre es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:1000}
        ],
    }
    //#endregion

    //#region Excel importar exportar
    const fileData = async (data) => {
        if(data.length> 1){
            data.map(function(dato,index){
                if(dato.id_adjunto == ""){
                  dato.id_adjunto = "0";
                }else if( (parseFloat(dato.id_adjunto.trim()) % 1) == 0){
                    dato.id_adjunto = dato.id_adjunto.trim();
                }else{
                    dato.id_adjunto = "0";
                }
                return dato;
            });

            let columnas = anexo.columnas.map(({nombre})=> nombre);
            columnas.push('id_adjunto');
            let columnasinexistentes = columnas.filter((item)=> !data[0].hasOwnProperty(item));
            if(columnasinexistentes.length > 0) {
                AlertDialog({title:'Formato incorrecto',message:`Las columnas ${JSON.stringify(columnasinexistentes)} no existen en el documento.`});
                return;
            }
        }
        data = data.filter((item)=> data.indexOf(item) !== 0 );
        data.forEach((elem,index)=> elem.renglon_numero = index+1);
        await saveEventoPuestoAnexoRegistros(data);
    };
    const agragarRegistro = async (registro)=>{
        registro.renglon_numero = (Math.max(0,...(toJS(anexo.data)).map(x=> x.renglon_numero)) + 1);
        const data = [...toJS(anexo.data),registro]
        if(await saveEventoPuestoAnexoRegistros(data)){
            AlertDialog({message:"Registro agregado exitosamente", isNotification: true});
            return true;
        }
        return false;
                  
    }
    const saveEventoPuestoAnexoRegistros = async (data) =>{
        const {data:{error,message,...rest}} = await postEventoPuestoAnexoRegistro({id_puesto_anexo : captura.anexo_id.id_puesto_anexo, data })
        if(error || (rest.data && rest.data.length && rest.data[0].error) ){
            anexo.fromJS({popupImportarErroresVisible:true, importarErrores : rest.data[0].message})
            return false;
        }else if(data.length) {
            anexo.fromJS({data:rest.data[0].data.sort((a,b) => a.renglon_numero-b.renglon_numero)})
            return true;
        }
    }

    const getHistorialPlantillaId = async (evento_id, anexo_id) =>{
        let result = null
        const {data:{data, message, error}} = await getAdjuntoIdPlantillaAnexo(evento_id, anexo_id);
        if(error){
            AlertDialog({message});
        }
        else{
            result = data[0].id_adjunto;
        }
        return result;
    }

    const descargarPlantilla = async () => {     
        let auxAdjunto_id = null;
        let data = toJS(anexo.data);
        let columnas  = anexo.columnas.map(({nombre})=> nombre);
        
        let anx = toJS(anexo);
        columnas.push('id_adjunto');

        if (!anexo.id_adjunto){
            AlertDialog({message:"Este Anexo no tiene una plantilla."});
            return;
        }

        if(captura.estatus_evento === 3){
            auxAdjunto_id = await getHistorialPlantillaId(captura.evento_id, anexo.id);
        }

        const archivo = await downloadFile(captura.estatus_evento === 3 && auxAdjunto_id !== null ? auxAdjunto_id : anexo.id_adjunto,(progress)=>{ console.log('Descargando archivo...'+progress+"%");});
        if (!archivo.data.byteLength>0){ 
            AlertDialog({message:"No es posible descargar este archivo."});
            return;
        }

        data = data.map((item)=>columnas.map((nombre)=>{ return {[nombre]:item[nombre] }}).reduce((a,b)=>{ return{...a,...b}}))
        const parameters = {
            plantilla:{systemFile:true,uid:archivo.sysfilename},
            data,
            columnas
        }
        const plantilla = await getEventoPuestoAnexoRegistroPlantilla(parameters,(pregress)=>{ console.log('Descargando archivo...'+pregress+"%");});
        const downloadUrl = window.URL.createObjectURL(new Blob([plantilla.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download',  `${anx.clave}_plantilla.xlsx`); 
            document.body.appendChild(link);
            link.click();
            link.remove();
    };

    //#endregion //
   
    //#region estatus del anexo 
    const allAnexosCapturados = ()=>{
        return anexo.data.length == (anexo.data.filter(val => val.id_adjunto)).length;
    }

    const clickCaptura = async()=>{
        if (!captura.btnCaptura.activo) return;
        let newState = toJS(captura.estatus_anexo);
        if (newState === 1 && anexo.data.length === 0 ){ AlertDialog({message:'eventos_puestos_anexos.error.notexists.rows'}); return;}
        if ((newState === 1 || newState === 4) && ((anexo.soporte_anexo && !captura.id_adjunto) || (anexo.soporte_registro && !allAnexosCapturados()))) { AlertDialog({message:'No se puede finalizar la captura ya que no se cuenta con la documentación soporte requerida.'}); return;}
        if      (newState == 1) {newState=2; AlertDialog({message:"Captura finalizada correctamente.", isNotification: true})}
        else if (newState == 2) newState=1;
        else if (newState == 4) newState=5;
        await actualizarEstatusAnexo(newState)
    }
    const clickRevision= async()=>{
        if (!captura.btnRevision.activo) return;   
        
        let newState = toJS(captura.estatus_anexo);
        if      (newState == 2) newState=3;
        else if (newState == 3 && captura.btnRevisionObservaciones && captura.tiene_observaciones_pendientes_revision)  newState=4;//con observaciones
        else if (newState == 3 && captura.btnRevisionObservaciones && !captura.tiene_observaciones_pendientes_revision)  newState=6;//con observaciones
        else if (newState == 3 && !captura.btnRevisionObservaciones) newState=6;//sin observaciones
        else if (newState == 5) newState=3;
        else if (newState == 6) newState=3;
        await actualizarEstatusAnexo(newState)
    }
    const clickObservaciones= async()=>{
        
        captura.fromJS({comentarioRevisionPopupVisible:true});
    }

    const clickObservacionesAceptacion = async()=>{
        captura.fromJS({comentarioAceptacionPopupVisible:true});
    }
    const clickConcluir= async()=>{
        if (!captura.btnConcluir.activo) return;

        AlertDialog({
            title: '¿Esta seguro de cambiar el anexo a estado "Revisado"?',
            message: 'Se matendran las observaciones pendientes',
            showCancelButton: true,
            confirmButtonText: `Confirmar`,
            cancelButtonText: `Cancelar`,
            onActionCallback: (result) => {
                console.log("onActionCallback", result);
                if (result.isConfirmed) {
                    let newState = toJS(captura.estatus_anexo);
                    if (newState == 3 || newState == 4) newState=6;
                    actualizarEstatusAnexo(newState);
                }else if (result.isDismissed){
                    return;
                }
            }
        })
    }
    const clickAceptapcion= async()=>{}
    const clickMovimientos= async()=>{}

    const actualizarEstatusAnexo = async(newEstatus)=>{
        if(newEstatus === 6 && anexo.data.length === 0 ){ AlertDialog({message:'eventos_puestos_anexos.error.notexists.rows'}); return false;}
        if (newEstatus === 6  && ((anexo.soporte_anexo && !captura.id_adjunto) || (anexo.soporte_registro && !allAnexosCapturados()))) { AlertDialog({message:'No se puede finalizar la captura ya que no se cuenta con la documentación soporte requerida.'}); return false;}
        let canSave = true;
        if(newEstatus === 5 && captura.tiene_observaciones_pendientes_revision && (grupoUsuario === GRUPO_ENTE_CAPTURA || grupoUsuario === GRUPO_ENTE_REGISTRO_LIMITADO))
            canSave = await confirmChangeWithObservacionesPendientes();

        if(newEstatus === 6 && captura.tiene_observaciones_pendientes_revision_usuario_revisor && [GRUPO_SISTEMA_REVISOR,GRUPO_ENTE_REVISOR_INTERNO].includes(grupoUsuario))
            canSave = await confirmChangeWithObservacionesPendientesUsuarioRevisor();
        
        if(canSave){
            setIsLoading(true);
            const {data:{error,message}} = await postEventoPuestoAnexoEstatus({id:captura.anexo_id.id_puesto_anexo,estatus: newEstatus,grupoUsuario});
            if(error){
                AlertDialog({message});
                setIsLoading(false);
                return false;
            } 
            else{  
                captura.estatus_anexo = newEstatus;
                await recargarCaptura(captura.anexo_id.id);
                gridRef.current.instance.repaint();
            }
            setIsLoading(false);
            return true; 
        }
    }

    const confirmChangeWithObservacionesPendientes = async()=>{
        return new Promise(resolve =>{
            AlertDialog({
                title:'Finalizar captura',
                type:"warning",
                showCancelButton:true,
                message:"Tienes observaciones que no estan marcadas como atendidas, ¿desea proseguir con finalizar su captura?, esto marcara todas las observaciones como atendidas",onActionCallback:async (e) =>{
                    if(e.isConfirmed){
                        resolve(true);
                        
                    } else resolve(false);
                } 
            });
     
    });
    }

    const confirmChangeWithObservacionesPendientesUsuarioRevisor = async()=>{
        return new Promise(resolve =>{
            AlertDialog({
                title:'Finalizar revisión',
                type:"warning",
                showCancelButton:true,
                message:"Tienes observaciones que no estan marcadas como revisadas, ¿desea proseguir con finalizar su revisión?, esto marcara todas las observaciones como revisadas",onActionCallback:async (e) =>{
                    if(e.isConfirmed){
                        resolve(true);
                    } else resolve(false);
                } 
            });
     
    });
    }

    const finishWithOutObservaciones = async ()=>{
        return new Promise(resolve =>{
                AlertDialog({
                    title:'Finalizar Anexo',
                    type:"warning",
                    showCancelButton:true,
                    message:"El anexo no cuenta con ninguna observación. ¿Desea terminar el anexo?",onActionCallback:async (e) =>{
                        if(e.isConfirmed){
                            resolve(true);
                            
                        } else resolve(false);
                    } 
                });
         
        });
        
    }

    const finalizaRevision  = async()=>{
        let estatus = null;
        console.log("finalizaRevision", captura);
        if(grupoUsuario === GRUPO_ENTE_REVISOR_ENTRANTE && captura.estatus_aceptacion === 1){
         //USUARIO ENTRANTE
          estatus = 2;
            if(!captura.tiene_observaciones){
                let response = await finishWithOutObservaciones();
                if(response) estatus = 4;
                else return;
            }
            else if(captura.tiene_observaciones && !captura.tiene_observaciones_no_revisadas) estatus = 4; //todas las observaciones deben estar revisadas
           
        }
        else if((grupoUsuario === GRUPO_ENTE_CAPTURA || grupoUsuario === GRUPO_ENTE_REGISTRO_LIMITADO) && captura.estatus_aceptacion === 2){
            estatus = 3;
            if(captura.tiene_observaciones_pendientes){
                AlertDialog({message:"No se puede finalizar la captura porque hay observaciones sin atender"});
                return;
            }
            
        }
        else if(grupoUsuario === GRUPO_ENTE_REVISOR_ENTRANTE && captura.estatus_aceptacion === 3 && captura.tiene_observaciones_no_revisadas) estatus = 2;
        else if(grupoUsuario === GRUPO_ENTE_REVISOR_ENTRANTE && captura.estatus_aceptacion === 3 && !captura.tiene_observaciones_no_revisadas) estatus = 4;
    
         setIsLoading(true);
            const {data:{error,message}} = await postEventoPuestoAnexoEstatusAceptacion({id:captura.anexo_id.id_puesto_anexo,estatus});
            if(error){
                AlertDialog({message});
                setIsLoading(false);
                return false;
            } 
            else{  
                captura.estatus_aceptacion = estatus;
                await recargarCaptura(captura.anexo_id.id);
            }
            setIsLoading(false);
    
           
        
    }

    const handleAceptacionEstatus  = async(estatus)=>{
        setIsLoading(true);
        const {data:{error,message}} = await postEventoPuestoAnexoEstatusAceptacion({id:captura.anexo_id.id_puesto_anexo,estatus});
        if(error){
            AlertDialog({message});
            setIsLoading(false);
            return false;
        } 
        else{  
            captura.estatus_aceptacion = estatus;
            await recargarCaptura(captura.anexo_id.id);
        }
        setIsLoading(false);
    }

    const canDocumentoAdjunto = ()=>{
        if(!permCapturaAdjunto) return false;
        if(!anexo.soporte_anexo) return false;
        if(captura.puesto_cerrado) return false;

        if([GRUPO_ENTE_CAPTURA, GRUPO_ENTE_REGISTRO_LIMITADO].includes(grupoUsuario) && [1,4].includes(captura.estatus_anexo)) return true; 

        return false;
    }

    const canQuitarDocumentoAdjunto = ()=>{
        return canDocumentoAdjunto();
    }

    const canDescargarDocumentoAdjunto = ()=>{
        if(!permCanDownLoad) return false;
        if(!anexo.soporte_anexo) return false;
        return true;
    }

    const canAddAnexoRecord = ()=>{
        if([GRUPO_ENTE_CAPTURA, GRUPO_ENTE_REGISTRO_LIMITADO].includes(grupoUsuario) && [1,4].includes(captura.estatus_anexo)) return true; 
        return false;
    }

    const showObservacionAceptacion = ()=>{
        if([GRUPO_ENTE_REVISOR_ENTRANTE, GRUPO_ENTE_REVISOR_INTERNO, GRUPO_SISTEMA_REVISOR].includes(grupoUsuario) && captura.estatus_aceptacion) return true;
        if([GRUPO_ENTE_CAPTURA, GRUPO_ENTE_REGISTRO_LIMITADO].includes(grupoUsuario) && captura.estatus_aceptacion) return true;
        return false;
    }
    const showFinalizarRevisionAceptacion = ()=>{
        let showBtn = false;
        if(grupoUsuario === GRUPO_ENTE_REVISOR_ENTRANTE) 
            showBtn = (captura.estatus_aceptacion === 1 || captura.estatus_aceptacion === 3);
        else if(grupoUsuario === GRUPO_ENTE_CAPTURA || grupoUsuario === GRUPO_ENTE_REGISTRO_LIMITADO)
            showBtn = captura.estatus_aceptacion === 2;
        return showBtn;
    }

    const canObservaciones = ()=>{
        if([GRUPO_SISTEMA_ADMINISTRADOR,GRUPO_ENTE_ADMINISTRADOR].includes(grupoUsuario)) return true; 
        if([GRUPO_SISTEMA_REVISOR,GRUPO_ENTE_REVISOR_INTERNO].includes(grupoUsuario) && [3,4,5,6].includes(captura.estatus_anexo)) return true; 
        if([GRUPO_ENTE_CAPTURA, GRUPO_ENTE_REGISTRO_LIMITADO].includes(grupoUsuario) && [4,5,6].includes(captura.estatus_anexo)) return true; 

        return false;
    }

    const canCouncluirRevisado = () => {
        //if([4].includes(captura.estatus_anexo)) return true;
        return false;
    }


    const actualizarBotonesCaptura = async (cap)=>{
        cap.btnCaptura       = {"activo": false,"texto":"FINALIZAR CAPTURA"}
        cap.btnRevision      = {"activo": false,"texto":"INICIAR REVISION"}    
        cap.btnObservaciones = {"activo": false,"texto":"OBSERVACIONES"}
        cap.btnConcluir      = {"activo": true,"texto":"CONCLUIR ANEXO COMO REVISADO"}    
        cap.btnAceptacion    = {"activo": false,"texto":"OBSERVACIONES PARA ACEPTACIÓN"}
        cap.btnBitacora      = {"activo": false,"texto":"BITÁCORA DE MOVIMIENTOS"}

        if (cap.estatus_anexo == 1){
            cap.btnCaptura       = {"activo":  true,"texto":"FINALIZAR CAPTURA"}      
        }
        if (cap.estatus_anexo == 2){
            cap.btnCaptura       = {"activo":  true,"texto":"REANUDAR CAPTURA"}  
            cap.btnRevision      = {"activo":  true,"texto":"INICIAR REVISIÓN"}       
        }
        if (cap.estatus_anexo == 3){
            cap.btnRevision       = {"activo":  true,"texto":"FINALIZAR VALIDACIÓN"} 
            cap.btnConcluir       = {"activo":  true,"texto":"CONCLUIR ANEXO COMO REVISADO"}       
        }
        if (cap.estatus_anexo == 4){
            cap.btnCaptura       = {"activo":  true,"texto":"FINALIZAR CAPTURA"}  
        }
        if (cap.estatus_anexo == 5){
            cap.btnRevision      = {"activo":  true,"texto":"INICIAR REVISIÓN"} 
        }
        if (cap.estatus_anexo == 6){
            cap.btnRevision      = {"activo":  true,"texto":"REANUDAR REVISIÓN"} 
        }
    }
    //#endregion

    const deleteAnexoRecord = async(e)=>{
        setIsLoading(true);
        const {data:{error,message}} = await deleteEventoPuestoAnexoRegistro(e.data.id,e.data.id_adjunto);
        setIsLoading(false);
        if(error){
            e.cancel = true;
            AlertDialog({message});
        } 
    }

    const GridComponent = observer(() => {

        //Observar estas variables para auto actualizar
        anexo.data && anexo.data.forEach(item=> item.uploading)
        anexo.data && anexo.data.forEach(item=> item.downloading)
        anexo.data && anexo.data.forEach(item=> item.id_adjunto)

        //render
        //return <div key='grid_anexos_eventos_registros'>{ anexo.refresh === null? <GridLoader/>:
        //<Loading isLoading ={anexo.refresh}>
        return    <DataGrid key="id" colSpan={12}
            ref={gridRef}
            dataSource={anexo.data}
            columnAutoWidth={true}
            repaintChangesOnly={true}
            showBorders={true} 
            width={"100%"}
            rowAlternationEnabled={true}
            height={500}
            onRowRemoving={(e)=>{deleteAnexoRecord(e)}}
            >
            <Sorting mode="none" />
            <Scrolling mode="virtual"/>
            <Editing
                mode="row"
                useIcons={true}
                allowDeleting={AuthCheck({
                    permission:"CAPTURA_REGISTRO_ADJUNTO",
                    onlyValidator: true
                }) && !captura.puesto_cerrado}
                allowUpdating={AuthCheck({
                    permission:"CAPTURA_REGISTRO_ADJUNTO",
                    onlyValidator: true
                }) && !captura.puesto_cerrado}
            />
            <Column type="buttons" width={110}
                
            >
                <GridButton hint='Eliminar' icon = 'trash' visible={canDelete()} name='delete'></GridButton>
                <GridButton name='01_subir_documento'
                    render={(ev) => {
                        const data = toJS(ev.row.data)
                        return <div key={`dgrid_upload_${data.renglon_numero}`} className={"dx-link dx-link-icon"}  style={{height:18,width:18,cursor:'auto'}} >
                                {
                                    captura.flag_disable ? null :
                                    !canLoad(ev) || captura.puesto_cerrado || toJS(captura.estatus_anexo) === 6 ? null:
                                    !data.uploading?
                                    (<i className="dx-link dx-icon-upload dx-link-icon"  title="Subir documento"  onClick={() => cargarAdjuntoRegistro(ev.row.data)}></i>):
                                    <div className={"dx-link dx-link-icon"}><LoadIndicator height={12} width={12}  />&nbsp;</div>
                                }
                                </div>
                    }}></GridButton>
                <GridButton name='02_bajar_documento'
                    render={(ev) => {
                        const data = toJS(ev.row.data)
                        return <div key={`dgrid_download_${data.renglon_numero}`} className={"dx-link dx-link-icon"}  style={{height:18,width:18,cursor:'auto'}} >
                            {   
                                !candownLoad(ev)?null:
                                !!data.uploading?null:
                                !data.downloading?
                                (<i className="dx-link dx-icon-download dx-link-icon" title="Bajar documento"  onClick={() =>{ 
                                    descargarAdjunto(ev.row.data)}}></i>):
                                <div className={"dx-link dx-link-icon"}><LoadIndicator  height={12} width={12}  />&nbsp;</div>
                            }
                            </div>
                    }}
                ></GridButton>
            </Column>
            <Column dataField={'renglon_numero'} caption={'#'} />
            {anexo.columnas.map((column)=><Column dataField={column.nombre} caption={column.nombre} /> )}
            </DataGrid>
        //</Loading>}
        //</div>
        })


        const GridComponentNoEditable = observer(() => {

            //Observar estas variables para auto actualizar
            anexo.data && anexo.data.forEach(item=> item.uploading)
            anexo.data && anexo.data.forEach(item=> item.downloading)
            anexo.data && anexo.data.forEach(item=> item.id_adjunto)
    
            //render
            //return <div key='grid_anexos_eventos_registros'>{ anexo.refresh === null? <GridLoader/>:
            //<Loading isLoading ={anexo.refresh}>
            return    <DataGrid key="id" colSpan={12}
                ref={gridRef}
                dataSource={anexo.data}
                columnAutoWidth={true}
                repaintChangesOnly={true}
                showBorders={true} 
                width={"100%"}
                rowAlternationEnabled={true}
                height={500}
                onRowRemoving={(e)=>{deleteAnexoRecord(e)}}
                >
                <Sorting mode="none" />
                <Scrolling mode="virtual"/>
                <Editing
                    mode="row"
                    useIcons={true}
                    allowDeleting={AuthCheck({
                        permission:"CAPTURA_REGISTRO_ADJUNTO",
                        onlyValidator: true
                    }) && !captura.puesto_cerrado}
                    allowUpdating={AuthCheck({
                        permission:"CAPTURA_REGISTRO_ADJUNTO",
                        onlyValidator: true
                    }) && !captura.puesto_cerrado}
                />
                <Column type="buttons" width={110}
                    
                >
                    <GridButton name='02_bajar_documento'
                        render={(ev) => {
                            const data = toJS(ev.row.data)
                            return <div key={`dgrid_download_${data.renglon_numero}`} className={"dx-link dx-link-icon"}  style={{height:18,width:18,cursor:'auto'}} >
                                {   
                                    !candownLoad(ev)?null:
                                    !!data.uploading?null:
                                    !data.downloading?
                                    (<i className="dx-link dx-icon-download dx-link-icon" title="Bajar documento"  onClick={() =>{ 
                                        descargarAdjunto(ev.row.data)}}></i>):
                                    <div className={"dx-link dx-link-icon"}><LoadIndicator  height={12} width={12}  />&nbsp;</div>
                                }
                                </div>
                        }}
                    ></GridButton>
                </Column>
                <Column dataField={'renglon_numero'} caption={'#'} />
                {anexo.columnas.map((column)=> <Column dataField={column.nombre} caption={column.nombre} />)}
                </DataGrid>
            //</Loading>}
            //</div>
            })


    const varObserver = (target) => target;
    
    const LoadingCaptura = observer(() => {
        return <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={isLoading.value}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                    
                />
    });

    
    const PrimaryForm = observer(() => {
        //Observar variables
        varObserver([captura.estatus_anexo]);

        //return captura.refresh || captura.refresh === null?<Loader/>:
        return <div>
            <Form
                id="capturaPrimary"
                ref={formRef}
                formData={captura.refresh?{}:captura}
                readOnly={false}
                showColonAfterLabel={true}
                showValidationSummary={true}
                validationGroup="validationRules"
                key='frm_anexo_evento_PrimaryForm'
            >
                <GroupItem colCount={3} key="gi_anexo_evento_g1">  
                    <Item 
                        colSpan={4}
                        dataField="entepublico"
                        stylingMode="filled"
                        editorOptions={{ readOnly:true}}>
                            <Label text={lang.getString("captura.caption.ente_publico")}></Label>
                    </Item>    
                    <Item 
                        colSpan={4}
                        dataField="unidadadministrativa"
                        stylingMode="filled"
                        editorOptions={{ readOnly:true}}>
                            <Label text={lang.getString("captura.caption.unidad_administrativa")}></Label>
                    </Item> 
                    <Item 
                        colSpan={4}
                        dataField="puesto"
                        stylingMode="outlined"
                        editorOptions={{ readOnly:true,}}>
                            <Label text={lang.getString("captura.caption.puesto")}></Label>
                    </Item>          
                </GroupItem>
                <GroupItem colCount={4} key="gi_anexo_evento_g2">  
                    <Item 
                        colSpan={6}
                        dataField="funcionariosaliente"
                        stylingMode="underlined"
                        editorOptions={{ readOnly:true,}}>
                            <Label text={lang.getString("captura.caption.funcionario_saliente")}></Label>
                    </Item>    
                    <Item 
                        colSpan={6}
                        dataField="funcionarioentrante"
                        stylingMode="underlined"
                        editorOptions={{ readOnly:true,}}>
                            <Label text={lang.getString("captura.caption.funcionario_entrante")}></Label>
                    </Item>   
                    <Item 
                        colSpan={6}
                        dataField="estatus_anexo_descripcion"
                        stylingMode="underlined"
                        editorOptions={{ readOnly:true,}}>
                            <Label text={lang.getString("captura.caption.estatus_anexo")}></Label>
                    </Item>    
                    <Item 
                        colSpan={6}
                        dataField="estatusaceptacion"
                        stylingMode="underlined"
                        editorOptions={{ readOnly:true,}}>
                            <Label text={lang.getString("captura.caption.estatus_aceptacion")}></Label>
                    </Item>     
                </GroupItem> 
                <GroupItem colCount={1} key="gi_anexo_evento_g3">
                    <Item
                        colSpan={4}
                        dataField="anexo_id"
                        editorType="dxSelectBox"
                        editorOptions={{
                            searchEnabled:true,
                            searchMode:"contains",
                            disabled: captura.flag_disable,
                            displayExpr: (item) =>  item && item.cmbanexo,                        
                            searchExpr: (item) =>  item && item.cmbanexo,
                            valueExpr: ({id,id_puesto_anexo}) => {return {id,id_puesto_anexo}},//"id",
                            dataSource: dataSourceAnexo,
                            //onValueChange = {columnas_anexo_onvaluechanged}
                        }}
                        validationRules={validationRules.anexo_id}>
                        <Label text={lang.getString("captura.caption.anexo")}></Label>
                    </Item>
                </GroupItem>

                <GroupItem colCount={2} key="gi_anexo_evento_g4">
                    <BoxImportar headerPosition = '0'  flag_disable={captura.flag_disable} estatus_anexo={captura.estatus_anexo} descargarPlantilla={descargarPlantilla} fileData={fileData} />
                        {
                        /*
                        No pueden importar los grupos de revisor interno ni de sistema(4,1)
                        No se puede improtar si eres capturista y el estatus del anexo esta en 2,5,6
                        */
                        }
                        {  
                            anexo.soporte_registro &&  grupoUsuario !== GRUPO_ENTE_REVISOR_INTERNO && 
                            !([2,3,5,6].includes(captura.estatus_anexo) && (grupoUsuario === GRUPO_ENTE_CAPTURA || grupoUsuario === GRUPO_ENTE_REGISTRO_LIMITADO)) &&
                            !captura.puesto_cerrado &&                               
                            AuthCheck({
                                permission:"CAPTURA_REGISTRO_ADJUNTO_IMPORT",
                                component: <Button style={{marginLeft:"5px",display:"inline-block"}} visible={captura.flag_disable ? false : true} horizontalAlignment="left" width="195" text={'IMPORTAR ARCHIVOS'} type={'default'} onClick={(e)=>{openFileDialogImportar()}} />
                            })
                        }  
                        
                        {
                            canDocumentoAdjunto()?
                            <Button style={{marginLeft:"5px", display:"inline-block"}}
                                    horizontalAlignment="left" width="195" text={'DOCUMENTO ADJUNTO'} 
                                    type={'default'} onClick={(e)=>{cargarAdjuntoCaptura()}} />  
                            :
                            null
                        }
                        {
                            canQuitarDocumentoAdjunto()?
                            <Button style={{marginLeft:"5px", display:"inline-block"}} horizontalAlignment="left" icon={"remove"} stylingMode={"text"} onClick={(e)=>{quitarAdjuntoCaptura()}} /> 
                            :
                            null
                        }
                        {
                            /*
                                EL grupo 1: adminsitrador y 3: Ente adminsitrador pueden descargar adjunto siempre
                            */
                            canDescargarDocumentoAdjunto()?
                            <Button style={{marginLeft:"5px",display:"inline-block"}} horizontalAlignment="left"  visible={captura.nombre_adjunto}  text={captura.nombre_adjunto} elementAttr={{class: 'boton'}}  onClick={(e)=>{descargarAdjunto(captura)}} /> 
                            :null
                        }
                        {
                            <Button style={{marginLeft:"5px"}} visible={captura.visible_btn_descarga_soporte ? true : false} key='btn_udownload_all_registros'  horizontalAlignment="left" text="Descargar soporte de registros" elementAttr={{class: 'boton'}} onClick={()=>{downloadAnexosRegistros()}}/>
                        }

                        
                        
                </GroupItem>
                <GroupItem colCount={20} key="gi_anexo_evento_g5">      
                    {
                       !captura.flag_disable && !captura.puesto_cerrado &&
                        AuthCheck({
                            permission:"CAPTURA_REGISTRO_ADD",
                            component: <Button style={{display:"inline-block"}} visible={canAddAnexoRecord()} horizontalAlignment="left" text={"Agregar registro"} type={'default'}  stylingMode={"text"} onClick={(e)=>{
                                anexo.fromJS({registroPopupVisible:true});
                                }} />
                        })
                    }
                </GroupItem>
                <GroupItem colCount={1} key="gi_anexo_evento_g6"> 
                    <GridComponent/>                    
                </GroupItem>
                columnas
                <GroupItem key="gi_anexo_evento_g7" visible={captura.flag_disable ? false : true}>
                    <Item key="item_anexo_evento_g7">
                        <div>
                    {/* BOTONES DE ANEXO */}
                    {  
                        !captura.puesto_cerrado &&                     
                        AuthCheck({
                            permission:"CAPTURA_FINALIZAR_CAP",
                            component:  <Button key='btn_anexo_evento_captura' style={{marginLeft:"7px", marginTop: '7px',display:"inline-block"}} horizontalAlignment="left" width="280" text={captura.btnCaptura.texto} type={captura.btnCaptura.activo ? 'default':'normal'} onClick={(e)=>{clickCaptura()}}/>
                            
                        })
                    }
                    {     
                        !captura.puesto_cerrado &&                                
                        AuthCheck({
                            permission:"CAPTURA_INICIAR_REVISION",
                            component: <Button style={{marginLeft:"7px", marginTop: '7px',display:"inline-block"}} horizontalAlignment="left" width="280" text={captura.btnRevision.texto} type={captura.btnRevision.activo ? 'default':'normal'} onClick={(e)=>{clickRevision()}} />
                        })  
                    }
                    {                                    
                        AuthCheck({
                            permission:"CAPTURA_OBSERVACIONES",
                            component: canObservaciones()? <Button style={{marginLeft:"7px", marginTop: '7px',display:"inline-block"}} horizontalAlignment="left" width="280" text={captura.btnObservaciones.texto} type={'default'} onClick={(e)=>{clickObservaciones()}} />:null
                        })
                    }
                    {     
                        !captura.puesto_cerrado &&                              
                        AuthCheck({
                            permission:"CAPTURA_CONCLUIR_ANEXO",
                            component: canCouncluirRevisado()? <Button style={{marginLeft:"7px", marginTop: '7px', display:"inline-block"}} horizontalAlignment="left" width="280" text={captura.btnConcluir.texto} type={'default'} onClick={(e)=>{clickConcluir()}} />:null
                        })
                    }  
                    {    
                        !captura.puesto_cerrado &&                                  
                        AuthCheck({
                            permission:"CAPTURA_BITACORA_MOV",
                            component: <Button style={{marginLeft:"7px", marginTop: '7px',display:"inline-block"}} horizontalAlignment="left" width="280" text={captura.btnBitacora.texto} type={captura.btnBitacora.activo ? 'default':'normal'} onClick={(e)=>{clickMovimientos()}} />
                        }) 
                    }
                    {/* BOTONES DE ACEPTACION */}
                    <Button key='btn_anexo_finaliza_revision' visible={showFinalizarRevisionAceptacion()} style={{marginLeft:"7px", marginTop: '7px',display:"inline-block"}} horizontalAlignment="left" width="280" text={captura.estatus_aceptacion == 2? "FINALIZAR CAPTURA": "FINALIZAR REVISIÓN"} type="default" onClick={(e)=>{finalizaRevision()}}/>
                    <Button key='btn_anexo_reanuda_revision' visible={grupoUsuario === GRUPO_ENTE_REVISOR_ENTRANTE && ([4,5].includes(captura.estatus_aceptacion)) } style={{marginLeft:"7px", marginTop: '7px',display:"inline-block"}} horizontalAlignment="left" width="280" text="REANUDAR REVISIÓN" type="default" onClick={(e)=>{handleAceptacionEstatus(1)}}/>  
                    {
                    AuthCheck({
                        permission:"CAPTURA_SEGUIMIENTO_ACEP",
                        component: <Button key='btn_anexo_observaciones_aceptacion' visible={showObservacionAceptacion()} style={{marginLeft:"7px", marginTop: '7px',display:"inline-block"}} horizontalAlignment="left" width="280" text="Observaciones de aceptación" type="default" onClick={(e)=>{clickObservacionesAceptacion()}}/>  
                    }) 
                    }
                    <Button key='btn_anexo_concluir_no_favorable' visible={grupoUsuario === GRUPO_ENTE_REVISOR_ENTRANTE && (captura.estatus_aceptacion === 2 || captura.estatus_aceptacion === 3)} style={{marginLeft:"7px", marginTop: '7px',display:"inline-block"}} horizontalAlignment="left" width="280" text="Terminar anexo" type="normal" stylingMode="text" onClick={(e)=>{handleAceptacionEstatus(4)}}/>
                
                        
                        
                    </div>
                    
                    </Item>
                    
                    </GroupItem>
                    {/* <GroupItem colCount={6} visible={captura.estatus_anexo == 3}>  
                        <Item colSpan={4}
                            dataField="btnRevisionObservaciones"
                            stylingMode="underlined"
                            editorType="dxSwitch">
                            <Label text={"Con observaciones"}></Label>
                        </Item>               
                    </GroupItem> */}

                <GroupItem key="gi_anexo_evento_g9">
                    <Item>
                        {
                            AuthCheck({
                                permission:'CAPTURA_ACLARACION_ADJ',
                                component: <Button visible={captura.estatus_aceptacion === 2  && grupoUsuario === GRUPO_ENTE_CAPTURA} key='btn_upload_aclaracion' horizontalAlignment="left" text="cargar aclaraciones" type="default" onClick={()=>{cargarAclaracion()}}/>
                            })
                        }
                        {
                            AuthCheck({
                                permission:'CAPTURA_ACLARACION_DESCARGA',
                                component: <Button visible={([2,3,4].includes(captura.estatus_aceptacion)  && grupoUsuario === GRUPO_ENTE_CAPTURA) || ([3,4].includes(captura.estatus_aceptacion) && grupoUsuario === GRUPO_ENTE_REVISOR_ENTRANTE)  } key='btn_download_aclaracion' horizontalAlignment="left" text= {captura.nombre_adjunto_aclaracion} type='normal' stylingMode="text" onClick={()=>{descargar()}}/> 
                            })
                        }
                            
                            
                    </Item>
                </GroupItem >
                <GroupItem colCount={1} key="gi_anexo_evento_g8"> 
                    {/* <ButtonItem horizontalAlignment="left" colSpan={2}
                        buttonOptions={{text:'Guardar',type:'success',useSubmitBehavior:true}}
                    /> */}
                    <ButtonItem horizontalAlignment="left" colSpan={10}
                        buttonOptions={{text:'Regresar',type:'default', visible: !captura.flag_disable, onClick:()=>{goToList()}}}
                    />
                </GroupItem>

                

            </Form>  
            <Button visible={captura.flag_disable} text={'Regresar'}  type={'default'} onClick={()=>{goToList()}}/> 
        </div>
    })

    const openFileDialog =  function( ) {
        var inputElement = document.createElement("input");
        inputElement.type = "file";
        inputElement.accept = '*.xlsx';
        inputElement.addEventListener("change", handleFileChange)
        inputElement.dispatchEvent(new MouseEvent("click")); 
    }
    const handleFileChange = function()  {
        Array.from(this.files)
        submitFiles(0,Array.from(this.files))
    }

    const openFileDialogImportar =async function( ) {
        var inputElement = document.createElement("input");
        inputElement.type = "file";
        inputElement.accept = '*.*';
        inputElement.multiple = true;
        inputElement.addEventListener("change", handleFileChangeImportar)
        inputElement.dispatchEvent(new MouseEvent("click")); 

        await recargarCaptura();
    }
    const handleFileChangeImportar = function()  {
        let renglones = anexo.data.map(({renglon_numero})=> renglon_numero)
        let archivos = Array.from(this.files).filter(({name})=> {
            try {
                let renglon_numero = parseInt(name.split('_')[0]);
                if(renglones.includes(renglon_numero)) return true
            } catch (error) {
            }
            return false 
        })
        archivos = archivos.map((file)=> {return { numero_renglon:parseInt(file.name.split('_')[0]), archivo:file.name, file }})
        anexo.fromJS({filesImportar:archivos, popupVisible : true, totalFiles : Array.from(this.files).length })
    }
    const submitFiles = (async()=>{
        try{
                let file = anexo.filesImportar.map(({file}) => file);
                anexo.refresh = true;
                progress.fromJS({
                    progress : 1,
                    progressActivo : true
                })
                const {data:{error,message,data:files}} = await uploadFiles(file,
                    (pregress)=>{ 
                        console.log('Subiendo archivo...'+pregress+"%");
                        
                        if(pregress === 100)
                        {
                            setTimeout(() => {
                                progress.fromJS({
                                    progress : 0,
                                    progressActivo : false
                                })
                            }, 250);
                        }
                        else{
                            progress.fromJS({
                                progress : pregress
                            })
                        }     
                });
                
                //Swal.close();
                anexo.refresh = false;
                if(error) AlertDialog({message});
                else {
                   anexo.filesImportar.forEach((element, index)=>{
                       anexo.data.find(({renglon_numero}) => renglon_numero === element.numero_renglon).id_adjunto = files[index].idrow_file
                   })
                   const {data:{error,message}} = await postEventoPuestoAnexoRegistro({id_puesto_anexo : captura.anexo_id.id_puesto_anexo, data:toJS(anexo.data) })
                   if(error){
                       AlertDialog({message});
                   }
                } 
                anexo.fromJS({filesImportar:[], popupVisible : false, totalFiles : 0 })
        }catch(err){
            anexo.refresh = false;
        }
    });

    //#region subir adjunto a anexo y registros del anexo
    const cargarAdjuntoCaptura = function() {
        const alCargarArchivo = (async(idAdjunto)=>{   
            captura.id_adjunto = idAdjunto;

            const {data:{error,message}} = await postEventoPuestoAnexoIdAdjunto({id:captura.anexo_id.id_puesto_anexo,id_adjunto:captura.id_adjunto});
            if(error) AlertDialog({message});
            else recargarCaptura(captura.anexo_id.id);
        });

        cargarArchivo('*.*', alCargarArchivo, setIsLoading);
    }  
    const quitarAdjuntoCaptura = async() => {
        captura.id_adjunto     = 0;
        captura.nombre_adjunto = "";
        
        const {data:{error,message}} = await postEventoPuestoAnexoIdAdjunto({id:captura.anexo_id.id_puesto_anexo,id_adjunto:captura.id_adjunto})
        if(error) AlertDialog({message});

        //setCaptura({...captura});
    } 
    const cargarAdjuntoRegistro = function(fila) {
        const alCargarArchivo = (async(idAdjunto)=>{ 
            const {data:{error,message}} = await postEventoPuestoAnexoRegistroIdAdjunto(fila.id,idAdjunto);
            if(error) AlertDialog({message});
            else fila.id_adjunto = idAdjunto;
            
            setTimeout(() => {recargarCaptura()}, 100);
        });
        const setIsLoading = (isLoading)=>{ fila.uploading = isLoading;  }
        //beginUpload();
        cargarArchivo('*.*', alCargarArchivo, setIsLoading);
    }  
    
    const descargarAdjunto = async (fila) => {
         if (!fila.id_adjunto){
             AlertDialog({message:"No hay un archivo adjunto."});
             return;
         }      
         const setIsLoading = (isLoading)=>{ fila.downloading = isLoading;  }
         descargarArchivo(fila.id_adjunto,setIsLoading)
    }
    
    //#endregion

    //#region render
    return (
        <React.Fragment >
            <h2 className={'content-block'}>{lang.getString("captura.detail.title")}</h2>
            <div id="captura" className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                <form  onSubmit={handleSubmit}>
                    <PrimaryForm>  </PrimaryForm>
                    <ModalArchivosImportar data={anexo} onImportar={submitFiles}></ModalArchivosImportar>
                    <ModalError data={anexo} ></ModalError>
                    <ModalRegistro data={anexo} callback={agragarRegistro}></ModalRegistro>
                    <ModalObservaciones data={captura}></ModalObservaciones>
                    <ModalProgressBar data={progress}></ModalProgressBar>
                    <LoadingCaptura></LoadingCaptura>
                </form>
                </div>
                
                
            </div>
      </React.Fragment>
        );
    //#endregion
}