import {instance} from '../constants/defaultValues';

export function getEventoPuestoAnexoContinuo({id_continuo_puesto=null, puesto_id=null,anexo_id=null}){
    return instance.post(`/api/read`,{
        "VC":"continuo_puesto_anexo",
        "VA":"anexoDetail",
        "continuo_anexo_id":id_continuo_puesto,
        "puesto_id":puesto_id,
        "anexo_id":anexo_id
    })
}

export function postEventoPuestoAnexoIdAdjuntoContinuo(captura){
    return instance.post(`/api/write`,{
        "VC":"continuo_puesto_anexo",
        "VA":"byidadjunto",
        "continuo_anexo_id": captura.id,
        "id_adjunto": captura.id_adjunto,
    })
}

export function postEventoPuestoAnexoRegistroContinuo({continuo_anexo_id, data}){
    return instance.post(`/api/write`,{
        "VC":"continuo_puesto_anexo",
        "VA":"import_data_anexo_registro",
        continuo_anexo_id,
        data
    })
}

export function postEventoPuestoAnexoRegistroIdAdjuntoContinuo(id,id_adjunto){
    return instance.post(`/api/write`,{
        "VC":"continuo_puesto_anexo",
        "VA":"byidadjunto_registro",
        "id":id,
        "id_adjunto":id_adjunto,
    })
}

export function deleteEventoPuestoAnexoRegistroContinuo(id,id_adjunto){
    return instance.post(`/api/delete`,{
        "VC":"continuo_puesto_anexo",
        "VA":"bysystem",
        id,
        id_adjunto
    })
}

export function getEventoPuestoAnexoRegistroContinuo(continuo_anexo_id){
    return instance.post(`/api/read`,{
        "VC":"continuo_puesto_anexo",
        "VA":"anexo_registros",
        continuo_anexo_id
    })
}

export function getUUIDSAdjuntosRegistroAnexoContinuo(continuo_anexo_id){
    return instance.post(`/api/read`,{
        "VC":"continuo_puesto_anexo",
        "VA":"get_uuids_registros",
        "continuo_anexo_id":continuo_anexo_id
    })
}