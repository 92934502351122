import {instance} from '../constants/defaultValues';

export function getBitacora({take = 1, skip = 0, filter = {}, sort = {}, usuario_id, fecha}){
    return instance.post(`/api/read`,{
        "VC":"api_usage",
        "VA":"list",
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc,
        "usuario_id":usuario_id,
        "fecha":fecha
    })
} 