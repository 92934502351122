import {instance} from '../constants/defaultValues';

export function deleteEventoUnidadAdministrativa(parameters){
    return instance.post(`/api/delete`,{
        "VC":"evento_unidad_administrativa",
        "VA":"bysystem",
        ...parameters
    });
}

export function postBlockUnidadAdministrativa(params){
    return instance.post(`/api/write`,{
        "VC":"evento_unidad_administrativa",
        "VA":"bloquear",
        ...params
    })
}

export function postAddUnidadAdministrativaEnte(params){
    return instance.post(`/api/write`,{
        "VC":"evento_unidad_administrativa",
        "VA":"addunidadente",
        ...params
    })
}

export function getEnteUnidadesAdmnistrativas(parameters){
    return instance.post(`/api/read`,{
        "VC":"evento_unidad_administrativa",
        "VA":"byidenteandevento",
        ...parameters
    });
}

