import { useGlobal } from 'contexts/global';

export default (props) =>{
    const { user:{permissionSet} } = useGlobal();
    
    const isAllow = !props.permission || (permissionSet && permissionSet.length && permissionSet.includes(props.permission));
    const Render = props.render;
    if(!!props.value) return isAllow ? props.value : props.falseValue;
    if(props.onlyValidator) return isAllow;
    if(Render){
        return Render({...props, isAllow:isAllow});
    } 
    if(isAllow && props.component){
        return props.component;
    } 
    return (()=>null);
}