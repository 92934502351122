import React,{useState,useRef,} from 'react';
import Form, { Item,ButtonItem,RequiredRule,} from 'devextreme-react/form';
import { GroupItem } from 'devextreme-react/tree-list';
import DatePickerItem from 'components/datePicker/datePickerItem';
import {PATTERN_RFC, PATTERN_CURP} from 'constants/defaultValues';
import {postFuncionarioModal} from 'api';
import AlertDialog from 'components/alertDialog/alertDialog.js';
import notify from 'devextreme/ui/notify';
/*
INSTRUCCIONES SUGERIDAS DE IMPLEMENTACION:
---IMPORTS NECESARIOS
import ModalFuncionarios from 'components/altaFuncionarios';
import CustomPopUp from 'components/custompopup';

---VARIABLES DE ESTADO NECESAROS
const [isOpen, setIsOpen] = useState(false);

---METODOS PARA CAMBIAR LA VARIABLE DE ESTADO(ESTO PARA ABRIR O CERRAR EL MODAL)
const ChageStateModalFuncionarios=()=>{
        setIsOpen(!isOpen)
    }
    
---IMPLEMENTACION DE COMPONENT
---DENTRO DEL onAccept del componentes se implementaria el metodo que se desee. La variable objetosIds retornara el id de funcionario que se inserto 
---y el id de usuario
{isOpen && 
    <CustomPopUp height={420} visible={true} onClose={()=>ChageStateModalFuncionarios && ChageStateModalFuncionarios()} title={"Alta de funcionarios"}>
        <ModalFuncionarios onAccept={(objetoIds)=>{}} />
    </CustomPopUp>
}
*/

export default function(props){
    const formData = useRef({});
    const [isLoading, setIsLoading] = useState(false);
    const [funcionario] = useState({
        "rfc":props.rfc? props.rfc: null,
        "nombre": null,
        "apellido_paterno": null,
        "apellido_paterno": null,
        "correo": null,
        "confirmacionCorreo": null,
        "curp":null,
        "fecha_nacimiento":null
    });

   const handleSubmit  = async(e)=>{
        let result = e.validationGroup.validate();
        if (result.isValid) {
            if (funcionario.correo && funcionario.confirmacionCorreo && funcionario.correo != funcionario.confirmacionCorreo){
                notify({message: "El correo y su confirmación no coinciden."});
                return;
            }
    
            setIsLoading(true);
            const {data:{error,message, data}} = await postFuncionarioModal(funcionario);
            if(error){
                setIsLoading(false);
                AlertDialog({message});
            } else if(data.length){
                setIsLoading(false);
                let objetosIds = data[0];
                props.onAccept && props.onAccept(objetosIds);
            }
        }
   }

   const validationRules ={
    nombre:[
        {type:"required",message:"Nombre obligatorio"},
    ],
    apellido_paterno:[
        {type:"required",message:"Apellido paterno obligatorio"},
    ],
    apellido_materno:[
        {type:"required",message:"Apellido materno obligatorio"},
    ],
    correo:[
        {type:"required",message:"correo obligatorio"},
    ],
    rfc:[
        {type:"required",message:"RFC obligatorio"},
        {type:"stringLength",message:"Máximo de caracteres excedido",max:13},
        {type:"pattern",message:"Formato de RFC incorrecto",pattern:PATTERN_RFC}
    ],
    fecha_nacimiento:[
        {type:"required",message:"Fecha de nacimiento  obligatorio"},
    ],
    curp:[
        {type:"required",message:"CURP obligatorio"},
        {type:"stringLength",message:"Máximo de caracteres excedido",max:18},
        {type:"pattern",message:"Formato de CURP incorrecto",pattern:PATTERN_CURP}
    ],
};


    return(
        <div>
         <form onSubmit={handleSubmit} style={{marginTop:"20px"}}>
                <Form
                    readOnly={false}
                    showColonAfterLabel={false}
                    showValidationSummary={false}
                    formData={funcionario}
                    disabled={isLoading}
                    >
                    <GroupItem colCount={12}>
                        <Item 
                            colSpan={4}
                            editorType="dxTextBox"
                            dataField="nombre"
                            label={"Nombre"}
                            validationRules={validationRules.nombre}
                        />
                        <Item 
                            colSpan={4}
                            editorType="dxTextBox"
                            dataField="apellido_paterno"
                            label={"Apellido paterno"}
                            validationRules={validationRules.apellido_paterno}
                        />
                        <Item 
                            colSpan={4}
                            editorType="dxTextBox"
                            dataField="apellido_materno"
                            label={"Apellido materno"}
                            validationRules={validationRules.apellido_materno}
                        />
                        <Item 
                            colSpan={2}
                            editorType="dxTextBox"
                            dataField="correo"
                            label={"Correo"}
                            validationRules={validationRules.correo}
                        />
                        <Item
                            colSpan={2}
                            editorType="dxTextBox"
                            dataField={'confirmacionCorreo'}
                            label={"Correo confirmacion"}
                        >
                            <RequiredRule message="La confirmacion es requerida" />
                            {/* <CustomRule
                                message={'La confirmación no coincide.'}
                                validationCallback={confirmEmail}
                            /> */}
                        </Item>
                        <Item 
                            colSpan={4}
                            editorType="dxTextBox"
                            dataField="rfc"
                            label={"RFC"}
                            validationRules={validationRules.rfc}
                        />
                        <Item 
                            colSpan={4}
                            editorType="dxTextBox"
                            dataField="curp"
                            label={"Curp"}
                            validationRules={validationRules.curp}
                        />
                        {DatePickerItem({
                                    dataField:"fecha_nacimiento", 
                                    colSpan:4, 
                                    type:"date", 
                                    //inputAttr:{readOnly:false}, 
                                    label:{text:"Fecha de nacimiento"},
                                    validationRules: validationRules.fecha_nacimiento
                                })}
                                <ButtonItem
                        colSpan={2}
                        buttonOptions={{
                            text: "Guardar",
                            type:'default',
                            width:"200",
                            onClick: (e) => handleSubmit(e)
                        }}
                    />
                    </GroupItem>
                    
                    
                </Form>
            </form>
        </div>
    )
}