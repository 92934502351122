import React from 'react';
import { Item }  from 'devextreme-react/box';

import lang from 'lang';


import Form, {GroupItem,Label } from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';

export default function(props) {
    //#region render
    return(
        <Popup
                    visible={props.isPopUpInfoOpen}
                    onHiding={() => props.setIsPopUpInfoOpen(false)}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title={props.titulo}
                    width={800}
                    height={350}
                    >              
                        <Form
                             formData={props.eventoInfo}
                             readOnly={true}
                             showColonAfterLabel={true}
                             showValidationSummary={true}
                        >
                            
                            <GroupItem colCount={2}>   
                                <Item 
                                    colSpan={4}
                                    dataField="registro_creacion_usuario"
                                    stylingMode="underlined">
                                        <Label text={lang.getString("catalogo.caption.registro_creacion_usuario")}></Label>
                                </Item>
                                <Item 
                                    colSpan={2}
                                    dataField="registro_creacion_fecha"
                                    stylingMode="underlined"
                                    editorType="dxDateBox" editorOptions={{type:"datetime"}}>
                                        <Label text={lang.getString("catalogo.caption.registro_creacion_fecha")}></Label>
                                </Item>
                            </GroupItem>  
                            <GroupItem colCount={2}>   
                                <Item 
                                    colSpan={4}
                                    dataField="registro_modificacion_usuario"
                                    stylingMode="underlined">
                                        <Label text={lang.getString("catalogo.caption.registro_modificacion_usuario")}></Label>
                                </Item>
                                <Item 
                                    colSpan={2}
                                    dataField="registro_modificacion_fecha"
                                    stylingMode="underlined"
                                    editorType="dxDateBox" editorOptions={{type:"datetime"}}>
                                        <Label text={lang.getString("catalogo.caption.registro_modificacion_fecha")}></Label>
                                </Item>
                            </GroupItem>  
                            <GroupItem colCount={2}>   
                                <Item 
                                    colSpan={4}
                                    dataField="registro_cancelacion_usuario"
                                    stylingMode="underlined">
                                        <Label text={lang.getString("catalogo.caption.registro_cancelacion_usuario")}></Label>
                                </Item>
                                <Item 
                                    colSpan={2}
                                    dataField="registro_cancelacion_fecha"
                                    stylingMode="underlined"
                                    editorType="dxDateBox" editorOptions={{type:"datetime"}}>
                                        <Label text={lang.getString("catalogo.caption.registro_cancelacion_fecha")}></Label>
                                </Item>
                            </GroupItem>  
                        </Form>
                </Popup> 
    );
    //#endregion
};