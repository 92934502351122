import React from 'react';
import { Popup} from 'devextreme-react/popup';
import { observer } from "mobx-react-lite"
import Observaciones from "./observaciones"

export default observer(({data,...props}) =>{

    return <Popup
          visible={data.comentarioRevisionPopupVisible || data.comentarioAceptacionPopupVisible}
          onHiding={()=> {
            data.comentarioRevisionPopupVisible = false;
            data.comentarioAceptacionPopupVisible = false;
          }}
          dragEnabled={false}
          closeOnOutsideClick={false}
          showCloseButton={true}
          showTitle={true}
          title="Observaciones"
          container=".dx-viewport"
          width={"80%"}
          height={"auto"}
        >
          <Observaciones data={data} {...props}></Observaciones>
        </Popup>
})