import React, { useState,useEffect,useRef } from 'react';
import Form, { 
    Item,
    Label,
    EmptyItem,
    GroupItem
} from 'devextreme-react/form';
import DatePickerItem from 'components/datePicker/datePickerItem';
import { Template } from 'devextreme-react/core/template';
import { Button} from 'devextreme-react';
import {getCreacionActaInfo, postActaPuestoEventoJson, getFile, adjuntoActaFirmada, generacionActaDoc, postReOpenPuesto} from 'api';
import AlertDialog from 'components/alertDialog/alertDialog';
import {cargarArchivo,descargarArchivo} from 'utils/utility.js';
import moment from "moment";
import { LoadPanel } from 'devextreme-react/load-panel';
import CustomPopUp from 'components/custompopup';
import ModalFechaFirma from './modalfechafirma';

const position = { of: '#creacionActa' };

export default function({onAccept=()=>{}, acta_json, id_evento_puesto, file_id ,filename, flag_acta_cargada}){
    //#region hooks de control
    const formRefModal = useRef();
    //#endregion

    //#region hooks de estado
   const [isLoading, setIsLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const [showForm, setShowForm] = useState(false);
   const [acta, setActa] = useState({
        parrafoIntroductorio:null,
        entePublico:null,
        areaEntrega:null,
        adscripcionAreaEntrega:null,
        domicilioAreaEntrega:null,
        nombreCargo: null,
        fechaInicioSaliente:null,
        fechaFinSaliente:null,
        fechaInicioEntrante:null,
        nombreEmitenteNombramiento:null,
        cargoEmitenteNombramiento:null,
        horaInicioLevantamiento:null,
        horaFinLevantamiento:null,
        fechaLevantamiento:null,
        noAnexosActa:null,
        
        nombreServidorSaliente:null,
        estadoOrigenSaliente:null,
        nacionalidadSaliente:null,
        estadoCivilSaliente:null,
        edadSaliente:null,
        ciudadOrigenSaliente:null,
        calleDomicilioSaliente:null,
        numeroLetradomicilioSaliente:null,
        numeroLetradomicilioSaliente_desc:null,
        numeroDomicilioSaliente:null,
        letraDomicilioSaliente:null,
        interiorDomicilioSaliente:null,
        tipoAsentamientoDomicilioSaliente:null,
        nombreAsentamientoDomicilioSaliente:null,
        ciudadDomicilioSaliente:null,
        estadoDomicilioSaliente:null,
        nombreTestigoSaliente:null,
        estadoOrigenTestigoSaliente:null,
        nacionalidadTestigoSaliente:null,
        estadoCivilTestigoSaliente:null,
        edadTestigoSaliente:null,
        ciudadOrigenTestigoSaliente:null,
        calleDomicilioTestigoSaliente:null,
        numeroDomicilioTestigoSaliente:null,
        numeroLetradomicilioTestigoSaliente:null, 
        numeroLetradomicilioTestigoSaliente_desc:null, 
        letraDomicilioTestigoSaliente:null,
        interiorDomicilioTestigoSaliente:null,
        tipoAsentamientoDomicilioTestigoSaliente:null,
        nombreAsentamientoDomicilioTestigoSaliente:null,
        ciudadoDomicilioTestigoSaliente:null,
        estadoDomicilioTestigoSaliente:null,
        nombreServidorEntrante:null, 
        estadoOrigenEntrante:null,
        nacionalidadEntrante:null,
        estadoCivilEntrante:null,
        edadEntrante:null,
        ciudadOrigenEntrante:null,
        calleDomicilioEntrante:null,
        numeroLetradomicilioEntrante:null, 
        numeroLetradomicilioEntrante_desc:null, 
        numeroDomicilioEntrante:null,
        letraDomicilioEntrante:null,
        interiorDomicilioEntrante:null,
        tipoAsentamientoDomicilioEntrante:null,
        nombreAsentamientoDomicilioEntrante:null,
        ciudadDomicilioEntrante:null,
        estadoDomicilioEntrante:null,
        nombreTestigoEntrante:null,
        estadoOrigenTestigoEntrante:null,
        nacionalidadTestigoEntrante:null,
        estadoCivilTestigoEntrante:null,
        edadTestigoEntrante:null,
        ciudadOrigenTestigoEntrante:null,
        calleDomicilioTestigoEntrante:null,
        numeroDomicilioTestigoEntrante:null,
        numeroLetradomicilioTestigoEntrante:null, 
        numeroLetradomicilioTestigoEntrante_desc:null, 
        letraDomicilioTestigoEntrante:null,
        interiorDomicilioTestigoEntrante:null,
        tipoAsentamientoDomicilioTestigoEntrante:null,
        nombreAsentamientoDomicilioTestigoEntrante:null,
        ciudadDomicilioTestigoEntrante:null,
        estadoDomicilioTestigoEntrante:null,
        nombreRepresentanteOrganoEstatal:null,
        filename: null,
        file_id:null,
        fecha_inicio_saliente_desc:null,
        fecha_final_saliente_desc:null,
        fecha_inicio_entrante_desc:null,
        fechaLevantamiento_desc: null,  
        horaFinLevantamiento_desc:null, 
        horaInicioLevantamiento_desc: null,
        /////////
        iii_documento_identificacion:null,
        iii_ente_expide:null,
        iii_folio_identificacion:null,
        iv_documento_identificacion:null,
        iv_ente_expide:null,
        iv_folio_identificacion:null,
        iv_nombre_cargo:null,
        v_documento_identificacion:null,
        v_ente_expide:null,
        v_folio_identificacion:null,
        vi_documento_identificacion:null,
        vi_ente_expide:null,
        vi_folio_identificacion:null,
        vii_adscripcion:null,
        vii_presenta_calidad: null,
        vii_numero_tantos_acta: null,
        vii_documento_identificacion:null,
        vii_ente_expide:null,
        vii_folio_identificacion:null,
        ii_anexo_desc: null,

        iii_edad_saliente_desc : null,
        iv_edad_entrante_desc : null,
        v_testigo_saliente_edad_desc: null,
        vi_testigo_entrante_edad_desc: null,

        fecha_carga:null,
        flag_acta_cargada:null,
        
   });
   //#endregion
   //#region hook de montado de la vista, se utiliza para generar el estado inicial
    useEffect(() => {
        let mounted = true; 
        async function fetchData(){
            if (mounted) { 
                if(acta_json !== null){
                    let ac = {...acta_json};
                    ac.file_id = file_id;
                    ac.filename = filename;
                    ac.flag_acta_cargada = flag_acta_cargada;
                    console.log('flag_acta_cargada', flag_acta_cargada);
                    setActa(ac);
                }else{
                    let info = await fetchInfo();
                    setActa(info);
                }
            }
        }
        fetchData();
        return () => { mounted = false; };
    },[]); 
    //#endregion

    const reOpen = async()=>{
        const {data:{error, message, data}} = await postReOpenPuesto(id_evento_puesto);
        if(error){
            AlertDialog({ message: message });
        }else{
            AlertDialog({ message: "Puesto re-abierto", isNotification: true });
        }
    }


    const fetchInfo=async()=>{
        let result = {...acta};
        const { data: { error, message, data } } = await getCreacionActaInfo(id_evento_puesto);
        if (error) {
          AlertDialog({ message: message });
        } else if (data.length) {
            if(data[0].flag === 0){
                result.entePublico = data[0].entePublico;
                result.nombreCargo = data[0].nombreCargo;
                result.adscripcionAreaEntrega = data[0].adscripcionAreaEntrega;
                result.fechaFinSaliente = data[0].fechaFinSaliente;
                result.fechaInicioEntrante = data[0].fechaInicioEntrante;
                result.nombreServidorSaliente = data[0].nombreServidorSaliente;
                result.edadSaliente = data[0].edadSaliente;
                result.nombreServidorEntrante = data[0].nombreServidorEntrante;
                result.edadEntrante = data[0].edadEntrante;
                result.filename = filename;
                result.file_id = file_id;
            }else{
                result.entePublico = data[0].entePublico;
                result.areaEntrega = data[0].areaEntrega;
                result.adscripcionAreaEntrega = data[0].adscripcionAreaEntrega;
                result.domicilioAreaEntrega = data[0].domicilioAreaEntrega;
                result.nombreCargo = data[0].nombreCargo;
                result.fechaInicioSaliente = data[0].fechaInicioSaliente;
                result.fechaFinSaliente = data[0].fechaFinSaliente;
                result.fechaInicioEntrante = data[0].fechaInicioEntrante;
                result.nombreEmitenteNombramiento = data[0].nombreEmitenteNombramiento;
                result.cargoEmitenteNombramiento = data[0].cargoEmitenteNombramiento;
                result.horaInicioLevantamiento = data[0].horaInicioLevantamiento;
                result.horaFinLevantamiento = data[0].horaFinLevantamiento;
                result.fechaLevantamiento = data[0].fechaLevantamiento;
                result.noAnexosActa = data[0].noAnexosActa;
                result.nombreServidorSaliente = data[0].nombreServidorSaliente;
                result.estadoOrigenSaliente = data[0].estadoOrigenSaliente;
                result.nacionalidadSaliente = data[0].nacionalidadSaliente;
                result.estadoCivilSaliente = data[0].estadoCivilSaliente;
                result.edadSaliente = data[0].edadSaliente;
                result.ciudadOrigenSaliente = data[0].ciudadOrigenSaliente;
                result.calleDomicilioSaliente = data[0].calleDomicilioSaliente;
                result.numeroDomicilioSaliente = data[0].numeroDomicilioSaliente;
                result.letraDomicilioSaliente = data[0].letraDomicilioSaliente;
                result.interiorDomicilioSaliente = data[0].interiorDomicilioSaliente;
                result.tipoAsentamientoDomicilioSaliente = data[0].tipoAsentamientoDomicilioSaliente;
                result.nombreAsentamientoDomicilioSaliente = data[0].nombreAsentamientoDomicilioSaliente;
                result.ciudadDomicilioSaliente = data[0].ciudadDomicilioSaliente;
                result.estadoDomicilioSaliente = data[0].estadoDomicilioSaliente;
                result.nombreTestigoSaliente = data[0].nombreTestigoSaliente;
                result.estadoOrigenTestigoSaliente = data[0].estadoOrigenTestigoSaliente;
                result.nacionalidadTestigoSaliente = data[0].nacionalidadTestigoSaliente;
                result.estadoCivilTestigoSaliente = data[0].estadoCivilTestigoSaliente;
                result.edadTestigoSaliente = data[0].edadTestigoSaliente;
                result.ciudadOrigenTestigoSaliente = data[0].ciudadOrigenTestigoSaliente;
                result.calleDomicilioTestigoSaliente = data[0].calleDomicilioTestigoSaliente;
                result.numeroDomicilioTestigoSaliente = data[0].numeroDomicilioTestigoSaliente;
                result.letraDomicilioTestigoSaliente = data[0].letraDomicilioTestigoSaliente;
                result.interiorDomicilioTestigoSaliente = data[0].interiorDomicilioTestigoSaliente;
                result.tipoAsentamientoDomicilioTestigoSaliente = data[0].tipoAsentamientoDomicilioTestigoSaliente;
                result.nombreAsentamientoDomicilioTestigoSaliente = data[0].nombreAsentamientoDomicilioTestigoSaliente;
                result.ciudadoDomicilioTestigoSaliente = data[0].ciudadoDomicilioTestigoSaliente;
                result.estadoDomicilioTestigoSaliente = data[0].estadoDomicilioTestigoSaliente;
                result.nombreServidorEntrante = data[0].nombreServidorEntrante;
                result.estadoOrigenEntrante = data[0].estadoOrigenEntrante;
                result.nacionalidadEntrante = data[0].nacionalidadEntrante;
                result.estadoCivilEntrante = data[0].estadoCivilEntrante;
                result.edadEntrante = data[0].edadEntrante;
                result.ciudadOrigenEntrante = data[0].ciudadOrigenEntrante;
                result.calleDomicilioEntrante = data[0].calleDomicilioEntrante;
                result.numeroDomicilioEntrante = data[0].numeroDomicilioEntrante;
                result.letraDomicilioEntrante = data[0].letraDomicilioEntrante;
                result.interiorDomicilioEntrante = data[0].interiorDomicilioEntrante;
                result.tipoAsentamientoDomicilioEntrante = data[0].tipoAsentamientoDomicilioEntrante;
                result.nombreAsentamientoDomicilioEntrante = data[0].nombreAsentamientoDomicilioEntrante;
                result.ciudadDomicilioEntrante = data[0].ciudadDomicilioEntrante;
                result.estadoDomicilioEntrante = data[0].estadoDomicilioEntrante;
                result.nombreTestigoEntrante = data[0].nombreTestigoEntrante;
                result.estadoOrigenTestigoEntrante = data[0].estadoOrigenTestigoEntrante;
                result.nacionalidadTestigoEntrante = data[0].nacionalidadTestigoEntrante;
                result.estadoCivilTestigoEntrante = data[0].estadoCivilTestigoEntrante;
                result.edadTestigoEntrante = data[0].edadTestigoEntrante;
                result.ciudadOrigenTestigoEntrante = data[0].ciudadOrigenTestigoEntrante;
                result.calleDomicilioTestigoEntrante = data[0].calleDomicilioTestigoEntrante;
                result.numeroDomicilioTestigoEntrante = data[0].numeroDomicilioTestigoEntrante;
                result.letraDomicilioTestigoEntrante = data[0].letraDomicilioTestigoEntrante;
                result.interiorDomicilioTestigoEntrante = data[0].interiorDomicilioTestigoEntrante;
                result.tipoAsentamientoDomicilioTestigoEntrante = data[0].tipoAsentamientoDomicilioTestigoEntrante;
                result.nombreAsentamientoDomicilioTestigoEntrante = data[0].nombreAsentamientoDomicilioTestigoEntrante;
                result.ciudadDomicilioTestigoEntrante = data[0].ciudadDomicilioTestigoEntrante;
                result.estadoDomicilioTestigoEntrante = data[0].estadoDomicilioTestigoEntrante;
                result.nombreRepresentanteOrganoEstatal = data[0].nombreRepresentanteOrganoEstatal;
                result.parrafoIntroductorio = data[0].parrafoIntroductorio;
                result.filename = data[0].filename;
                result.file_id = data[0].file_id;
                result.fecha_inicio_saliente_desc= data[0].fecha_inicio_saliente_desc;
                result.fecha_final_saliente_desc = data[0].fecha_final_saliente_desc;
                result.fecha_inicio_entrante_desc = data[0].fecha_inicio_entrante_desc;
                result.fechaLevantamiento_desc = data[0].fechaLevantamiento_desc;
                result.horaFinLevantamiento_desc = data[0].horaFinLevantamiento_desc;
                result.horaInicioLevantamiento_desc = data[0].horaInicioLevantamiento_desc;
                // 
                result.iii_documento_identificacion = data[0].iii_documento_identificacion;
                result.iii_ente_expide = data[0].iii_ente_expide;
                result.iii_folio_identificacion = data[0].iii_folio_identificacion;
                result.iv_documento_identificacion = data[0].iv_documento_identificacion;
                result.iv_ente_expide = data[0].iv_ente_expide;
                result.iv_folio_identificacion = data[0].iv_folio_identificacion;
                result.iv_nombre_cargo = data[0].iv_nombre_cargo;
                result.v_documento_identificacion = data[0].v_documento_identificacion;
                result.v_ente_expide = data[0].v_ente_expide;
                result.v_folio_identificacion = data[0].v_folio_identificacion;
                result.vi_documento_identificacion = data[0].vi_documento_identificacion;
                result.vi_ente_expide = data[0].vi_ente_expide;
                result.vi_folio_identificacion = data[0].vi_folio_identificacion;
                result.vii_adscripcion = data[0].vii_adscripcion;
                result.vii_presenta_calidad = data[0].vii_presenta_calidad;
                result.vii_numero_tantos_acta = data[0].vii_numero_tantos_acta;
                result.vii_documento_identificacion = data[0].vii_documento_identificacion;
                result.vii_ente_expide = data[0].vii_ente_expide;
                result.vii_folio_identificacion = data[0].vii_folio_identificacion;
                result.numeroLetradomicilioTestigoEntrante = data[0].numeroLetradomicilioTestigoEntrante;
                result.numeroLetradomicilioTestigoEntrante_desc = data[0].numeroLetradomicilioTestigoEntrante_desc;
                result.numeroLetradomicilioEntrante = data[0].numeroLetradomicilioEntrante;
                result.numeroLetradomicilioEntrante_desc = data[0].numeroLetradomicilioEntrante_desc;
                result.numeroLetradomicilioTestigoSaliente = data[0].numeroLetradomicilioTestigoSaliente;
                result.numeroLetradomicilioTestigoSaliente_desc = data[0].numeroLetradomicilioTestigoSaliente_desc;
                result.numeroLetradomicilioSaliente = data[0].numeroLetradomicilioSaliente;
                result.numeroLetradomicilioSaliente_desc = data[0].numeroLetradomicilioSaliente_desc;

                //
                result.iii_edad_saliente_desc = data[0].iii_edad_saliente_desc
                result.iv_edad_entrante_desc = data[0].iv_edad_entrante_desc
                result.v_testigo_saliente_edad_desc = data[0].v_testigo_saliente_edad_desc
                result.vi_testigo_entrante_edad_desc = data[0].vi_testigo_entrante_edad_desc
            }
        }
        return result;
    }

   const saveActa = async()=>{
        let actaInfo ={...acta};
        const {data:{data, error, message}} = await postActaPuestoEventoJson(actaInfo, id_evento_puesto);
        if(error){
            AlertDialog({ message: message });
        }else{
            AlertDialog({message:"Acta guardada", isNotification:true});
            onAccept && onAccept()
        }
   }
   function appendLeadingZeroes(n){
    if(n <= 9){
      return "0" + n;
    }
    return n
  }
   const generateActa =async()=>{
    let actaInfo ={...acta};
    const {data:{data, error, message}} = await postActaPuestoEventoJson(actaInfo, id_evento_puesto);
    if(error){
        AlertDialog({ message: message });
    }else{
        await AlertDialog({message:"Acta guardada", isNotification:true});

        actaInfo.fechaInicioSaliente = moment(actaInfo.fechaInicioSaliente).format("DD/MM/YYYY");
        actaInfo.fechaFinSaliente = moment(actaInfo.fechaFinSaliente).format("DD/MM/YYYY");
        actaInfo.fechaInicioEntrante = moment(actaInfo.fechaInicioEntrante).format("DD/MM/YYYY");
        actaInfo.fechaLevantamiento = moment(actaInfo.fechaLevantamiento).format("DD/MM/YYYY");
        actaInfo.horaInicioLevantamiento= moment(actaInfo.horaInicioLevantamiento).format("HH:mm");
        actaInfo.horaFinLevantamiento= moment(actaInfo.horaFinLevantamiento).format("HH:mm");

        

        const file = await generacionActaDoc(actaInfo, id_evento_puesto);
        try{
            var reader = new FileReader();
            reader.readAsArrayBuffer(new Blob([file.data]));
            const downloadUrl = window.URL.createObjectURL(new Blob([file.data],{type:"application/vnd.openxmlformats-officedocument.wordprocessingml.document"}));
            const link = document.createElement('a');
            link.href = downloadUrl;
            let current_datetime = new Date()
            link.setAttribute('download', `acta_${current_datetime.getFullYear()}${appendLeadingZeroes(current_datetime.getMonth() + 1)}${appendLeadingZeroes(current_datetime.getDate())}_${appendLeadingZeroes(current_datetime.getHours())}${appendLeadingZeroes(current_datetime.getMinutes())}.docx`);
            document.body.appendChild(link);
            link.click();
            link.remove(); 
        }
        catch(e){
            console.log('error generacion acta', e);
        }
    }
   }

   const cargarActaFirmada=async (fechaFirma)=>{
    const alCargarArchivo = (async(idAdjunto)=>{   
        let ac = {...acta}
        ac.file_id = idAdjunto;

        let file = await getFile({fileId: idAdjunto});
        ac.filename = file.data.data[0].filename;
        ac.fecha_carga = moment(fechaFirma).format("DD/MM/YYYY")
            const {data:{error,message}} = await adjuntoActaFirmada(id_evento_puesto, idAdjunto, fechaFirma);
            if(error){
                AlertDialog({message});
                ac.filename = null;
                ac.file_id = "";    
            } 
        await handleOpenModal();
        setActa(ac);
        AlertDialog({message:'Acta cargada de manera exitosa.', isNotification:true, type:'success'});
    });

    await cargarArchivo('.pdf', alCargarArchivo, setIsLoading);
   }

   const deleteActaFirmada =async()=>{
       setIsLoading(true);
        let ac = {...acta}
        ac.file_id  = null;
        ac.filename = "";
        
        const {data:{error,message}} = await adjuntoActaFirmada(id_evento_puesto, ac.file_id)
        if(error){
            AlertDialog({message});
        } else{
            setActa(ac);
        }
        setIsLoading(true);
   }

   const descargar = (async()=>{
        if (acta.file_id) descargarArchivo(acta.file_id, setIsLoading)
    });

    const handleOpenModal=()=>{
        setOpenModal(!openModal);
    }

    return(
        <div>
            {openModal && 
                    <CustomPopUp height={"35%"} width={"35%"} visible={true} onClose={()=>handleOpenModal && handleOpenModal()} title={"Fecha de firma de acta"}>
                        <ModalFechaFirma onAccept={(fechaFirma)=>{cargarActaFirmada(fechaFirma)}}/>
                    </CustomPopUp>
            }
                <Form
                    readOnly={false}
                    showColonAfterLabel={false}
                    showValidationSummary={false}
                    formData={acta}
                    ref={formRefModal}
                >
                    <Item colSpan={12}>
                        <h6>Fecha de carga de acta: {acta.fecha_carga}</h6> 
                        <div>
                            <Button horizontalAlignment="left" width="200" text='Guardar' type='success' onClick={()=>{saveActa()}}/>
                            <Button style={{marginLeft:"5px"}} horizontalAlignment="left" width="200" text='Generar Acta' type='default' onClick={()=>{generateActa()}}/>
                            <Button style={{marginLeft:"5px"}} horizontalAlignment="left" width="200" text='Re-abrir puesto' visible={acta.flag_acta_cargada ? false : true} type='default' onClick={()=>{reOpen()}}/>
                            <Button style={{marginLeft:"5px"}} horizontalAlignment="left" width="200" text='Cargar acta firmada' type='default' onClick={()=>{handleOpenModal()}} />
                            <Button style={{marginLeft:"5px"}} horizontalAlignment="left" visible={false} icon="remove" type='normal' stylingMode="text" onClick={ ()=> deleteActaFirmada()}/>
                            <Button horizontalAlignment="left" text= {acta.filename} type='normal' stylingMode="text" onClick={()=> descargar()}/> 
                        </div>
                        <div style={{marginTop:"10px"}}>
                        <Button horizontalAlignment="left" width="200" text='Mostrar/ocultar' type='success' onClick={()=>{setShowForm(!showForm)}}/>
                        </div>
                    </Item>
                    <Item colSpan={12}>
                        <h6></h6> 
                    </Item>
                    <GroupItem caption={"0. FUNDAMENTO LEGAL"} colCount={12} visible={showForm}> 
                        <Item 
                            colSpan={12}
                            dataField="parrafoIntroductorio"
                            editorType="dxTextArea"
                            editorOptions={{
                                height:90,
                                stylingMode:"outlined",
                                inputAttr:{style:"overflow:scroll"}
                            }}
                        >
                            <Label text="Párrafo introductorio"/>
                        </Item>
                    </GroupItem>
                    <GroupItem caption={"I. DATOS GENERALES DEL CARGO"} colCount={12} visible={showForm}>
                        <Item 
                            colSpan={12}
                            dataField="entePublico"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Ente Público'}/>
                        </Item>
                        <Item 
                            colSpan={12}
                            dataField="areaEntrega"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Área que se entrega'}/>
                        </Item>
                        <Item 
                            colSpan={12}
                            dataField="adscripcionAreaEntrega"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Adscripción del área que se entrega'}/>
                        </Item>
                        <Item 
                            colSpan={12}
                            dataField="domicilioAreaEntrega"
                            editorType="dxTextArea"
                            editorOptions={{
                                height:90,
                                stylingMode:"outlined",
                                inputAttr:{style:"overflow:scroll"}
                            }}
                        >
                                <Label text="Domicilio del área que se entrega"/>
                        </Item>
                        <Item 
                            colSpan={12}
                            dataField="nombreCargo"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Nombre del cargo'}/>
                        </Item>
                        <Item 
                            colSpan={5}
                            dataField="fechaInicioSaliente"
                            stylingMode="underlined"
                            editorType="dxDateBox" 
                            editorOptions={{
                                type:"date",
                                width: '100%', 
                                inputAttr:{readOnly:false},
                                dropDownButtonTemplate:"buttonRender",
                            }}
                        >
                            <Label text={'Fecha inicio(Saliente)'} location="left" />
                            <Template name="buttonRender" render={()=> <Button style={{backgroundColor:"unset",marginLeft:"-13px"}} icon={"event"} />}/>
                        </Item>
                        <Item 
                            colSpan={7}
                            dataField="fecha_inicio_saliente_desc"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Fecha inicio(Saliente) descripción'} location='left'/>
                        </Item>
                        <Item 
                            colSpan={5}
                            dataField="fechaFinSaliente"
                            stylingMode="underlined"
                            editorType="dxDateBox" 
                            editorOptions={{
                                type:"date",
                                width: '100%', 
                                inputAttr:{readOnly:false},
                                dropDownButtonTemplate:"buttonRender",
                            }}
                        >
                            <Label text={'Fecha fin(Saliente)'} location="left"/>
                            <Template name="buttonRender" render={()=> <Button style={{backgroundColor:"unset",marginLeft:"-13px"}} icon={"event"} />}/>
                        </Item>
                        <Item 
                            colSpan={7}
                            dataField="fecha_final_saliente_desc"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                            /*
                            fecha_inicio_entrante_desc:null*/
                        >
                            <Label text={'Fecha fin(Saliente) descripción'} location="left"/>
                        </Item>
                        <Item 
                            colSpan={5}
                            dataField="fechaInicioEntrante"
                            stylingMode="underlined"
                            editorType="dxDateBox" 
                            editorOptions={{
                                type:"date",
                                width: '100%', 
                                inputAttr:{readOnly:false},
                                dropDownButtonTemplate:"buttonRender",
                            }}
                        >
                            <Label text={'Fecha inicio(Entrante)'} location="left"/>
                            <Template name="buttonRender" render={()=> <Button style={{backgroundColor:"unset",marginLeft:"-13px"}} icon={"event"} />}/>
                        </Item>
                        <Item 
                            colSpan={7}
                            dataField="fecha_inicio_entrante_desc"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Fecha inicio(Entrante) descripción'} location="left"/>
                        </Item>
                        <Item 
                            colSpan={12}
                            dataField="nombreEmitenteNombramiento"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Nombre de quien emite nombramiento'}  location="left"/>
                        </Item>
                        <Item 
                            colSpan={12}
                            dataField="cargoEmitenteNombramiento"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Cargo de quien emite nombramiento'}  location="left"/>
                        </Item>
                    </GroupItem>
                    <GroupItem caption={"II.DATOS DE LEVANTAMIENTO"} colCount={12} visible={showForm}>
                        <Item 
                            colSpan={5}
                            dataField="fechaLevantamiento"
                            stylingMode="underlined"
                            editorType="dxDateBox" 
                            editorOptions={{
                                type:"date",
                                width: '100%', 
                                inputAttr:{readOnly:false},
                                dropDownButtonTemplate:"buttonRender",
                            }}
                        >
                        
                            <Label text={'Fecha'} location="left"/>
                            <Template name="buttonRender" render={()=> <Button style={{backgroundColor:"unset",marginLeft:"-13px"}} icon={"event"} />}/>
                        </Item> 
                        <Item 
                            colSpan={7}
                            dataField="fechaLevantamiento_desc"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Fecha descripción'}  location="left"/>
                        </Item>
                        <Item 
                            colSpan={4}
                            dataField="noAnexosActa"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'No. anexo en acta'} location="left"/>
                        </Item>
                        <Item 
                            colSpan={8}
                            dataField="ii_anexo_desc"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'No. anexo en acta descripción'} location="left"/>
                        </Item>
                        {DatePickerItem({dataField:"horaInicioLevantamiento", colSpan:5, type:"time", inputAttr:{readOnly:false}, label:{text:"Hora de inicio", location:"left"}})}
                        <Item  
                            colSpan={5}
                            dataField="horaInicioLevantamiento_desc"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Hora de inicio descripción'}  location="left"/>
                        </Item>
                        <EmptyItem colSpan={2}/>                
                        {DatePickerItem({dataField:"horaFinLevantamiento", colSpan:5, type:"time", inputAttr:{readOnly:false}, label:{text:"Hora de termino", location:"left"}})}
                        <Item 
                            colSpan={7}
                            dataField="horaFinLevantamiento_desc"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Hora de termino descripción'}  location="left"/>
                        </Item>
                    </GroupItem>
                    <GroupItem caption={"III. DATOS DEL SERVIDOR PÚBLICO SALIENTE"} colCount={12} visible={showForm}>
                        <Item 
                            colSpan={12}
                            dataField="nombreServidorSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Nombre'}/>
                        </Item>
                        <Item 
                            colSpan={5}
                            dataField="nacionalidadSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Nacionalidad'}/>
                        </Item>
                        <Item 
                            colSpan={5}
                            dataField="estadoCivilSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Estado Civil'}/>
                        </Item>
                        <EmptyItem colSpan={2}/>
                        <Item 
                            colSpan={4}
                            dataField="edadSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Edad'}/>
                        </Item>
                        <Item 
                            colSpan={4}
                            dataField="iii_edad_saliente_desc"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Edad descripción'}/>
                        </Item>
                        <EmptyItem colSpan={4}/>
                        <Item 
                            colSpan={6}
                            dataField="ciudadOrigenSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Ciudad de origen'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="estadoOrigenSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Estado de origen'}/>
                        </Item>
                        
                        <Item colSpan={12}>
                            <h6>Domicilio: </h6>
                        </Item>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={6}
                            dataField="calleDomicilioSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Calle(s)'} location={'left'}/>
                        </Item>
                        <EmptyItem colSpan={5}/>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={5}
                            dataField="numeroLetradomicilioSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Número y/o letra'} location={'left'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="numeroLetradomicilioSaliente_desc"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Número y/o letra descripción'} location="left"/>
                        </Item>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={6}
                            dataField="nombreAsentamientoDomicilioSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Nombre de asentamiento'} location={'left'}/>
                        </Item>
                        <EmptyItem colSpan={5}/>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={6}
                            dataField="ciudadDomicilioSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Ciudad'} location={'left'}/>
                        </Item>
                        <EmptyItem colSpan={5}/>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={6}
                            dataField="estadoDomicilioSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Estado'} location={'left'}/>
                        </Item>
                        <EmptyItem colSpan={5}/>
                        <EmptyItem colSpan={12}/>
                        <Item 
                            colSpan={12}
                            dataField="iii_documento_identificacion"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Documento de identificación'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="iii_ente_expide"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Ente que expide'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="iii_folio_identificacion"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Folio número de identificiación'}/>
                        </Item>
                    </GroupItem>
                    <GroupItem caption={"IV. DATOS DEL SERVIDOR PÚBLICO ENTRANTE"} colCount={12} visible={showForm}>
                        <Item 
                            colSpan={12}
                            dataField="nombreServidorEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Nombre'}/>
                        </Item>
                        <Item 
                            colSpan={12}
                            dataField="iv_nombre_cargo"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Nombre cargo'}/>
                        </Item>
                        <Item 
                            colSpan={5}
                            dataField="nacionalidadEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Nacionalidad'}/>
                        </Item>
                        <Item 
                            colSpan={5}
                            dataField="estadoCivilEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Estado Civil'}/>
                        </Item>
                        <EmptyItem colSpan={2}/>
                        <Item 
                            colSpan={4}
                            dataField="edadEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Edad'}/>
                        </Item>
                        <Item 
                            colSpan={4}
                            dataField="iv_edad_entrante_desc"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Edad descripción'}/>
                        </Item>
                        <EmptyItem colSpan={4}/>
                        <Item 
                            colSpan={6}
                            dataField="ciudadOrigenEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Ciudad de origen'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="estadoOrigenEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Estado de origen'}/>
                        </Item>
                        <Item colSpan={12}>
                            <h6>Domicilio: </h6>
                        </Item>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={6}
                            dataField="calleDomicilioEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Calle(s)'} location={'left'}/>
                        </Item>
                        <EmptyItem colSpan={5}/>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={5}
                            dataField="numeroLetradomicilioEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Número y/o letra'} location={'left'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="numeroLetradomicilioEntrante_desc"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Número y/o letra descripción'} location="left"/>
                        </Item>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={6}
                            dataField="nombreAsentamientoDomicilioEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Nombre de asentamiento'} location={'left'}/>
                        </Item>
                        <EmptyItem colSpan={5}/>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={6}
                            dataField="ciudadDomicilioEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Ciudad'} location={'left'}/>
                        </Item>
                        <EmptyItem colSpan={5}/>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={6}
                            dataField="estadoDomicilioEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Estado'} location={'left'}/>
                        </Item>
                        <EmptyItem colSpan={5}/>
                        <EmptyItem colSpan={12}/>
                        <Item 
                            colSpan={12}
                            dataField="iv_documento_identificacion"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Documento de identificación'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="iv_ente_expide"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Ente que expide'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="iv_folio_identificacion"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Folio número de identificiación'}/>
                        </Item>
                    </GroupItem>
                    <GroupItem caption={"V. DATOS DEL TESTIGO (SALIENTE)"} colCount={12} visible={showForm}>
                        <Item 
                            colSpan={12}
                            dataField="nombreTestigoSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Nombre'}/>
                        </Item>
                        <Item 
                            colSpan={5}
                            dataField="nacionalidadTestigoSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Nacionalidad'}/>
                        </Item>
                        <Item 
                            colSpan={5}
                            dataField="estadoCivilTestigoSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Estado Civil'}/>
                        </Item>
                        <EmptyItem colSpan={2}/>
                        <Item 
                            colSpan={4}
                            dataField="edadTestigoSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Edad'}/>
                        </Item>
                        <Item 
                            colSpan={4}
                            dataField="v_testigo_saliente_edad_desc"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Edad descripción'}/>
                        </Item>
                        <EmptyItem colSpan={4}/>
                        <Item 
                            colSpan={6}
                            dataField="ciudadOrigenTestigoSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Ciudad de origen'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="estadoOrigenTestigoSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Estado de origen'}/>
                        </Item>
                        <Item colSpan={12}>
                            <h6>Domicilio: </h6>
                        </Item>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={6}
                            dataField="calleDomicilioTestigoSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Calle(s)'} location={'left'}/>
                        </Item>
                        <EmptyItem colSpan={5}/>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={5}
                            dataField="numeroLetradomicilioTestigoSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Número y/o letra'} location={'left'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="numeroLetradomicilioTestigoSaliente_desc"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Número y/o letra descripción'} location="left"/>
                        </Item>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={6}
                            dataField="nombreAsentamientoDomicilioTestigoSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Nombre de asentamiento'} location={'left'}/>
                        </Item>
                        <EmptyItem colSpan={5}/>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={6}
                            dataField="ciudadoDomicilioTestigoSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Ciudad'} location={'left'}/>
                        </Item>
                        <EmptyItem colSpan={5}/>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={6}
                            dataField="estadoDomicilioTestigoSaliente"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Estado'} location={'left'}/>
                        </Item>
                        <EmptyItem colSpan={5}/>
                        <EmptyItem colSpan={12}/>
                        <Item 
                            colSpan={12}
                            dataField="v_documento_identificacion"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Documento de identificación'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="v_ente_expide"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Ente que expide'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="v_folio_identificacion"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Folio número de identificiación'}/>
                        </Item>
                    </GroupItem>
                   
                    <GroupItem caption={"VI. DATOS DEL TESTIGO (ENTRANTE)"} colCount={12} visible={showForm}>
                        <Item 
                            colSpan={12}
                            dataField="nombreTestigoEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Nombre'}/>
                        </Item>
                        <Item 
                            colSpan={5}
                            dataField="nacionalidadTestigoEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Nacionalidad'}/>
                        </Item>
                        <Item 
                            colSpan={5}
                            dataField="estadoCivilTestigoEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Estado Civil'}/>
                        </Item>
                        <EmptyItem colSpan={2}/>
                        <Item 
                            colSpan={4}
                            dataField="edadTestigoEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Edad'}/>
                        </Item>
                        <Item 
                            colSpan={4}
                            dataField="vi_testigo_entrante_edad_desc"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Edad descripción'}/>
                        </Item>
                        <EmptyItem colSpan={4}/>
                        <Item 
                            colSpan={6}
                            dataField="ciudadOrigenTestigoEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Ciudad de origen'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="estadoOrigenTestigoEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Estado de origen'}/>
                        </Item>
                        <Item colSpan={12}>
                            <h6>Domicilio: </h6>
                        </Item>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={6}
                            dataField="calleDomicilioTestigoEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Calle(s)'} location={'left'}/>
                        </Item>
                        <EmptyItem colSpan={5}/>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={5}
                            dataField="numeroLetradomicilioTestigoEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Número y/o letra'} location={'left'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="numeroLetradomicilioTestigoEntrante_desc"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Número y/o letra descripción'} location="left"/>
                        </Item>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={6}
                            dataField="nombreAsentamientoDomicilioTestigoEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Nombre de asentamiento'} location={'left'}/>
                        </Item>
                        <EmptyItem colSpan={5}/>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={6}
                            dataField="ciudadDomicilioTestigoEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Ciudad'} location={'left'}/>
                        </Item>
                        <EmptyItem colSpan={5}/>
                        <EmptyItem colSpan={1}/>
                        <Item 
                            colSpan={6}
                            dataField="estadoDomicilioTestigoEntrante"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Estado'} location={'left'}/>
                        </Item>
                        <EmptyItem colSpan={5}/>
                        <EmptyItem colSpan={12}/>
                        <Item 
                            colSpan={12}
                            dataField="vi_documento_identificacion"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Documento de identificación'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="vi_ente_expide"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Ente que expide'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="vi_folio_identificacion"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Folio número de identificiación'}/>
                        </Item>
                    </GroupItem>
                    <GroupItem caption={"VII. DATOS DEL REPRESENTANTE DEL ÓRGANO ESTATAL DE CONTROL"} colCount={12} visible={showForm}>
                        <Item 
                            colSpan={12}
                            dataField="nombreRepresentanteOrganoEstatal"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Nombre'}/>
                        </Item>
                        <Item 
                            colSpan={12}
                            dataField="vii_adscripcion"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Adscripcion'}/>
                        </Item>
                        <Item 
                            colSpan={12}
                            dataField="vii_presenta_calidad"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Se presenta en calidad de'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="vii_numero_tantos_acta"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Número de tantos acta'}/>
                        </Item>
                        <EmptyItem colSpan={6}/>
                        <Item 
                            colSpan={12}
                            dataField="vii_documento_identificacion"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Documento de identificación'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="vii_ente_expide"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Ente que expide'}/>
                        </Item>
                        <Item 
                            colSpan={6}
                            dataField="vii_folio_identificacion"
                            stylingMode="underlined"
                            editorType="dxTextBox"
                        >
                            <Label text={'Folio número de identificiación'}/>
                        </Item>
                    </GroupItem>
                </Form>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={isLoading}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
        </div>
    )
}