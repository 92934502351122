import React, { useState } from 'react';
import Form, { Item,ButtonItem,GroupItem, Label} from 'devextreme-react/form';
import { Template } from 'devextreme-react/core/template';
import { Button} from 'devextreme-react';


export default function(props){
    const [fecha] =  useState({"fecha":null});

    const handleSubmit  = async(e)=>{
        let result = e.validationGroup.validate();
        if (result.isValid) {
            props.onAccept && props.onAccept(fecha.fecha);
        }
   }

    const validationRules ={
        fecha:[
            {type:"required",message:"Fecha de firma obligatorio"},
        ],
    };
    return(
        <div>
            <form onSubmit={handleSubmit} style={{marginTop:"20px"}}>
                <Form
                    readOnly={false}
                    showColonAfterLabel={false}
                    showValidationSummary={false}
                    formData={fecha}
                >
                    <GroupItem colCount={12}>
                        <Item 
                            colSpan={12}
                            dataField="fecha"
                            stylingMode="underlined"
                            editorType="dxDateBox" 
                            editorOptions={{
                                type:"date",
                                width: '100%', 
                                inputAttr:{readOnly:false},
                                dropDownButtonTemplate:"buttonRender",
                            }}
                            validationRules={validationRules.fecha}
                        >
                            <Label text={'Fecha de firma'} location="left" />
                            <Template name="buttonRender" render={()=> <Button style={{backgroundColor:"unset",marginLeft:"-13px"}} icon={"event"} />}/>
                        </Item>
                        <ButtonItem
                        colSpan={10}
                        buttonOptions={{
                            text: "Cargar acta firmada",
                            type:'default',
                            width:"300",
                            onClick: (e) => handleSubmit(e)
                        }}
                    />
                    </GroupItem>
                </Form>
            </form>
        </div>
    );
}