import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { signIn as sendSignInRequest,signOut as sendLogoutRequest,getLogedUser,sysInfo, getUsuarioById, getUsuarioLoged } from '../api/index';
import esMessages from "devextreme/localization/messages/es.json";
import { locale, loadMessages } from "devextreme/localization";
import utils from "utils/session";
//:todo:implementar bien desde base de datos
const permissionSet = ["NAV_EXA_000","NAV_EXA_001","NAV_EXA_002","NAV_CAT_000", "NAV_CAT_001", "NAV_CAT_002"];


function GlobalProvider(props) {
  const [user, setUser] = useState();
  //const [organizacion, setOrganizacion] = useState();
  //const [permissionSet, setPermissionSet] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      loadMessages(esMessages);
      locale(navigator.language);
       if(utils.doesHttpOnlyCookieExist()) try { setUser(await fetchUserData()) } catch(error){ setLoading(false);}
       setLoading(false); 
    })();
  }, []);


  //#region fetch
  const fetchUserData = (async() =>{
    const {data:{error,data,accion}} = await getUsuarioLoged();
    //const {data:{error,data,accion}} = await getLogedUser();
    console.log('getLogedUser', data[0]);
    //console.log('getLogedUser', data);
    if(!error && data !== null){
        let session = {...data[0]};
        //let session = {...data};
        const {data:{error:SysError,data:SysData,accion:SysAccion}} = await sysInfo();
        if(!SysError && SysData !== null){   
          session.sysinfo = SysData[0];
        }  
        else if (SysAccion === "signout") {
          window.location.reload();
          return;
        }

        //console.log("getLogedUser_session",session);
        //data.permissionSet = permissionSet;
        //console.log("da",data);
        return session;
    }
    else if (accion === "signout") 
      window.location.reload();

    return null;
  });
  
  //#endregion

  const signIn = useCallback(async (user, password) => {
    const result = await sendSignInRequest(user, password);
    //console.log(result)
    
    return result;
  }, []);

  const signOut = useCallback(async() => {
    await sendLogoutRequest();
    localStorage.removeItem(utils.getSpaceNameProperty("nombre_seer"));
    localStorage.removeItem("SER_CAPTURA_EVTO");
    localStorage.removeItem("SER_CAPTURA_PTO");
    setUser();
    
  }, []);


  return (
    <GlobalContext.Provider value={{ user,loading, signIn, signOut,setUser,permissionSet }} {...props} />
  );
}

const GlobalContext = createContext({});
const useGlobal = () => useContext(GlobalContext);

export { GlobalProvider, useGlobal }