import React, { useState,useRef ,useLayoutEffect } from 'react';
import { Button } from 'devextreme-react/button';
import { useHistory } from 'react-router-dom';
import CustomPopUp from 'components/custompopup';
import SelectBox from 'devextreme-react/select-box';
import Box, { Item }  from 'devextreme-react/box';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import ComponentWrapper from 'components/componentWrapper';
import { 
    DEFAULT_PAGE_SIZE_CMB, 
} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog'
import ComentarioModal from 'components/comentarioModal';
import { 
    getEventoBySearchTerm_captura,
     getEventoBySearchTermPuestoEnte,
     getEventoBySearchTermAnexo,
     postEventoPuestoAnexoAplica,
     getUUIDSAdjuntosRegistroAnexo,
     handleDownloadPlantilla,
     getEventoAnexoArchivo,
    } from 'api';
    import {descargarArchivo} from 'utils/utility.js';
import './index.scss';
import InfoCard from './infoCard.js';
import { observer, useLocalObservable } from "mobx-react-lite"
import {Lines3} from 'components/loader';
import { toJS } from 'mobx';
import lang from 'lang';
import AuthCheck from '../../../components/authCheck/authCheck.js';
import utils from "utils/session";
import moment from "moment";
import swal from 'sweetalert2';
import ModalReplica from './modalReplica.js';

function fromJS(json){
    Object.keys(json).forEach(key => {
        if (this.hasOwnProperty(key)) {
            this[key] = json[key];
            console.log(key, this[key],json[key])
        }
    });
}

export default function() {
    //#region hook de control
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const evento_ref = useRef(null);
    const puesto_ente_ref = useRef(null);
    const viewerRef = useRef(null);
    const cardViewerRef = useRef(null);
    const replicaModalRef = useRef(null);
    

    //#endregion

    //#region hook de estado
    const [evento,setEvento] = useState({id:null});
    const [puestoEnte,setPuestoEnte] = useState({id:null});
    const [popUpComentario, setPopUpComentario] = useState({isOpen: false});
    const [flag, setFlag] = useState(false);
    
    const listKey = useLocalObservable(()=>{return{
        "key": 1,
        data:[],
        fromJS
    }});

    const [isEdit, setIsEdit] = useState(false);
    //#endregion

    //#region Cargar datos iniciales   
    useLayoutEffect ( () => {  
        let mounted = true; 
        async function fetchData(){

            if (mounted) {                
                setIsEdit(true);
                
                let jsonEvento = await utils.Decrypt(localStorage.getItem("SER_CAPTURA_EVTO"));
                let jsonPuesto = await utils.Decrypt(localStorage.getItem("SER_CAPTURA_PTO"));

                let id_evento = jsonEvento !== "undefined"? JSON.parse(jsonEvento) : null;
                let id_puesto = jsonPuesto !== "undefined"? JSON.parse(jsonPuesto) : null;

                let _eventos = await fetchEventos({});
                let evento_id = id_evento ? id_evento.id : _eventos.length? _eventos[0].id:0;
                let _puestosEntes = null;
                if(evento_id===999999999 || _eventos[0].nombre === 'CAPTURA CONTINUA')
                    _puestosEntes = await fetchPuestosEntes({eventoId: evento_id, cargaContinua: true});
                else
                    _puestosEntes = await fetchPuestosEntes({eventoId: evento_id });

                /*SI EL LOCALSTORAGE CUENTA CON PUESTO O EVENTO SE TOMAN ESTOS VALORES PARA CARGAR ESTA INFO Y NO EL PRIMER ELEMENTO EN LA LISTA*/
                if (_eventos.length>0) setEvento({ ... (id_evento ? id_evento : _eventos[0])});
                if (_puestosEntes.length>0) setPuestoEnte({... (id_puesto ? id_puesto : _puestosEntes[0])});
                if (_eventos.length>0 && _puestosEntes.length>0 ) refrescarTarjetas(id_evento ? id_evento.id :_eventos[0].id,id_puesto ? id_puesto.id : _puestosEntes[0].id, evento_id === 999999999 ? false : true);

                /*SI EN EL LOCALSTORAGE NO EXISTEN DATOS DE LOS FILTROS EVENTO Y PUESTO ENTONCES SE AGREGAN PARA LO PROXIMA VEZ TOMARLOS DE AQUI*/
                if(!id_evento) localStorage.setItem("SER_CAPTURA_EVTO",await utils.Encript(JSON.stringify(_eventos[0])));
                if(!id_puesto && _puestosEntes.length > 0 ) localStorage.setItem("SER_CAPTURA_PTO",await utils.Encript(JSON.stringify(_puestosEntes[0])));
                
                setIsLoading(false);
                setPopUpComentario(false);
            }
        }
         fetchData();
        return () => { mounted = false; };
    },[]); 
    //#endregion

    //#region fetch    

    const fetchEventos = (async ({eventoId = 0 ,seachterm = ''}, take = 10, skip = 0) =>{
        let result = [];
        const {data:{error,message,data}} =  await getEventoBySearchTerm_captura(seachterm, take, skip);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    })
    
    const fetchPuestosEntes = (async ({eventoId = 0 ,cargaContinua = false,  puestoEnteId = 0 ,seachterm = ''}, take = 10, skip = 0) =>{
        let result = [];
        const {data:{error,message,data}} =  await getEventoBySearchTermPuestoEnte(eventoId,puestoEnteId, seachterm, take, skip, cargaContinua);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    });

    const fetchAnexos = (async (eventoId, puestoEnteId, seachterm, take, skip) =>{
        let result = [];
        if (eventoId && eventoId > 0 && puestoEnteId && puestoEnteId > 0) {
            const {data:{error,message,data}} =  await getEventoBySearchTermAnexo(eventoId, puestoEnteId, seachterm, take, skip);
            if(error){
                AlertDialog({message});
            }else if(data.length) {
                result = data;
                
                console.log('fetch anexo', data);

                if(!flag && !cardViewerRef){
                    cardViewerRef.current.setProps({visible: true, data: result});
                    setFlag(true);
                }
                
            }
        }        
        return result;
    });

    
    //#endregion

    //#region dataSource
    const dataSourceEventos = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["id"],
            load: (options) => {
                if(options.filter)
                    return fetchEventos({eventoId:options.filter[2]}, options.take, options.skip);   
                else if(options.searchValue)
                    return fetchEventos({seachterm:options.searchValue}, options.take, options.skip);    
                else 
                    return fetchEventos({}, options.take, options.skip);                 
            },
            byKey: async (key) => {
                return {...evento};
            }            
        })
    });
    const dataSourcePuestosEntes = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["id"],
            load: (options) => {
                if(options.filter) {
                    setPuestoEnte(options.filter[2] ? options.filter[2] : 0);
                    return fetchPuestosEntes({eventoId:evento.id,puestoEnteId:puestoEnte.id, cargaContinua: evento.id === 999999999 ? true : false}, options.take, options.skip);
                }
                else if(options.searchValue) 
                    return fetchPuestosEntes({eventoId:evento.id,puestoEnteId:puestoEnte.id, seachterm:options.searchValue, cargaContinua: evento.id === 999999999 ? true : false}, options.take, options.skip);    
                else 
                    return fetchPuestosEntes({eventoId:evento.id,puestoEnteId:puestoEnte.id, cargaContinua: evento.id === 999999999 ? true : false}, options.take, options.skip);                 
            },
           
            byKey: (key) => {
                return {...puestoEnte};
            }
            
        })
    });
    const storeAnexos = new CustomStore({
        key: "id",
        load: async (loadOptions) => {
            const anexos = await fetchAnexos(evento.id, puestoEnte.id,'', loadOptions.take, loadOptions.skip);
            const anexos_activos = anexos.filter(item=> item.aplica === 1).sort((a,b)=> a.anexo_orden - b.anexo_orden);
            const anexos_inactivos = anexos.filter(item=> item.aplica !== 1).sort((a,b)=> a.anexo_orden - b.anexo_orden);
            return anexos_activos.concat(anexos_inactivos);
        },
        update: async (key, data) => {
            const {data:{error,message}} = await postEventoPuestoAnexoAplica(data);
            if(error){
                AlertDialog({message});
                return true;
            } else{
                storeAnexos.load();
                return false;
            }     
        }
    });

    const fetchlista = async ({puestoenteId, eventoId})=>{
        listKey.fromJS({key: 0});
        const anexos = await fetchAnexos(eventoId, puestoenteId,'', 100000,0);
        anexos.forEach(element => {element.fromJS = fromJS; element.refresh=false});
            const anexos_activos = anexos.filter(item=> item.aplica === 1).sort((a,b)=> a.anexo_orden - b.anexo_orden);
            const anexos_inactivos = anexos.filter(item=> item.aplica !== 1).sort((a,b)=> a.anexo_orden - b.anexo_orden);
            listKey.fromJS({data : anexos_activos.concat(anexos_inactivos),key: 1}) ;
    }
    const updateitemlista = async (oldData,newData)=>{
        let index = listKey.data.indexOf(oldData);
        const anexos = toJS(listKey.data);
        anexos[index] = newData;
        /*listKey.fromJS({key: 0});
        const anexos = await fetchAnexos(eventoId, puestoenteId,'', 100000,0);*/
        anexos.forEach(element => {element.fromJS = fromJS; element.refresh=false});
        const anexos_activos = anexos.filter(item=> item.aplica === 1).sort((a,b)=> a.anexo_orden - b.anexo_orden);
        const anexos_inactivos = anexos.filter(item=> item.aplica !== 1).sort((a,b)=> a.anexo_orden - b.anexo_orden);
        listKey.fromJS({data : anexos_activos.concat(anexos_inactivos),key: 1}) ;
    }
    //#endregion
    
    //#region eventos interfaz
    const closeModalComentario = async( flag, data=null)=>{
        viewerRef.current.setProps({visible: false});
        console.log('closeModalComentario', flag, data);
        if(flag && data) await aplica(data);

        
    }
    
    const changeStateModalReplica = (flag,evento_id = null, puesto_id = null) =>{
        replicaModalRef.current.setProps({visible: flag, evento_id:evento_id, puesto_id:puesto_id});
    } 

    const openModalComentario = (data)=>{
        viewerRef.current.setProps({visible: true, data:data});
    }

    const goToDetail = (data = null) =>{  
        console.log("da",data);
        if(data.continuo){
            history.push({pathname:'/eventos/capturas/detalleContinuo',state:{data}})
        }
        else{
            history.push({pathname:'/eventos/capturas/detalle',state:{data}})
        }
    }
    const goToUsuarios = async() =>{ 
        if (!puestoEnte.id) return;

        const {data:{error,message,data}} =  await getEventoBySearchTermAnexo(evento.id, puestoEnte.id, '', 1, 0);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            let id_evento_puesto=data[0].id_evento_puesto; 
            if  (id_evento_puesto && id_evento_puesto>0)
                history.push({pathname:'/eventos/usuarioRegistroLimitado',state:{id_evento_puesto}});      
        }

    }
    
    const editar = (data) =>{
        goToDetail(data)
    }

    const descargarAdjunto = async (data) => {
        if (!data.id_adjunto){
            AlertDialog({message:"Este Anexo no tiene un archivo adjunto."});
            return;
        }
        descargarArchivo(data.id_adjunto,setIsLoading)
    }

    const aplica = async (oldData) =>{        
        //listViewerRef.current.setProps({visible: false});
        console.log('evento.id ', oldData );
            let newdata = {...toJS(oldData), ...{
                flag_continuo: oldData.id_evento === 999999999 ? true : false,
                id: oldData.id_evento === 999999999 ? oldData.id_continuo_puesto : oldData.id_puesto_anexo, 
                aplica: (oldData.aplica == 1 ? 0 : 1) ,
                justificacion:( (oldData.justificacion === null ||  oldData.justificacion === undefined) ? '' : oldData.justificacion)
            }}
            oldData.refresh = true;
            const {data:{error,message}} = await postEventoPuestoAnexoAplica(newdata);
            if(error){
                AlertDialog({message});
                return true;
            } else{
                //TODO: Esto es un parche, lo deberia de regresar el servicio
                newdata.id = oldData.id;
                newdata.justificacion_fecha = moment().format("DD/MM/YYYY HH:mm");
                //----------------------
                updateitemlista(oldData,newdata )
                return false;
            } 

            listKey.fromJS({key: 0});
            setTimeout(() => {
                listKey.fromJS({key: 1});
            }, 100);
    }

    const observaciones = (data) =>{
        AlertDialog({message:"Aqui se mostrará el listado de observaciones pendientes"});
    }
    const eventos_onvaluechanged= async(e) =>{
        //datasour
        let item = dataSourceEventos.items().find((val => val.id == e));
        let evto = await utils.Encript(JSON.stringify(item));
        localStorage.setItem("SER_CAPTURA_EVTO",evto);
        localStorage.removeItem("SER_CAPTURA_PTO"); 
        setEvento({id:e});  
        setPuestoEnte({id:null});
        console.log('eventos_onvaluechanged', e);
        refrescarTarjetas(e,0, e === 999999999 ? false : true);
    }
    const puestos_entes_onvaluechanged=async(e) =>{
        if(e){
            let item = dataSourcePuestosEntes.items().find((val => val.id == e));
            let puesto = await utils.Encript(JSON.stringify(item));
            localStorage.setItem("SER_CAPTURA_PTO",puesto);
            setPuestoEnte({id:e});
            refrescarTarjetas(evento.id,e, evento.id === 999999999 ? false : true);
        }
    }
    const refrescarTarjetas = (eventoId, puestoEnteId, isVisible = true)=>{
        cardViewerRef.current.setProps({visible: isVisible, puestoenteId: puestoEnteId, eventoId: eventoId});
        fetchlista({ puestoenteId: puestoEnteId, eventoId: eventoId});
    }
    const mensaje_observaciones = (n) =>{
        if (n==0) return "Sin observaciones pendientes";
        else if (n==1) return n + " observación pendiente";
        else return n + " observaciones pendientes";
    }


    const canEditAnexo = (data) =>{
        return true;
    }

    const canNoAplicaAnexo = (data) =>{
        if(evento.estatus == 3) return false;
        if(data.flag_estatus_anexo) return !data.bloqueo_puesto && [1,2,4].includes(data.estatus);
        if(evento.id === 999999999) return true;
        return false;
    }

    const canHabilitar = (data) =>{
        if(evento.estatus == 3) return false;
        if(data.bloqueo_puesto) return false;
        return true;
    }

    const downloadAnexosRegistros = async (dato)=>{
        const {data:{data, message, error}} = await getUUIDSAdjuntosRegistroAnexo(dato.id_puesto_anexo)
        if(error){
            AlertDialog({message});
            return;
        }else if(data.length){
            let files = [];
            data.forEach((val)=>{
                files.push(val.sysfilename);
            });
            await handleDownloadPlantilla({uid:files},"Registros_anexos.zip");
        }else{
            AlertDialog({message:'No existe registro de anexos', type:'warning'});
        }
    }

    const handleDownloadAnexos=async()=>{
        swal.fire({
            title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
            text: "Por favor espere.",
            //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
            showConfirmButton: false,
            allowOutsideClick: false
        });
        swal.showLoading();
        let progress = 0;
        setTimeout(() => {
            if(progress===0){
                swal.update({
                    html: "Recopilando información...",
                    title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                    //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                    showConfirmButton: false,
                    allowOutsideClick: false
                });
                setTimeout(() => {
                    if(progress===0){
                        swal.update({
                            html: "Creando archivo comprimido. Esto puede tardar varios minutos...",
                            title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                            //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                            showConfirmButton: false,
                            allowOutsideClick: false
                        });
                    }
                },2000);
            }
        }, 200);

        const {data:{data, message, error}} = await getEventoAnexoArchivo({eventoId:evento.id,puesto_id : puestoEnte.id},
            (pregress)=>{ 
                progress = pregress;
                console.log('Descargando archivo...'+pregress+"%");
            swal.update({
                    html: 'Descargando archivo...'+pregress+"%",
                    title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                    //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                    showConfirmButton: false,
                    allowOutsideClick: false
                });
            });
        if(error){
            AlertDialog({message});
        }else{
            swal.fire({
                title: `<span style='font-size:30px;font-weight:bold'>`+  "Descarga finalizada" +`</span>`,
                showConfirmButton: false,
                icon:'success',
                timer: 1000
            });
        }
        return;
      }
    //#endregion

   //#region render
   const Skelton = () => <div style={{marginTop:'10px'}}><div className={'dx-card'}><div className="contenido" style={{padding:'15px'}}>  <Lines3/> </div></div></div>
    const Lista = observer(()=>{
        return <div>
            {!!!listKey.key && Array(3).fill().map(item=> <Skelton></Skelton>)}
            {listKey.data && listKey.data.map(item => <ListItem data={item} refresh={item.refresh}></ListItem>)}
        </div>;
    })
    const ListItem = ({data}) => {

        console.log('EVENTO', evento);
        console.log('data de tarjetas',{data});
        return (!!data.refresh?<Skelton></Skelton>:
            <div style={{marginTop:'10px'}}>
            <div className={'dx-card'}>  
                <div className={( data.aplica == 1 ? "item-lista" : "item-lista no-aplica")}>          
                    <div className="header_sep">
                        <div className="circle-title">{data.anexo_orden}</div>
                        <div className='titleCard'>
                            <div className="titleCard-element">
                                 <b>{data.anexo}</b>
                            </div>
                        <div className="titleCard-element"> 
                                <h6> {data.rubro_nombre}</h6> 
                            </div>
                        </div>
                        { data.aplica == 1 ? <div className='status-cards'>
                            <div className='one'>
                                <h8 style={{borderRadius: "25px", background: data.estatus_evento.color, padding: "4px 18px 4px 18px", width: "155px", color:"white", fontSize: "15px",}}>
                                    {data.estatus_evento.valor}
                                </h8>
                            </div>
                            <div className='two'>
                                <h8 style={{borderRadius: "25px", background: data.estatus_aceptacion.color, padding: "4px 18px 4px 18px", width: "155px", color:"white", fontSize: "15px",}}>
                                    {data.estatus_aceptacion.valor}
                                </h8>
                            </div>
                        </div> : null}                   
                    </div> 
                                 
                    { data.aplica == 1 
                        ?
                        <React.Fragment>         
                            {/*<div className="contenido">
                                <div className="contenido-izquierda">   
                                    <div className='separacion'>
                                        <div className='label'><b>{"Ente público: "}</b></div>
                                        <div className='separacion-izquierda'>{data.ente_publico}</div>
                                    </div>             
                                    <div className='separacion'>
                                        <div className='label'><b>{"Unidad administrativa: "}</b></div>
                                        <div  className='separacion-izquierda'>{data.unidad_administrativa}</div>
                                    </div>                 
                                    <div className='separacion'>
                                        <div className='label'><b>{"Puesto: "}</b></div>
                                        <div className='separacion-izquierda'>{data.puesto}</div>
                                    </div>         
                                    <div className='separacion'>
                                        <div className='label'><b>{"Funcionario saliente: "}</b></div>
                                        <div className='separacion-izquierda'>{data.funcionario}</div>
                                    </div>              
                                </div>
                                <div className="contenido-derecha">
                                    <div className='label'><b>{"Ultimos movimientos:"}</b></div>
                                    <div className='separacion-izquierda'><b>{data.ultimos_movimientos_fecha}</b></div>
                                    <div className='separacion-izquierda'><p>{data.ultimos_movimientos}</p></div>
                                </div>
                            </div> */} 
                            <div className="pie">
                                <div className='separacion-bottom'>
                                    {                                     
                                        AuthCheck({
                                            permission:"MISCAP_EDIT",
                                            component: canEditAnexo(data)? <Button type="success" text={"Editar anexo"} onClick={(e)=>{editar(data)}}/>: null
                                        })
                                    }
                                </div> 
                                <div className='separacion-bottom'>
                                <Button visible={data.id_adjunto} onClick={(e)=>{descargarAdjunto(data)}} text={"Descargar adjunto"} />
                                </div> 
                                { data.flag_descarga_button ? <div className='separacion-bottom'>
                                    <Button visible={data.flag_descarga_button ? true : false} elementAttr={{class: 'boton'}} onClick={()=>{downloadAnexosRegistros(data)}} text={"DESCARGAR SOPORTE DE REGISTROS"} />
                                </div> : null }
                                <div className='separacion-bottom'>
                                    {                                     
                                        AuthCheck({
                                            permission:"MISCAP_NO_APLICA",
                                            component: canNoAplicaAnexo(data)? <Button type="danger" text={"No aplica"} onClick={(e)=>{ openModalComentario(data)}}/>: null
                                        })
                                    }
                                </div>  
                            </div>          
                        </React.Fragment> 
                        : 
                        <React.Fragment> 
                            <div className="contenido">
                                <div className="contenido-izquierda">
                                    <div className='label'><b>{"Justificación fecha: "}</b></div>
                                    <div className='separacion-izquierda'>{data.justificacion_fecha ? data.justificacion_fecha : ''}</div>  
                                </div> 
                                <div className="contenido-derecha">
                                    <div className='label'><b>{"Justificación: "}</b></div>
                                    <div className='separacion-izquierda'>{data.justificacion}</div>
                                </div>
                            </div>    
                            <div className='pie'>
                                <div className='separacion-bottom'>
                                    {                                     
                                        AuthCheck({
                                            permission:"MISCAP_NO_APLICA",
                                            component: canHabilitar(data)? <Button elementAttr={{class: 'button-style-apply'}}  text={"Habilitar"} onClick={(e)=>{aplica(data)}}/>: null
                                        })
                                    }
                                </div> 
                            </div> 
                        </React.Fragment>    
                        }  
                </div>                   
            </div>
            </div>
        );
    };

    return(
        <React.Fragment>
            <h2 className={'content-block'}>{lang.getString("captura.index.title")}</h2>
            <div className={'content-block'}>
                <ComponentWrapper ref={replicaModalRef} component={(props)=> props.visible ? 
                    <CustomPopUp  width={"65%"}  height="60%" visible={true} onClose={()=>changeStateModalReplica(false, null, null)} title={"Replica de anexos"}>
                        <ModalReplica evento_id={props.evento_id} puesto_id={props.puesto_id} onAccept={(data)=>{changeStateModalReplica(false, null, null)}}/>
                    </CustomPopUp> : null}
                />
                <ComponentWrapper ref={viewerRef} component={(props)=> props.visible ? 
                    <CustomPopUp  width={"65%"}  height="680" visible={true} onClose={()=>closeModalComentario(false)} title={"Justificación"}>
                        <ComentarioModal data={props.data} onAccept={(data)=>{closeModalComentario(true, data);}}/>
                    </CustomPopUp> : null}
                />
                <div className={'dx-card responsive-paddings'}>
                    <div className={'area-combos'}>  
                            <div>
                                <div>Evento</div>
                                <div >
                                    <SelectBox
                                        ref={evento_ref}
                                        searchEnabled = {true} 
                                        searchMode    = "contains"
                                        displayExpr   = {(item) => item && item.nombre}                        
                                        searchExpr    = {(item) => item && item.nombre}  
                                        value         = {evento.id}                
                                        valueExpr     = "id"
                                        dataSource    = {dataSourceEventos}  
                                        onValueChange = {eventos_onvaluechanged}
                                    >
                                    </SelectBox> 
                                </div>
                            </div> 
                            <div>
                                <div>Puesto / Ente público</div>
                                <div>
                                <SelectBox
                                        ref={puesto_ente_ref}
                                        searchEnabled = {true} 
                                        searchMode    = "contains"
                                        displayExpr   = {(item) => item && item.puesto}                        
                                        searchExpr    = {(item) => item && item.puesto}  
                                        value         = {puestoEnte.id}                     
                                        valueExpr     = "id"
                                        dataSource    = {dataSourcePuestosEntes}   
                                        onValueChange = {puestos_entes_onvaluechanged}
                                    >
                                    </SelectBox> 
                                </div>
                            </div> 
                            <div>
                                <div>.</div>
                                <div>
                                    {                                     
                                        AuthCheck({
                                            permission:"MISCAP_REGIS_LIMIT",
                                            component: <Button style={{display:"inline-block" , float: "left", marginTop:"2px"}} text="Usuarios de registro limitado" visible = {evento.id === 999999999 ? false : puestoEnte.id} type={'default'} onClick={()=> goToUsuarios()}/>
                                        })
                                    }        
                                    <Button style={{marginLeft: '3px', display:"inline-block", float: "left", marginTop:"2px"}} visible = { evento.id === 999999999 ? false : puestoEnte.id} icon={'inactivefolder'} type={'default'} onClick={()=> handleDownloadAnexos()}/>
                                <Button style={{marginLeft: '3px', display:"inline-block", float: "left", marginTop:"2px"}} visible = { evento.id === 999999999 ? false : puestoEnte.id} type={'danger'} text={"Subordinados"} onClick={()=>changeStateModalReplica(true, evento.id, puestoEnte.id)}/>                            
                                </div>
                                
                            </div> 
                    </div>
                    <Box width="100%" direction="row">
                        
                        
                        <Item ratio={12}>
                            <ComponentWrapper ref={cardViewerRef} component={(props)=> props.visible ? 
                                <InfoCard puestoenteId={props.puestoenteId} eventoId={props.eventoId} /> : null
                            }/>   
                        </Item>
                    </Box>
                </div>
                <Lista></Lista>
            </div>
        </React.Fragment>
    );
    //#endregion
};
