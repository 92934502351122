import React,{ useRef, useEffect} from 'react';
import { Button } from 'devextreme-react/button';
import DataGrid, { Column, Editing, Pager, Paging, FilterRow,Export} from 'devextreme-react/data-grid';
import Box, {
  Item
} from 'devextreme-react/box';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import AlertDialog from 'components/alertDialog/alertDialog'
import {
    getEntregaRealizadaEnte,
  getEventoBySearchTerm,
} from 'api';
import { DEFAULT_PAGE_SIZE_ONE_GRID, DEFAULT_PAGE_SIZES, DEFAULT_PAGE_SIZE_CMB } from 'constants/defaultValues';
import { getDataSourceFilter, getDataSourceSort, descargarArchivo} from 'utils/utility';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Form, {
    GroupItem,
    Label
  } from 'devextreme-react/form';

  export default function () {
    //#region hook de control
        const formRef = useRef();
    //#endregion
    //#region hook de estado
    const [evento,setEvento] = useStateWithCallbackLazy({
        "evento_id":null,
  })
  //#endregion
   //#region carga de datos iniciales 
   useEffect(() => {
    let mounted = true; 
    async function fetchData(){
        if (mounted) { 
        }
    }
    fetchData();
    return () => { mounted = false; };
  },[]); 
  //#endregion

  //#region fetch
  const fetchData = (async (take, skip, filter, sort) => {
    let result =[];
    let eventos = {...evento};
    let evento_id = eventos.evento_id;

    const { data: { error, message, data } } = await getEntregaRealizadaEnte({take, skip, filter, sort, evento_id,});
    if (error) {
      AlertDialog({ message: message });
    } else if (data.lenght) {
      result = data;
    }
    return result;
  });
  //#endregion

  //#region dataSource
  const dataSource = new DataSource({
    pageSize: 10,
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return fetchData(loadOptions.take, loadOptions.skip, getDataSourceFilter(loadOptions), getDataSourceSort(loadOptions));
      }
    })
  });

  const generarDataSource = (getFunc)=>{
    const fetchFunc = async ({seachterm = ''}, take = 1, skip = 0, ) =>{
      let result = []
      const grupoUsuario = parseInt(localStorage.getItem('grupo_id'));
      const {data:{error,message,data}} = await getFunc(seachterm, take, skip, grupoUsuario);
      if(error){
          AlertDialog({message});
      }else if(data.length) {
          result = data;
      }
      return result;
    }

    const ds = new DataSource({
      pageSize: DEFAULT_PAGE_SIZE_CMB,
      store: new CustomStore({
          key:"id",
          load: (loadOptions) => {
              if(loadOptions.searchValue)
                  return fetchFunc({seachterm:loadOptions.searchValue}, loadOptions.take, loadOptions.skip);     
              else 
                  return fetchFunc({}, loadOptions.take, loadOptions.skip);             
          },
          byKey: (key) => {
              return key;
          }
        })
    });
    return ds;
  }
  
  const eventoDatasource = generarDataSource(getEventoBySearchTerm);
  //#endregion

  //#region eventos interfaz
  const searchFilter = () =>{
    dataSource.reload();
  }

  const cleanFilter = async() =>{
      formRef.current.instance.updateData('evento_id',null);
      dataSource.reload();
    
  }
  //#endregion

  //#region render
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Entrega realizada - Nivel Ente</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
            <form>
                <Form
                    ref={formRef}
                    formData={evento}
                    readOnly={false}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                    validationGroup="eventoData"
                >
                    <GroupItem colCount={8}>
                      <Item
                        colSpan={3}
                        dataField="evento_id"
                        editorType="dxSelectBox"
                        editorOptions={{
                            valueExpr:"id",
                            searchEnabled:true,
                            searchMode:"contains",
                            displayExpr: (item) => item && item.nombre,                     
                            searchExpr:(item) => item && item.nombre,
                            dataSource: eventoDatasource,
                        }}
                      >
                        <Label text={"Evento"}/>
                      </Item>
                        <Item colSpan={4}>
                        <Button text={"Buscar"} type="success" onClick={()=>{searchFilter()}}/>
                        <Button style={{marginLeft:"5px"}}  text={"Limpiar"} type="default" onClick={()=>{cleanFilter()}}/>
                        </Item>
                    </GroupItem>
                </Form>
            </form>
        
          <Box width="100%" direction="row">
            <Item ratio={12}>
              <DataGrid 
                key="id"
                onRowPrepared={(e)=>{if('data' in e) e.rowElement.title = e.data.nombre_puesto}}
                rowAlternationEnabled={true}
                dataSource={dataSource}
                rowAlternationEnabled={true}                
                showBorders={true}
                columnAutoWidth={true}
                width={"100%"}>
                <FilterRow visible={true} showOperationChooser={false}/>
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                        <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                        showInfo={true} />
                <Editing
                  mode="row"
                  useIcons={false}
                  allowDeleting={false}
                  allowUpdating={false}
                >
                  </Editing>
                  <Export enabled={true} allowExportSelectedData={false} fileName={'Entrega realizada - Nivel Ente'}/>
                <Column dataField = "ente" caption="Ente"/>
                <Column dataField = "unidades_administrativas" caption="Unidad administrativa" alignment={'center'}/>
                <Column dataField = "puestos" caption="Total puestos" alignment={'center'}/>
                 <Column dataField = "puestos_proceso_aceptacion" caption="Puestos en proceso de aceptacion" alignment={'center'}/>
                <Column dataField = "puestos_observaciones_pendientes" caption="Puestos con observaciones pendientes" alignment={'center'}/>

                <Column dataField = "puestos_aceptados_favorables" caption="Puestos aceptados favorables" alignment={'center'}/>
                <Column dataField = "puestos_aceptados_no_favorables" caption="Puestos aceptados no favorables" alignment={'center'}/>
                <Column dataField = "avance_captura" caption="% Avance captura" alignment={'center'}/>
                <Column dataField = "avance_captura_porc" caption="Rango avance de captura" alignment={'center'}/>
                <Column dataField = "total_anexo_revisado" caption="Total anexos revisados" alignment={'center'}/>
              </DataGrid>
            </Item>
          </Box>
        </div>
      </div>
    </React.Fragment>
  );
  }