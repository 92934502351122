import React,{useRef} from 'react';
import Form, {
    Item,
    ButtonItem
  } from 'devextreme-react/form';
  import {useLocalObservable,observer } from "mobx-react-lite"
import { toJS} from "mobx";

const CommentSeccion = (props)=>{

    const data = useLocalObservable(()=>
    {return{
         comentario:null
     }});
    const formRef = useRef();
    const handleSubmit = ()=>{
        if(formRef.current.instance.validate().isValid){
            props.callback && props.callback(toJS(data.comentario));
            data.comentario  = null;
        }
    }

    const Component = observer(() => {

        return(
            <form onSubmit={handleSubmit}>
                <Form
                    id="form"
                    ref={formRef}
                    formData={data}
                    readOnly={false}
                    showColonAfterLabel={true}
                    labelLocation={'top'}
                    minColWidth={300}
                    width={'auto'}
                    colCount={2}
                >
                    <Item colSpan={2}
                        dataField="comentario"
                        editorType="dxTextBox"
                        editorOptions={{
                            buttons:[{
                                name:"comentario",
                                location:"after",
                                options:{
                                    stylingMode:"text",
                                    icon:"fas fa-play",
                                    onClick:()=>handleSubmit()
                                }
                            }] 
                        }}
                        validationRules={[ {type:"required",message:"Debe esscribir un comentario"}]}
                    >
                    </Item>
                </Form>
            </form>
            
          );
    });

    return(
        <Component></Component> 
      );
}

export default React.memo(CommentSeccion);