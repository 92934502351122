import React, { useRef, useEffect } from 'react';
import DataGrid, { Column, Pager, Paging,FilterRow, Selection} from 'devextreme-react/data-grid';
import {
    DEFAULT_PAGE_SIZE_TWO_GRID,
    DEFAULT_PAGE_SIZES,
} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog';
import {getDescendants, replicarPuesto} from 'api';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { Button } from 'devextreme-react/button';
import { useStateWithCallbackLazy } from 'use-state-with-callback';

export default function(props){
    const grdRef = useRef();
    const [arrayDatasource, setArrayDatasource] = useStateWithCallbackLazy([])

    useEffect(() => {
        let mounted = true;
        async function fetchData() {
            if (mounted) {
                setArrayDatasource(await getDataSource(), ()=>{
                });
               
            }
        }
        fetchData();
        return () => { mounted = false; };
    }, []);

    const getDataSource = async() =>{
        let result = [];
        const {data:{error,message,data}} = await getDescendants(props.evento_id, props.puesto_id);
        console.log('getDataSource', data);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    }

    const datasource = new DataSource({
        store: new CustomStore({
            key:"id",
            load: (loadOptions) => {
                    return arrayDatasource 
            },
            onLoaded:()=>{
                loadSelectedRows()
            }
          })
      });

      const loadSelectedRows = () => {
          console.log('loadSelectedRows', datasource.items());
        let selectedRows = arrayDatasource.filter((eventoPuesto)=>(eventoPuesto.flag_checked == "1")).map(eventoPuesto => {return eventoPuesto.id; });
        console.log('selectedRows', selectedRows)
        grdRef.current.instance.selectRows(selectedRows, false);
    }

    
      const handleSubmit  = async() =>{
        let selectedKeys = grdRef.current.instance.getSelectedRowKeys();
            let selectedRowsData = grdRef.current.instance.getSelectedRowsData();
            const {data:{data,error, message}} = await replicarPuesto(selectedRowsData, props.evento_id, props.puesto_id);
            if(error){
                AlertDialog({message});
            }else {
                AlertDialog({message: "Puestos a replicar fueron registrados con exito.",isNotification:true, type:"success"})
                props.onAccept && props.onAccept();
            }
        
   }


    return(
        <div>
            <Button
                type="default"
                text="GUARDAR PUESTOS A REPLICAR"
                onClick={()=>{handleSubmit()}}
            />
            <div style={{marginTop:"15px"}}>
                <DataGrid 
                    key="id"
                    ref={grdRef}
                    dataSource={datasource}
                    showBorders={true}
                    columnAutoWidth={true}
                    rowAlternationEnabled={true}
                    >
                    <FilterRow visible={true} showOperationChooser={false}/>
                    <Paging defaultPageSize={DEFAULT_PAGE_SIZE_TWO_GRID} />
                    <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={DEFAULT_PAGE_SIZES}
                            showInfo={true} />
                    <Selection
                        mode="multiple"
                        selectAllMode={'page'}
                        showCheckBoxesMode={'always'}
                    />
                        <Column dataField="id" caption="Id" visible={false} />
                        <Column dataField="funcionario_nombre" caption="Funcionario" />
                        <Column dataField="unidad_administrativa_nombre" caption="Unidad Administrativa" />
                        <Column dataField="puesto_nombre" caption="Puesto" />
                </DataGrid>
            </div>
            
        </div>
    )
}