import {instance} from '../constants/defaultValues';

export function getAnexoRubro({anexoRubroId = 0, take = 1, skip = 0, filter = {}, sort = {}}){
    if(anexoRubroId){
        let ent = instance.post(`/api/read`,{
            "VC":"cat_anexo_rubro",
            "VA":"byid",
            "id":anexoRubroId
          })
       
        return ent;
    }
    else{
        return instance.post(`/api/read`,{
            "VC":"cat_anexo_rubro",
            "VA":"listdata",
            "page":skip/take,
            "pagesize":take,
            ...filter,
            "sortby": sort.selector,
            "sortdesc": sort.desc
        })
    }
}


export function postAnexoRubro(anexoRubro){

    return instance.post(`/api/write`,{
        "VC":"cat_anexo_rubro",
        "VA":"bysystem",
        "id":anexoRubro.id,
        "nombre": anexoRubro.nombre,
        "clave" : anexoRubro.clave,
        "orden" : anexoRubro.orden,
        "estatus": 1
    })
}

export function deleteAnexoRubro(anexoRubroId){
    return instance.post(`/api/delete`,{
        "VC":"cat_anexo_rubro",
        "VA":"bysystem",
        "id" : anexoRubroId
    });
}

export function activateAnexoRubro(anexoRubroId){
    return instance.post(`/api/write`,{
        "VC":"cat_anexo_rubro",
        "VA":"activate_rubro",
        "id" : anexoRubroId
    });
}
