import React,{ useRef, useEffect} from 'react';
import { Button } from 'devextreme-react/button';
import DataGrid, { Column, Editing, Pager, Paging, FilterRow,Export} from 'devextreme-react/data-grid';
import Box, {
  Item
} from 'devextreme-react/box';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import AlertDialog from 'components/alertDialog/alertDialog';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import {
  getProgresoEntregaPuesto,
  getEventoBySearchTerm,
  getEnteBySearchTerm,
  handleDownloadPlantilla
} from 'api';
import { DEFAULT_PAGE_SIZE_ONE_GRID, DEFAULT_PAGE_SIZES, DEFAULT_PAGE_SIZE_CMB } from 'constants/defaultValues';
import { getDataSourceFilter, getDataSourceSort, } from 'utils/utility';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Form, {
    GroupItem,
    Label
  } from 'devextreme-react/form';
import { files } from 'jszip';


 

  export default function () {
    function IconRenderanexos(data){
      return <div key={`dgrid_eventodetails_actas_download_${data.data.id}`}>
          {
            !data.data.downloading ?
              (<i className="dx-link dx-icon-file dx-link-icon" title="Descargar archivo" style={{fontSize:'22px',textDecoration:'unset'}} onClick={()=>{handleDownloadAnexos(data.data)}}></i>)
                : <div className={"dx-link dx-link-icon"}><LoadIndicator  height={20} width={20}  /></div>
                      
          }
      </div>
  }

  const handleDownloadAnexos=async(data)=>{
    let files  = data.anexos_uuids;
    if(files.length){
      await handleDownloadPlantilla({uid:files},`anexos_${data.ente}_${data.puesto}.zip`);
    }
  }

    //#region hook de control
        const formRef = useRef();
    //#endregion
    //#region hook de estado
    const [evento,setEvento] = useStateWithCallbackLazy({
        "evento_id":null,
        "ente_id":null
  })
  //#endregion
   //#region carga de datos iniciales 
   useEffect(() => {
    let mounted = true; 
    async function fetchData(){
        if (mounted) { 
        }
    }
    fetchData();
    return () => { mounted = false; };
  },[]); 
  //#endregion

  //#region fetch
  const fetchData = (async (take, skip, filter, sort) => {
    let result = [];
    let eventos = {...evento};
    let evento_id = eventos.evento_id;
    let ente_id = eventos.ente_id

    const { data: { error, message, data } } = await getProgresoEntregaPuesto({take, skip, filter, sort, evento_id,ente_id});
    if (error) {
      AlertDialog({ message: message });
    } else if (data.length) {
      result = data;
      console.log('getProgresoEntregaPuesto', data);
    }
    return result;
  });
  //#endregion

  //#region dataSource
  const dataSource = new DataSource({
    pageSize: 10,
    store: new CustomStore({
      key: "id",
      load: async(loadOptions) => {
        //return fetchData(loadOptions.take, loadOptions.skip, getDataSourceFilter(loadOptions), getDataSourceSort(loadOptions));
        return (await fetchData(loadOptions.take, loadOptions.skip, getDataSourceFilter(loadOptions), getDataSourceSort(loadOptions))).map(item=>{item.downloading=0;return item})
      }
    })
  });

  const generarDataSource = (getFunc)=>{
    const fetchFunc = async ({seachterm = ''}, take = 1, skip = 0, ) =>{
      let result = []
      const grupoUsuario = parseInt(localStorage.getItem('grupo_id'));
      const {data:{error,message,data}} = await getFunc(seachterm, take, skip, grupoUsuario, true);
      if(error){
          AlertDialog({message});
      }else if(data.length) {
          result = data;
      }
      return result;
    }

    const ds = new DataSource({
      pageSize: DEFAULT_PAGE_SIZE_CMB,
      store: new CustomStore({
          key:"id",
          load: (loadOptions) => {
              if(loadOptions.searchValue)
                  return fetchFunc({seachterm:loadOptions.searchValue}, loadOptions.take, loadOptions.skip);     
              else 
                  return fetchFunc({}, loadOptions.take, loadOptions.skip);             
          },
          byKey: (key) => {
              return key;
          }
        })
    });
    return ds;
  }
  
  const eventoDatasource = generarDataSource(getEventoBySearchTerm);
  const enteDatasource = generarDataSource(getEnteBySearchTerm);
  //#endregion

  //#region eventos interfaz
  const searchFilter = () =>{
    dataSource.reload();
  }

  const cleanFilter = async() =>{
      formRef.current.instance.updateData('evento_id',null);
      formRef.current.instance.updateData('ente_id',null);
      dataSource.reload();
    
  }
  //#endregion

  //#region render
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Progreso de entrega - Nivel Puesto</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
            <form>
                <Form
                    ref={formRef}
                    formData={evento}
                    readOnly={false}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                    validationGroup="eventoData"
                >
                    <GroupItem colCount={8}>
                      <Item
                        colSpan={4}
                        dataField="evento_id"
                        editorType="dxSelectBox"
                        editorOptions={{
                            valueExpr:"id",
                            searchEnabled:true,
                            searchMode:"contains",
                            displayExpr: (item) => item && item.nombre,                     
                            searchExpr:(item) => item && item.nombre,
                            dataSource: eventoDatasource,
                        }}
                      >
                        <Label text={"Evento"}/>
                      </Item>
                      <Item
                        colSpan={4}
                        dataField="ente_id"
                        editorType="dxSelectBox"
                        editorOptions={{
                            valueExpr:"id",
                            searchEnabled:true,
                            searchMode:"contains",
                            displayExpr: (item) => item && item.nombre,                     
                            searchExpr:(item) => item && item.nombre,
                            dataSource: enteDatasource,
                        }}
                        >
                          <Label text={"Énte publico"}/>
                      </Item>
                        <Item colSpan={4}>
                        <Button text={"Buscar"} type="success" onClick={()=>{searchFilter()}}/>
                        <Button style={{marginLeft:"5px"}}  text={"Limpiar"} type="default" onClick={()=>{cleanFilter()}}/>
                        </Item>
                    </GroupItem>
                </Form>
            </form>
        
          <Box width="100%" direction="row">
            <Item ratio={12}>
              <DataGrid 
                key="id"
                onRowPrepared={(e)=>{if('data' in e) e.rowElement.title = e.data.nombre_puesto}}
                rowAlternationEnabled={true}
                dataSource={dataSource}
                rowAlternationEnabled={true}                
                showBorders={true}
                columnAutoWidth={true}
                width={"100%"}>
                <FilterRow visible={true} showOperationChooser={false}/>
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                        <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                        showInfo={true} />
                <Editing
                  mode="row"
                  useIcons={false}
                  allowDeleting={false}
                  allowUpdating={false}
                >
                  </Editing>
                  <Export enabled={true} allowExportSelectedData={false} fileName={'Progreso de entrega - Nivel Puesto'}/>
                <Column dataField = "ente" caption="Ente"/>
                <Column dataField = "unidad_administrativa" caption="Unidad administrativa" alignment={'center'}/>
                <Column dataField = "puesto" caption="Puesto" alignment={'center'}/>


                <Column dataField = "anexos_aplicables" caption="Anexo aplicables" alignment={'center'}/>
                <Column dataField = "anexos_pendientes" caption="Anexos pendientes" alignment={'center'}/>
                <Column dataField = "anexos_terminados" caption="Anexos terminados" alignment={'center'}/>
                <Column dataField = "avance_captura" caption="% Avance captura" alignment={'center'}/>
                <Column dataField = "avance_captura_porc" caption="Rango avance de captura" alignment={'center'}/>

                <Column dataField = "anexos_en_captura" caption="Anexos 1.En proceso de captura" alignment={'center'}/>
                <Column dataField = "anexos_revision" caption="Anexos 3.En revision" alignment={'center'}/>
                <Column dataField = "anexos_observaciones_pendientes" caption="Anexos 4. Observaciones pendientes" alignment={'center'}/>

                <Column dataField = "anexos_capturados" caption="Anexos 2.Captura terminada" alignment={'center'}/>
                <Column dataField = "anexos_observaciones_atendidas" caption="Anexos 5.Observaciones atendidas" alignment={'center'}/>
                <Column dataField = "anexos_revisados" caption="Anexos 6.Revisado" alignment={'center'}/>

                <Column dataField = "clave_puesto" caption="Puesto clave" alignment={'center'}/>
                <Column dataField = "titular" caption="Titular" alignment={'center'}/>
                <Column dataField = "jerarquia" caption="Jerarquia" alignment={'center'}/>
                <Column dataField = "funcionario_saliente" caption="Funcionario saliente" alignment={'center'}/>
                <Column dataField = "correo_saliente" caption="Correo saliente" alignment={'center'}/>
                <Column dataField = "funcionario_entrante" caption="Funcionario entrante" alignment={'center'}/>
                <Column dataField = "bloqueo" caption="Bloqueo" alignment={'center'}/>
                <Column dataField = "cerrado" caption="Cerrado" alignment={'center'}/>
                <Column dataField = "fecha_firma" caption="Fecha firma acta" alignment={'center'}/>
                <Column dataField = "downloading" caption="Anexos" alignment={'center'} cellRender={IconRenderanexos}/>
                <Column dataField = "estatus" caption="Estatus seguimiento" alignment={'center'}/>

              </DataGrid>
            </Item>
          </Box>
        </div>
      </div>
    </React.Fragment>
  );
  }