import React, { useState,useRef,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DataGrid, { Column, Editing, Pager, Paging, FilterRow, Selection, Scrolling,} from 'devextreme-react/data-grid';
import Box, { Item as BoxItem } from 'devextreme-react/box';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {DEFAULT_PAGE_SIZE_ONE_GRID,
    DEFAULT_PAGE_SIZES,
} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog'
import notify from 'devextreme/ui/notify';
import { getUsuarioLimitadoPuesto,postUsuarioLimitadoPuesto,
    deleteUsuarioLimitadoPuesto, activateUsuarioLimitadoPuesto,
    getEventoPuesto,getEventoPuestoAnexoByIdEventoPuesto} 
     from '../../../api';
import { getDataSourceFilter, getDataSourceSort } from 'utils/utility'
import lang from '../../../lang';
import AuthCheck from '../../../components/authCheck/authCheck.js';
import { custom } from 'devextreme/ui/dialog';
import PopUpEntidadInfo from '../../../components/PopUpEntidadInfo';
import { Popup } from 'devextreme-react/popup';
import Form, {ButtonItem,GroupItem,Item,Label,EmptyItem} from 'devextreme-react/form';
import { LoadPanel } from 'devextreme-react/load-panel';
import ScrollView from 'devextreme-react/scroll-view';

const position = { of: '#usuario' }; //TODO: CSS - Se utiliza para posicionar el panel de loading

export default function(props) {
    //#region hook de control
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const[isPopUpOpen, setIsPopUpOpen] = useState(false);
    const[isPopUpInfoOpen, setIsPopUpInfoOpen] = useState(false);
    const permCanEdit = AuthCheck({permission: "MISCAP_REGIS_EDIT", onlyValidator: true});
    const permCanDelete = AuthCheck({permission: "MISCAP_REGIS_DELETE", onlyValidator: true});
    
    const formRef = useRef(null);
    const anexosRef = useRef();
    //#endregion

    //#region hook de estado
    const entidadPlantilla = {
        "id":0,
        "id_usuario":null,
        "id_evento_puesto":null,
        "nombre":'',
        'registro_creacion_usuario':'',
        'registro_creacion_fecha':'',
        'registro_modificacion_usuario':'',
        'registro_modificacion_fecha':'',
        'registro_cancelacion_usuario':'',
        'registro_cancelacion_fecha':'',
        "usuario":'',
        "usuario_password":'',
        "usuario_password_confirm":'',
        "anexos":[],
        "anexos_seleccionados":[],
    };
    const eventoPuestoPlantilla = {
        id:0,
        puesto_ente_publico:'',
        funcionario_saliente:'',
    };
    const [entidad,setEntidad] = useState({...entidadPlantilla})
    const [eventoPuesto,setEventoPuesto] = useState({...eventoPuestoPlantilla})
    //#endregion


    useEffect(() => {        
        async function fetchData(){
            let ent = {...eventoPuestoPlantilla};           
            if(props.location.state && props.location.state.id_evento_puesto){
                ent = await fetchEventoPuesto(props.location.state.id_evento_puesto); 
                setEventoPuesto({...ent})
            }   
             setIsLoading(false);
        }
        fetchData(); 
      },[]); 



    //#region fetch
    const fetchEntidad = (async(id) =>{
        let result = {...entidad};
        if(id){     
            const {data:{error,message,data}} = await getUsuarioLimitadoPuesto({id});
            
            if(error){
                AlertDialog({message: message});
            }else if(data.length){
                result.id = data[0].id;
                result.id_usuario = data[0].id_usuario;
                result.id_evento_puesto = data[0].id_evento_puesto;
                result.nombre = data[0].nombre;
                result.orden = data[0].orden;
                result.registro_creacion_usuario = data[0].registro_creacion_usuario;
                result.registro_creacion_fecha = data[0].registro_creacion_fecha;
                result.registro_modificacion_usuario = data[0].registro_modificacion_usuario;
                result.registro_modificacion_fecha = data[0].registro_modificacion_fecha;
                result.registro_cancelacion_usuario = data[0].registro_cancelacion_usuario;
                result.registro_cancelacion_fecha = data[0].registro_cancelacion_fecha;
                result.usuario = data[0].usuario;
                result.usuario_password = data[0].usuario_password;
                result.usuario_password_confirm = data[0].usuario_password;
                result.anexos = data[0].anexos;
                result.anexos_seleccionados = data[0].anexos_seleccionados;
            }
        }
        return result;
    })
    const fetchEventoPuesto = (async(id) =>{
        let result = {...eventoPuestoPlantilla};
        if(id){     
            const {data:{error,message,data}} = await getEventoPuesto({id});
            
            if(error){
                AlertDialog({message: message});
            }else if(data.length){
                result.id = data[0].id;
                result.puesto_ente_publico = data[0].puesto_ente_publico;
                result.funcionario_saliente = data[0].funcionario_saliente;
            }
        }
        return result;
    })
    const fetchData = (async (id_evento_puesto,take, skip, filter, sort) =>{
        let result= {data:[], totalCount:0};
        const {data:{error,message,data}} = await getUsuarioLimitadoPuesto({id_evento_puesto,take, skip, filter, sort});
        if(error){
            AlertDialog({message});
        }else if(data.length && data[0].totalCount) {
            result = data[0];
        }
        return result;
    })
    const fetchAnexos = (async (id_evento_puesto) =>{
        let result= {data:[], totalCount:0};
        const {data:{error,message,data}} = await getEventoPuestoAnexoByIdEventoPuesto(id_evento_puesto);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    })
    const deleteData = (async (key) =>{
        const {data:{error,message}} = await deleteUsuarioLimitadoPuesto(key);
        if(error){
            AlertDialog({message});
        }
    })
    const activateData = (async (key) =>{
        const {data:{error,message}} = await activateUsuarioLimitadoPuesto(key);
        if(error){
            AlertDialog({message});
        }
    })
    //#endregion

    //#region dataSource
    const dataSource = new DataSource({
        store: new CustomStore({
            key: "id",
            load: (loadOptions) => {
                return fetchData(
                    props.location.state.id_evento_puesto,
                    loadOptions.take, 
                    loadOptions.skip, 
                    getDataSourceFilter(loadOptions), 
                    getDataSourceSort(loadOptions));             
            },
            remove: (key) => {
                return deleteData(key);
            }
        })
    });

    const dataSourceEventosPuestosAnexos = new DataSource({
        store: new CustomStore({
            key: "id",
            load: (loadOptions) => entidad.anexos
        })
    });
   
    //#endregion
    
    //#region eventos interfaz
    const goToCapturas = () =>{
        history.push({pathname:'/eventos/capturas'});
    }
    
    const openModal = async(id=0) =>{
        let ent = {...entidadPlantilla}

        ent.id_evento_puesto = props.location.state.id_evento_puesto;

        let seleccionados =[];
        if (id>0) {
            ent = await fetchEntidad(id); 
            seleccionados = ent.anexos_seleccionados.map(x=> x.id)   
        }else{
            ent.anexos = await fetchAnexos(props.location.state.id_evento_puesto);
            seleccionados = [];
        }

        setEntidad(ent);  
        setTimeout(()=>{
            anexosRef.current.instance.selectRows(seleccionados, false);
            setIsPopUpOpen(true);
        }, 300);   
    }
    const handleSubmit = async(e) => {

        let validacion = formRef.current.instance.validate();
        if (!validacion.isValid) return;
     
        if (entidad.usuario_password != entidad.usuario_password_confirm){
            notify({message: "La contraseña y su confirmación no coinciden."});
            return;
        }
        setIsLoading(true);
        entidad.anexos_seleccionados = anexosRef.current.instance.getSelectedRowKeys().map((x) => {return {id:x};}) ;

        let ent = {...entidad}
        const {data:{error,message}} = await postUsuarioLimitadoPuesto(ent);
        if(error) AlertDialog({message: message});
        else setIsPopUpOpen(false);  
        
       
       
        setIsLoading(false);     
        dataSource.reload();
        
    }

    const openModalInfo = async(id) =>{    
        let ent = await fetchEntidad(id); 
        setEntidad(ent);    
        setIsPopUpInfoOpen(!isPopUpInfoOpen);
    }

    const  activateRecord = (e) =>{
        let activateDialog = custom({
            showTitle: false,
            messageHtml: "¿Está seguro que desea activar este registro?",
            buttons: [
                {
                    text: "SÍ",
                    onClick: () => {
                        return true;
                    }
                }, 
                {
                    text: "NO",
                    onClick: () => {
                        return false;
                    }
                }, 
            ]
        });

        activateDialog.show().then(async (dialogResult) => {
            if(dialogResult){
                await activateData(e.row.data.id);
                dataSource.reload();
            }
        });
    }
    function canEdit(e){
        return e.row.data.estatus == 1 && permCanEdit;
    }

    function canDelete(e){
        return e.row.data.estatus == 1 && permCanDelete;
    }

    function canActivate(e){
        return e.row.data.estatus != 1 && permCanDelete;
    }

    const passwordComparison=async()=> {
        return entidad.usuario_password == entidad.usuario_password_confirm;
    }

    //#endregion
    //#region validaciones
    const validationRules={         
        nombre:[
            {type:"required",message:"Nombre es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:250}
        ],
        usuario:[
            {type:"required",message:"Nombre de usuario es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:250}
        ],
        
        usuario_password:[
            /*
            {type:"custom",message:"La contraseña no coincide con su confirmación.",
            validationCallback:(e)=> {
                let clave = e.value;
                let confirmacion= formRef.current.instance.getEditor("usuario_password_confirm").option("value");
                return clave == confirmacion;
            }
            }
            */  
        ],
        usuario_password_confirm:[
            /*
           {type:"custom",message:"La contraseña no coincide con su confirmación.",
                validationCallback:(e)=> {
                    let clave = formRef.current.instance.getEditor("usuario_password").option("value");
                    let confirmacion= e.value;
                    return clave == confirmacion;
                }
                
            }
            */ 
        ],
        
    }
    //#endregion
    //#region render

    const gridPageSize =()=>{    
        if  (window.screen.availHeight>=1080) return 10;
        else if  (window.screen.availHeight>=800) return 5;
        else return 3;
        //return PAGE_SIZE_GRID_LISTA;
    }
    
    return(
        <React.Fragment>
            <h2 className={'content-block'}>{lang.getString("usuario.registro.limitado.index.title")}</h2>
            <div className={'content-block'}>
            <div className={'dx-card responsive-paddings'}>
                   
            <Form horizontalAlignment="left" formData={eventoPuesto} readOnly={true}>      
                <GroupItem colCount={3}>
                    <Item colSpan={1} dataField="puesto_ente_publico" stylingMode="underlined">
                        <Label text="Puesto / Ente publico"></Label>
                    </Item> 
                    <Item colSpan={1} dataField="funcionario_saliente" stylingMode="underlined">
                        <Label text="Funcionario saliente"></Label>
                    </Item>

                    {
                    AuthCheck({
                        permission:"MISCAP_REGIS_ADD",
                        component: 
                            <ButtonItem colSpan={1} horizontalAlignment="right" verticalAlignment="bottom" colSpan={1}
                                buttonOptions={{
                                    text:lang.getString("usuario.registro.limitado.index.agregar"),
                                    type:'default',icon:"plus", onClick:()=>openModal()
                                }}
                            /> 
                    })   
                }
                     
                </GroupItem>                   
            </Form>

            <Box width="100%" direction="row">
                <BoxItem ratio={1}>
                    <DataGrid key="id"
                    dataSource={dataSource}
                    remoteOperations={{paging:true, sorting:true, filtering: true}}
                    columnAutoWidth={true}
                    repaintChangesOnly={true}
                    showBorders={true} width={"100%"}
                    rowAlternationEnabled={true}>
                        <FilterRow visible={true} showOperationChooser={false}/>
                        <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={DEFAULT_PAGE_SIZES}
                                showInfo={true} />
                        <Editing
                            mode="row"
                            useIcons={true}
                            allowDeleting={AuthCheck({
                                permission:"MISCAP_REGIS_DELETE",
                                onlyValidator: true
                            })}
                            allowUpdating={AuthCheck({
                                permission:"MISCAP_REGIS_EDIT",
                                onlyValidator: true
                            })}
                        />
                        <Column caption="Acción" type="buttons" width={120}
                        buttons={[
                            {hint:'Editar'  ,icon:'edit' ,name:'edit'   , visible:canEdit   ,onClick:(event) => openModal(event.row.key)},
                            {hint:'Eliminar',icon:'trash',name:'delete' , visible:canDelete   },
                            {hint:'Activar',icon:'check',name:'activate', visible:canActivate, onClick:activateRecord},
                        ]}
                        />
                        <Column dataField="id" visible={false} />
                        <Column dataField="nombre"  caption={lang.getString("usuario.registro.limitado.caption.nombre")} allowFiltering={false} allowFiltering={false} />
                        <Column dataField="usuario" caption={lang.getString("usuario.registro.limitado.caption.usuario")} allowFiltering={false} allowFiltering={false} />
                    </DataGrid>
                </BoxItem>
                
            </Box>
            <Form  horizontalAlignment="left" readOnly={true}>      
                <GroupItem colCount={1}> 
                    <ButtonItem colSpan={1} horizontalAlignment="left" verticalAlignment="bottom" colSpan={1}
                        buttonOptions={{text:'Regresar',type:'default', onClick:()=>goToCapturas()}}
                    />           
                </GroupItem>                   
            </Form>

            <PopUpEntidadInfo
                    isPopUpInfoOpen={isPopUpInfoOpen}
                    setIsPopUpInfoOpen={setIsPopUpInfoOpen}
                    entidadInfo={entidad}
                    titulo={"Documento"}/>
            </div>
            <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={isLoading}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />

            <Popup
                    visible={isPopUpOpen}
                    onHiding={() => {setIsPopUpOpen(false)} }
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title={lang.getString("usuario.registro.limitado.detail.title")}
                    width="80%"
                    height="90%"
                    >  
                    <ScrollView width='100%' height='100%'>
                        <Form
                             ref={formRef}
                             formData={entidad}
                             readOnly={false}
                             showColonAfterLabel={true}
                             validationGroup="validationRules"
                        >
                            <GroupItem colCount={4}>    
                                <Item 
                                    colSpan={1}
                                    dataField="nombre"
                                    stylingMode="underlined"
                                    validationRules={validationRules.nombre}>
                                        <Label text={lang.getString("usuario.registro.limitado.caption.nombre")}></Label>
                                </Item>
                                <Item  
                                    colSpan={1}
                                    dataField="usuario"
                                    stylingMode="underlined"
                                    validationRules={validationRules.usuario}>
                                        <Label text={lang.getString("usuario.registro.limitado.caption.usuario")}></Label>
                                </Item>
                                <Item
                                    editorOptions={{mode:"password"}}
                                    colSpan={1}
                                    dataField="usuario_password"
                                    stylingMode="underlined"
                                    validationRules={validationRules.usuario_password}>
                                        <Label text={lang.getString("usuario.registro.limitado.caption.usuario_password")}></Label>
                                </Item>
                                <Item 
                                    editorOptions={{mode:"password"}}
                                    colSpan={1}
                                    dataField="usuario_password_confirm"
                                    stylingMode="underlined"
                                    validationRules={validationRules.usuario_password_confirm}>
                                        <Label text={lang.getString("usuario.registro.limitado.caption.usuario_password_confirm")}></Label>
                                </Item>
                            </GroupItem>
                            <GroupItem colCount={1}> 
                               
                                <DataGrid key="id" colSpan={12} 
                                    ref={anexosRef}
                                    dataSource={dataSourceEventosPuestosAnexos}
                                    columnAutoWidth={true}
                                    repaintChangesOnly={true}
                                    showBorders={true} width={"100%"}
                                    height={350}
                                    rowAlternationEnabled={true}>
                                    <Scrolling mode="virtual" />     
                                    <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                                        showInfo={true} />
                                    <FilterRow visible={true} showOperationChooser={false}/>                                 
                                    
                                    <Selection mode="multiple" />    
                                    <Column dataField="id" caption="Id" visible={false} />   
                                    <Column dataField="clave"  caption={lang.getString("anexo.caption.clave")} /> 
                                    <Column dataField="anexo" caption={lang.getString("anexo.caption.nombre")} />
                                    <Column dataField="aplica" caption="Aplica" dataType="boolean" allowFiltering={false} />  
                                    <Column dataField="rubro"  caption={lang.getString("anexo.caption.id_rubro")} />
                                    
                                </DataGrid>
                                 

                            </GroupItem>  
                                <ButtonItem horizontalAlignment="left" verticalAlignment="bottom" colSpan={1}
                                    buttonOptions={{text:'Guardar',type:'default', onClick:handleSubmit}}
                                />    
                            <GroupItem colCount={1}> 
                            </GroupItem>     
                        </Form>
                    </ScrollView>                  
            </Popup>      

            
            </div>
        </React.Fragment>
    );
    //#endregion
}

//hacer validationRule para contraseña y confirmacion
//encriptar contraseña
//marcar check de anexos seleccionados en datagrid
//preguntar para que sirven los botones con iconos de undo y guardar