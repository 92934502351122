import React,{useEffect} from 'react';
import {postFingerPrint } from 'api';

export default function ({isValidating,huella_dactilar,nombre,apellidoPaterno,apellidoMaterno,departamento,foto,puesto,onFailed=(message)=>{},onSuccess=(data)=>{}}) {
    //#region hook de montado de la vista, se utiliza para generar el estado inicial
    useEffect(() => {
        let mounted = true; 
        async function fetchData(){
            if (mounted) { 
                //aqui se manda a llamar los fetchs iniciales
                digitalPersonAction();
            }
        }
        fetchData();
        return () => { mounted = false; };
      },[]); 
    //#endregion
    //#endregion
     

    //#region eventos interfaz
        const digitalPersonAction = async() => {
            //templates se sacaria de la variable huella_dactilar
            let templates = [{template:huella_dactilar}];
            let postData = {
                perfil:{
                    nombre: nombre+" "+apellidoPaterno+" "+apellidoMaterno,
                    departameto:departamento  ? departamento.departamento_nombre:"",
                    puesto: puesto? puesto.puesto_nombre :"",
                    foto
                },
                templates
            };
            console.log("pos",postData);
            const {data:{error,message,data}} = await postFingerPrint(isValidating,postData);
            console.log("er",error,"me",message,"da",data);
            if(error) onFailed && onFailed(message);
            else onSuccess && onSuccess(isValidating ? message : data);
            
        }
    //#endregion


    //#region render
    return (
        <div></div>    
    );
    //#endregion
}