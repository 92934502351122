import AlertDialog from 'components/alertDialog/alertDialog';
import {instance} from '../constants/defaultValues';

export function getEvento({eventoId = 0, take = 1, skip = 0, filter = {}, sort = {}, grupoPermiso = null}){
    if(eventoId){
        return instance.post(`/api/read`,{
            "VC":"eventos",
            "VA":"byid",
            "id":eventoId
        })
    }
    else{
        return instance.post(`/api/read`,{
            "VC":"eventos",
            "VA":"list",
            "page":skip/take,
            "pagesize":take,
            ...filter,
            "sortby": sort.selector,
            "sortdesc": sort.desc,
            "grupo_permiso": grupoPermiso
        })
    }
}
export function getEventoInfoCMC(evento_id){
    return instance.post(`/api/read`,{
        "VC":"eventos",
        "VA":"modalinfo",
        "evento_id": evento_id
    })
}


export function getActasFirmadasEvento(evento_id){
    return instance.post(`/api/read`,{
        "VC":"eventos",
        "VA":"actasFirmadasDownload",
        "evento_id": evento_id
    })
} 

export function getAnexosEvento(evento_id){
    return instance.post(`/api/read`,{
        "VC":"eventos",
        "VA":"anexosDownload",
        "evento_id": evento_id
    })
}

export function getInfoEvento(puestioId, eventoId){
    return instance.post(`/api/read`,{
        "VC":"eventos",
        "VA":"infopuesto",
        "puestoente_id": puestioId,
        "evento_id": eventoId
    })
}

export function cerrarEvento(evento){
    return instance.post(`/api/write`,{
        "VC":"eventos",
        "VA":"closevent",
        "id":evento.id,
        "nombre":evento.nombre,
        "orden":evento.orden,
        "id_evento_tipo":evento.id_evento_tipo,
        "fecha_publicacion":evento.fecha_publicacion,
        "dias_rpfa":evento.dias_rpfa,
        "dias_atencion":evento.dias_atencion,
        "id_acta": evento.id_acta,
        "entes": evento.entes_participantes
    })
}


export function postEvento(evento){
    return instance.post(`/api/write`,{
        "VC":"eventos",
        "VA":"bysystem",
        "id":evento.id,
        "nombre":evento.nombre,
        "orden":evento.orden,
        "id_evento_tipo":evento.id_evento_tipo,
        "fecha_publicacion":evento.fecha_publicacion,
        "dias_rpfa":evento.dias_rpfa,
        "dias_atencion":evento.dias_atencion,
        "id_acta": evento.id_acta,
        "entes": evento.entes_participantes,
        "habiles_rpfa": evento.habiles_rpfa,
        "habiles_atencion": evento.habiles_atencion
    })
}

export function postEntregaParcial(puesto_id){
    return instance.post(`/api/write`,{
        "VC":"eventos",
        "VA":"eventoparcial",
        "puesto_id":puesto_id
    })
}

export function postOrganizationalStructure(evento){
    return instance.post(`/api/write`,{
        "VC":"eventos",
        "VA":"update_organizational_structure",
        "id":evento.id
    })
}

export function getEventoById(){}

export function deleteEvento(eventoId){
    return instance.post(`/api/delete`,{
        "VC":"eventos",
        "VA":"bysystem",
        "id" : eventoId
    });
}

export function reviveEvento(eventoId){
    return instance.post(`/api/delete`,{
        "VC":"eventos",
        "VA":"revivir",
        "id" : eventoId
    });
}

export async function getEventoAnexoArchivo({eventoId = null, enteId = null,id_unidad_administrativa = null,puesto_id=null, evento_puesto_id = null,name = 'Archivo Anexo'},callbackprogress){

    return new Promise(  function(resolver, rechazar) {  
        const consumir = async ()=>{
            try{
                const plantilla = await instance.post(`/api/read`,{
                    "VC":"eventos",
                    "VA":"byeventoarchivo",
                    "ente_id":enteId,
                    "evento_id":eventoId,
                    "unidad_id":id_unidad_administrativa,
                    "puesto_id":puesto_id ,
                    "evento_puesto_id" : evento_puesto_id
                },
                {
                    //responseType: 'arraybuffer',
                    onDownloadProgress: (evt) => {
                        callbackprogress && callbackprogress(parseInt( Math.round( parseInt((evt.loaded / evt.total) * 100) )));
                    }
                })
                console.log('paltilla',{plantilla})
                
                console.log('xxmsmx', typeof({plantilla}));
        
                    const {data:{data:{url}}} = plantilla;
                callbackprogress && callbackprogress(0);
                function appendLeadingZeroes(n){
                    if(n <= 9){
                    return "0" + n;
                    }
                    return n
                }
        
                
                try {
                
                    let current_datetime = new Date()
                    const downloadUrl = url;// window.URL.createObjectURL(new Blob([plantilla.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `${name}_${current_datetime.getFullYear()}${appendLeadingZeroes(current_datetime.getMonth() + 1)}${appendLeadingZeroes(current_datetime.getDate())}_${appendLeadingZeroes(current_datetime.getHours())}${appendLeadingZeroes(current_datetime.getMinutes())}.zip`); 
                    document.body.appendChild(link);
                    link.click();
                    link.remove();     
                    
                } catch (error) {
                    console.error( error);
                }
                resolver(plantilla)
            }
            catch(err){
                    AlertDialog({message:'Hubo un problema al descargar los archivos'});
                    rechazar({data:{}, error: true, message:'Error al descargar el archivo'})
            }
        
        }
        
    consumir();
} );   
}
export async function getEventoAnexoArchivoDD({eventoId = null, enteId = null,id_unidad_administrativa = null,puesto_id=null, evento_puesto_id = null,name = 'Archivo Anexo',token = null},callbackprogress){
    return new Promise(  function(resolver, rechazar) {  
        const consumir = async ()=>{
        
        function appendLeadingZeroes(n){
            if(n <= 9){
            return "0" + n;
            }
            return n
        }
        const serialize = function (obj) {
            let str = [];
            for (let p in obj)
              if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
              }
            return str.join("&");
          }
        const PAYLOAD = btoa( JSON.stringify({
            "VC":"eventos",
            "VA":"byeventoarchivo",
            "ente_id":enteId,
            "evento_id":eventoId,
            "unidad_id":id_unidad_administrativa,
            "puesto_id":puesto_id ,
            "evento_puesto_id" : evento_puesto_id
        }));

        const queryString = serialize({
            VA : 'eventos',
            VC : 'byeventoarchivo',
            PAYLOAD,
            h_token : token
        });


        let current_datetime = new Date()
        const downloadUrl = `https://localhost:44377/api/read?${queryString}`;
        
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${name}_${current_datetime.getFullYear()}${appendLeadingZeroes(current_datetime.getMonth() + 1)}${appendLeadingZeroes(current_datetime.getDate())}_${appendLeadingZeroes(current_datetime.getHours())}${appendLeadingZeroes(current_datetime.getMinutes())}.zip`); 
        document.body.appendChild(link);
        link.click();
        link.remove();     
        resolver({data:{error:false, message: '',data :null}})
    }
    consumir();
} );   
}

export function getActasFirmadasEventos(evento_id, ente_id){ 
    return instance.post(`/api/read`,{
        "VC":"eventos",
        "VA":"ids_actas_by_ente_and_evento",
        "evento_id":evento_id,
        "ente_id": ente_id
    });
}

export function getActaTitular(evento_id, ente_id){ 
    return instance.post(`/api/read`,{
        "VC":"eventos",
        "VA":"id_acta_titular",
        "evento_id":evento_id,
        "ente_id": ente_id
    });
}