import React, { useState,useEffect,useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Form, {
    ButtonItem,
    GroupItem,
    Item,
    Label,
  } from 'devextreme-react/form';
import ReportViwer from 'components/reportviewer';
import { LoadPanel } from 'devextreme-react/load-panel';
import { postActa,getActa,adjuntoActa,getFile } from 'api';
import {cargarArchivo,descargarArchivo} from 'utils/utility.js';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import 'devextreme-react/autocomplete';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {DEFAULT_PAGE_SIZE_CMB} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog'
import { Button} from 'devextreme-react';
import CustomPopUp from 'components/custompopup';
import ComponentWrapper from 'components/componentWrapper';
import lang from 'lang';
const position = { of: '#usuario' }; //TODO: CSS - Se utiliza para posicionar el panel de loading

export default function (props) {
    //#region hook de control
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [isPopUpOpen,setIsPopupOpen] = useState(false);
    const formRef = useRef(null);
    const viewerRefFiel = useRef();
    const reportVieweRef = useRef();
    //#endregion

    //#region hook de estado
    const [entidad,setEntidad] = useStateWithCallbackLazy({
        "id": 0,
        "adj_acta_docx": '',
        "observaciones": '',
        "clave": '',
        "orden": 0,
        "nombre": '',
        "id_adjunto": null,
        "nombre_adjunto": "",
        "adjunto": [],
    });
    const [isEdit, setIsEdit] = useState(false);
    const [visible, setVisible] = useState(false);
    //#endregion

    //#region hook de montado de la vista, se utiliza para generar el estado inicial
    useEffect(() => {
        let mounted = true; 
        async function fetchData(){
            let sector = {...entidad};
            if (mounted) { 
                //aqui se manda a llamar los fetchs iniciales
                if(props.location.state && props.location.state.id){
                    setIsEdit(true);
                    sector = await fetchActa(props.location.state.id);
                    setEntidad(sector);
                }                
                setIsLoading(false);
            }
        }
        fetchData();
        return () => { mounted = false; };
      },[]); 
    //#endregion

    //#region fetch
    const fetchActa = (async(actaId) =>{
        let result = {...entidad};
        if(actaId){
            const {data:{error,message,data}} = await getActa({actaId});
            if(error){
                AlertDialog({message: message});
            }else if(data.length){
                result.id = data[0].id;
                result.adj_acta_docx = data[0].adj_acta_docx;
                result.observaciones = data[0].observaciones;
                result.clave = data[0].clave;
                result.orden = data[0].orden;
                result.nombre = data[0].nombre;
                result.id_adjunto = data[0].id_adjunto;
                result.nombre_adjunto = data[0].nombre_adjunto;
            }
        }
        return result;
    })
    //#endregion    
    
    //#region dataSource
    const actaDataSource = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: "id",
            load: (options) => {               
                return fetchActa(options.id, options.take, options.skip);                    
            },
            byKey: (key) => {
                return [];
            }
        })
    });
    //#endregion

    //#region eventos interfaz
    const goToList = () =>{
      history.push({pathname:'/catalogos/acta'});
    }
    //#endregion

    //#region eventos CRUD
    const handleSubmit = (async (e) => {   
        console.log("handleSubmit>entidad",entidad) 
        e.preventDefault();
        setIsLoading(true);

        const {data:{error,message}} = await postActa(entidad);
        if(error) AlertDialog({message: message});  
        else      goToList();
        
        setIsLoading(false);
    });
    //#endregion

    //#region archivo
    const cargar =  function() {
        const alCargarArchivo = (async(idAdjunto)=>{   
            entidad.id_adjunto = idAdjunto;
    
            let file = await getFile({fileId: idAdjunto});
            entidad.nombre_adjunto = file.data.data[0].filename;
    
            if (entidad.id>0){
                const {data:{error,message}} = await adjuntoActa(entidad)
                if(error){
                    AlertDialog({message});
                    entidad.id_adjunto = null;
                    entidad.nombre_adjunto = "";    
                } 
            }
    
            setEntidad({...entidad});
        });

        cargarArchivo('.docx', alCargarArchivo, setIsLoading);
    }  
    const quitarAdjunto = async() => {
        entidad.id_adjunto     = null;
        entidad.nombre_adjunto = "";
        
        const {data:{error,message}} = await adjuntoActa(entidad)
        if(error){
            AlertDialog({message});
            entidad.id_adjunto = null;
            entidad.nombre_adjunto = "";    
        } 
        
        setEntidad({...entidad});
    } 
    const descargar = (async()=>{
        if (entidad.id_adjunto) descargarArchivo(entidad.id_adjunto, setIsLoading)
    });
    //#endregion

    //#region validaciones
    const customCallback =(e) => {
        if (visible) 
            return true;
        else
            return false;
    }
    const validationRules ={ 
        nombre:[
            {type:"required",message:"Nombre es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:1000}
        ],
        clave:[
            {type:"required",message:"Clave es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:1000},
        ],
    }
    //#endregion

    //#region render
    return (
        <React.Fragment >
            <ComponentWrapper ref={viewerRefFiel}  component={(props)=> {
                return(
                props.visible ? 
                <CustomPopUp width={600} height={600} visible={true} title={"Registrar fiel"} onClose={()=> props && props.setProps({visible:false})}>
                
                </CustomPopUp>
                :null);
                }
            } 
            />
            <h2 className={'content-block'}>{lang.getString("acta.detail.title")}</h2>
            <div id="usuario" className={'content-block'}>
                <ReportViwer ref={reportVieweRef}/>
                <div className={'dx-card responsive-paddings'}>
                
                <form  onSubmit={handleSubmit}>
                    <Form
                        ref={formRef}
                        formData={entidad}
                        readOnly={false}
                        showColonAfterLabel={true}
                        showValidationSummary={true}
                        validationGroup="validationRules"
                    >
                        <GroupItem colCount={4}>
                            <Item 
                                colSpan={2}
                                dataField="nombre"
                                stylingMode="underlined"
                                validationRules={validationRules.nombre}>
                                    <Label text={lang.getString("acta.caption.nombre")}></Label>
                            </Item>
                            <Item 
                                colSpan={1}
                                dataField="clave"
                                stylingMode="underlined"
                                validationRules={validationRules.clave}>
                                    <Label text={lang.getString("acta.caption.clave")}></Label>
                            </Item>
                            <Item 
                                colSpan={1}
                                dataField="orden"
                                stylingMode="underlined"
                                editorType="dxNumberBox"
                                validationRules={validationRules.orden}>
                                    <Label text={lang.getString("acta.caption.orden")}></Label>
                            </Item>
                            
                        </GroupItem>
                        <GroupItem colCount={1}>
                            <Item 
                                colSpan={4}
                                dataField="observaciones"
                                editorType="dxTextArea"
                                stylingMode="underlined"
                                validationRules={validationRules.clave}>
                                    <Label text={lang.getString("acta.caption.observaciones")}></Label>
                            </Item>
                        </GroupItem>
                        <GroupItem colCount={20}>
                            <Item colSpan={20}>
                                <div>
                                    <Button style={{marginRight:"5px"}} width={250} text="Seleccionar documento" type="default" onClick={()=>cargar()}/>
                                    <Button style={{marginRight:"5px"}} width={50} type="normal" icon="remove" stylingMode="text" visible={entidad.nombre_adjunto !== ""} onClick={()=>quitarAdjunto()}/>
                                    <Button style={{marginRight:"5px"}} width={250} type="normal" text={entidad.nombre_adjunto} stylingMode="text" onClick={()=>descargar()}/>
                                </div>
                            </Item>
                        </GroupItem>
                        <GroupItem colSpan={12}>
                            <ButtonItem horizontalAlignment="left" colSpan={2}
                                buttonOptions={{text:'Guardar',type:'success',useSubmitBehavior:true}}
                            />
                            <ButtonItem horizontalAlignment="left" colSpan={10}
                                buttonOptions={{text:'Regresar',type:'default',onClick:()=>{goToList()}}}
                            />
                        </GroupItem>
                            
                    </Form>
                    </form>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={isLoading}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
                
            </div>
      </React.Fragment>
        );
    //#endregion
}