import React from 'react';
import { 
    getObservacionesRevision,
    getObservacionesAceptacion,
    postAddRevisionObservacion,
    postAddAceptacionObservacion,
    postFinishRevisionObservacion,
    postFinishAceptacionObservacion
    } from 'api'
import 'devextreme-react/autocomplete';
import AlertDialog from 'components/alertDialog/alertDialog'
import ModalDescripcion from './comentarioModal';
import { Button } from 'devextreme-react/button';
import CustomPopUp from 'components/custompopup';
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { 
    DEFAULT_PAGE_SIZE_ONE_GRID, 
    DEFAULT_PAGE_SIZES } from 'constants/defaultValues';
import DataGrid, {Column,Editing,Pager, Paging} from 'devextreme-react/data-grid';
import {Loading} from 'components/loader'
import {getDataSourceFilter, getDataSourceSort} from 'utils/utility'

import { observer, useLocalObservable } from "mobx-react-lite"
import ModalAgregarObservacion from './modalAgregarObservacion';
import AuthCheck from 'components/authCheck/authCheck.js';
import {
    GRUPO_ENTE_CAPTURA,
    GRUPO_ENTE_REGISTRO_LIMITADO
} from 'constants/defaultValues';
import { GRUPO_SISTEMA_REVISOR } from 'constants/defaultValues';
import { GRUPO_ENTE_REVISOR_INTERNO } from 'constants/defaultValues';


export default function ({data, ...props}) {
    function descripcionCellRender(cellData){
        let desc = cellData.data.descripcion.toString();
        console.log('descripcionCellRender', desc)
        return(
          <div>
            {desc !== null ? <Button icon="info" onClick={()=>{ changeStateModal(desc) }}/> : null} 
           </div>
        )
      }


    console.log("OBSERVACIONES", data.estatus_anexo);
    let isRevision = data.comentarioRevisionPopupVisible ? true : false;
    console.log("isRevision",isRevision);
    let puedeComentar = AuthCheck({permission: isRevision ? "CAPTURA_OBSERV_COMENT" : "CAPTURA_OBSERV_ACEPT_COMENT",onlyValidator: true});
    let puedeDevolver = AuthCheck({permission: isRevision ? "CAPTURA_OBSERV_DEVOL":"CAPTURA_OBSERV_ACEPT_DEVOL",onlyValidator: true});
    let puedeAtender = AuthCheck({permission:  isRevision ? "CAPTURA_OBSERV_ATEND":"CAPTURA_OBSERV_ACEPT_ATEND",onlyValidator: true});
    let puedeRevisar = AuthCheck({permission:  isRevision ? "CAPTURA_OBSERV_REVISADAS":"CAPTURA_OBSERV_ACEPT_REVISADAS",onlyValidator: true});
    const grupoUsuario = parseInt(localStorage.getItem('grupo_id'));
    
    //#region hook de control
    const comentario = useLocalObservable(()=>{return{
       "id_evento_puesto_anexo_observacion_revision": null,
        "visible": false,
        'visible_descripcion':false,
        titulo:null,
        descripcion:null,
        info_creacion:null,
        comentarios:[],
        comentario:null,
        isLoading:false,
        isSavingComment:false,
        isSaving:false,
        estatus:null,
        estatus_anexo:null,
        estatus_aceptacion:null,
        puesto_cerrado:null,
        isRevision:null,
        fromJS:function(json){
            Object.keys(json).forEach(key => {
                if (this.hasOwnProperty(key)) {
                    this[key] = json[key];
                    console.log(key, this[key],json[key])
                }
            });
        },
        clear(){
            this.id_evento_puesto_anexo_observacion_revision=null
            this.visible=false
            this.visible_descripcion = false
            this.isLoading=false
            this.titulo=null
            this.descripcion=null
            this.estatus=null
            this.estatus_anexo=null
            this.estatus_aceptacion = null
            this.puesto_cerrado = null
            this.isRevision=null
            this.info_creacion=null
            this.comentarios=[]
            this.comentario=null
            this.isSavingComment = false
            this.isSaving=false
        }
    }});
    //#endregion

    const dataSource = new DataSource({
        store: new CustomStore({
            key: "id_evento_puesto_anexo_observacion_revision",
            load: (loadOptions) => {
                console.log("load",loadOptions);
                return fetchData(
                    loadOptions.take, 
                    loadOptions.skip, 
                    getDataSourceFilter(loadOptions), 
                    getDataSourceSort(loadOptions));             
            }
        })
    });

    const fetchData = (async (take, skip, filter, sort) =>{
        let result= {data:[], totalCount:0};
        let id_puesto_anexo = data.anexo_id.id_puesto_anexo;
        const {data:{error,message,data:response}} = isRevision ? await getObservacionesRevision({id_puesto_anexo,take, skip, filter, sort}) : await getObservacionesAceptacion({id_puesto_anexo,take, skip, filter, sort});
        if(error){
            AlertDialog({message});
        }else if(response.length && response[0].totalCount) {
            result = response[0];
        }
        //console.log("fetchData result",result)
        return result;
    })

    const openComments = ({row:{data:info}})=>{
        console.log("info",info);
        comentario.fromJS({...info,estatus_anexo:data.estatus_anexo,estatus_aceptacion:data.estatus_aceptacion,visible:true,isRevision,puesto_cerrado:data.puesto_cerrado});
    }

    const agregarObservacion = async(result)=>{
            comentario.fromJS({isLoading:true,visible:false});
            let params = {titulo:comentario.titulo,descripcion:comentario.descripcion,id_puesto_anexo:data.anexo_id.id_puesto_anexo};
            const {data:{error,message,data:response}} =  isRevision ? await postAddRevisionObservacion(params) : await postAddAceptacionObservacion(params);
            if(error){
                AlertDialog({message,onActionCallback:(resp)=>{
                    comentario.fromJS({isLoading:false,visible:true});
                }});   
            }else {
                dataSource.reload();
                comentario.clear();
                data.refreshFromModal(data.anexo_id.id);
            }
        
        
        //comentario.clear();
    }

    const finalizarRevision = async()=>{
        comentario.fromJS({isSaving:true});
        const {data:{error,message,data:response}} = isRevision ? await postFinishRevisionObservacion(comentario.id_evento_puesto_anexo_observacion_revision,6): await postFinishAceptacionObservacion(comentario.id_evento_puesto_anexo_observacion_revision,6); //se manda 6 que es el estatus de revisado
        comentario.fromJS({isSaving:false});
        if(error) AlertDialog({message});   
        else {
            dataSource.reload();
            comentario.clear();
            data.refreshFromModal(data.anexo_id.id);
        }  
    }

    const handleChageRevisionEstatus = async(id_evento_puesto_anexo_revision,estatus)=>{
        comentario.fromJS({isSaving:true});
        console.log("handleChageRevisionEstatus", isRevision);
        const {data:{error,message,data:response}} = isRevision ? await postFinishRevisionObservacion(id_evento_puesto_anexo_revision,estatus) : await postFinishAceptacionObservacion(id_evento_puesto_anexo_revision,estatus)
        comentario.fromJS({isSaving:false});
        if(error) AlertDialog({message});   
        else {
            dataSource.reload();
            comentario.clear();
            data.refreshFromModal(data.anexo_id.id);
        }  
    }

    const canReturnAttetion = (observacion)=>{
        if(data.puesto_cerrado) return false;
        return puedeDevolver && observacion.estatus !== 4 && ((isRevision && (grupoUsuario === 4 || grupoUsuario === 2) && data.estatus_anexo != 4 && data.estatus_anexo != 6) || (!isRevision && grupoUsuario === 7));
    }

    const canChangeAttention = (observacion)=>{
        if(isRevision && data.puesto_cerrado) return false;
        if(!puedeAtender) return false;
        // Seguimiento
        if(isRevision && [GRUPO_ENTE_CAPTURA,GRUPO_ENTE_REGISTRO_LIMITADO].includes(grupoUsuario) && [4].includes(data.estatus_anexo) && [4].includes(observacion.estatus)) return true;
        // Aceptacion
        if(!isRevision && [GRUPO_ENTE_CAPTURA,GRUPO_ENTE_REGISTRO_LIMITADO].includes(grupoUsuario) && data.estatus_aceptacion === 2 && observacion.estatus === 4) return true;
        return false;
    }

    const canChangeReviewed = (observacion)=>{
        if(data.puesto_cerrado) return false;
        if(!puedeRevisar) return false;
        if([GRUPO_ENTE_REVISOR_INTERNO,GRUPO_SISTEMA_REVISOR].includes(grupoUsuario) && isRevision && [3].includes(data.estatus_anexo) && [4,5].includes(observacion.estatus)) return true;
        if(observacion.estatus !== 6 && ((isRevision && (grupoUsuario === 4 || grupoUsuario === 2) && data.estatus_anexo != 4 && data.estatus_anexo != 6) || (!isRevision && grupoUsuario === 7))) return true;
        return false;
    }

    const changeStateModal = (desc = null) =>{
        if(comentario.visible_descripcion)
            comentario.fromJS({visible_descripcion:false,descripcion:null});
        else
            comentario.fromJS({visible_descripcion:true,descripcion:desc});
    }


    const GridComponent = observer(() => {
        return <Loading isLoading ={comentario.isLoading}>
            <DataGrid key="id_evento_puesto_anexo_observacion_revision" colSpan={12}
            dataSource={dataSource}
            remoteOperations={{paging:true, sorting:true, filtering: true}}
            columnAutoWidth={true}
            repaintChangesOnly={true}
            showBorders={true} width={"100%"}
            rowAlternationEnabled={true}>
            <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                <Pager
                showPageSizeSelector={true}
                allowedPageSizes={DEFAULT_PAGE_SIZES}
                showInfo={true} />
            <Editing
                mode="row"
                useIcons={true}
            />
            <Column type="buttons" width={130} style={{fontSize:"18px"}}
                buttons={[
                    //{hint:'Editar'   ,icon:'edit',name:'edit'    ,onClick:(event) => cargarColumna(event.row.data.id)},
                    {hint:'Eliminar',icon:'trash',name:'delete'},
                    {hint: "Comentarios",icon:"far fa-comments",visible:(e)=> puedeComentar,onClick:(e)=>openComments(e)},
                    {hint: "Devolver a observación pendiente",icon:"far fa-stop-circle",visible:(e)=> canReturnAttetion(e.row.data),onClick:(e)=>handleChageRevisionEstatus(e.row.data.id_evento_puesto_anexo_observacion_revision,4)},
                    {hint: "Cambiar a observación atendida",icon:"far fa-edit",visible:(e)=> canChangeAttention(e.row.data),onClick:(e)=>handleChageRevisionEstatus(e.row.data.id_evento_puesto_anexo_observacion_revision,5)}, //se cambia a estatus 5 (observaciones atendidas)
                    {hint: "Cambiar a revisado",icon:"far fa-check-circle",visible:(e)=> canChangeReviewed(e.row.data),onClick:(e)=>handleChageRevisionEstatus(e.row.data.id_evento_puesto_anexo_observacion_revision,6)}
                ]}
            />
                <Column dataField="id_evento_puesto_anexo_observacion_revision" visible={false}/>
                <Column dataField="id_puesto_anexo" visible={false}/>
                <Column dataField="titulo" caption="Titulo"/>
                <Column dataField="id_usuario_revisor" visible={false}/>
                <Column dataField="revisor" caption="Revisor" />
                <Column dataField="estatus" caption="Estatus" visible={false} />
                <Column dataField="estatus_text" caption="Estatus"/>
                <Column dataField="fecha_creacion" caption="Fecha creación" />
                <Column dataField="fecha_ultimo_comentario" caption="Fecha último comentario"  />
                <Column dataField="descripcion" caption="Descripcion" cellRender={descripcionCellRender}/>
            </DataGrid>
        </Loading>
        })

    const PrimaryForm = observer(() => {
        return <React.Fragment id="observacionRevision">
            {
                comentario.visible_descripcion && <CustomPopUp height={'50%'} width={'65%'} visible={true} onClose={()=>changeStateModal && changeStateModal()} title={'Descripcion'}>
                    <ModalDescripcion data={comentario.descripcion}/>
                </CustomPopUp>
            }
            {
                !data.puesto_cerrado && (isRevision && (grupoUsuario === 4 || grupoUsuario === 2) && data.estatus_anexo != 4 && data.estatus_anexo != 6)
                ||
                (!isRevision && grupoUsuario === 7 && [1,3].includes(data.estatus_aceptacion)) 
                ?
                AuthCheck({
                        permission: isRevision ? "CAPTURA_OBSERV_ADD":"CAPTURA_OBSERV_ACEPT_ADD",
                        component: <div style={{marginBottom:"10px",textAlign:"right"}}>
                        <Button disabled={comentario.isLoading} style={{display:"inline-block"}} horizontalAlignment="left" text={"Agregar observación"} type={'default'}   onClick={(e)=>{
                            comentario.clear();
                            comentario.fromJS({visible:true});
                        }} />
                    </div>
                    }):<div></div>
            }

          <ModalAgregarObservacion data={comentario} commentAdded={() => {dataSource.reload(); data.refreshFromModal(data.anexo_id.id);}} changeEstatus={handleChageRevisionEstatus} finishRevaluacionCb={finalizarRevision} callback={agregarObservacion}></ModalAgregarObservacion>
            <GridComponent></GridComponent>                      
               
        </React.Fragment>
    })



    //#region subir adjunto a anexo y registros del anexo

 
 
    //#endregion

    //#region render
    return (
        <React.Fragment >
            
            <div id="observaciones" className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                
                <form >
                    <PrimaryForm>  </PrimaryForm>
                </form>
                </div>
            </div>
      </React.Fragment>
        );
    //#endregion
}