import {instance} from '../constants/defaultValues';

export function getConfiguracionSistema(){
    return instance.post(`/api/read`,{
        "VC":"sys_configuracion",
        "VA":"list"
    });
}

export function getDiasHabiles(){
    return instance.post(`/api/read`,{
        "VC":"sys_configuracion",
        "VA":"dias_asueto"
    });
}

export function postDiasHabiles(valores, valoresArchivo){
    return instance.post(`/api/write`,{
        "VC":"sys_configuracion",
        "VA":"bysystem",
        "valores":valores,
        "valoresarchivo":valoresArchivo
    });
}

export function postConfiguracionSistema(datos, uma,adeudos,usuarios){
    return instance.post(`/api/write`,{
        "VC":"sys_configuracion",    
        "VA":"bysystem",
        "configuracion" : datos,
        "uma" : uma ,
        "adeudos":adeudos,
        "usuarios":usuarios
    });
}
export function postEmailTest(datos){
    return instance.post(`/api/write`,{
        "VC":"sys_configuracion",    
        "VA":"emailtest",
        "email" : datos,
    });
}