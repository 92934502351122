import React, { useState,useEffect,useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Form, {
    ButtonItem,
    GroupItem,
    Item,
    Label,
    EmptyItem
  } from 'devextreme-react/form';
import DataGrid, {
    Column,
    Editing,
    Pager, Paging
} from 'devextreme-react/data-grid';
import AuthCheck from '../../../components/authCheck/authCheck.js';
import ReportViwer from 'components/reportviewer';
import { LoadPanel } from 'devextreme-react/load-panel';
import { 
    postEnte,
    getEnte,
    getEnteTipoBySearchTerm,
    getEnteSectorBySearchTerm,
    activateEntePublicoUnidadAdministrativa,
    postEntePublicoUnidadAdministrativa,
    deleteEntePublicoUnidadAdministrativa,
    cambiarPasswordEnte} 
from 'api';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import 'devextreme-react/autocomplete';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {DEFAULT_PAGE_SIZE_CMB,
    DEFAULT_PAGE_SIZE_ONE_GRID,
    DEFAULT_PAGE_SIZES
} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog';
import notify from 'devextreme/ui/notify';
import { Popup } from 'devextreme-react/popup';
import CustomPopUp from 'components/custompopup';
import ComponentWrapper from 'components/componentWrapper';
import { custom } from 'devextreme/ui/dialog';
import lang from '../../../lang';
const position = { of: '#usuario' }; //TODO: CSS - Se utiliza para posicionar el panel de loading

export default function (props) {
    //#region hook de control
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [isPopupColumnasOpen,setIsPopupColumnasOpen] = useState(false);

    const formRef = useRef(null);
    const formRefColumna = useRef(null);

    const viewerRefFiel = useRef();
    const reportVieweRef = useRef();

    const permCanEdit = AuthCheck({permission: "UNIDAD_EDIT", onlyValidator: true});
    const permCanDelete = AuthCheck({permission: "UNIDAD_DELETE", onlyValidator: true});
    //#endregion

    //#region hook de estado
    const anexoColumnaPlantilla = {
        "id":0,
        "nombre":'',
        "orden":null,
        "id_ente_publico":null
    };

    const [entidad,setEntidad] = useStateWithCallbackLazy({
        "id": 0,
        "nombre": '',
        "nombre_corto": '',
        "clave": 'NUEVA',
        "orden": 0,
        "id_tipo": 0,
        "id_sector": 0,
        "tipo": '',
        "sector": '',
        "columnas":[],
        "cmbtipo":{"id": null,"cmbtipo": ''},
        "cmbsector":{"id": null,"cmbsector": ''},
        "usuarioai_id": 0,
        "usuarioai": '',
        "usuarioai_email": ''
    });

    const [anexoColumna,setAnexoColumna] = useStateWithCallbackLazy({...anexoColumnaPlantilla});
    const [isEdit, setIsEdit] = useState(false);
    //#endregion

    //#region hook de montado de la vista, se utiliza para generar el estado inicial
      const cargar = async(id)=>{
        let result = {...entidad};
        setIsEdit(true);
        result = await fetchEnte(id);
        setEntidad(result);
    }
    useEffect(() => {
        let mounted = true;   
        if(props.location.state && props.location.state.id){   
            cargar(props.location.state.id);
        } 
        setIsLoading(false);      
        return () => { mounted = false; };
      },[]); 



    //#endregion

    //#region fetch
    const fetchEnte = (async(enteId) =>{
        let result = {...entidad};
        if(enteId){
            const {data:{error,message,data}} = await getEnte({enteId});
            if(error){
                AlertDialog({message: message});
            }else if(data.length){
                result.id = data[0].id;
                result.nombre = data[0].nombre;
                result.clave = data[0].clave;
                result.orden = data[0].orden;
                result.nombre_corto = data[0].nombre_corto;
                result.id_tipo = data[0].id_tipo;
                result.id_sector = data[0].id_sector;
                result.tipo = data[0].tipo;
                result.sector = data[0].sector;
                result.columnas = data[0].columnas;
                result.cmbtipo = data[0].cmbtipo;
                result.cmbsector = data[0].cmbsector;
                result.usuarioai_id = data[0].usuarioai_id;
                result.usuarioai = data[0].usuarioai;
                result.usuarioai_email = data[0].usuarioai_email;
            }
        }
        return result;
    }) 
    //#endregion


    //#region origen de datos combo enteTipo
    const fetchEnteTipos = (async ({enteTipoId = 0 ,seachterm = ''}, take = 10, skip = 0) =>{
        let result = [];
        const {data:{error,message,data}} =  await getEnteTipoBySearchTerm(seachterm, take, skip);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    })
    const dataSourceEnteTipo  = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["id"],
            load: (options) => {
                if(options.filter)
                    return fetchEnteTipos({enteTipoId:options.filter[2]}, options.take, options.skip);   
                else if(options.searchValue)
                    return fetchEnteTipos({seachterm:options.searchValue}, options.take, options.skip);     
                else 
                    return fetchEnteTipos({}, options.take, options.skip);                    
            },
            byKey: (key) => {
                return entidad.cmbtipo ? entidad.cmbtipo : [];
            }
        })
    });
    //#endregion

    //#region origen de datos combo enteSector
    const fetchEnteSectores = (async ({enteTipoId = 0 ,seachterm = ''}, take = 10, skip = 0) =>{
        let result = [];
        const {data:{error,message,data}} =  await getEnteSectorBySearchTerm(seachterm, take, skip);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    })
    const dataSourceEnteSector  = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["id"],
            load: (options) => {
                if(options.filter)
                    return fetchEnteSectores({enteTipoId:options.filter[2]}, options.take, options.skip);   
                else if(options.searchValue)
                    return fetchEnteSectores({seachterm:options.searchValue}, options.take, options.skip);     
                else 
                    return fetchEnteSectores({}, options.take, options.skip);                    
            },
            byKey: (key) => {
                return entidad.cmbsector ? entidad.cmbsector : [] ;
            }
        })
    });
    //#endregion

    //#region origen de datos unidades administrativas
    const dataSourceColumnas  = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["id"]
            ,load: (options) => entidad.columnas
            ,remove: (key) => quitarColumna(key)
        })
    });
    //#endregion

    //#region dataSource
    //#endregion

    //#region eventos interfaz
    const goToList = () =>{
      history.push({pathname:'/catalogos/ente'});
    }

    const goToUnidadAdministrativa = (unidadAdministrativa) =>{
        history.push({pathname:'/catalogos/entePublicoUnidadAdministrativa/detalle', 
            state: {
                ente_publico_id: entidad.id,
                ente_publico_nombre: entidad.nombre,
                ente_publico_nombre_corto: entidad.nombre_corto,
                unidad_administrativa_id: unidadAdministrativa? unidadAdministrativa.id :null,
                unidad_administrativa_orden: unidadAdministrativa? unidadAdministrativa.orden :null,
                unidad_administrativa_nombre: unidadAdministrativa? unidadAdministrativa.nombre :null
            }
        });
    }
    const cambiarPassword =async() =>{
        setIsLoading(true);
        const {data:{error,message,data}} = await cambiarPasswordEnte(entidad);

        if(error) {
            AlertDialog({message: message});  
        } else {
            notify({message:message}); 
        }
      
        setIsLoading(false);
    }
    //#endregion

    //#region eventos CRUD
    const handleSubmit = (async (e) => {    
       
        e.preventDefault();
        setIsLoading(true);

        const {data:{error,message,data}} = await postEnte(entidad);

        if(error) {
            AlertDialog({message: message});  
        } else {
            if (entidad.id>0) {
                goToList();
            }else {
                notify({message: "Registro guardado con éxito."});
                cargar(data[0].id);        
            }   
        }
      
        setIsLoading(false);
    });

    const recargarUnidades = async() => {
        setIsLoading(true); 
        let result = await fetchEnte(entidad.id); 
        entidad.columnas = [...result.columnas];    
        setIsLoading(false); 
    }

    function canDeleteUnidad(e){
        return e.row.data.estatus == 1 && permCanDelete;
    }

    function canEditUnidad(e){
        return e.row.data.estatus == 1 && permCanEdit;
    }

    function canActivateUnidad(e){
        return e.row.data.estatus != 1;
    }

    const mostrarUnidades = () => entidad.id > 0;

    const guardarColumna = async(e) => {  
        e.preventDefault();
        setIsLoading(true);
        const {data:{error,message}} = await postEntePublicoUnidadAdministrativa(anexoColumna);
        if(error) AlertDialog({message: message});  
        else await recargarUnidades();
        setIsLoading(false);
    }

    const quitarColumna = async(e) => {
        const {data:{error,message}} = await deleteEntePublicoUnidadAdministrativa(e.id);
        if(error) AlertDialog({message: message});  
        else await recargarUnidades();  
    }

    const activateUnidad = async(e) =>{
        let activateDialog = custom({
            showTitle: false,
            messageHtml: "¿Está seguro que desea activar este registro?",
            buttons: [
                {
                    text: "SÍ",
                    onClick: () => {
                        return true;
                    }
                }, 
                {
                    text: "NO",
                    onClick: () => {
                        return false;
                    }
                }, 
            ]
        });

        activateDialog.show().then(async (dialogResult) => {
            if(dialogResult){
                setIsLoading(true);
                const {data:{error,message}} = await activateEntePublicoUnidadAdministrativa(e.row.data.id);
                setIsLoading(false);
                if(error) AlertDialog({message: message});  
                else {
                    await recargarUnidades();
                    //dataSourceColumnas.reload();
                }
            }
        });
        
    }
    //#endregion

    //#region validaciones
    const validationRules ={
        id_tipo:[
            {type:"required",message:"Tipo de ente es obligatorio."}
        ],
        id_sector:[
            {type:"required",message:"Sector es obligatorio."}
        ],
        usuarioai:[
            {type:"required",message:"Usuario administrador es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:50}
        ],
        usuarioai_email:[
            {type:"required",message:"e-mail es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:50},
            {type:"email",message:"Formato de correo electrónico incorrecto"}
        ],
        nombre:[
            {type:"required",message:"Nombre es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:1000}
        ],
        nombre_corto:[
            {type:"required",message:"Nombre corto es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:1000}
        ],
        orden:[
            {type:"required",message:"Orden es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:1000}
        ]
    }

    const validationRulesColumnas ={ 
        nombre:[
            {type:"required",message:"Nombre es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:1000}
        ],
        orden:[
            {type:"required",message:"Orden es obligatorieo"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:10}
        ]
    }
    //#endregion

    //#region render
    return (
        <React.Fragment >
            <ComponentWrapper ref={viewerRefFiel}  component={(props)=> {
                return(
                props.visible ? 
                <CustomPopUp width={600} height={600} visible={true} title={"Registrar fiel"} onClose={()=> props && props.setProps({visible:false})}>
                
                </CustomPopUp>
                :null);
                }
            } 
            />
            <h2 className={'content-block'}>{lang.getString("ente.detail.title")}</h2>
            <div id="usuario" className={'content-block'}>
                <ReportViwer ref={reportVieweRef}/>
                <div className={'dx-card responsive-paddings'}>
                
                <form onSubmit={handleSubmit}>
                    <Form
                        ref={formRef}
                        formData={entidad}
                        readOnly={false}
                        showColonAfterLabel={true}
                        showValidationSummary={true}
                        validationGroup="validationRules"
                    >
                        <GroupItem colCount={2}>
                            <Item 
                                colSpan={4}
                                dataField="nombre"
                                stylingMode="underlined"
                                validationRules={validationRules.nombre}>
                                    <Label text={lang.getString("ente.detail.nombre")}></Label>
                            </Item>
                            <Item 
                                colSpan={4}
                                dataField="nombre_corto"
                                stylingMode="underlined"
                                validationRules={validationRules.nombre_corto}>
                                    <Label text={lang.getString("ente.detail.nombre_corto")}></Label>
                            </Item>
                            <Item 
                                colSpan={4}
                                dataField="clave"
                                disabled={true}
                                stylingMode="underlined"                                
                                validationRules={validationRules.clave}>
                                    <Label text={lang.getString("ente.detail.clave")}></Label>
                            </Item>
                            <Item 
                                colSpan={4}
                                dataField="orden"
                                stylingMode="underlined"
                                editorType="dxNumberBox"
                                validationRules={validationRules.orden}>
                                    <Label text={lang.getString("ente.detail.orden")}></Label>
                            </Item>

                            <Item
                                colSpan={4}
                                dataField="id_tipo"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    searchEnabled:true,
                                    searchMode:"contains",
                                    displayExpr: (item) => item && item.cmbtipo,                        
                                    searchExpr: (item) =>  item && item.cmbtipo,
                                    valueExpr:"id",
                                    dataSource: dataSourceEnteTipo                                    
                                }}
                                validationRules={validationRules.id_tipo}>
                                <Label text={lang.getString("ente.detail.id_tipo")}></Label>
                            </Item>

                            <Item
                                colSpan={4}
                                dataField="id_sector"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    searchEnabled:true,
                                    searchMode:"contains",
                                    displayExpr: (item) => item && item.cmbsector,
                                    searchExpr: (item) =>  item && item.cmbsector,
                                    valueExpr:"id",
                                    dataSource: dataSourceEnteSector                                    
                                }}
                                validationRules={validationRules.id_sector}>
                                <Label text={lang.getString("ente.detail.id_sector")}></Label>
                            </Item>
                        </GroupItem>
                        <GroupItem colCount={8}>
                            <Item 
                                colSpan={2}
                                dataField="usuarioai"
                                stylingMode="underlined"
                                validationRules={validationRules.usuarioai}>
                                    <Label text={lang.getString("ente.detail.usuarioai")}></Label>
                            </Item>
                            <Item 
                                colSpan={4}
                                dataField="usuarioai_email"
                                stylingMode="underlined"
                                validationRules={validationRules.usuarioai_email}>
                                    <Label text={lang.getString("ente.detail.usuarioai_email")}></Label>
                            </Item>
                            <ButtonItem horizontalAlignment="right" verticalAlignment="bottom" colSpan={2} 
                                    visible={entidad.id>0}
                                    
                                    buttonOptions={{text:'Reenviar password', type:'default', onClick: () => cambiarPassword()}}
                                />
                            
                            <Item itemType="empty"/>
                        </GroupItem>
                        <GroupItem colCount={1} visible={mostrarUnidades()}>
                            <GroupItem caption={lang.getString("ente.detail.columnas")} colCount={1}> 
                                <ButtonItem horizontalAlignment="right" colSpan={10}
                                    buttonOptions={{text:'Agregar unidades administrativas', type:'default', onClick: () => goToUnidadAdministrativa(null)}}
                                />
                            </GroupItem>

                            <GroupItem colCount={1}> 
                                <DataGrid key="orden" colSpan={12}
                                    dataSource={dataSourceColumnas}
                                    columnAutoWidth={true}
                                    repaintChangesOnly={true}
                                    showBorders={true} width={"100%"}
                                    rowAlternationEnabled={true}>
                                    <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                                        showInfo={true} />
                                    <Editing
                                        mode="row"
                                        useIcons={true}
                                        allowDeleting={AuthCheck({
                                            permission:"UNIDAD_DELETE",
                                            onlyValidator: true
                                        })}
                                        allowUpdating={AuthCheck({
                                            permission:"UNIDAD_EDIT",
                                            onlyValidator: true
                                        })}
                                    />
                                    <Column type="buttons" width={110}
                                        buttons={[
                                            {hint:'Editar'  ,icon:'edit',name:'edit', visible:canEditUnidad ,onClick:(event) => goToUnidadAdministrativa(event.row.data)},
                                            {hint:'Eliminar',icon:'trash',name:'delete', visible:canDeleteUnidad},
                                            {hint:'Activar' ,icon:'check',name:'activate', visible:canActivateUnidad, onClick:activateUnidad},    
                                          
                                        ]}
                                    />
                                    <Column dataField="id" caption="Id" visible={false} />
                                    <Column dataField="orden" caption={lang.getString("ente.detail.columnas.orden")} />
                                    <Column dataField="nombre" caption={lang.getString("ente.detail.columnas.nombre")} />
                                </DataGrid>

                            </GroupItem>
                        </GroupItem>

                       
                        <GroupItem colSpan={12}>
                            <ButtonItem horizontalAlignment="left" colSpan={2}
                                buttonOptions={{text:'Guardar',type:'success',useSubmitBehavior:true}}
                            />
                            <ButtonItem horizontalAlignment="left" colSpan={10}
                                buttonOptions={{text:'Regresar',type:'default',onClick:()=>{goToList()}}}
                            />
                        </GroupItem>
                            
                    </Form>
                    </form>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={isLoading}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
                <Popup
                    visible={isPopupColumnasOpen}
                    onHiding={() => {setIsPopupColumnasOpen(false)} }
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Unidad administrativa"
                    height={270}
                    width={470}
                    >
                    <form onSubmit={guardarColumna}>
                        <Form
                             ref={formRefColumna}
                             formData={anexoColumna}
                             readOnly={false}
                             showColonAfterLabel={true}
                             showValidationSummary={true}
                             validationGroup="validationRulesColumnas"
                        >
                            <GroupItem colCount={2}>    
                                <Item 
                                    colSpan={4}
                                    dataField="nombre"
                                    stylingMode="underlined"
                                    validationRules={validationRulesColumnas.nombre}>
                                        <Label text={lang.getString("ente.detail.unidad.nombre")}></Label>
                                </Item> 
                                <Item 
                                    colSpan={4}
                                    dataField="orden"
                                    stylingMode="underlined"
                                    editorType="dxNumberBox"
                                    validationRules={validationRulesColumnas.orden}>
                                        <Label text={lang.getString("ente.detail.unidad.orden")}></Label>
                                </Item>                               
                            </GroupItem>                            
                            
                            <GroupItem colCount={1}>    
                                <ButtonItem horizontalAlignment="left" colSpan={2}
                                    buttonOptions={{text:'Guardar',type:'success',useSubmitBehavior:true}}
                                /> 
                            </GroupItem>
                                     
                        </Form> 
                    </form> 
                </Popup>
            </div>
      </React.Fragment>
        );
    //#endregion
}