import {instance} from 'constants/defaultValues';

export function getProgresoEntregaEnte({take = 1, skip = 0, filter = {}, sort = {}, evento_id = null}){
    return instance.post(`/api/read`,{
        "VC":"consulta_nivel_ente",
        "VA":"listdata_progreso",
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc,
        "evento_id": evento_id,
    })
}

export function getEntregaRealizadaEnte({take = 1, skip = 0, filter = {}, sort = {}, evento_id = null}){
    return instance.post(`/api/read`,{
        "VC":"consulta_nivel_ente",
        "VA":"listdata_entrega",
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc,
        "evento_id": evento_id,
    })
}

