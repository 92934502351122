import React, { useState,useRef ,useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SelectBox from 'devextreme-react/select-box';
import Box, { Item}  from 'devextreme-react/box';
import DataGrid, { Column, Editing, Pager, Paging, FilterRow } from 'devextreme-react/data-grid';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {DEFAULT_PAGE_SIZE_ONE_GRID,
    DEFAULT_PAGE_SIZES,
    DEFAULT_PAGE_SIZE_CMB
} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog'
import { 
    getEventoPuestosByIdEvento,
     getEventoByidandsearchtermandidusuario,
     handleDownloadPlantilla,
     getAnexosRegistrosDescargas,
     terminoEventoPuesto,
     getEventoAnexoArchivo
    } from 'api';
import { getDataSourceFilter, getDataSourceSort } from 'utils/utility';
import lang from 'lang';
import AuthCheck from 'components/authCheck/authCheck.js';
import './index.scss';
import TerminoEntregaModal from 'components/terminoEntregaModal/index.js';
import CustomPopUp from 'components/custompopup';
import { Button } from 'devextreme-react/button';
import swal from 'sweetalert2';

export default function() {

    function labelCellRender(cellData){
        let color = '#73AD21';
        let valor = 'N/A';
        color = cellData.data.estatus_evento_color || cellData.value !== undefined ?  cellData.data.estatus_evento_color : color;
        valor = cellData.value || cellData.value !== undefined ? cellData.value : valor; 
        return(
            <h8 style={{color:"white", background: color, fontSize: "10px", borderRadius: "25px", padding: "1px 18px 1px 18px"}}>
                {valor}
            </h8>
        )
    }

    function labelCellRenderAceptacion(cellData){
        let color = '#73AD21';
        let valor = 'N/A';
        color = cellData.data.estatus_aceptacion_anexo_color || cellData.value !== undefined ?  cellData.data.estatus_aceptacion_anexo_color : color;
        valor = cellData.value || cellData.value !== undefined ? cellData.value : valor; 
        return(
            <h8 style={{color:"white", background: color, fontSize: "10px", borderRadius: "25px", padding: "1px 18px 1px 18px"}}>
                {valor}
            </h8>
        )
    }

    function notaCellRender(cellData){
    
        let nota = cellData.data.nota;
        return(
          <div>
            {nota !== null ? <Button icon="info" onClick={()=>{AlertDialog({message:nota, title:'Nota', type:'info'})}}/> : null} 
           </div>
        )
    }

    //#region hook de control
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const evento_ref = useRef(null);
    const permCanEdit = AuthCheck({permission: "REVISION_EDIT_ANEXO", onlyValidator: true});
    const permCanTerminate = AuthCheck({permission: "MISREVISIONES_FINALIZAR_ACEPTACION", onlyValidator: true});
    //#endregion

    //#region hook de estado
    const [evento,setEvento] = useState({value:null});
    const [isEdit, setIsEdit] = useState(false);
    
    const [openTerminoModal, setOpenTerminoModal] = useState(false);
    const [terminoEventoPuestoId, setTerminoEventoPuestoId] = useState(true);
    //#endregion

    //#region Cargar datos iniciales   
    useLayoutEffect (() => {  
        let mounted = true; 
        async function fetchData(){
            let result = {...evento};
            if (mounted) {                
                setIsEdit(true);
                result = (await fetchEventos({})); 
                if(result.length)
                    setEvento({value:result[0].id, nombre: result[0].nombre});
                setIsLoading(false);
            }
        }
        fetchData();
        return () => { mounted = false; };
    },[]); 
    //#endregion

    //#region fetch    
    const fetchEventos = (async ({eventoId = 0 ,seachterm = ''}, take = 10, skip = 0) =>{
        let result = [];
        const {data:{error,message,data}} =  await getEventoByidandsearchtermandidusuario(seachterm, take, skip);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    })

    const fetchPuestos = (async (id_evento, take, skip, filter, sort) =>{
        let result= {data:[], totalCount:0};
        
        const {data:{error,message,data}} = await getEventoPuestosByIdEvento(id_evento, take, skip, filter, sort);
        if(error){
            AlertDialog({message});
        }else if(data.length) {


            result = {data:data, totalCount:data.length};
            console.log('fetch puesto', result)
        }
        return result;
    })
    //#endregion

    //#region dataSource
    const dataSourceEventos = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["id"],
            load: (options) => {
                if(options.filter)
                    return fetchEventos({eventoId:options.filter[2]}, options.take, options.skip);   
                else if(options.searchValue)
                    return fetchEventos({seachterm:options.searchValue}, options.take, options.skip);    
                else 
                    return fetchEventos({}, options.take, options.skip);                 
            },
            byKey: async (key) => {
                return {id:evento.value,'nombre':evento.nombre};
            }            
        })
    });

    const dataSource = new DataSource({
        store: new CustomStore({
            key: "id_evento_puesto",
            load: (loadOptions) => {
                return fetchPuestos(
                    evento.value,
                    loadOptions.take,
                    loadOptions.skip, 
                    getDataSourceFilter(loadOptions), 
                    getDataSourceSort(loadOptions));             
            },
        })
    });

    //#endregion
    
    //#region eventos interfaz

    const goToDetail = (data = null) =>{  
        //al ir a al detalle de anexo, regresar a esta ventana con history.go(-1) o goBack()
        console.log("goToDetail>data", data)
        let params = {
            id_evento_puesto: data.id_evento_puesto,
            id_evento: data.id_evento,
            evento_nombre: data.evento_nombre,
            id_ente_publico :data.id_ente_publico,
            id_unidad_administrativa:data.id_unidad_administrativa,
            unidad_administrativa_nombre:data.unidad_administrativa_nombre,
            nombre_puesto:data.nombre_puesto,
            ev_nom:data.ev_nom
        }

        history.push({pathname:'/eventos/revisiones',state:{data:params}})
    }
    
    const eventos_onvaluechanged = (e)=>{
        setEvento({value:e}); 
    }
    function canEdit(e){
        return permCanEdit;
    }
    function canFinish(e){
        return permCanTerminate && e.row.data.estatus_aceptacion !== '2. Entrega Terminada' ;
    }

    const handleDownload=async(dato)=>{
        const {data:{data,error, message}}= await getAnexosRegistrosDescargas(dato.id_evento_puesto);
        if(error){
            AlertDialog({message})
        }else if(data.length){

            let files = [];
            data.forEach((val)=>{
                files.push(val.sysfilename);
            });

            AlertDialog({message:'Descargando adjunto...', isNotification: true, type:'success'});
            await handleDownloadPlantilla({uid:files},"anexos_registros.zip");
        }else{
            AlertDialog({message:'No existen registros de exte anexo', isNotification: true, type:'error'})
        }
        
      }

      const changeOpenTerminoModal = (id = null) =>{
        console.log('entrega terminada', id)
        setOpenTerminoModal(!openTerminoModal)
        setTerminoEventoPuestoId(id)
    }

    const terminoPuesto = async(terminoObj)=>{
        const {data:{data, error, message}} = await terminoEventoPuesto(terminoEventoPuestoId,terminoObj);
        if(error){
            AlertDialog({message:message});
        }else {
            await changeOpenTerminoModal()
            AlertDialog({message:'Entrega terminada', isNotification: true, type:'success'});
            dataSource.reload();
            
        }
    }

    //#endregion

   //#region render
    const renderPuestoCell = (cell) => {
        return <div>
                    <h6 style={{marginBottom:"4px", marginTop:"8px"}}><b>{cell.data.nombre_puesto}</b></h6>
                    <p style={{marginBottom:"8px", marginLeft:"15px"}}>{cell.data.evento_nombre}, {cell.data.unidad_administrativa_nombre}</p>
                </div>;
    } 

    function labelCellRender(cellData){
        let color = '#73AD21';
        let valor = 'N/A';
        color = cellData.data.estatus_seguimiento_color || cellData.value !== undefined ?  cellData.data.estatus_seguimiento_color : color;
        valor = cellData.value || cellData.value !== undefined ? cellData.value : valor; 
        return(
            <h8 style={{color:"white", background: color, fontSize: "10px", borderRadius: "25px", padding: "1px 18px 1px 18px"}}>
                {valor}
            </h8>
        )
    }

    function labelCellRenderAceptacion(cellData){
        let color = '#73AD21';
        let valor = 'N/A';
        color = cellData.data.estatus_aceptacion_color || cellData.value !== undefined ?  cellData.data.estatus_aceptacion_color : color;
        valor = cellData.value || cellData.value !== undefined ? cellData.value : valor; 
        return(
            <h8 style={{color:"white", background: color, fontSize: "10px", borderRadius: "25px", padding: "1px 18px 1px 18px"}}>
                {valor}
            </h8>
        )
    }

    const handleDownloadAnexos=async(evento_puesto_id = null)=>{
        swal.fire({
            title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
            text: "Por favor espere.",
            imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
            showConfirmButton: false,
            allowOutsideClick: false
        });
        const {data:{data, message, error}} = await getEventoAnexoArchivo({eventoId:evento.id, evento_puesto_id: evento_puesto_id});
        if(error){
            AlertDialog({message});
        }else{
            swal.fire({
                title: `<span style='font-size:30px;font-weight:bold'>`+  "Descarga finalizada" +`</span>`,
                showConfirmButton: false,
                icon:'success',
                timer: 1000
            });
        }
        return;
      }

    return(
        <React.Fragment>
            {
                openTerminoModal &&  
                <CustomPopUp height={'75%'} width={'70%'} visible={true} onClose={()=>changeOpenTerminoModal && changeOpenTerminoModal()} title={"Termino de entrega"}>
                    <TerminoEntregaModal onAccept={(terminoObj)=>terminoPuesto(terminoObj)}/>
                </CustomPopUp>
                
                
            }
            <h2 className={'content-block'}>{lang.getString("eventos.revisiones.puestos.index.title")}</h2>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                    
                    <div className={'area-combos'} >
                        <div>
                            <div>Evento:</div>
                            <SelectBox
                                ref={evento_ref}
                                searchEnabled = {true} 
                                searchMode    = "contains"
                                displayExpr   = {(item) => item && item.nombre}                        
                                searchExpr    = {(item) => item && item.nombre}  
                                value         = {evento.value}               
                                valueExpr     = "id"
                                dataSource    = {dataSourceEventos}  
                                onValueChange = {eventos_onvaluechanged}
                                    >
                                    </SelectBox> 
                        </div> 
                        <div>
                            <Button style={{marginTop:'7px', marginLeft: '7px', display:'inline-block', float:'left'}} visible={false} icon={'inactivefolder'} type={'default'} onClick={()=> handleDownloadAnexos(null)}/>
                        </div>
                    </div>
                   
                   
                    <Box width="100%" direction="row">
                        <Item ratio={12}>
                        <DataGrid key="id_evento_puesto"
                            dataSource={dataSource}
                            remoteOperations={{paging:true, sorting:true, filtering: true}}
                            columnAutoWidth={true}
                            repaintChangesOnly={true}
                            showBorders={true} width={"100%"}
                            rowAlternationEnabled={true}>
                                <FilterRow visible={true} showOperationChooser={false}/>
                                <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                                        showInfo={true} />
                                <Editing
                                    mode="row"
                                    useIcons={true}
                                    allowDeleting={AuthCheck({
                                        permission:"REVISION_EDIT_ANEXO",
                                        onlyValidator: true
                                    })}
                                    allowUpdating={AuthCheck({
                                        permission:"REVISION_EDIT_ANEXO",
                                        onlyValidator: true
                                    })}
                                />

                                <Column caption="Acción" type="buttons"
                                buttons={[
                                    {hint:'Editar', icon:'edit' ,name:'edit', visible:canEdit(),onClick:(event) => goToDetail(event.row.data)},
                                    {hint:'Descargar anexos', icon:'inactivefolder' ,name:'descargar anexos', onClick:(event) => handleDownloadAnexos(event.row.data.id_evento_puesto)},
                                    {hint:'Termino de entrega',icon:'check' ,visible:canFinish,  name:'termino',onClick:(event) =>{ console.log('onclick'); changeOpenTerminoModal(event.row.data.id_evento_puesto)}}
                                ]}
                                />
                                <Column dataField="id_evento_puesto" visible={false} />
                                <Column dataField="evento_nombre" caption="Puesto en revisión" cellRender={renderPuestoCell} />
                                <Column dataField="anexos_revisados" caption="Anexos revisados" width={150} alignment={'center'}/>
                                <Column dataField="anexos_pendientes" caption="Anexos por revisar" width={150} alignment={'center'}/>
                                <Column dataField="estatus_seguimiento" caption={"Estatus seguimiento"} width="190" cellRender={labelCellRender} allowFiltering={false} allowSorting={false}/>
                                <Column dataField="estatus_aceptacion" caption={"Estatus aceptación"}  width="190" cellRender={labelCellRenderAceptacion} allowFiltering={false} allowSorting={false}/>
                                <Column dataField="fecha_firma" caption="Fecha de firma"/>
                                <Column dataField="fecha_ultima_observacion" caption="Fecha ultima observación"/>
                                <Column dataField="fecha_limite_cierre" caption="Fecha limite de cierre"/>
                                <Column dataField="termino_tipo" caption="Termino" allowFiltering={false}  allowSorting={false}/> 
                                <Column dataField="nota" caption="Nota" cellRender={notaCellRender} allowFiltering={false} allowSorting={false}/>
                                </DataGrid>
                            </Item>
                    </Box>
                            
                </div>
            </div>
        </React.Fragment>
    );
    //#endregion
};
