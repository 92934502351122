import {instance} from '../constants/defaultValues';

export function getEnteTipo({enteTipoId = 0, take = 10, skip = 0, filter = {}, sort = {}}){
    if(enteTipoId && enteTipoId > 0){
        return instance.post(`/api/read`,{
            "VC":"cat_ente_tipo",
            "VA":"byid",
            "id":enteTipoId
        })
    }
    else{
        return instance.post(`/api/read`,{
            "VC":"cat_ente_tipo",
            "VA":"listdata",
            "page":skip/take,
            "pagesize":take,
            ...filter,
            "sortby": sort.selector,
            "sortdesc": sort.desc
        })
    }
}

export function postEnteTipo(enteTipo){
    return instance.post(`/api/write`,{
        "VC":"cat_ente_tipo",
        "VA":"bysystem",
        "id":       enteTipo.id,
        "nombre" :  enteTipo.nombre,
        "clave" :   enteTipo.clave,
        "orden" :   enteTipo.orden
    })
}

export function deleteEnteTipo(enteTipoId){
    return instance.post(`/api/delete`,{
        "VC":"cat_ente_tipo",
        "VA":"bysystem",
        "id" : enteTipoId
    });
}


export function activateEnteTipo(enteTipoId){
    return instance.post(`/api/write`,{
        "VC":"cat_ente_tipo",
        "VA":"activate_ente_tipo",
        "id" : enteTipoId
    });
}