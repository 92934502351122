import {instance} from '../constants/defaultValues';
import moment from 'moment';

export function getDiasAsueto(take, skip, filter, sort, ano){
    return instance.post(`/api/read`,{
        "VC":"sys_configuracion_dias_asueto",
        "VA":"list",
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc,
        "ano": parseInt(ano)
    })
}


export function postDiaAsueto(asueto, asuetoId, ano){
    return instance.post(`/api/write`,{
        "VC":"sys_configuracion_dias_asueto",    
        "VA":"bysystem",
        "id" : asuetoId !== null ? asuetoId : undefined,
        "ano" :  ano,
        "fecha_inicio" : asueto.fecha_inicio ? moment(asueto.fecha_inicio).format('YYYY-MM-DD hh:mm:ss') : undefined,
        "fecha_final" : asueto.fecha_final ? moment(asueto.fecha_final).format('YYYY-MM-DD hh:mm:ss') : undefined,
        "dia_descripcion" : asueto.dia_descripcion ? asueto.dia_descripcion : undefined
    });
}

export function deleteDiaAsueto(id){
    return instance.post(`/api/delete`,{
        "VC":"sys_configuracion_dias_asueto",    
        "VA":"bysystem",
        "id": id
    });
}