import React, { useMemo,useRef,useState,useEffect } from 'react';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useGlobal } from '../../contexts/global';
import './user-panel.scss';
import ComponentWrapper from 'components/componentWrapper';
import CustomPopUp from 'components/custompopup';
import ChangePassword from 'components/changePassword';
import AlertDialog from 'components/alertDialog/alertDialog';
import {getLoggedUserId, getCanViewPerfil} from 'api';
import { useHistory } from 'react-router-dom';
import Utils from 'utils/session';

export default function ({ menuMode }) {
  const { user: _SESSION, signOut } = useGlobal();
  const viewerRef = useRef(null);
  const history = useHistory();

  const [nombre,setNombre] = useState ("");
  let eNombre = localStorage.getItem(Utils.getSpaceNameProperty("nombre_seer"));
  

  useEffect(() => {
    let mounted = true;
    async function fetchNombre() {
        if (mounted && eNombre) {
            setNombre(await getDecryptedNombre());
        }
    }
    fetchNombre();
    return () => { mounted = false; };
},[]);

const getDecryptedNombre = async()=>{
  let nombre = ' - '+await Utils.Decrypt(eNombre);
  return nombre;
}

const getPefilVisible = async () =>{
  let result = false;
  let {data:{data, error, message}} = await getCanViewPerfil();
  console.log('getCanViewPerfil', data);
  if(error){
    AlertDialog({message})
  }else if (data.length){
    result = data[0].can_view;

    console.log('getCanViewPerfil result', result);
  }
  return result;
}


  //let rfc="3598";
  const goToDetail = (id = null) =>{
    console.log('goToDetail', id);
    history.push({pathname:'/catalogos/funcionario/detalle',state:{id, perfil:true}})
}

  
  const menuItems = useMemo(() => ([
    {
      text: 'Cerrar sesión',
      icon: 'runner',
      onClick: signOut
    },
    {
      text: 'Cambiar contraseña',
      icon: 'preferences',
      onClick: ()=>{
        viewerRef.current.setProps({visible:true});
      },
    },
    {
      text: 'Perfil',
      icon: 'group',
      onClick: async()=>{
        let userid;
        const{data:{data, message,error}} = await getLoggedUserId();
        if(error){
          AlertDialog({message})
        }else if(data.length){
          userid = data[0].id_funcionario;
          goToDetail(userid);
        }else{
          AlertDialog({message:"El usuario actual no puede acceder al perfil debido a que no se encuentra realcionado a un funcionario.", isNotification:true});
        }
      },
      visible: getPefilVisible
    }
  ]), [signOut]);

  return (
    <div className={'user-panel'}>
      <ComponentWrapper ref={viewerRef}  component={(props)=> props.visible ? 
                    <CustomPopUp height={450} width={370} visible={true} onClose={()=>props.setProps({visible:false})} title="CAMBIAR CONTRASEÑA">
                        <ChangePassword onClose={()=>props.setProps({visible:false})}/>
                </CustomPopUp> : null
                }
            />
      <div className={'user-info'}>
        <div className={'image-container'}>
          {/*<div
            style={{
              background: `url(${_SESSION.user.avatarUrl}) no-repeat #fff`,
              backgroundSize: 'cover'
            }}
            className={'user-image'} />*/}
            <span style={{fontSize:"25px"}} className="dx-icon-user"/>
        </div>
        <div className={'user-name'}>{_SESSION != null && _SESSION.user.name +nombre}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={210}
          cssClass={'user-menu'}
        >
          <Position my={'top center'} at={'bottom center'} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
    </div>
  );
}

