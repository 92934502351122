import React, { useState,useRef ,useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Box, { Item as BoxItem}  from 'devextreme-react/box';
import DataGrid, { Column, Editing, Pager, Paging, FilterRow } from 'devextreme-react/data-grid';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {
    DEFAULT_PAGE_SIZES,
    DEFAULT_PAGE_SIZE_CMB
} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog'
import { 
        getMisRevisionesByEventoPuestoId,
     getEventoByidandsearchtermandidusuario,
     handleDownloadPlantilla,
     getAnexosRegistrosDescargas
    } from 'api';
import { getDataSourceFilter, getDataSourceSort } from 'utils/utility';
import lang from 'lang';
import AuthCheck from '../../../components/authCheck/authCheck.js';
import './index.scss';
import { Button } from 'devextreme-react/button';
import Form, {
    GroupItem,
    Item,
    Label,
  } from 'devextreme-react/form';

export default function(props) {

    function labelCellRender(cellData){
        let color = '#73AD21';
        let valor = 'N/A';
        color = cellData.data.estatus_evento_color || cellData.value !== undefined ?  cellData.data.estatus_evento_color : color;
        valor = cellData.value || cellData.value !== undefined ? cellData.value : valor; 
        return(
            <h8 style={{color:"white", background: color, fontSize: "10px", borderRadius: "25px", padding: "1px 18px 1px 18px"}}>
                {valor}
            </h8>
        )
    }

    function labelCellRenderAceptacion(cellData){
        let color = '#73AD21';
        let valor = 'N/A';
        color = cellData.data.estatus_aceptacion_anexo_color || cellData.value !== undefined ?  cellData.data.estatus_aceptacion_anexo_color : color;
        valor = cellData.value || cellData.value !== undefined ? cellData.value : valor; 
        return(
            <h8 style={{color:"white", background: color, fontSize: "10px", borderRadius: "25px", padding: "1px 18px 1px 18px"}}>
                {valor}
            </h8>
        )
    }

    //#region hook de control
    const history = useHistory();
    const evento_ref = useRef(null);
    const permCanEdit = AuthCheck({permission: "REVISION_EDIT_ANEXO", onlyValidator: true});
    //#endregion

    //#region hook de estado
    const [evento,setEvento] = useState({value:null});
    const [grupo,setGrupo] = useState({value:localStorage.getItem('grupo_id')});
    const [data,setData] = useState({
        "id_evento_puesto": null,
            "id_evento": null,
            "evento_nombre": null,
            "id_ente_publico" : null,
            "id_unidad_administrativa": null,
            "unidad_administrativa_nombre": null,
            "nombre_puesto": null,
            "ev_nom":null
    })
    //#endregion

    //#region Cargar datos iniciales   
    useLayoutEffect (() => {  
        let mounted = true; 
        async function fetchData(){
            if (mounted) {           
                if(props.location.state && props.location.state.data){
                   await setData(props.location.state.data);
                   dataSource.reload();
                }
            }
        }
        fetchData();
        return () => { mounted = false; };
    },[]); 
    //#endregion

    //#region fetch    
    const fetchEventos = (async ({eventoId = 0 ,seachterm = ''}, take = 10, skip = 0) =>{
        let result = [];
        const {data:{error,message,data}} =  await getEventoByidandsearchtermandidusuario(seachterm, take, skip);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    })

    const fetchAnexos = (async (id_evento_puesto, id_grupo, take, skip, filter, sort,ente_id) =>{
        let result= {data:[], totalCount:0};
        
        const {data:{error,message,data}} = await getMisRevisionesByEventoPuestoId(id_evento_puesto, id_grupo, take, skip, filter, sort,ente_id);
        if(error){
            AlertDialog({message});
        }else if(data.length) {

            console.log('fetchAnexos', data);
            result = data[0];
        }
        return result;
    })
    //#endregion

    //#region dataSource
    const dataSourceEventos = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["id"],
            load: (options) => {
                if(options.filter)
                    return fetchEventos({eventoId:options.filter[2]}, options.take, options.skip);   
                else if(options.searchValue)
                    return fetchEventos({seachterm:options.searchValue}, options.take, options.skip);    
                else 
                    return fetchEventos({}, options.take, options.skip);                 
            },
            byKey: async (key) => {
                return {id:evento.value,'nombre':evento.nombre};
            }            
        })
    });

    const dataSource = new DataSource({
        store: new CustomStore({
            key: "id",
            load: (loadOptions) => {
                return fetchAnexos(
                    data.id_evento_puesto,
                    grupo.value,
                    loadOptions.take,
                    loadOptions.skip, 
                    getDataSourceFilter(loadOptions), 
                    getDataSourceSort(loadOptions),
                    data.id_ente_publico
                    );             
            },
        })
    });

    //#endregion
    
    //#region eventos interfaz

    const goToDetail = (data = null) =>{  
        let params = {
            id_evento_puesto: props.location.state.data.id_evento_puesto,
            id_evento:data.id_evento,
            id_ente_publico:data.id_ente_publico,
            id_unidad_administrativa:data.id_unidad_administrativa,
            id_puesto:data.id_puesto,
            evento_nombre: props.location.state.data.evento_nombre,
            unidad_administrativa_nombre:props.location.state.data.unidad_administrativa_nombre,
            nombre_puesto:props.location.state.data.nombre_puesto,
            ev_nom:props.location.state.data.ev_nom,
            id:data.id_anexo,
            isMisRevisiones: true}

        console.log("goToDetail>data", params)

        history.push({pathname:'/eventos/capturas/detalle',state:{data:params}})
    }
    
    const eventos_onvaluechanged = (e)=>{
        setEvento({value:e}); 
        
        //const grupo_id = localStorage.getItem('grupo_id')
        //alert(grupo_id)
        //cargar listado

        //dataSource.reload();
    }

    function openComments(e){
        AlertDialog({type: "info", title: 'No aplica - Justificación', message: e.justificacion});
    }

    function canComentarios(e){
        return e.aplica == 0;
    }

    function canEdit(e){
        return permCanEdit && e.aplica == 1;
    }

    const handleDownload=async(dato)=>{
        const {data:{data,error, message}}= await getAnexosRegistrosDescargas(dato.anexo_id);
        if(error){
            AlertDialog({message})
        }else if(data.length){

            let files = [];
            data.forEach((val)=>{
                files.push(val.sysfilename);
            });

            AlertDialog({message:'Descargando adjunto...', isNotification: true, type:'success'});
            await handleDownloadPlantilla({uid:files},"anexos_registros.zip");
        }else{
            AlertDialog({message:'No existen registros de exte anexo', isNotification: true, type:'error'})
        }
        
      }

    //#endregion

   //#region render
    return(
        <React.Fragment>
            <h2 className={'content-block'}>{lang.getString("eventos.revisiones.index.title")}</h2>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                    <Form
                        formData={data}
                        readOnly={false}
                        showColonAfterLabel={true}
                        showValidationSummary={true}
                    >
                         <GroupItem colCount={12} >
                            <Item 
                                colSpan={6}
                                dataField="ev_nom"
                                stylingMode="underlined"
                                editorType="dxTextBox"
                                editorOptions={{
                                    maxLength:250, 
                                    disabled:true
                                }}
                            >
                                <Label text="Evento"/>
                            </Item>
                            <Item 
                                colSpan={6}
                                dataField="evento_nombre"
                                stylingMode="underlined"
                                editorType="dxTextBox"
                                editorOptions={{
                                    maxLength:250, 
                                    disabled:true
                                }}
                            >
                                <Label text="Ente publico"/>
                            </Item>
                            <Item 
                                colSpan={6}
                                dataField="nombre_puesto"
                                stylingMode="underlined"
                                editorType="dxTextBox"
                                editorOptions={{
                                    maxLength:250, 
                                    disabled:true
                                }}
                            >
                                <Label text="Nombre puesto"/>
                            </Item>
                            <Item 
                                colSpan={6}
                                dataField="unidad_administrativa_nombre"
                                stylingMode="underlined"
                                editorType="dxTextBox"
                                editorOptions={{
                                    maxLength:250, 
                                    disabled:true
                                }}
                            >
                                <Label text="Unidad adminiastrativa"/>
                            </Item>
                         </GroupItem>
                        

                        

                    </Form>
                    
                    <Box width="100%" direction="row">
                        <BoxItem ratio={1}>
                            <DataGrid key="id"
                            dataSource={dataSource}
                            remoteOperations={{paging:true, sorting:true, filtering: true}}
                            columnAutoWidth={true}
                            repaintChangesOnly={true}
                            showBorders={true} width={"100%"}
                            rowAlternationEnabled={true}>
                                <FilterRow visible={true} showOperationChooser={false}/>
                                <Paging defaultPageSize={50} />
                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                                        showInfo={true} />
                                <Editing
                                    mode="row"
                                    useIcons={true}
                                    allowDeleting={AuthCheck({
                                        permission:"REVISION_EDIT_ANEXO",
                                        onlyValidator: true
                                    })}
                                    allowUpdating={AuthCheck({
                                        permission:"REVISION_EDIT_ANEXO",
                                        onlyValidator: true
                                    })}
                                />
                                <Column caption="Acción" type="buttons" width={120}
                                buttons={[
                                    {hint: "Comentarios",icon:"far fa-comments",visible:(e)=> canComentarios(e.row.data),onClick:(e)=>openComments(e.row.data)},
                                    {hint:'Editar',icon:'edit' ,name:'edit', visible:(e) => canEdit(e.row.data),onClick:(event) => goToDetail(event.row.data)},
                                    {hint:'Descargar adjuntos registros',icon:'download' ,name:'descarga', onClick:(event) => handleDownload(event.row.data)},
                                ]}
                                />
                                <Column dataField="id" visible={false} />
                                <Column dataField="ente_publico"  caption={lang.getString("captura.caption.ente_publico") } visible={false}/>
                                <Column dataField="unidad_administrativa" caption={lang.getString("captura.caption.unidad_administrativa")} visible={false} />
                                <Column dataField="puesto" caption={lang.getString("captura.caption.puesto")} visible={false}/>
                                <Column dataField="anexo_clave" caption={lang.getString("captura.caption.anexo_clave")} />
                                <Column dataField="anexo" caption={lang.getString("captura.caption.anexo")} />
                                <Column dataField="estatus_evento" caption={lang.getString("captura.caption.estatus_anexo")} width="190" cellRender={labelCellRender} allowSorting={false}/>
                                <Column dataField="estatus_aceptacion_anexo" caption={"Estatus aceptación"}  width="190" cellRender={labelCellRenderAceptacion} allowSorting={false}/>
                                
                                </DataGrid>
                        </BoxItem>   
                    </Box>
                    <Button horizontalAlignment="left" style={{marginTop:'7px', marginLeft:"5px"}} width="200" text='Regresar' type='default' onClick={()=>history.go(-1)} />
                </div>
            </div>
        </React.Fragment>
    );
    //#endregion
};
