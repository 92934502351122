import {instance} from '../constants/defaultValues';

export async function uploadFiles(files,callbackprogress){
    if(files){    
        let formData = new FormData();
        files.forEach((val)=>{
            formData.append(val.name,val);
        })
        return instance.post(`/api/file/upload`, formData, {
            headers:{'Content-Type': 'multipart/form-data'},
            onUploadProgress:  ( progressEvent ) => {
                callbackprogress && callbackprogress(parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 )));
                !callbackprogress && console.log('UploadProgress :',parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 )));
            }
        })   
    }
}

export const downloadPlantilla = async  (parameters,callbackprogress) =>{
    return instance.post(`/api/file/download`,parameters,
    {
        responseType: 'arraybuffer',
        onDownloadProgress: (evt) => {
            callbackprogress && callbackprogress(parseInt( Math.round( parseInt((evt.loaded / evt.total) * 100) )));
        }
    })
}

export const handleDownloadPlantilla = (parameters,name,callbackprogress)=>{
    try{
        downloadPlantilla(parameters,callbackprogress).then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', name); 
            document.body.appendChild(link);
            link.click();
            link.remove();
        }); 
    }catch(err){
        console.log("we",err);
    }
     
}

export const handleDownloadUrl = async (parameters,type,callbackprogress)=>{
    try{
      const {data} = await downloadPlantilla(parameters,callbackprogress);
      const downloadUrl = window.URL.createObjectURL(new Blob([data],{type:type}));
      return downloadUrl;
    }catch(err){
        console.log("we",err);
        return null;
    }     
}



export function getFile({fileId = 0}){
    if(fileId){
        let ent = instance.post(`/api/read`,{
            "VC": "sys_files",
            "VA": "byid",
            "id": fileId
          })
        return ent;
    }
    else{
        return null
    }
}

export async function downloadFile(fileId, callbackprogress){
    const info = async ()=> {
            return instance.post(`/api/read`,{
                "VC": "sys_files",
                "VA": "byid",
                "id": fileId
      })};

    let _info = await info();

    let parameters = {
        systemFile:true,uid:_info.data.data[0].sysfilename,
    }

    let resultado = await instance.post(`/api/file/download`,parameters,
        {
            responseType: 'arraybuffer',
            onDownloadProgress: (evt) => {
                callbackprogress && callbackprogress(parseInt( Math.round( parseInt((evt.loaded / evt.total) * 100) )));
            }
        })
    resultado["filename"] =     _info.data.data[0].filename;
    resultado["sysfilename"] =  _info.data.data[0].sysfilename;
   
    return resultado;

}


