import React,{useRef } from 'react';
import {
    DEFAULT_PAGE_SIZE_ONE_GRID,
    DEFAULT_PAGE_SIZES,
} from 'constants/defaultValues';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { getDataSourceFilter, getDataSourceSort } from 'utils/utility'
import {getMissingPuestoUnidadEnteEvento} from 'api';
import AlertDialog from 'components/alertDialog/alertDialog';
import lang from 'lang';
import { Button } from 'devextreme-react/button';
import DataGrid, { Column, Editing, Pager, Paging,Selection, FilterRow} from 'devextreme-react/data-grid';

export default function({parameters,onAccept=()=>{}}){

    const grdRef = useRef();

    const fetchPuestos = (async (take, skip, filter, sort) =>{
        let result= {data:[], totalCount:0};
        const {data:{error,message,data}} = await getMissingPuestoUnidadEnteEvento({parameters,take, skip, filter, sort});
        if(error){
            AlertDialog({message});
        }else if(data.length && data[0].totalCount) {
            result = data[0];
            return result;
        }
        //AlertDialog({message:"La unidad administrativa, no tiene puestos o todos los puestos ya fueron asignados al evento."});
        return result;
    })

    const datasource = new DataSource({
        store: new CustomStore({
            key: "id_puesto",
            load: (loadOptions) => {
                return fetchPuestos(
                    loadOptions.take, 
                    loadOptions.skip, 
                    getDataSourceFilter(loadOptions), 
                    getDataSourceSort(loadOptions));             
            }
        })
    });

   const handleSubmit  = ()=>{
        let selectedKeys = grdRef.current.instance.getSelectedRowKeys();
        if(selectedKeys.length > 0){
            let selectedRowsData = grdRef.current.instance.getSelectedRowsData();
            onAccept && onAccept(selectedRowsData, grdRef);
        }else{
            AlertDialog({message: "No se ha seleccionado ningun elemento.",isNotification:true, type:"error"})
        }
        
   }

    return(
        <div>
            <Button
                type="default"
                text="Añadir puesto"
                onClick={()=>{handleSubmit()}}
            />
            <div style={{marginBottom:"15px"}}/>
            <DataGrid 
                key="id_puesto"
                ref={grdRef}
                dataSource={datasource}
                remoteOperations={{ paging: true, sorting: true, filtering: true }}
                showBorders={true}
                columnAutoWidth={true}
                rowAlternationEnabled={true}
                noDataText={"La unidad administrativa, no tiene puestos o todos los puestos ya fueron asignados al evento."}>
                <FilterRow visible={true} showOperationChooser={false}/>
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                        <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                        showInfo={true} />
                <Editing
                    mode="row"
                    useIcons={true}
                    allowDeleting={false}
                    allowUpdating={false}
                />
                <Selection
                    mode="multiple"
                    selectAllMode={'page'}
                    showCheckBoxesMode={'onClick'}
                />
                <Column dataField="id_puesto" caption="Id" visible={false} />
                    <Column dataField="clave" caption="Clave" />
                    <Column dataField="nombre" caption="Nombre del puesto"/>
                    <Column dataField="jerarquia" caption="Jerarquía"/>
                    <Column dataField="funcionario_saliente" caption="Funcionario saliente"/>
                    <Column dataField="id_funcionario" caption="" visible={false} />
                    <Column dataField="estatus" allowSorting={false} cellRender={(e)=> e.data.estatus === 1 ? 'Activo' : 'Cancelado'}
                        caption={lang.getString("catalogo.caption.estatus")}
                        allowFiltering={false}>
                    </Column>
            </DataGrid>
            
        </div>
    )
}