import React,{Suspense} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useRoutes } from 'contexts/routes';
import { buildRoutes } from './../../app-routes';
import LoadPanel from 'devextreme-react/load-panel';

export default () =>{
    const { routes } = useRoutes();
    const routesInstace = buildRoutes(routes);
    console.log('Verify',routesInstace,routesInstace.filter(x=> x.layout === 'verify' ));
    return (
    <Suspense fallback={<LoadPanel visible={true} />}>
        <Switch>
          {routesInstace.filter(x=> x.layout === '/verify' ).map(({ path, component }) => (
            <Route
              exact
              key={path}
              path={path}
              component={component}
            />
          ))}
        </Switch>
      </Suspense>
    )
}