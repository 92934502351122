import {instance} from '../constants/defaultValues';

export function postAnexoColumna(anexoColumna){

    return instance.post(`/api/write`,{
        "VC":"cat_anexo_columna",
        "VA":"bysystem",
        "id":anexoColumna.id,
        "id_anexo":anexoColumna.id_anexo,
        "nombre": anexoColumna.nombre,
        "descripcion": anexoColumna.descripcion,
        "id_tipo_dato": anexoColumna.id_tipo_dato,
        "dato_longitud": anexoColumna.dato_longitud,
        "columna_longitud": 10,//anexoColumna.columna_longitud,
        "obligatorio" : anexoColumna.obligatorio,
        "orden" : anexoColumna.orden,
        "estatus": 1,
    })
}

export function deleteAnexoColumna(anexoColumnaId){
    return instance.post(`/api/delete`,{
        "VC":"cat_anexo_columna",
        "VA":"bysystem",
        "id" : anexoColumnaId
    });
}

export function activateAnexoColumna(anexoColumnaId){
    return instance.post(`/api/write`,{
        "VC":"cat_anexo_columna",
        "VA":"activate",
        "id" : anexoColumnaId
    });
}