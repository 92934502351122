import {instance} from './../constants/defaultValues';

export function getGrupoPermiso(take, skip, filter, sort){
    return instance.post(`/api/read`,{
        "VC":"seg_usuario_grupo",
        "VA":"list",
        "page":skip/take,
        "pagesize":take,
        ...filter,
    });
}

export function getGrupoPermisoById(grupoId){
    return instance.post(`/api/read`,{
        "VC":"seg_usuario_grupo",
        "VA":"byid",
        "grupo_id":grupoId,
    })
}

export function getGrupoPermisoByUsuarioId(){
    return instance.post(`/api/read`,{
        "VC":"seg_usuario_grupo",
        "VA":"listbyusuario"
    })
}

export function postGrupoPermiso(grupoPermiso){ //TODO:falta validacion de undefined
    return instance.post(`/api/write`,{
        "VC":"seg_usuario_grupo",
        "VA":"bysystem",
        "grupo_id":grupoPermiso.id ? grupoPermiso.id : undefined,
        "grupo_nombre" : grupoPermiso.grupo_nombre,
        "grupo_permisos": grupoPermiso.grupoPermiso,
    })
}

export function deleteGrupoPermisos(grupoId){
    return instance.post(`/api/delete`,{
        "VC":"seg_usuario_grupo",
        "VA":"bysystem",
        "grupo_id" : grupoId
    });
}