import {instance} from 'constants/defaultValues';

export function getProgresoEntregaPuesto({take = 1, skip = 0, filter = {}, sort = {}, evento_id = null, ente_id =null}){
    return instance.post(`/api/read`,{
        "VC":"consulta_nivel_puesto",
        "VA":"listdata_progreso",
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc,
        "evento_id": evento_id,
        "ente_id": ente_id
    })
}

export function getEntregaRealizadaPuesto({take = 1, skip = 0, filter = {}, sort = {}, evento_id = null}){
    return instance.post(`/api/read`,{
        "VC":"consulta_nivel_puesto",
        "VA":"listdata_entrega",
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc,
        "evento_id": evento_id,
    })
}

export function getHistorialPuesto({take = 1, skip = 0, filter = {}, sort = {}, ente_id = null, unidad_id = null, puesto_id = null}){
    return instance.post(`/api/read`,{
        "VC":"consulta_nivel_puesto",
        "VA":"listdata_historial",
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc,
        "ente_id": ente_id,
        "unidad_administrativa_id" : unidad_id,
        "puesto_id" : puesto_id
    })
}

