import {instance} from '../constants/defaultValues';

export function getBibliotecaDocumento({bibliotecaDocumentoId = 0, take = 10, skip = 0, filter = {}, sort = {}}){
    if(bibliotecaDocumentoId && bibliotecaDocumentoId > 0){
        return instance.post(`/api/read`,{
            "VC":"cat_biblioteca_documento",
            "VA":"byid",
            "id":bibliotecaDocumentoId
        })
    }
    else{
        return instance.post(`/api/read`,{
            "VC":"cat_biblioteca_documento",
            "VA":"listdata",
            "page":skip/take,
            "pagesize":take,
            ...filter,
            "sortby": sort.selector,
            "sortdesc": sort.desc
        })
    }
}

export function postBibliotecaDocumento(bibliotecaDocumento){
    return instance.post(`/api/write`,{
        "VC":"cat_biblioteca_documento",
        "VA":"bysystem",
        "id":          bibliotecaDocumento.id,
        "nombre":      bibliotecaDocumento.nombre,
        "id_adjunto" : bibliotecaDocumento.id_adjunto,
        "orden" :      bibliotecaDocumento.orden,
    })
}

export function deleteBibliotecaDocumento(bibliotecaDocumentoId){
    return instance.post(`/api/delete`,{
        "VC":"cat_biblioteca_documento",
        "VA":"bysystem",
        "id" : bibliotecaDocumentoId
    });
}

export function activateBibliotecaDocumento(bibliotecaDocumentoId){
    return instance.post(`/api/write`,{
        "VC":"cat_biblioteca_documento",
        "VA":"activate",
        "id" : bibliotecaDocumentoId
    });
}

export function adjuntoBibliotecaDocumento(bibliotecaDocumento){
    return instance.post(`/api/write`,{
        "VC":"cat_biblioteca_documento",
        "VA":"adjunto",
        "id":bibliotecaDocumento.id,
        "id_adjunto":bibliotecaDocumento.id_adjunto,
    })
}