import React ,{useState} from 'react';
import { DataGrid, Column,Editing } from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import AlertDialog from 'components/alertDialog/alertDialog'
import {
    getPuestosAnexos,
    postJustificacionEventoAnexo,
    getEventoPuestoAnexoHeaders
} from 'api';
import { LoadPanel } from 'devextreme-react/load-panel';
import swal from 'sweetalert2';
import{
  STRING_ALERTDIALOG_CREATE_SUCCESS
} from 'constants/defaultValues';
import AuthCheck from 'components/authCheck/authCheck.js';

const position = { of: '#masterDetail' };
  export default function(props){

    const history = useHistory();
    const [puestoBloqeado] = useState(props.data.data.bloqueo);
    const [puestoCerrado] = useState(props.data.data.cerrado);
    const [isLoading,setIsLoading] = useState(false);

    const permEditar    = AuthCheck({permission: "EVENT_ANEXO_EDIT", onlyValidator: true});
    const permNoAplica    = AuthCheck({permission: "EVENT_ANEXO_NOAPLICA", onlyValidator: true});

    
    const fetchData = (async () => {
        let parameters = props.data.key;
        let result = [];
        const { data: { error, message, data } } = await getPuestosAnexos(parameters);
        if (error) {
          AlertDialog({ message: message });
        } else if (data.length) {
          console.log('getPuestosAnexos', data);
          result = data;
        }
        return result;
      })

      const dataSource = new DataSource({
        paginate:false,
        store: new CustomStore({
          key: "id_evento_anexo",
          load: (loadOptions) => {
            return fetchData();
          }
        })
      });

      const handleAplica = async(id_evento_anexo,aplica)=>{

        let message = aplica ? '¿Esta seguro de cambiar a "No Aplica" el anexo?' : '¿Esta seguro de cambiar a "Aplica" el anexo?';
        let auxTitle =  message? `<span style='font-size:30px;font-weight:bold'>`+ message +`</span>`: '';
        swal.fire({
          title: auxTitle,
          showCancelButton: true,
          confirmButtonText: 'Aceptar',
        }).then(async(result) => {
          if (result.isConfirmed) {
            if(aplica){
              await swal.fire({
                input: 'textarea',
                inputAttributes:{required:"true"},
                inputLabel: 'Justificación',
                inputPlaceholder: 'Ingrese la justificación aqui...',
                confirmButtonText:"Aceptar",
                confirmButtonColor:"#009688",
                cancelButtonText:"Cancelar",
                inputValidator: (value) => {
                  return new Promise((resolve) => {
                    if (value) {
                      resolve()
                    } else {
                      resolve('Debe agregar una justificación')
                    }
                  })
                },
                showCancelButton: true
              }).then(async (result) => {
                if(result.isConfirmed && result.value){
                  try{
                    setIsLoading(true);
                    const {data:{error,message}} = await postJustificacionEventoAnexo(id_evento_anexo,result.value);
                    setIsLoading(false);
                        if(error) AlertDialog({message});
                        else AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true });
                        
                  }catch(error){
                    setIsLoading(false);
                    AlertDialog({message:"Ha ocurrido un error al tratar actualizar la justificación"})
                  }
                }
            })
    
            }
            else{
              try{
                setIsLoading(true);
                const {data:{error,message}} = await postJustificacionEventoAnexo(id_evento_anexo,"");
                setIsLoading(false);
                    if(error) AlertDialog({message});
                    else AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true });
                    
              }catch(error){
                setIsLoading(false);
                AlertDialog({message:"Ha ocurrido un error al tratar actualizar la justificación"})
              }
            }
          } 
        });
      }
      

      return (
        <React.Fragment id="masterDetail">

          <div className="master-detail-caption" style={{marginBottom:"20px"}}>

          </div>
          <DataGrid
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            onRowPrepared={(e)=>{
              if(e.rowType === 'data' && !e.data.aplica){
                e.rowElement.style.backgroundColor = '#FFCECE';  
                e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");  
              }
            }}
            paging={false}
            rowAlternationEnabled={true}>
              <Editing
                mode="row"
                useIcons={true}
                allowDeleting={false}
                />
            <Column type="buttons" width={150} visible={!puestoCerrado || localStorage.getItem('grupo_id') == 9}
            buttons={[
                { hint: "Aplica", icon: 'fas fa-tasks',visible:(e)=> !puestoBloqeado && !e.row.data.aplica && permNoAplica,
                onClick:(e)=>handleAplica(e.row.data.id_evento_anexo,e.row.data.aplica) },
                { hint: "No aplica", icon: 'fas fa-tasks',visible:(e)=>  !puestoBloqeado && e.row.data.aplica && permNoAplica && [1,2].includes(e.row.data.estatus)
                ,onClick:(e)=>handleAplica(e.row.data.id_evento_anexo,e.row.data.aplica) },
                { hint: "Editar", icon: 'fas fa-pencil-alt', visible:(e)=> permEditar, onClick: async (e)=>{ 
                    setIsLoading(true);
                    const {data:{error,message,data}} = await getEventoPuestoAnexoHeaders({eventos_puestos_anexos_id: e.row.data.id_evento_anexo});
                    if(error) {
                      AlertDialog({message});
                      setIsLoading(false);
                    } else if(data.length){
                      history.push({pathname:'/eventos/capturas/detalle',state:{data: {...data[0], isEvento: true}}})
                    }
                  } 
                }
            ]}/>
            <Column dataField="clave" caption="Clave" width={100} />
            <Column dataField="id_evento_anexo" visible={false} />
            <Column dataField="nombre_anexo" caption="Anexo" width={500}/>
            <Column dataField="nombre_rubro" caption="Rubro" width={500} alignment="center"/>
            <Column dataField="aplica" caption="Aplica" alignment="center" width={100} data="boolean" />
            <Column dataField="justificacion" caption="Justificación" alignment="center" width={300} />
            <Column dataField="estatus_anexo" caption="Estatus anexo" alignment="center" width={300}  />
            <Column dataField="estatus_aceptacion" caption="Estatus aceptación" alignment="center" width={300}/>
          </DataGrid>
          <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={position}
                visible={isLoading}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false}
                />
        </React.Fragment>
      );
  }


