import React, { useState,useEffect,useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Form, {
    ButtonItem,
    GroupItem,
    Item,
    Label,
  } from 'devextreme-react/form';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import {getFuncionario,postFuncionario,adjuntoFuncionario,getFile} from 'api'
import {cargarArchivo,descargarArchivo} from 'utils/utility.js';
import 'devextreme-react/autocomplete';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import AlertDialog from 'components/alertDialog/alertDialog'
import DataGrid, {Column, Pager, Paging} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {DEFAULT_PAGE_SIZES,DEFAULT_PAGE_SIZE_CMB} from 'constants/defaultValues';
import lang from 'lang';
const position = { of: '#usuario' }; //TODO: CSS - Se utiliza para posicionar el panel de loading
const grupoUsuario = localStorage.getItem('grupo_id');


export default function (props) {
    const PAGE_SIZE_GRID = 5;
    //#region hook de control
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const formRef = useRef(null);
    //#endregion

    //#region hook de estado
    const [funcionario,setFuncionario] = useStateWithCallbackLazy({
        "id":0,
        "prefijo":'',
        "nombre":'',
        "apellido_paterno":'',
        "apellido_materno":'',
        "rfc":'',
        "curp":'',
        "correo":'',
        "fecha_nacimiento":new Date(),
        "flag_permiso_admin":false,
        "flag_permiso_revisor":false,
        "orden":0,
        "id_usuario":null,
        "id_adjunto": null,
        "nombre_adjunto": "",
        "adjunto": [],
        "estatus":1,
        "puestos": [],
        "historial":[],
    });

    const [isEdit, setIsEdit] = useState(false);
    const [visible, setVisible] = useState(false);
    
    //#endregion
    
    //#Cargar datos iniciales
    useEffect(() => {
        let mounted = true; 
        async function fetchData(){
            let result = {...funcionario};
            if (mounted) { 
                if(props.location.state && props.location.state.id){   
                    setIsEdit(true);
                    result = await fetchFuncionario(props.location.state.id); 
                    setFuncionario(result);    
                }   
                setIsLoading(false);
            }
        }
        fetchData();
        return () => { mounted = false; };
      },[]); 
    //#endregion

    //#region fetch
    const fetchFuncionario = (async(funcionarioId) =>{
        let result = {...funcionario};
        if(funcionarioId){
           
            const {data:{error,message,data}} = await getFuncionario({funcionarioId});
            if(error){
                AlertDialog({message: message});
            }else if(data.length){
                result.id = data[0].id;
                result.prefijo = data[0].prefijo;
                result.nombre = data[0].nombre;
                result.apellido_paterno = data[0].apellido_paterno;
                result.apellido_materno = data[0].apellido_materno;
                result.rfc = data[0].rfc;
                result.curp = data[0].curp;
                result.correo = data[0].correo;
                result.fecha_nacimiento = data[0].fecha_nacimiento;
                result.flag_permiso_admin   = (data[0].flag_permiso_admin   == "1") ;
                result.flag_permiso_revisor = (data[0].flag_permiso_revisor == "1") ;
                result.orden = data[0].orden;
                result.id_adjunto = data[0].id_adjunto;
                result.nombre_adjunto = data[0].nombre_adjunto;
                result.puestos = data[0].puestos;
                result.id_usuario = data[0].id_usuario;
                result.historial = data[0].historial;
            }
        }
        return result;
    })
    //#endregion

    //#region origen de datos
    const dataSourcePuestos  = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["id"]
            ,load: (options) => funcionario.puestos
        })
    });

    const dataSourceHistorial  = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["id"]
            ,load: (options) => funcionario.historial
        })
    });
    //#endregion
    
    //#region eventos interfaz
    const goToList = () =>{
        history.push({pathname:'/catalogos/funcionario'});
      }

    const goBack =()=>{
        history.push({pathname:'/eventos/capturas'});
    }
    //#region eventos CRUD
    const handleSubmit = (async (e) => {    
        e.preventDefault();
        setIsLoading(true);

        const {data:{error,message}} = await postFuncionario(funcionario);
        if(error) AlertDialog({message: message});  
        else     {
            if(props.location.state.perfil)
                goBack();
            else 
                goToList();
        } 
        
        setIsLoading(false);
    });
    //#endregion

    //#region validaciones
    const customCallback =(e) => {
        if (visible) 
            return true;
        else
            return false;
    }

    //#region archivo
    const cargar =  () => {
        const alCargarArchivo = (async(idAdjunto)=>{   
            funcionario.id_adjunto = idAdjunto;
    
            let file = await getFile({fileId: idAdjunto});
            funcionario.nombre_adjunto = file.data.data[0].filename;
    
            if (funcionario.id>0){
                const {data:{error,message}} = await adjuntoFuncionario(funcionario)
                if(error){
                    AlertDialog({message});
                    funcionario.id_adjunto = null;
                    funcionario.nombre_adjunto = "";    
                } 
            }
    
            setFuncionario({...funcionario});
        });

        cargarArchivo('.pdf,.png,.gif,.jpeg,.jpg', alCargarArchivo, setIsLoading);
    } 
    
    const quitarAdjunto = async() => {
        funcionario.id_adjunto     = null;
        funcionario.nombre_adjunto = "";
        
        const {data:{error,message}} = await adjuntoFuncionario(funcionario)
        if(error){
            AlertDialog({message});
            funcionario.id_adjunto = null;
            funcionario.nombre_adjunto = "";    
        } 
        
        setFuncionario({...funcionario});
    } 


    const descargar = (async()=>{
        if (funcionario.id_adjunto) descargarArchivo(funcionario.id_adjunto, setIsLoading)
    });
    //#endregion

    const validationRules ={    
        prefijo:[
            {type:"required",message:"Prefijo es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:255}
        ],
        nombre:[
            {type:"required",message:"Nombre es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:250}
        ],
        apellido_paterno:[
            {type:"required",message:"Apellido paterno es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:250}
        ],
        apellido_materno:[
            {type:"required",message:"Apellido materno es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:250}
        ],
        rfc:[
            {type:"required",message:"RFC es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:13}
        ],
        curp:[
            {type:"required",message:"CURP es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:18}
        ],
        correo:[
            {type:"required",message:"Correo es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:250}
        ],
        fecha_nacimiento:[
            {type:"required",message:"Fecha nacimiento es obligatorio"},
        ],
        orden:[
            {type:"required",message:"Nombre es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:50}
        ],
    }
    //#endregion


    //#region render
    return (
        <React.Fragment >
            <h2 className={'content-block'}>{lang.getString("funcionario.detail.title")}</h2>
            <div id="anexoRubro" className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                
                <form  onSubmit={handleSubmit}>
                    <Form
                        ref={formRef}
                       formData={funcionario}
                       readOnly={false}
                       showColonAfterLabel={true}
                       showValidationSummary={true}
                       validationGroup="validationRules"
                    >
                        <GroupItem  colCount={21}>
                            <Item 
                                colSpan={4}
                                dataField="rfc"
                                stylingMode="underlined"
                                validationRules={validationRules.rfc}>
                                    <Label text={lang.getString("funcionario.caption.rfc")}></Label>
                            </Item>
                            <Item 
                                colSpan={4}
                                dataField="nombre"
                                stylingMode="underlined"
                                validationRules={validationRules.nombre}>
                                    <Label text={lang.getString("funcionario.caption.nombre")}></Label>
                            </Item>
                            <Item 
                                colSpan={4}
                                dataField="apellido_paterno"
                                stylingMode="underlined"
                                validationRules={validationRules.apellido_paterno}>
                                    <Label text={lang.getString("funcionario.caption.apellido_paterno")}></Label>
                            </Item>
                            <Item 
                                colSpan={4}
                                dataField="apellido_materno"
                                stylingMode="underlined"
                                validationRules={validationRules.apellido_materno}>
                                    <Label text={lang.getString("funcionario.caption.apellido_materno")}></Label>
                            </Item>
                            <Item 
                                colSpan={5}
                                dataField="curp"
                                stylingMode="underlined"
                                validationRules={validationRules.curp}>
                                    <Label text={lang.getString("funcionario.caption.curp")}></Label>
                            </Item>
                        </GroupItem>
                       
                        <GroupItem colCount={17}>
                            <Item 
                                colSpan={4}
                                dataField="correo"
                                stylingMode="underlined"
                                validationRules={validationRules.correo}>
                                    <Label text={lang.getString("funcionario.caption.correo")}></Label>
                            </Item>
                            <Item 
                                colSpan={3}
                                dataField="fecha_nacimiento"
                                stylingMode="underlined"
                                editorType="dxDateBox" 
                                editorOptions={{
                                    type:"date",
                                    width: '100%', 
                                    dropDownButtonTemplate:"buttonRender",
                                }}
                                validationRules={validationRules.fecha_nacimiento}>
                                    <Label text={lang.getString("funcionario.caption.fecha_nacimiento")}></Label>
                                    <Template name="buttonRender" render={()=> <Button style={{backgroundColor:"unset",marginLeft:"-13px"}} icon={"event"} />}/>
                            </Item>
                            <GroupItem colSpan={6}>
                                <ButtonItem horizontalAlignment="right" 
                                    buttonOptions={{text: "Seleccionar identificación", type:'default', onClick:cargar}}/>
                            </GroupItem>
                            <GroupItem colCount={10} colSpan={4}>                                  
                                <ButtonItem colSpan={1} horizontalAlignment="left" 
                                    buttonOptions={{icon:"remove", type:'normal',  stylingMode:"text" ,onClick: ()=> quitarAdjunto()}}/>    
                                <ButtonItem colSpan={9} horizontalAlignment="left" 
                                    buttonOptions={{text: funcionario.nombre_adjunto, type:'normal',  stylingMode:"text" ,onClick: ()=> descargar()}}/>
                            </GroupItem>
                        </GroupItem>
                        
                        <GroupItem colCount={4} visible={grupoUsuario === '1' ? true : false}> 
                                <Item 
                                    colSpan={1}
                                    dataField="flag_permiso_admin"
                                    stylingMode="underlined"
                                    editorType="dxSwitch">
                                        <Label text={lang.getString("funcionario.caption.flag_permiso_admin")}></Label>
                                </Item> 
                                <Item 
                                    colSpan={1}
                                    dataField="flag_permiso_revisor"
                                    stylingMode="underlined"
                                    editorType="dxSwitch">
                                        <Label text={lang.getString("funcionario.caption.flag_permiso_revisor")}></Label>
                                </Item>     
                        </GroupItem>

                        <Item itemType="empty" dataField="id_adjunto"/>        
                        <GroupItem colCount={1} caption="Puestos asignados"> 
                                <DataGrid key="id" colSpan={12}
                                    dataSource={dataSourcePuestos}
                                    columnAutoWidth={true}
                                    repaintChangesOnly={true}
                                    showBorders={true} width={"100%"}
                                    rowAlternationEnabled={true}>
                                    <Paging defaultPageSize={PAGE_SIZE_GRID} />
                                        <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                                        showInfo={true} />
                                    <Column dataField="evento" caption={'Evento'}/>
                                    <Column dataField="fecha_firma" caption={'Fecha de firma'}/>
                                    <Column dataField="ente_publico" caption={"Ente público"} />
                                    <Column dataField="unidad_administrativa" caption={lang.getString("ente.publico.puesto.caption.id_unidad_administrativa")} />
                                    <Column dataField="clave" caption={lang.getString("ente.publico.puesto.caption.clave")} />
                                    <Column dataField="puesto" caption={lang.getString("ente.publico.puesto.caption.nombre")} />
                                    <Column dataField="tipo" caption={"Tipo"} />
                                </DataGrid>
                        </GroupItem>

                        <GroupItem colCount={1} caption="Historial de puestos" visible ={funcionario.id > 0 ? true : false}> 
                                <DataGrid key="id" colSpan={12}
                                    dataSource={dataSourceHistorial}
                                    columnAutoWidth={true}
                                    repaintChangesOnly={true}
                                    showBorders={true} width={"100%"}
                                    rowAlternationEnabled={true}>
                                    <Paging defaultPageSize={PAGE_SIZE_GRID} />
                                        <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                                        showInfo={true} />
                                    <Column dataField="fecha_firma" caption={'Fecha de firma'}/>
                                    <Column dataField="ente_publico" caption={"Ente público"} />
                                    <Column dataField="unidad_administrativa" caption={lang.getString("ente.publico.puesto.caption.id_unidad_administrativa")} />
                                    <Column dataField="puesto" caption={lang.getString("ente.publico.puesto.caption.nombre")} />
                                    <Column dataField="evento" caption={"Evento"} />
                                </DataGrid>
                        </GroupItem>

                        <GroupItem colSpan={12}>
                            <ButtonItem horizontalAlignment="left" colSpan={2}
                                buttonOptions={{text:'Guardar',type:'success',useSubmitBehavior:true}}
                            />
                            <ButtonItem horizontalAlignment="left" colSpan={10}
                                buttonOptions={{text:'regresar', visible:props.location.state.perfil ? false : true, type:'default',onClick:()=>{goToList()}}}
                            />
                        </GroupItem>

                    </Form>
                    </form>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={isLoading}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
                
            </div>
      </React.Fragment>
        );
    //#endregion
}


