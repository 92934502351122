import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CompareRule
} from 'devextreme-react/form';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import LoadIndicator from 'devextreme-react/load-indicator';
import AlertDialog from '../alertDialog/alertDialog'
import {STRING_ALERTDIALOG_CREATE_SUCCESS} from 'constants/defaultValues';
import { changeCurrentPassword } from 'api';

export default function(props){
    //#region hook de control
    const [loading, setLoading] = useState(false);
    //#endregion

    //#region hook de estado
    const [formData] = useStateWithCallbackLazy ({
        currentPassword:null,
        newPassword:null,
        verifyPassword:null
    });

    const onSubmit = async (e) => {
        try{
          e.preventDefault();
          const { currentPassword,newPassword} = formData;
          setLoading(true);
        let {data:{error,message}}  = await changeCurrentPassword(currentPassword,newPassword);
            setLoading(false);
            if(error) AlertDialog({message});
            else{
                AlertDialog({message: "Se ha cambiado la contraseña exitosamente.", type:"success",onActionCallback:(resp)=>{
                    props.onClose && props.onClose();
                    
                }});
                
            } 
        }catch(error){
          AlertDialog({message: STRING_ALERTDIALOG_CREATE_SUCCESS, isNotification: true});
          setLoading(false);
        }
        
      }

    const currentPassworEditorOptions = { stylingMode: 'filled', placeholder: 'Contraseña actual', mode: 'password' };
    const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Contraseña', mode: 'password' };
    const verifyPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Verificar contraseña', mode: 'password' };

    return (
        <form className={'login-form'} onSubmit={onSubmit}>
          <Form formData={formData} disabled={loading}>
            <Item
              dataField={'currentPassword'}
              editorType={'dxTextBox'}
              editorOptions={currentPassworEditorOptions}
            >
              <RequiredRule message="Contraseña actual es requerida" />
              <Label visible={false} />
            </Item>
            <Item
              dataField={'newPassword'}
              editorType={'dxTextBox'}
              editorOptions={passwordEditorOptions}
            >
              <RequiredRule message="Contraseña es requerida" />
              <Label visible={false} />
            </Item>
            <Item
              dataField={"verifyPasswordEditorOptions"}
              editorType="dxTextBox"
              editorOptions={verifyPasswordEditorOptions}
            >
                    <RequiredRule message="La confirmación de la contraseña es requerida"></RequiredRule>
                    <CompareRule message="Las contraseñas no coinciden" comparisonTarget={()=>formData.newPassword}></CompareRule>
              <Label visible={false} />
            </Item>
            <ButtonItem>
              <ButtonOptions
                width={'100%'}
                type={'default'}
                useSubmitBehavior={true}
              >
                <span className="dx-button-text">
                  {
                    loading
                      ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                      : 'Guardar'
                  }
                </span>
              </ButtonOptions>
            </ButtonItem>
          </Form>
        </form>
      );
}