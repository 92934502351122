import React,{useState} from 'react';
import Form, { Item,ButtonItem,Label} from 'devextreme-react/form';
import { GroupItem } from 'devextreme-react/tree-list';
import {DATASOURCE_EVENTOS_TIPO_TERMINO} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog';
export default function(props){
    const [isLoading, setIsLoading] = useState(false);
    const [estatus] = useState({
        'nota':null,
        'id_estatus':1
    });

   const handleSubmit  = async(e)=>{
        let result = e.validationGroup.validate();
        if (result.isValid) {
            let data = {...estatus}
            if(data.id_estatus === 2 && (data.nota === null || data.nota ==='')){
                AlertDialog({message:'Favor de agregar una nota', type:'error'});
                return;
            }
            console.log('probando');
            props.onAccept && props.onAccept(data);
        }
   }
    return(
        <div>
         <form onSubmit={handleSubmit} style={{marginTop:"20px"}}>
                <Form
                    readOnly={false}
                    showColonAfterLabel={false}
                    showValidationSummary={false}
                    formData={estatus}
                    disabled={isLoading}
                    >
                    <GroupItem colCount={12}>
                    <Item
                        colSpan={12}
                        dataField="id_estatus"
                        editorType="dxSelectBox"
                        editorOptions={{
                            valueExpr:"id_estatus",
                            searchEnabled:true,
                            searchMode:"contains",
                            displayExpr: (item) => item && item.estatus_nombre,                     
                            searchExpr:(item) => item && item.estatus_nombre,
                            dataSource:DATASOURCE_EVENTOS_TIPO_TERMINO,
                        }}
                        validationRules={[
                            {type:"required",message:"es es obligatorio"}
                        ]}
                    >
                        <Label text={"Tipo de termino"}/>
                    </Item>
                    <Item 
                        colSpan={12}
                        editorType="dxTextArea"
                        dataField="nota"
                        
                        label={"Nota de termino"}
                        editorOptions={{
                            stylingMode:"outlined",
                            height:"260"
                        }}
                    >
                        <Label text="Nota de termino"/>
                    </Item>
                    <ButtonItem
                        colSpan={2}
                        buttonOptions={{
                            text: "Guardar",
                            type:'default',
                            width:"200",
                            onClick: (e) => handleSubmit(e)
                        }}
                    />
                    </GroupItem>
                    
                    
                </Form>
            </form>
        </div>
    )
}