import React,{useState} from 'react';
import Form, { Item,ButtonItem, Label} from 'devextreme-react/form';

export default function({data,onAccept=()=>{}}){
   const [motivo] = useState({
        justificacion:null
   });

   const handleSubmit  = (e)=>{
        e.preventDefault();
        let dato = data;

        dato.justificacion = motivo.justificacion;
        onAccept && onAccept(dato);
   }

    return(
        <div>
         <form onSubmit={handleSubmit} style={{marginTop:"20px"}}>
                <Form
                    readOnly={false}
                    showColonAfterLabel={false}
                    showValidationSummary={false}
                    formData={motivo}
                    colCount={1}
                    >
                    <Item 
                        colSpan={10}
                        editorType="dxTextArea"
                        dataField="justificacion"
                        
                        label={"Justificación"}
                        validationRules={[
                            {type:"required",message:"Justificación es obligatorio"}
                        ]}
                        editorOptions={{
                            stylingMode:"outlined",
                            height:"260"
                        }}
                    >
                        <Label text="Justificación"/>
                    </Item>
                    <ButtonItem
                        colSpan={1}
                        name="btnMotivo" 
                        cssClass="top30"
                        horizontalAlignment="right"
                        buttonOptions={{
                            text: "Aceptar",
                            type:'default',
                            useSubmitBehavior:true,
                            width:"200"
                        }}
                    />
                </Form>
            </form>
        </div>
    )
}