import {instance} from '../constants/defaultValues';

export function getEventoPuestoAnexo({evento_id = 0, ente_id = 0, unidad_id = 0, puesto_id = 0, anexo_id = 0, take = 1, skip = 0, filter = {}, sort = {}}){
    if(anexo_id){
        return instance.post(`/api/read`,{
            "VC":"evento_puesto_anexo",
            "VA":"byid",
            "evento_id":evento_id,
            "ente_id":ente_id,
            "unidad_id":unidad_id,
            "puesto_id":puesto_id,
            "anexo_id":anexo_id
        })
    }
    else{
        return instance.post(`/api/read`,{
            "VC":"evento_puesto_anexo",
            "VA":"list",
            "evento_id":evento_id,
            "ente_id":ente_id,
            "unidad_id":unidad_id,
            "puesto_id":puesto_id,
            "page":skip/take,
            "pagesize":take,
            ...filter,
            "sortby": sort.selector,
            "sortdesc": sort.desc
        })
    }
}

export function getEventoPuestoAnexoHeaders({eventos_puestos_anexos_id = 0}){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto_anexo",
        "VA":"headers_byid",
        "eventos_puestos_anexos_id":eventos_puestos_anexos_id
    })
}

export function getUUIDSAdjuntosRegistroAnexo(id_evento_puesto){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto_anexo",
        "VA":"get_uuids_registros",
        "id_evento_puesto":id_evento_puesto
    })
}

export function adjuntoAclaracion(id_puesto_anexo, file_id){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto_anexo",
        "VA":"adjunto_aclaracion",
        "id":id_puesto_anexo,
        "file_id":file_id,
    })
}

/*
export function postEventoPuestoAnexo(evento){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto_anexo",
        "VA":"bysystem",
        "id":                   evento.id,
        "nombre":               evento.nombre,
        "orden":                evento.orden,
        "id_evento_tipo":       evento.id_evento_tipo,
        "fecha_publicacion":    evento.fecha_publicacion,
        "fecha_termino":        evento.fecha_termino,
        "estatus":              evento.estatus
    })
}
*/
export function postEventoPuestoAnexoAplica(evento){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto_anexo",
        "VA":"byaplica",
        "id":                   evento.id,
        "aplica":               evento.aplica,
        "justificacion":        evento.justificacion,
        "flag_continuo": evento.flag_continuo
    })
}
export function postEventoPuestoAnexoEstatus(evento){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto_anexo",
        "VA":"byestatus",
        "id":                   evento.id,
        "estatus":              evento.estatus,
        "grupo_id": evento.grupoUsuario
    })
}
export function postEventoPuestoAnexoEstatusAceptacion(evento){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto_anexo",
        "VA":"byestatusaceptacion",
        "id": evento.id,
        "estatus": evento.estatus,
    })
}
export function postEventoPuestoAnexoIdAdjunto(evento){
    console.log("postEventoPuestoAnexoIdAdjunto", evento)
    return instance.post(`/api/write`,{
        "VC":"evento_puesto_anexo",
        "VA":"byidadjunto",
        "id":                   evento.id,
        "id_adjunto":           evento.id_adjunto,
    })
}
export function deleteEventoPuestoAnexo(eventoId){
    return instance.post(`/api/delete`,{
        "VC":"evento_puesto_anexo",
        "VA":"bysystem",
        "id" : eventoId
    });
}

export function postEventoPuestoAnexoRegistro({id_puesto_anexo, data}){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto_anexo_registro",
        "VA":"bysystem",
        id_puesto_anexo,
        data
    })
}

export function deleteEventoPuestoAnexoRegistro(id,id_adjunto){
    return instance.post(`/api/delete`,{
        "VC":"evento_puesto_anexo_registro",
        "VA":"bysystem",
        id,
        id_adjunto
    })
}

export function postEventoPuestoAnexoRegistroIdAdjunto(id,id_adjunto){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto_anexo_registro",
        "VA":"byidadjunto",
        "id":id,
        "id_adjunto":id_adjunto,
    })
}

export function getEventoPuestoAnexoRegistro(id_puesto_anexo){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto_anexo_registro",
        "VA":"bysystem",
        id_puesto_anexo
    })
}
export function getEventoPuestoAnexoRegistroPlantilla(parameters,callbackprogress){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto_anexo_registro",
        "VA":"downloadplantilla",
        ...parameters
    },
    {
        responseType: 'arraybuffer',
        onDownloadProgress: (evt) => {
            callbackprogress && callbackprogress(parseInt( Math.round( parseInt((evt.loaded / evt.total) * 100) )));
        }
    })
}
export function getEventoPuestoAnexoAdjunto(parameters,callbackprogress){
    return instance.post(`/api/file/download`,parameters,
    {
        responseType: 'arraybuffer',
        onDownloadProgress: (evt) => {
            callbackprogress && callbackprogress(parseInt( Math.round( parseInt((evt.loaded / evt.total) * 100) )));
        }
    })
}
export function getEventoPuestoAnexoByIdEventoPuesto(id_evento_puesto, grupo_id){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto_anexo",
        "VA":"listbyideventopuesto",
        id_evento_puesto,
        "grupo_id": Number(grupo_id)
    })
}
export function getEventoPuestoAnexoByIdEvento(eventoId,grupoId, take = 1, skip = 0, filter = {}, sort = {}, ente_id){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto_anexo",
        "VA":"listbyidevento",
        "evento_id":eventoId,
        "grupo_id":grupoId,
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc,
        "ente_id":ente_id
    })
}

export function getMisRevisionesByEventoPuestoId(eventoPuestoId,grupoId, take = 1, skip = 0, filter = {}, sort = {}){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto_anexo",
        "VA":"list_for_mis_revisiones",
        "evento_puesto_id":eventoPuestoId,
        "grupo_id":grupoId,
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc
    })
}

export function getEventoPuestosByIdEvento(eventoId, take = 1, skip = 0, filter = {}, sort = {}){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto",
        "VA":"by_evento_puesto",
        "evento_id":eventoId,
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc,
        "grupo_id":localStorage.getItem('grupo_id')
    })
}
export function getEventoPuestoAnexoByIdFuncionarioIdEvento(funcionarioId, eventoId, grupoId, take = 1, skip = 0, filter = {}, sort = {}){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto_anexo",
        "VA":"listbyidfuncionarioidevento",
        "funcionario_id":funcionarioId,
        "evento_id":eventoId,
        "grupo_id":grupoId,
        "page":skip/take,
        "pagesize":take,
        ...filter,
        "sortby": sort.selector,
        "sortdesc": sort.desc
    })
}

export function getPuestosAnexos(id_evento_puesto){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto_anexo",
        "VA":"byeventopuesto",
        id_evento_puesto
    })
}


export function getAnexosRegistrosDescargas(id_anexo){
    return instance.post(`/api/read`,{
        "VC":"evento_puesto_anexo",
        "VA":"ids_anexos_registros_descargas",
        "anexo_id":id_anexo
    })
}

export function postJustificacionEventoAnexo(id_evento_anexo,justificacion){
    return instance.post(`/api/write`,{
        "VC":"evento_puesto_anexo",
        "VA":"justificacion",
        id_evento_anexo,
        justificacion
    })
}