import React, { useRef } from 'react';
import Form, {
    ButtonItem,
    GroupItem,
    Item,
    EmptyItem,
  PatternRule,
  RequiredRule,
  StringLengthRule,
  } from 'devextreme-react/form';
  import ScrollView from 'devextreme-react/scroll-view';
import {toJS} from "mobx";
import { observer, useLocalObservable } from "mobx-react-lite"

const TextItem = (props) =>{
    return <Item dataField={props.nombre} 
    >
    {props.obligatorio && <RequiredRule message={`${props.nombre} requerido.`} />}
    {props.dato_longitud && <StringLengthRule max={props.dato_longitud} message={`${props.nombre} tiene una longitud mayor a ${props.dato_longitud}.` } />}
    </Item>   
}
const N2Item = (props) =>{
    return <Item dataField={props.nombre} 
                editorOptions={{
                    placeholder: '0.00',
                  }}
                  >
                  {props.obligatorio && <RequiredRule message={`${props.nombre} requerido.`} />}
                  {props.dato_longitud && <StringLengthRule max={props.dato_longitud} message={`${props.nombre} tiene una longitud mayor a ${props.dato_longitud}.` } />}
                  <PatternRule
                    message={`${props.nombre} tiene un formato invalido.`}
                    pattern={/^-?\$?((\d{1,3})(?:,?[0-9]{3}){0,10})(\.\d{1,2})?$/}
                  />
                  </Item>           
}
const N4Item = (props) =>{
    return <Item dataField={props.nombre} 
                editorOptions={{
                    placeholder: '0.0000',
                  }}
                  >
                  {props.obligatorio && <RequiredRule message={`${props.nombre} requerido.`} />}
                  {props.dato_longitud && <StringLengthRule max={props.dato_longitud} message={`${props.nombre} tiene una longitud mayor a ${props.dato_longitud}.` } />}
                  <PatternRule
                    message={`${props.nombre} tiene un formato invalido.`}
                    pattern={/^-?\$?((\d{1,3})(?:,?[0-9]{3}){0,10})(\.\d{1,4})?$/}
                  />
                  </Item>          
}
const C0Item = (props) =>{
    return <Item dataField={props.nombre} 
                editorOptions={{
                    placeholder: '0,000',
                  }}
                  >
                  {props.obligatorio && <RequiredRule message={`${props.nombre} requerido.`} />}
                  {props.dato_longitud && <StringLengthRule max={props.dato_longitud} message={`${props.nombre} tiene una longitud mayor a ${props.dato_longitud}.` } />}
                  <PatternRule
                    message={`${props.nombre} tiene un formato invalido.`}
                    pattern={/^-?\$?((\d{1,3})(?:,?[0-9]{3}){0,10})?$/}
                  />
                  </Item>          
}
const E0Item = (props) =>{
    return <Item dataField={props.nombre} 
            
                editorOptions={{
                    placeholder: '0000',
                  }}
                  >
                  {props.obligatorio && <RequiredRule message={`${props.nombre} requerido.`} />}
                  {props.dato_longitud && <StringLengthRule max={props.dato_longitud} message={`${props.nombre} tiene una longitud mayor a ${props.dato_longitud}.` } />}
                  <PatternRule
                    message={`${props.nombre} tiene un formato invalido.`}
                    pattern={/^-?\$?((\d{1,3})(?:,?[0-9]{3}){0,10})?$/}
                  />
                  </Item>          
}
const DateItem = (props) =>{
    return <Item dataField={props.nombre} 
                
                editorOptions={{
                    placeholder: '01/01/1900',
                  }}
                  >
                  {props.obligatorio && <RequiredRule message={`${props.nombre} requerido.`} />}
                  {props.dato_longitud && <StringLengthRule max={props.dato_longitud} message={`${props.nombre} tiene una longitud mayor a ${props.dato_longitud}.` } />}
                  <PatternRule
                    message={`${props.nombre} tiene un formato invalido.`}
                    pattern={/((0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/[12]\d{3})/}
                  />
                  </Item>           
}
const TimeItem = (props) =>{
    return <Item dataField={props.nombre} 
               
                editorOptions={{
                    placeholder: '00:00'
                  }}
                  >
                  {props.obligatorio && <RequiredRule message={`${props.nombre} requerido.`} />}
                  {props.dato_longitud && <StringLengthRule max={props.dato_longitud} message={`${props.nombre} tiene una longitud mayor a ${props.dato_longitud}.` } />}
                  <PatternRule
                    message={`${props.nombre} tiene un formato invalido.`}
                    pattern={new RegExp('([01]?[0-9]|2[0-3]):[0-5][0-9]')}
                  />
                  </Item>               
}
const ListItem = (props) =>{

    return <Item dataField={props.nombre}  editorType="dxSelectBox" editorOptions={{items: props.lista.map(item=> item.nombre)}}/>        
}
const PercentItem = (props) =>{
    return <Item dataField={props.nombre} 
                editorOptions={{
                    placeholder: '100%',
                  }}
            >
                {props.obligatorio && <RequiredRule message={`${props.nombre} requerido.`} />}
                {props.dato_longitud && <StringLengthRule max={props.dato_longitud} message={`${props.nombre} tiene una longitud mayor a ${props.dato_longitud}.` } />}
                <PatternRule
                  message={`${props.nombre} tiene un formato invalido.`}
                  pattern={/^((100(\.0{1,2})?)|(\d{1,2}(\.\d{1,2})?))%$/}
                />
                </Item>        
}
const CustomItem = (props) =>{
    const ref = useRef(null);
    if(ref && ref.current === null){
        switch (props.id_tipo_dato) {
            case 1:
                ref.current = TextItem(props);
                break;
            case 2:
                ref.current = N2Item(props);
                break;
            case 3:
                ref.current = N4Item(props);
                break;  
            case 4:
                ref.current = C0Item(props);
                break;
            case 5:
                ref.current = E0Item(props);
                break;
            case 6:
                ref.current = DateItem(props);
                break;  
            case 7:
                ref.current = TimeItem(props);
                break;
            case 8:
                ref.current = ListItem(props);
                break;
            case 9:
                ref.current = PercentItem(props);
                break;    
            default:
                break;
        }
    }
    return ref && ref.current;
}
const CustomItem2 = (props) =>{
        switch (props.id_tipo_dato) {
            case 1: return TextItem(props);
            case 2: return N2Item(props);
            case 3: return N4Item(props);
            case 4: return C0Item(props);
            case 5: return E0Item(props);
            case 6: return DateItem(props);
            case 7: return TimeItem(props);
            case 8: return ListItem(props);
            case 9: return PercentItem(props);
            default: return null
        }
}
const varObserver = (target) => target;
const Component = observer( (({data,model, ...props}) => {

    const formRef = useRef(null);
    const handleSubmit = (async (e) => {   
        if(formRef.current){
            const res = formRef.current.instance.validate(); 
            if(res.isValid){
                props.handleSubmit && props.handleSubmit(toJS(model.model))
                //model.fromJS(createModel());
            }
            
        }
    });
     return <Form
                 ref={formRef}
                 id="form"
                 formData={model.model}
                 readOnly={false}
                 showColonAfterLabel={true}
                 labelLocation={'top'}
                 minColWidth={300}
                 width={'auto'}
             >
                 <GroupItem>
                     {data.columnas && toJS(data.columnas).map(item=> CustomItem2(item))}
                     <EmptyItem></EmptyItem>
                     <ButtonItem width={200} horizontalAlignment="left"
                                     buttonOptions={{text:'Guardar',type:'success',onClick:()=>{handleSubmit(model)}}}
                                 />
                 </GroupItem>
                 </Form>
 })
)

export default observer(  ({data, ...props}) => {
    const createModel = () =>{
        const model = {id_adjunto:0};
        console.debug('data.columnas',toJS(data.columnas))
        if(data.columnas){
            data.columnas.forEach(element => {
                model[element.nombre] = undefined;
            });
        }
        return model;
    } 
    const model = useLocalObservable(()=>{return{
        model : {...(createModel())},
        fromJS:function(json){
            json && Object.keys(json).forEach(key => {
                if (this.hasOwnProperty(key)) {
                    this[key] = json[key];
                }
            });
        }
    }});

    const handleSubmit = (async (e) => {   
        if(props.callback && await props.callback(e))
            model.fromJS({model:createModel()});
    });
    
    
    return <ScrollView id="always" 
    scrollByContent={true}
    bounceEnabled={false}
    showScrollbar={"always"}
    scrollByThumb={true}>
            <Component data={data} columnas={data.columnas} model={model} handleSubmit={handleSubmit}></Component>
    </ScrollView>
})