import React,{ useRef, useEffect} from 'react';
import { Button } from 'devextreme-react/button';
import Swal from 'sweetalert2';
import {
  Item
} from 'devextreme-react/box';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import AlertDialog from 'components/alertDialog/alertDialog'
import {
    getEventoBySearchTerm,
    readRPTseguimientoTitulares,
    readRPTseguientoPuestos
} from 'api';
import { DEFAULT_PAGE_SIZE_CMB } from 'constants/defaultValues';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Form, {
    GroupItem,
    Label
  } from 'devextreme-react/form';
import XLSX from 'xlsx';

  export default function () {
    //#region hook de control
    const formRefReport1 = useRef();
    const formRefReport2 = useRef();
    //const ExcelFile = ExportExcel
    //#endregion
    //#region hook de estado
    const [dato] = useStateWithCallbackLazy({
        "evento_id":null,
    });
    const [datoReporte] = useStateWithCallbackLazy({
      "evento_id":null,
  });
    //#endregion
   //#region carga de datos iniciales 
   useEffect(() => {
    let mounted = true; 
    async function fetchData(){
        if (mounted) { 
        }
    }
    fetchData();
    return () => { mounted = false; };
  },[]); 
  //#endregion

  //#region fetch
  //#endregion

  //#region dataSource
  const generarDataSource = (getFunc)=>{
    const fetchFunc = async ({seachterm = ''}, take = 1, skip = 0, ) =>{
      let result = []
      const grupoUsuario = parseInt(localStorage.getItem('grupo_id'));
      const {data:{error,message,data}} = await getFunc(seachterm, take, skip, grupoUsuario);
      if(error){
          AlertDialog({message});
      }else if(data.length) {
          result = data;
      }
      return result;
    }

    const ds = new DataSource({
      pageSize: DEFAULT_PAGE_SIZE_CMB,
      store: new CustomStore({
          key:"id",
          load: (loadOptions) => {
              if(loadOptions.searchValue)
                  return fetchFunc({seachterm:loadOptions.searchValue}, loadOptions.take, loadOptions.skip);     
              else 
                  return fetchFunc({}, loadOptions.take, loadOptions.skip);             
          },
          byKey: (key) => {
              return key;
          }
        })
    });
    return ds;
  }
  
  const eventoDatasource = generarDataSource(getEventoBySearchTerm);
  const eventoDatasource2 = generarDataSource(getEventoBySearchTerm);
  //#endregion

  //#region eventos interfaz
  const downloadExcelSegTitulares=async()=>{
        let result = []
        let evento_id = dato.evento_id
        if(evento_id === null){
            AlertDialog({message:'Seleccione evento.', isNotification:true, type:'warning'});
            return;
        }
        Swal.fire({
          html: 'Generando reporte...',
          didOpen: () => {
            Swal.showLoading()
          }
        });
        const {data:{error,message,data}} = await readRPTseguimientoTitulares(evento_id);
        if(error){
          Swal.close();
            AlertDialog({message});
            return;
        }else if(data.length) {
            result = data;
            console.log('excel', data);
            const workSheet = XLSX.utils.json_to_sheet(result);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook,workSheet, 'Seguimiento titulares');
            let buf = XLSX.write(workBook,{bookType:'xlsx', type:"buffer"});
            XLSX.write(workBook,{bookType:'xlsx', type:"binary"});
            XLSX.writeFile(workBook, "Reporte de seguimiento de titulares.xlsx");
            Swal.close();
        }else{
            Swal.close();
            AlertDialog({message:'No existen datos para el evento seleccionado, intente con uno diferente.', isNotification:true, type:'info'});
            return;
        }
    }


    const downloadExcelSegPuestos=async()=>{
      let result = []
      let evento_id = datoReporte.evento_id
      if(evento_id === null){
          AlertDialog({message:'Seleccione evento.', isNotification:true, type:'warning'});
          return;
      }
      Swal.fire({
        html: 'Generando reporte...',
        didOpen: () => {
          Swal.showLoading()
        }
      });
      const {data:{error,message,data}} = await readRPTseguientoPuestos(evento_id);
      if(error){
        Swal.close();
          AlertDialog({message});
          return;
      }else if(data.length) {
          result = data;
          console.log('excel', data);
          const workSheet = XLSX.utils.json_to_sheet(result);
          const workBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workBook,workSheet, 'Seguimiento puestos');
          let buf = XLSX.write(workBook,{bookType:'xlsx', type:"buffer"});
          XLSX.write(workBook,{bookType:'xlsx', type:"binary"});
          XLSX.writeFile(workBook, "Reporte de seguimiento de puestos.xlsx");
          Swal.close();
      }else{
          Swal.close();
          AlertDialog({message:'No existen datos para el evento seleccionado, intente con uno diferente.', isNotification:true, type:'info'});
          return;
      }
  }

  //#endregion

  //#region render
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Reportes de sistemas</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
            <h5 style={{fontWeight:'bold', padding:'0px'}} >Reporte de seguimiento de titulares</h5>
            <form>
                <Form
                    ref={formRefReport1}
                    formData={dato}
                    readOnly={false}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                    validationGroup="eventoData"
                >
                    <GroupItem colCount={8}>
                      <Item
                        colSpan={3}
                        dataField="evento_id"
                        editorType="dxSelectBox"
                        editorOptions={{
                            valueExpr:"id",
                            searchEnabled:true,
                            searchMode:"contains",
                            displayExpr: (item) => item && item.nombre,                     
                            searchExpr:(item) => item && item.nombre,
                            dataSource: eventoDatasource,
                        }}
                      >
                        <Label text={"Evento"}/>
                      </Item>
                        <Item colSpan={4}>
                        <Button text={"Obtener reporte"} type="success" onClick={()=>{downloadExcelSegTitulares()}}/>
                        </Item>
                    </GroupItem>
                </Form>
            </form>
        </div>
        <div style={{marginTop:'15px'}}/>
        <div className={'dx-card responsive-paddings'}>
            <h5 style={{fontWeight:'bold', padding:'0px'}} >Reporte de seguimiento de todos los puestos</h5>
            <form>
                <Form
                    ref={formRefReport2}
                    formData={datoReporte}
                    readOnly={false}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                    validationGroup="eventoData"
                >
                    <GroupItem colCount={8}>
                      <Item
                        colSpan={3}
                        dataField="evento_id"
                        editorType="dxSelectBox"
                        editorOptions={{
                            valueExpr:"id",
                            searchEnabled:true,
                            searchMode:"contains",
                            displayExpr: (item) => item && item.nombre,                     
                            searchExpr:(item) => item && item.nombre,
                            dataSource: eventoDatasource2,
                        }}
                      >
                        <Label text={"Evento"}/>
                      </Item>
                        <Item colSpan={4}>
                        <Button text={"Obtener reporte"} type="success" onClick={()=>{downloadExcelSegPuestos()}}/>
                        </Item>
                    </GroupItem>
                </Form>
            </form>
        </div>
      </div>
    </React.Fragment>
  );
  }