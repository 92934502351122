import {instance} from '../constants/defaultValues';

export function getFuncionario({funcionarioId = 0, take = 1, skip = 0, filter = {}, sort = {}}){
    if(funcionarioId){
        let ent = instance.post(`/api/read`,{
            "VC":"cat_funcionario",
            "VA":"byid",
            "id":funcionarioId
          })
       
        return ent;
    }
    else{
        return instance.post(`/api/read`,{
            "VC":"cat_funcionario",
            "VA":"listdata",
            "page":skip/take,
            "pagesize":take,
            ...filter,
            "sortby": sort.selector,
            "sortdesc": sort.desc
        })
    }
}


export function postFuncionario(funcionario){

    return instance.post(`/api/write`,{
        "VC":"cat_funcionario",
        "VA":"bysystem",
        "id":funcionario.id,
        "prefijo": funcionario.prefijo,
        "nombre": funcionario.nombre,
        "apellido_paterno": funcionario.apellido_paterno,
        "apellido_materno": funcionario.apellido_materno,
        "rfc": funcionario.rfc,
        "curp": funcionario.curp,
        "correo": funcionario.correo,
        "password": funcionario.password,
        "fecha_nacimiento": funcionario.fecha_nacimiento,
        "flag_permiso_admin": funcionario.flag_permiso_admin,
        "flag_permiso_revisor": funcionario.flag_permiso_revisor,
        "orden": funcionario.orden,
        "estatus": 1,
        "id_usuario": funcionario.id_usuario,
        "id_adjunto": funcionario.id_adjunto,
    })
}

export function postFuncionarioModal(funcionario){
    return instance.post(`/api/write`,{
        "VC":"cat_funcionario",
        "VA":"bysystem",
        "nombre": funcionario.nombre,
        "apellido_paterno": funcionario.apellido_paterno,
        "apellido_materno": funcionario.apellido_materno,
        "rfc": funcionario.rfc,
        "curp": funcionario.curp,
        "correo": funcionario.correo,
        "fecha_nacimiento": funcionario.fecha_nacimiento
    })
}

export function deleteFuncionario(funcionarioId){
    return instance.post(`/api/delete`,{
        "VC":"cat_funcionario",
        "VA":"bysystem",
        "id" : funcionarioId
    });
}

export function activateFuncionario(funcionarioId){
    return instance.post(`/api/write`,{
        "VC":"cat_funcionario",
        "VA":"activate",
        "id" : funcionarioId
    });
}

export function adjuntoFuncionario(funcionario){
    return instance.post(`/api/write`,{
        "VC":"cat_funcionario",
        "VA":"adjunto",
        "id":funcionario.id,
        "id_adjunto":funcionario.id_adjunto,
    })
}

export function getFuncionarioByRFC({rfc = "", validateRevisorSistema = false}){
    return instance.post(`/api/read`,{
        "VC":"cat_funcionario",
        "VA":"byrfc",
        "rfc":rfc,
        "validateRevisorSistema": validateRevisorSistema
      })
}

export function getFuncionarioRevisorByIdAndSearchTerm(funcionarioId, seachTerm, take, skip){
    return instance.post(`/api/read`,{
        "VC":"cat_funcionario",
        "VA":"revisor",
        "id":funcionarioId,
        "page":skip/take,
        "pagesize":take,
        "searchterm":seachTerm
    })
}

export function getCanViewPerfil(){
    return instance.post(`/api/read`,{
        "VC":"cat_funcionario",
        "VA":"viewPerfil",
    })
}
