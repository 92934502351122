import {instance} from '../constants/defaultValues';

export function getEnteSector({enteSectorId = 0, take = 1, skip = 0, filter = {}, sort = {}}){
    if(enteSectorId){
        return instance.post(`/api/read`,{
            "VC":"cat_ente_sector",
            "VA":"byid",
            "id":enteSectorId
          })
    }
    else{
        return instance.post(`/api/read`,{
            "VC":"cat_ente_sector",
            "VA":"listdata",
            "page":skip/take,
            "pagesize":take,
            ...filter,
            "sortby": sort.selector,
            "sortdesc": sort.desc
        })
    }
}

export function postEnteSector(enteSector){
    return instance.post(`/api/write`,{
        "VC":"cat_ente_sector",
        "VA":"bysystem",
        "id":       enteSector.id,
        "nombre" :  enteSector.nombre,
        "clave" :   enteSector.clave,
        "orden" :   enteSector.orden /*,
        "estatus":enteSector.estatus,
        "registro_estado" : enteSector.registro_estado,
        "registro_creacion_usuario_id," : enteSector.registro_creacion_usuario_id,
        "registro_creacion_fecha":enteSector.registro_creacion_fecha,
        "registro_modificacion_usuario_id" : enteSector.registro_modificacion_usuario_id,
        "registro_modificacion_fecha" : enteSector.registro_modificacion_fecha,
        "registro_cancelacion_usuario_id" : enteSector.registro_cancelacion_usuario_id,
        "registro_cancelacion_fecha":enteSector.registro_cancelacion_fecha */
    })

}

export function deleteEnteSector(enteSectorId){
    return instance.post(`/api/delete`,{
        "VC":"cat_ente_sector",
        "VA":"bysystem",
        "id" : enteSectorId
    });
}

export function activateEnteSector(enteSectorId){
    return instance.post(`/api/write`,{
        "VC":"cat_ente_sector",
        "VA":"activate_sector",
        "id" : enteSectorId
    });
}

