import React from 'react';
export default class Wrapper extends React.Component{
    constructor(props){
      super(props);
      this.setProps = this.setProps.bind(this);
      this.state={
        ...props,
        setProps:this.setProps
      }
      
    }
  
    setProps = (params) => {
        const {component:ComponentWraped,...rest} = this.state;
      this.setState({...rest,...params})
    }

    render(){
      const {component:ComponentWraped,...rest} = this.state;
      return <ComponentWraped {...rest}></ComponentWraped>
    }
  }