import React from 'react';
import { Button } from 'devextreme-react/button';
import { useHistory } from 'react-router-dom';
import DataGrid, { Column, Editing, Pager, Paging, FilterRow } from 'devextreme-react/data-grid';
import Box, {
    Item
  } from 'devextreme-react/box';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {DEFAULT_PAGE_SIZE_ONE_GRID, DEFAULT_PAGE_SIZES} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog'
import {getGrupoPermiso,deleteGrupoPermisos} from 'api'
//import { getConstruccionCategoria } from 'api';

export default function() {
    //#region hook de control
    const history = useHistory();
    //#endregion

    //#region fetch
    const fetchData = (async (take, skip, filter, sort) =>{
        let result= {data:[], totalCount:0};
        const {data:{error,message,data}} = await getGrupoPermiso(take, skip, filter, sort);
        if(error){
            AlertDialog({message});
        }else if(data.totalCount) {
            
            result = data
        }
        return result;
    })

    const deleteData = (async (key) =>{
        const {data:{error,message}} = await deleteGrupoPermisos(key);
        if(error){
            AlertDialog({message});
        }
    })
    //#endregion

    //#region dataSource
    const gruposDataSource = new DataSource({
        store: new CustomStore({
            key: "grupo_id",
            load: (loadOptions) => {
                return fetchData(loadOptions.take, loadOptions.skip);             
            },
            remove: (key) => {
                return deleteData(key);
            }
        })
    });
    //#endregion
    
    //#region eventos interfaz
    const goToDetail = (id = null) =>{
        history.push({pathname:'/admin/grupoPermisos/detalle',state:{id}})
    }
    //#endregion

    //#region render
    return(
        <React.Fragment>
            <h2 className={'content-block'}>Grupo de permisos</h2>
            <div className={'content-block'}>
            <div className={'dx-card responsive-paddings'}>
            <div className="dx-field-value" style={{marginBottom:"20px"}}>
                <Button icon="plus"
                    type="default"
                    text="Agregar grupo de permisos"
                    onClick={()=>{goToDetail()}}/>
             </div>
            <Box width="100%" direction="row">
                <Item ratio={12}>
                <DataGrid key="id"
                dataSource={gruposDataSource}
                remoteOperations={{paging:true, sorting:true, filtering: true}}
                columnAutoWidth={true}
                repaintChangesOnly={true}
                showBorders={true} width={"100%"}
                rowAlternationEnabled={true}>
                    <FilterRow visible={true} applyFilter={"auto"} applyFilterText={'Aplicar texto'} showOperationChooser={false}/>
                    <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                        <Pager
                        enabled={true}
                        showPageSizeSelector={true}
                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                        showInfo={true} />
                    <Editing
                        mode="row"
                        useIcons={true}
                        allowDeleting={true}
                        allowUpdating={true}
                    />
                    <Column type="buttons" width={110}
                    buttons={[
                        {hint:'Editar',icon:'edit',name:'edit',onClick:(event) => goToDetail(event.row.key)},
                        {hint:'Eliminar',icon:'trash',name:'delete'}
                    ]}
                    />
                    <Column dataField="grupo_id" caption="Id" visible={false} />
                    <Column dataField="grupo_nombre" caption={'Nombre del grupo'}/>
                </DataGrid>
                </Item>
            </Box>

            </div>
            
            </div>
        </React.Fragment>
    );
    //#endregion
};
