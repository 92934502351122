import React,{Suspense} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import appInfo from './app-info';

import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';
import LoadPanel from 'devextreme-react/load-panel';
import { useGlobal } from './contexts/global';
import { useRoutes } from './contexts/routes';
import { buildRoutes } from './app-routes';
import WaterMark from 'components/watermark';

const Content = function() {
  const {  user: _SESSION } = useGlobal();
  console.log("user",_SESSION);
  const { routes } = useRoutes();
  const routesInstace = buildRoutes(routes, 
    _SESSION != undefined && _SESSION !== null ? _SESSION.permissionSet : []);

  return (
    <SideNavBarLayout title={"Sistema Electrónico de Entrega y Recepción"}>
      <Suspense fallback={<LoadPanel visible={true} />}>
        <Switch>
          {routesInstace.map(({ path, component }) => (
            <Route
              exact
              key={path}
              path={path}
              component={component}
            />
          ))}
          <Redirect to={'/admin/home'} />
        </Switch>
      </Suspense>
      <Footer>
          <span>{`${appInfo.title}.:. appv${appInfo.version} - apiv${_SESSION != undefined ? _SESSION.sysinfo.backend_version : ''} - dbv${_SESSION != undefined ? _SESSION.sysinfo.version : ''}(${_SESSION != undefined ? _SESSION.sysinfo.database : ''})`}</span>
      </Footer>
    </SideNavBarLayout>
  );
}

export default Content;