import React, { useState,useEffect,useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Form, {
    ButtonItem,
    EmptyItem,
    GroupItem,
    Item,
    Label,
  } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import DataGrid, {Column, Editing, Pager, Paging, Lookup} from 'devextreme-react/data-grid';

import AuthCheck from '../../../components/authCheck/authCheck.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import {
    getAnexo,
    postAnexo,
    getAnexoRubroBySearchTerm,
    postAnexoColumna,
    postAnexoColumnaLista,
    deleteAnexoColumna,
    activateAnexoColumna,
    deleteAnexoColumnaLista,
    uploadFiles,
    getFile,
    ligaAnexoFile,
    getEventoValidaColumnasPlantilla,
} from 'api';
import {descargarArchivo} from 'utils/utility.js';
import 'devextreme-react/autocomplete';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {DEFAULT_PAGE_SIZE_CMB,ESTATUS_SOURCE_CONFIG} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog'
import notify from 'devextreme/ui/notify';
import './anexo.scss';
import { Popup } from 'devextreme-react/popup';
import { custom } from 'devextreme/ui/dialog';
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {
    DEFAULT_PAGE_SIZES,
    DEFAULT_PAGE_SIZE_GRID,
    DATASOURCE_CAT_ANEXO_COLUMNAS_TIPOS_DATOS} 
from 'constants/defaultValues';
import lang from 'lang';
const position = { of: '#usuario' }; //TODO: CSS - Se utiliza para posicionar el panel de loading

export default function (props) {
    const PAGE_SIZE_GRID_LISTA = 2;
    const DEFAULT_PAGE_SIZES_CUSTOM = [5, ...DEFAULT_PAGE_SIZES];
    const ID_TIPO_LISTA = 8;
    //#region hook de control
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [isPopupColumnasOpen,setIsPopupColumnasOpen] = useState(false);
    const [isPopUpListaOpen,setIsPopUpListaOpen] = useState(false);
    const formRef = useRef(null);
    const formRefColumna = useRef(null);
    const formRefLista = useRef(null);
    const RefComboTipoDato = useRef(null);
    const permCanEdit = AuthCheck({permission: "ANEXO_EDIT", onlyValidator: true});
    const permCanDelete = AuthCheck({permission: "ANEXO_DELETE", onlyValidator: true});
    //#endregion

    const filePlantilla = {
        "idrow_file":0,
        "filename":'',
        "filesize":0,
        "fileext":'',
        "filepath":'',
        "filecontent":'',
        "contenttype":'',
        "sysfilename":'',
    };
    const anexoPlantilla = {
        "id":0,
        "id_rubro":null,
        "clave":'',
        "nombre":'',
        "descripcion":'',
        "soporte_anexo":false,
        "soporte_registro":false,
        "replicable":false,
        "orden":0,
        "columnas":[],
        "cmbrubro":{"id":null,"cmbrubro":''},
        "plantilla": [],
        "id_adjunto": null,
        "existe":false
    };
    const anexoColumnaPlantilla = {
        "id":0,
        "id_anexo":null,
        "nombre":'',
        "descripcion":'',
        "id_tipo_dato": null,
        "obligatorio":false,
        "dato_longitud":'',
        "columna_longitud":'',
        "orden":0,
        "estatus":1,
        "tipo_dato": "",
        "lista":[],
    };
    const anexoColumnaListaPlantilla = {
        "id":0,
        "id_anexo_columna":null,
        "clave":'',
        "nombre":'',
        "descripcion":'',
        "orden":null,
    };

    //#region hook de estado
    const [file,setFile] = useStateWithCallbackLazy(filePlantilla);
    const [anexo,setAnexo] = useStateWithCallbackLazy(anexoPlantilla);
    const [anexoColumna,setAnexoColumna] = useStateWithCallbackLazy({...anexoColumnaPlantilla});
    const [anexoColumnaLista, setAnexoColumnaLista] = useStateWithCallbackLazy({...anexoColumnaListaPlantilla});

    const [isEdit, setIsEdit] = useState(false);
    //const [showbtn, setShowbtn] = useState(true);
    //#endregion
    
    //#region Cargar datos iniciales
    const cargar = async(id)=>{
        let result = {...anexo};
        let resultFile = {...file};
        setIsEdit(true);
        result = await fetchAnexo(id);
        if (result.id_adjunto && result.id_adjunto > 0) {                        
            resultFile = await fetchFile(result.id_adjunto);
            setFile(resultFile);
        }
        setAnexo(result);
    }
    useEffect(() => {
        let mounted = true;   
        if(props.location.state && props.location.state.id){   
            cargar(props.location.state.id);
        } 
        setIsLoading(false);      
        return () => { mounted = false; };
      },[]); 
    /*
    useEffect(() => {
        let mounted = true; 
        async function fetchData(){
            let result = {...anexo};
            let resultFile = {...file};
            if (mounted) { 
                if(props.location.state && props.location.state.id){   
                    setIsEdit(true);
                    result = await fetchAnexo(props.location.state.id);
                    if (result.id_adjunto && result.id_adjunto > 0) {                        
                        resultFile = await fetchFile(result.id_adjunto);
                        setFile(resultFile);
                    }
                    setAnexo(result);
                }   
                setIsLoading(false);
            }
        }
        fetchData();
        return () => { mounted = false; };
      },[]); 
      */
    //#endregion

    //#region fetch
    const fetchAnexo = (async(anexoId) =>{
        let result = {...anexo};
        if(anexoId){
           
            const {data:{error,message,data}} = await getAnexo({anexoId});
           
            if(error){
                AlertDialog({message: message});
            }else if(data.length){
              
                result.id = data[0].id;
                result.id_rubro = data[0].id_rubro;
                result.clave = data[0].clave;
                result.nombre = data[0].nombre;
                result.descripcion = data[0].descripcion;
                result.soporte_anexo = (data[0].soporte_anexo == "1") ;
                result.soporte_registro = (data[0].soporte_registro == "1") ;
                result.replicable = data[0].replicable === "1" ? true : false ;
                result.orden = data[0].orden;
                result.columnas = data[0].columnas;
                result.cmbrubro = data[0].cmbrubro;
                result.id_adjunto = data[0].id_adjunto;
                result.existe = true;

            }
        }
        return result;
    })
    const fetchFile = (async(fileId) =>{
        let result = {...file};
        if(fileId){
           
            const {data:{error,message,data}} = await getFile({fileId});
           
            if(error){
                AlertDialog({message: message});
            }else if(data.length){
              
                result.idrow_file = data[0].idrow_file;
                result.filename = data[0].filename;
                result.filesize = data[0].filesize;
                result.fileext = data[0].fileext;
                result.filepath = data[0].filepath;
                result.filecontent = data[0].filecontent;
                result.contenttype = data[0].contenttype;
                result.sysfilename = data[0].sysfilename;
                //setShowbtn(data[0].id_adjunto && data[0].id_adjunto > 0 ? true : false);

            }
        }
        return result;
    })
    const fetchAnexosRubros = (async ({grupoId = 0 ,seachterm = ''}, take = 1, skip = 0) =>{
        let result = [];
        const {data:{error,message,data}} =  await getAnexoRubroBySearchTerm(seachterm, take, skip);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    })
    //#endregion
    
    //#region origen de datos
    const dataSourceRubro = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["id"],
            load: (options) => {
                if(options.filter)
                    return fetchAnexosRubros({grupoId:options.filter[2]}, options.take, options.skip);   
                else if(options.searchValue)
                    return fetchAnexosRubros({seachterm:options.searchValue}, options.take, options.skip);    
                else 
                    return fetchAnexosRubros({}, options.take, options.skip);                 
            },
            byKey: (key) => {
                return anexo.cmbrubro ? anexo.cmbrubro : [] ;
            }
        })
    });
    const dataSourceColumnas  = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["id"]
            ,load: (options) => anexo.columnas
            ,remove: (key) => quitarColumna(key)
        })
    });
    let dataSourceLista = new DataSource({
            pageSize: DEFAULT_PAGE_SIZE_CMB,
            store: new CustomStore({
                key: ["id"]
                ,load: (options) => anexoColumna.lista
                ,remove: (key) => listaQuitar(key)
            })
    });
    //#endregion

    //#region eventos interfaz
    const calcularSiguente=(elementos, campo) => {
        let maximo = Math.max.apply(Math,elementos.map((e) => e[campo] ));
        return (isFinite(maximo) ? maximo : 0) + 1; 
    }
    const goToList = () =>{
        history.push({pathname:'/catalogos/anexos'});   
    }
    //#endregion

    //#region eventos CRUD
    const handleSubmit = (async (e) => {    
        if(formRef.current.instance.validate().isValid){
            setIsLoading(true);
            const {data:{error,message,data}} = await postAnexo(anexo);
            if(error) {
                AlertDialog({message: message});  
            } else {
                if (anexo.id>0) {
                    goToList();
                }else {
                    notify({message: "Registro guardado con éxito."});
                    cargar(data[0].id);   
                
                }   
            }
            //else      
            setIsLoading(false);
        }
    });
    //#endregion

    //#region eventos columna 
    const recargarColumnas = async() => {
        let result = await fetchAnexo(anexo.id); 
        anexo.columnas = [...result.columnas];     
    }
    const  activateColumna = async(e) =>{
        if (!await verificarAdjunto()) return;
        let activateDialog = custom({
            showTitle: false,
            messageHtml: "¿Está seguro que desea activar este registro?",
            buttons: [
                {
                    text: "SÍ",
                    onClick: () => {
                        return true;
                    }
                }, 
                {
                    text: "NO",
                    onClick: () => {
                        return false;
                    }
                }, 
            ]
        });

        activateDialog.show().then(async (dialogResult) => {
            if(dialogResult){
                setIsLoading(true);
                const {data:{error,message}} = await activateAnexoColumna(e.row.data.id);
                setIsLoading(false);
                if(error) AlertDialog({message: message});  
                else {
                    await recargarColumnas();
                    //dataSourceColumnas.reload();
                }
            }
        });
    }
    function canDeleteColumna(e){
        //console.log("canDeleteColumna",e.row.data.estatus)
        return e.row.data.estatus == 1 && permCanDelete;
    }
    function canActivateColumna(e){
        //console.log("canActivateColumna",e.row.data.estatus)
        return e.row.data.estatus != 1 && permCanDelete;
    }

    const  verificarAdjunto = async(e) =>{
        let resultado=false;
        if (anexo.id_adjunto){
            let activateDialog = custom({
                showTitle: false,
                messageHtml: "Para continuar es necesario quitar el archivo adjunto. ¿Desea continuar?",
                buttons: [
                    {text: "SÍ",onClick: () => true}, 
                    {text: "NO", onClick: () => false}, 
                ]
            });
            let dialogResult = await activateDialog.show();
            
            if(dialogResult){
                anexo.id_adjunto = 0;
                const {data:{error,message}} = await ligaAnexoFile(anexo)
                if(error){
                    AlertDialog({message});
                }else{
                    resultado=true;
                    setFile({...filePlantilla})
                }
            }
        }
        else{
            resultado=true;
        }
        return resultado;       
    }

    const cargarColumna = async (id) => {
        if (!await verificarAdjunto()) return;
        
        if (id>0){
            let col = anexo.columnas.find(col => col.id == id);
            setAnexoColumna({...col})
        }
        else{
            let newcol ={...anexoColumnaPlantilla}
            newcol.id_anexo = anexo.id;
            setAnexoColumna(newcol);
        }        
        formRefColumna.current.instance.repaint();
        setIsPopupColumnasOpen(true);
    }
    const guardarColumna = async(e) => {
        e.preventDefault();
        setIsLoading(true);
        
        const {data:{error,message}} = await postAnexoColumna(anexoColumna);
        if(error) AlertDialog({message: message});  
        else {
            await recargarColumnas();
            console.log("guardarColumna anexo.columnas", anexo.columnas)
            setIsPopupColumnasOpen(false);
        };

        setIsLoading(false);     
    }
    const quitarColumna = async(e) => {
        if (!await verificarAdjunto()) return;
        const {data:{error,message}} = await deleteAnexoColumna(e.id);
        if(error) AlertDialog({message: message});  
        else await recargarColumnas();   
    }
    //#endregion

    //#region eventos lista 
    const listaMostrar = async(e) => {
        if (e.id_tipo_dato != ID_TIPO_LISTA) {AlertDialog({message: "Para poder agregar elemento es necesario que la columna sea de tipo Lista."});return;}

        let col = anexo.columnas.find(col => col.id == e.id);
        setAnexoColumna({...col});
        setAnexoColumnaLista({...anexoColumnaListaPlantilla});

        setIsPopUpListaOpen(true);
    }
    const recargarColumnaLista = async() => {
        let result = await fetchAnexo(anexo.id); 

        let i  = result.columnas.findIndex(c => c.id == anexoColumna.id);

        setAnexoColumna({...result.columnas[i]});
        anexoColumna.lista      = [...result.columnas[i].lista];
        anexo.columnas[i].lista = [...result.columnas[i].lista];

        setTimeout(() => {
            formRefLista.current.instance.resetValues();
        }, 100);
    }
    const listaAgregar = async() =>{
        if (!await verificarAdjunto()) return;
        let validacion = formRefLista.current.instance.validate();
        if (!validacion.isValid) {
            return;
        };
        
        setIsLoading(true);
        anexoColumnaLista.orden = calcularSiguente(anexoColumna.lista, 'orden');
        anexoColumnaLista.id_anexo_columna = anexoColumna.id;
        const {data:{error,message}} = await postAnexoColumnaLista(anexoColumnaLista);
        if(error) AlertDialog({message: message});  
        else recargarColumnaLista();

        setIsLoading(false);     
        setAnexoColumnaLista({...anexoColumnaListaPlantilla});
    }
    const listaQuitar = async(data) => {
        if (!await verificarAdjunto()) return;
        //let elementos = anexoColumna.lista.filter(e => e.id != data.id);
        //anexoColumna.lista = elementos;      
        //let col = anexo.columnas.find(c=> c.id == anexoColumna.id)
        //col.lista = elementos; 

       // dataSourceLista.reload();

       const {data:{error,message}} = await deleteAnexoColumnaLista(data.id);
       if(error) AlertDialog({message: message});  
       else recargarColumnaLista();
    }
    //#endregion

    //#region validaciones
    const validationRules = { 
        id_rubro:[
            {type:"required",message:"Rubro es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:500},
        ],
        clave:[
            {type:"required",message:"Clave es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:50},
        ],
        orden:[
            {type:"required",message:"Orden es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:10},
        ],
        nombre:[
            {type:"required",message:"Nombre es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:1000}
        ],
        descripcion:[
            {type:"required",message:"Descripción es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:1000},
        ],
    }
    const validationRulesColumnas = {         
        nombre:[
            {type:"required",message:"Nombre es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:500}
        ],
        orden:[
            {type:"required",message:"Orden es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:10}
        ],
        descripcion:[
            {type:"required",message:"Descripción es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:1000},
        ],
        id_tipo_dato:[
            {type:"required",message:"Tipo de dato es obligatorio"},
        ],
        dato_longitud:[
            {type:"required",message:"Dato longitud es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:10},
        ],
        columna_longitud:[
            {type:"required",message:"Columna longitud es obligatorio"},            
            {type:"stringLength",message:"Máximo de caracteres excedido",max:10},
        ],
    }
    const validationRulesLista ={         
        nombre:[
            {type:"required",message:"Nombre es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:500}
        ],
        descripcion:[
            {type:"required",message:"Descripción es obligatorio"},
            {type:"stringLength",message:"Máximo de caracteres excedido",max:1000},
        ],
    }
    //#endregion

    //#region importar plantilla
    const openFileDialog =  function() {
        var inputElement = document.createElement("input");
        inputElement.type = "file";
        inputElement.accept = '.xlsx';
        inputElement.addEventListener("change", handleFileChange)
        inputElement.dispatchEvent(new MouseEvent("click")); 
    }
    const handleFileChange = function()  {
        //console.log('handleFileChange',Array.from(this.files));
        anexo.plantilla = Array.from(this.files);
        submitFiles();
    }
    const submitFiles = (async()=>{
        
        try{
            setIsLoading(true);
            //console.log('submitFiles', anexo.plantilla);
            const {data:{error,message,data:files}} = await uploadFiles(anexo.plantilla,
                (pregress)=>{ console.log('Subiendo archivo...'+pregress+"%");});
            
            if(error) AlertDialog({message});
            else {
                let data = [];
                let columnas = anexo.columnas.map(({nombre})=> nombre);
                columnas.push('id_adjunto');

                const parameters = {
                    plantilla:{systemFile:true,uid:files[0].sysfilename},
                    data,
                    columnas
                }
                const {data:{error,message}} = await getEventoValidaColumnasPlantilla(parameters);

                if(error){    
                    AlertDialog({message}); 
                }else{
                    anexo.id_adjunto = files[0].idrow_file;
                    const {data:{error,message}} = await ligaAnexoFile(anexo)
                    if(error){
                        
                        AlertDialog({message});
                  
                    }else{
                        let resultFile = {...file};
                        resultFile = await fetchFile(anexo.id_adjunto);
                        setFile(resultFile);
                   
                        notify({message: "Registro guardado con éxito."});
                      
                    }
                }
            } 
            setIsLoading(false);    
        }catch(err){
            console.log("Error", err)
        }
    });
    //#endregion
    const gridPageSize =()=>{    
        if  (window.screen.availHeight>=1080) return 10;
        else if  (window.screen.availHeight>=800) return 5;
        else return 4;
        //return PAGE_SIZE_GRID_LISTA;
    }
    //#region render
    
    return (
        <React.Fragment >
            <h2 className={'content-block'}>{lang.getString("anexo.detail.title")}</h2>
            <div id="anexoRubro" className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
               
                <form onSubmit={handleSubmit}>
                    <Form
                        ref={formRef}
                        formData={anexo}
                        readOnly={false}
                        showColonAfterLabel={true}
                        showValidationSummary={true}
                        validationGroup="validationRules">
                        <GroupItem colCount={4}>  
                            <Item colSpan={2}
                                dataField="id_rubro"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    //placeholder: "",
                                    searchEnabled:true,
                                    searchMode:"contains",
                                    displayExpr: (item) => item && item.cmbrubro,                        
                                    searchExpr: (item) =>  item && item.cmbrubro,                 
                                    valueExpr: "id",
                                    dataSource:dataSourceRubro
                                }}
                                validationRules={ validationRules.id_rubro } >
                                <Label text={lang.getString("anexo.caption.id_rubro")}></Label>
                            </Item>                       
                            <Item colSpan={1}
                                dataField="clave"
                                stylingMode="underlined"
                                validationRules={validationRules.clave}>
                                    <Label text={lang.getString("anexo.caption.clave")}></Label>
                            </Item> 
                            <Item colSpan={1}
                                dataField="orden"
                                stylingMode="underlined"
                                validationRules={validationRules.orden}>
                                <Label text={lang.getString("anexo.caption.orden")}></Label>
                            </Item>
                        </GroupItem>
                        <GroupItem colCount={2}>                                 
                            <Item colSpan={1}
                                dataField="nombre"
                                stylingMode="underlined"
                                validationRules={validationRules.nombre}>
                                    <Label text={lang.getString("anexo.caption.nombre")}></Label>
                            </Item>
                            <Item colSpan={1}
                                dataField="descripcion"
                                stylingMode="underlined"
                                validationRules={validationRules.descripcion}>
                                    <Label text={lang.getString("anexo.caption.descripcion")}></Label>
                            </Item>
                        </GroupItem>   
                        <GroupItem colCount={12}>
                            <Item colSpan={3}
                                dataField="replicable"
                                stylingMode="underlined"
                                editorType="dxSwitch">
                                    <Label text={'Replicable'}></Label>
                            </Item> 
                            <Item colSpan={3}
                                dataField="soporte_anexo"
                                stylingMode="underlined"
                                editorType="dxSwitch">
                                <Label text={lang.getString("anexo.caption.soporte_anexo")}></Label>
                            </Item>
                            <Item colSpan={3}
                                dataField="soporte_registro"
                                stylingMode="underlined"
                                editorType="dxSwitch">
                                    <Label text={lang.getString("anexo.caption.soporte_registro")}></Label>
                            </Item> 
                            <EmptyItem colSpan={3}/>
                        </GroupItem>
                        <GroupItem colCount={12}>
                            <ButtonItem colSpan={3} width="100%" visible={anexo.existe} 
                                buttonOptions={{text: "Seleccionar plantilla", type:'default', onClick:openFileDialog }}/>
                            <ButtonItem colSpan={3} width="100%" visible={anexo.existe} horizontalAlignment="left"
                                buttonOptions={{text: file.filename, type:'normal',  stylingMode:"text",
                                onClick: ()=> descargarArchivo(file.idrow_file,setIsLoading)}}/> 
                            <EmptyItem colSpan={6}/> 
                        </GroupItem>                        
                        <GroupItem colCount={1} visible={anexo.existe}>
                            <GroupItem caption={lang.getString("anexo.detail.columnas")} colCount={1}> 
                                <ButtonItem horizontalAlignment="right" colSpan={10}
                                    buttonOptions={{text:'Agregar columna',onClick:()=>cargarColumna(0)}}
                                />
                            </GroupItem>
                            <GroupItem colCount={1}> 
                                <DataGrid 
                                    key="id" 
                                    colSpan={12}
                                    dataSource={dataSourceColumnas}
                                    columnAutoWidth={true}
                                    repaintChangesOnly={true}
                                    showBorders={true} width={"100%"}
                                    rowAlternationEnabled={true}>
                                    <Paging defaultPageSize={DEFAULT_PAGE_SIZE_GRID} />
                                        <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                                        showInfo={true} />
                                    <Editing
                                        mode="row"
                                        useIcons={true}
                                        allowDeleting={AuthCheck({
                                            permission:"ANEXO_DELETE",
                                            onlyValidator: true
                                        })}
                                        allowUpdating={AuthCheck({
                                            permission:"ANEXO_EDIT",
                                            onlyValidator: true
                                        })}
                                    />
                                    <Column type="buttons" width={110}
                                        buttons={[
                                            {hint:'Editar'   ,icon:'edit',name:'edit' ,onClick:(event) => cargarColumna(event.row.data.id)},
                                            {hint:'Eliminar',icon:'trash',name:'delete', visible:canDeleteColumna},
                                            {hint:'Activar',icon:'check',name:'activate', visible:canActivateColumna, onClick:activateColumna},    
                                            {hint:'Lista',icon:'orderedlist',name:'list' 
                                                ,onClick:(event) => listaMostrar(event.row.data)
                                                ,visible: (event) => (event.row.data.id_tipo_dato == ID_TIPO_LISTA)
                                            },
                                        ]}
                                    />
                                    <Column dataField="orden" caption={lang.getString("anexo.columnas.orden")} />
                                    <Column dataField="nombre" caption={lang.getString("anexo.columnas.nombre")} />
                                    <Column dataField="descripcion" caption={lang.getString("anexo.columnas.descripcion")} />
                                    <Column dataField="tipo_dato" caption={lang.getString("anexo.columnas.tipo_dato")} />
                                    <Column dataField="obligatorio" caption={lang.getString("anexo.columnas.obligatorio")} />
                                    <Column dataField="dato_longitud" caption={lang.getString("anexo.columnas.dato_longitud")} />
                                    {/*<Column dataField="columna_longitud" caption={lang.getString("anexo.columnas.columna_longitud")} />*/}
                                    <Column dataField="estatus" allowSorting={false}
                                        caption={lang.getString("catalogo.caption.estatus")}
                                        allowFiltering={false}>
                                        <Lookup dataSource={ESTATUS_SOURCE_CONFIG} valueExpr="id" displayExpr="name"/>
                                    </Column>
                                </DataGrid>
                            </GroupItem>
                        </GroupItem>
                        <GroupItem colSpan={12}>
                            <Item>
                                <div>
                                    <Button horizontalAlignment="left" width="200" text='Guardar' type='success' onClick={handleSubmit}/>
                                    <Button style={{marginLeft:"5px"}} horizontalAlignment="left" width="200" text='Regresar' type='default' onClick={()=>goToList()} />
                                </div>
                            </Item>
                        </GroupItem>
                    </Form>
                </form>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={isLoading}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
                <Popup
                    visible={isPopupColumnasOpen}
                    onHiding={() => {setIsPopupColumnasOpen(false)} }
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Columna"
                    
                    >
                        <form onSubmit={guardarColumna}>
                        <Form
                             ref={formRefColumna}
                             formData={anexoColumna}
                             readOnly={false}
                             showColonAfterLabel={true}
                             showValidationSummary={true}
                             validationGroup="validationRulesColumnas"
                        >
                            <GroupItem colCount={2}>    
                                <Item 
                                    colSpan={4}
                                    dataField="nombre"
                                    stylingMode="underlined"
                                    validationRules={validationRulesColumnas.nombre}>
                                        <Label text={lang.getString("anexo.columnas.nombre")}></Label>
                                </Item>
                                <Item 
                                    colSpan={4}
                                    dataField="orden"
                                    stylingMode="underlined"
                                    editorType="dxNumberBox"
                                    validationRules={validationRulesColumnas.orden}>
                                        <Label text={lang.getString("anexo.columnas.orden")}></Label>
                                </Item>
                                                                
                            </GroupItem>

                            <GroupItem colCount={1}>    
                                <Item 
                                    colSpan={4}
                                    dataField="descripcion"
                                    stylingMode="underlined"
                                    validationRules={validationRulesColumnas.descripcion}>
                                        <Label text={lang.getString("anexo.columnas.descripcion")}></Label>
                                </Item>
                            </GroupItem>

                            <GroupItem colCount={1}>
                                <Item
                                    ref={RefComboTipoDato}
                                    colSpan={4}
                                    dataField="id_tipo_dato"
                                    editorType="dxSelectBox"
                                    editorOptions={{
                                        searchEnabled:true,
                                        searchMode:"contains",
                                        displayExpr: (item) => item && item.nombre+' - '+item.descripcion +' - ejemplo: '+ item.ejemplo,                        
                                        searchExpr: (item) =>  item && item.nombre,                 
                                        valueExpr: (item) => item ? item.id : 1,
                                        dataSource: DATASOURCE_CAT_ANEXO_COLUMNAS_TIPOS_DATOS,
                                        onValueChanged:(item) =>{
                                            if(item && item.value) {
                                                console.log("evento",item.value);
                                                anexoColumna.tipo_dato = item.value;
                                            }
                                            // if(item.event && item.event.rangeParent) {
                                            //     console.log("evento",item.event);
                                            //     anexoColumna.tipo_dato = item.event.rangeParent.data.split('-')[0].trim();
                                            // }
                                        }
                                    }}
                                     
                                    validationRules={validationRulesColumnas.id_tipo_dato}>
                                    <Label text={lang.getString("anexo.columnas.id_tipo_dato")}></Label>
                                </Item>
                            </GroupItem>
                            <GroupItem colCount={3}>
                                <Item 
                                    colSpan={4}
                                    dataField="dato_longitud"
                                    stylingMode="underlined"
                                    editorType="dxNumberBox"
                                    editorOptions={{
                                        min:0,format:"##",step: 0,
                                        inputAttr:{style:"text-align:right"}
                                    }}
                                    validationRules={validationRulesColumnas.dato_longitud}>
                                        <Label text={lang.getString("anexo.columnas.dato_longitud")}></Label>
                                </Item>
                               {/* <Item 
                                    colSpan={4}
                                    dataField="columna_longitud"
                                    stylingMode="underlined"
                                    editorType="dxNumberBox"
                                    editorOptions={{
                                        min:0,format:"##",step: 0,
                                        inputAttr:{style:"text-align:right"}
                                    }}
                                    validationRules={validationRulesColumnas.columna_longitud}>
                                        <Label text={lang.getString("anexo.columnas.columna_longitud")}></Label>
                                </Item>*/}
                                <Item 
                                    colSpan={4}
                                    dataField="obligatorio"
                                    stylingMode="underlined"
                                    editorType="dxSwitch">
                                        <Label text={lang.getString("anexo.columnas.obligatorio")}></Label>
                                </Item>   
                            </GroupItem>
                            <GroupItem colCount={1}>    
                                <ButtonItem horizontalAlignment="left" colSpan={2}
                                    buttonOptions={{text:'Guardar',type:'success',useSubmitBehavior:true}}
                                /> 
                            </GroupItem>
                                     
                        </Form> 
                        </form> 
                </Popup>

                <Popup
                    visible={isPopUpListaOpen}
                    onHiding={() => {setIsPopUpListaOpen(false)} }
                    dragEnabled={false}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    title="Elementos de la Lista"
                    height="750px"

                    >
                       <ScrollView width='100%' height='100%'>
                       <Form
                             ref={formRefLista}
                             formData={anexoColumnaLista}
                             readOnly={false}
                             showColonAfterLabel={true}
                             validationGroup="usuarioData"
                        >
                            <GroupItem colCount={16}>    
                                <Item 
                                    colSpan={7}
                                    dataField="nombre"
                                    stylingMode="underlined"
                                    validationRules={validationRulesLista.nombre}>
                                        <Label text={lang.getString("anexo.columnas.lista.nombre")}></Label>
                                </Item>
                                 <Item 
                                    colSpan={7}
                                    dataField="descripcion"
                                    stylingMode="underlined"
                                    validationRules={validationRulesLista.descripcion}>
                                        <Label text={lang.getString("anexo.columnas.lista.descripcion")}></Label>
                                </Item>
                                <ButtonItem horizontalAlignment="right" verticalAlignment="bottom" colSpan={2}
                                    buttonOptions={{text:'Guardar',type:'success',onClick:(event) => listaAgregar()}}
                                /> 
                            </GroupItem>
                            <GroupItem > 
                                <DataGrid 
                                    key="id" 
                                    dataSource={dataSourceLista}
                                    columnAutoWidth={true}
                                    repaintChangesOnly={true}
                                    showBorders={true} width={"100%"}
                                    rowAlternationEnabled={true}>
                                    <Paging defaultPageSize={10} />
                                    
                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={[5,10,20,50,100]}
                                        showInfo={true} 
                                    />

                                    <Editing
                                        mode="row"
                                        useIcons={true}
                                        allowDeleting={AuthCheck({
                                            permission:"ANEXO_DELETE",
                                            onlyValidator: true
                                        })}
                                        allowUpdating={AuthCheck({
                                            permission:"ANEXO_EDIT",
                                            onlyValidator: true
                                        })}
                                    />
                                    <Column caption="Acción" type="buttons" width={55}
                                        buttons={[
                                            {hint:'Eliminar', icon:'trash', name:'delete'},
                                        ]}
                                    />
                                    <Column dataField="orden" caption={'Orden'} width={100}/>
                                    <Column dataField="nombre" caption={lang.getString("anexo.columnas.lista.nombre")} />
                                    <Column dataField="descripcion" caption={lang.getString("anexo.columnas.lista.descripcion")} />
                                </DataGrid>
                            </GroupItem>     
                        </Form>
                       </ScrollView>
                </Popup>       
            </div>
      </React.Fragment>
        );
        
    //#endregion
}