import axios from 'axios';
import utils from 'utils/session';
import AlertDialog from '../alertDialog/alertDialog'

const instance = axios.create({
    withCredentials: true,
    //baseURL : "https://localhost:44377"
    baseURL:"/api/.."
})

//instance.defaults.baseURL = "https://localhost:44377";
//instance.defaults.withCredentials = true;
instance.interceptors.request.use(async function (config) {
    //config.headers.Authorization = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyLCJ1aWQiOjF9.ghaCaOzK0-503H6shx5HpugWTKSByWg5a5iU33JPnsg';
    //config.headers.apikey = '43d46c52-8210-4248-ac70-54c5c2c271a9';
    //config.headers.secret = 'e2ed28a70a091f09be05c3cd35dfc9bf';
    const hashes = window.location.hash.split('?');
    hashes.shift();
    const urlParams = new URLSearchParams(hashes.join('?'));
    if(urlParams.has('token')){
        config.headers.Authorization = urlParams.get('token');
    }
    if(utils.browserFingerPrint === undefined) {
        await new Promise((resolver)=>{
          let callBack = ()=>{
            if(utils.browserFingerPrint === undefined) setTimeout(callBack,100);
            else { resolver();}
          }
          callBack();
      })
    }
    config.headers["Client-FK"] = utils.browserFingerPrint;

    return config;
});

instance.interceptors.response.use(function(response){
    return response;
},function(error){
    if(error.response){
        let {data} = error.response;
        if(data && data.error)
        {
           AlertDialog({message: data.message,isNotification:true, type:"error",onActionCallback:(resp)=>{
                if(data.accion === "signout"){
                    localStorage.removeItem("SER_CAPTURA_EVTO");
                    localStorage.removeItem("SER_CAPTURA_PTO");
                    window.location.reload();
                }
                
            }});
        }
    }
    return Promise.reject(error)
});

export default instance;