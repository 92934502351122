import React,{useState,useRef} from 'react';
import { Button } from 'devextreme-react/button';
import { useHistory } from 'react-router-dom';
import DataGrid, { Column, Editing, Pager, Paging, FilterRow,Texts } from 'devextreme-react/data-grid';
import Box, {
  Item
} from 'devextreme-react/box';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import AlertDialog from 'components/alertDialog/alertDialog'
import {
  getEvento, 
  getEventoInfoCMC,
  deleteEvento,
  handleDownloadPlantilla,
  getActasFirmadasEvento,
  getEventoAnexoArchivo,
  reviveEvento
} from 'api';
import { DEFAULT_PAGE_SIZE_ONE_GRID, DEFAULT_PAGE_SIZES, STRING_DATAGRID_ADD_ROW } from 'constants/defaultValues';
import { getDataSourceFilter, getDataSourceSort, descargarArchivo } from 'utils/utility'
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import EventoInfo from './eventoInfo.js';
import AuthCheck from '../../../components/authCheck/authCheck.js';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import swal from 'sweetalert2';



export default function () {

  function IconRenderActaFirmadas(data){
    let flag = permDownloadActa ? true : false;
        return <div key={`dgrid_eventomain_actas_firmadas_download_${data.data.id}`}>
            {
                !flag ? null : !data.data.downloading_actas_firmadas  ? (
                    <i className="dx-link dx-icon-file dx-link-icon" title="Descargar archivo" style={{fontSize:'22px',textDecoration:'unset'}} onClick={()=>{downloadActasFirmadas(data)}}></i>) 
                    : <div className={"dx-link dx-link-icon"}><LoadIndicator  height={20} width={20}  /></div>
            }
        </div>
  }
  
  function IconRenderAnexos(data){
    let flag = permDownloadActa ? true : false;
    return <div key={`dgrid_eventomain_anexos_download_${data.data.id}`}>
        {
            !flag ? null : !data.data.dowloading_anexos  ? (
                <i className="dx-link dx-icon-file dx-link-icon" title="Descargar archivo" style={{fontSize:'22px',textDecoration:'unset'}} onClick={()=>{downloadAnexos(data)}}></i>) 
                : <div className={"dx-link dx-link-icon"}><LoadIndicator  height={20} width={20}  /></div>
        }
    </div>
  }


  const downloadActasFirmadas =async(dato) =>{
    dataGridRef.current.instance.getDataSource().store().push(
        [{
            type: 'update',
            key: dato.key,
            data: {
                ...dato.data, downloading_actas_firmadas: 1
            }
        }]
    );

    const {data:{data, message, error}} = await getActasFirmadasEvento(dato.data.id);
    if(error){
        AlertDialog({message});
    }else if(data.length){
        let files = [];
        data.forEach((val)=>{
          files.push(val.sysfilename);
        });
        AlertDialog({message:'Descargando adjunto...', isNotification: true, type:'success'});
        await handleDownloadPlantilla({uid:files},"actas_firmadas_eventos.zip");
    }else {
        AlertDialog({message:'No se encuentra ninguna acta firmada.',type:'warning'});
    }

    dataGridRef.current.instance.getDataSource().store().push(
        [{
            type: 'update',
            key: dato.key,
            data: {
                ...dato.data, downloading_actas_firmadas: 0
            }
        }]
    );
  }

  const downloadAnexos = async(dato) =>{
    swal.fire({
      title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
      text: "Por favor espere.",
      imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
      showConfirmButton: false,
      allowOutsideClick: false
    });
    let progress = 0;
    setTimeout(() => {
      if(progress===0){
          swal.update({
              html: "Recopilando información...",
              title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
              //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
              showConfirmButton: false,
              allowOutsideClick: false
          });
          swal.showLoading();
          setTimeout(() => {
              if(progress===0){
                  swal.update({
                      html: "Creando archivo comprimido...",
                      title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
                      //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
                      showConfirmButton: false,
                      allowOutsideClick: false
                  });
                  swal.showLoading();
              }
          },2000);
      }
  }, 200);

  const {data:{data, message, error}} = await getEventoAnexoArchivo({eventoId : dato.data.id,},
    (pregress)=>{ 
        progress = pregress;
        console.log('Descargando archivo...'+pregress+"%");
    swal.update({
            html: 'Descargando archivo...'+pregress+"%",
            title:   `<span style='font-size:30px;font-weight:bold'>`+  "Descargando archivos..." +`</span>`,
            //imageUrl:'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif',
            showConfirmButton: false,
            allowOutsideClick: false
        });
    });
    if(error){
      AlertDialog({message});
      swal.close();
      }else{
          swal.fire({
              title: `<span style='font-size:30px;font-weight:bold'>`+  "Descarga finalizada" +`</span>`,
              showConfirmButton: false,
              icon:'success',
              timer: 1000
          });
      }
  }
  
  const descargarActa = (async(id_adjunto)=>{
    if (id_adjunto) descargarArchivo(id_adjunto,  ()=>{})
  });


  //#region hook de control
  const history = useHistory();
  const dataGridRef = useRef();
  const[isPopUpInfoOpen, setIsPopUpInfoOpen] = useState(false);
  const permCanEdit = AuthCheck({permission: "EVENT_EDIT", onlyValidator: true});
  const permCanDelete = AuthCheck({permission: "EVENT_DELETE", onlyValidator: true});
  const permDownloadActa = AuthCheck({permission: "EVENT_DOWNLOAD_ACTA", onlyValidator: true});
  //#endregion

  //#region hook de estado
  const [eventoInfo,setEventoInfo] = useStateWithCallbackLazy({
    "id":0,
    'registro_creacion_usuario':'',
    'registro_creacion_fecha':'',
    'registro_modificacion_usuario':'',
    'registro_modificacion_fecha':'',
    'registro_cancelacion_usuario':'',
    'registro_cancelacion_fecha':'',
})
//#endregion

  //#region fetch
  const fetchData = (async (take, skip, filter, sort) => {
    let result = { data: [], totalCount: 0 };
    const { data: { error, message, data } } = await getEvento({eventoId:null, take, skip, filter, sort, grupoPermiso: localStorage.getItem('grupo_id')? localStorage.getItem('grupo_id'):null });
    if (error) {
      AlertDialog({ message: message });
    } else if (data.totalCount) {
      result = data;

      console.log('datax', result)
    }
    return result;
  })

  const deleteData = (async (key) => {
    const { data: { error, message } } = await deleteEvento(key);
    if (error) {
      AlertDialog({ message: message });
    }
  })

  const fetchEventoInfo = async(id)=>{
    let result;
    const { data: { error, message, data } } = await getEventoInfoCMC(id);
    if (error) {
      AlertDialog({ message: message });
    } else if (data.length) {
      result = data[0];
    }
    return result;
  }

  const reviveEvent=async(key)=>{
    const { data: { error, message } } = await reviveEvento(key);
    if (error) {
      AlertDialog({ message: message });
      return;
    }else{
      dataSource.reload();
      AlertDialog({message: 'Evento resucitado.', isNotification: true, type:'success'});
      return;
    }
  }
  //#endregion

  //#region dataSource
  const dataSource = new DataSource({
    store: new CustomStore({
      key: "id",
      load: async(loadOptions) => {
        return (
          await fetchData(loadOptions.take, loadOptions.skip, getDataSourceFilter(loadOptions), getDataSourceSort(loadOptions))
          )
      },
      remove: (key) => {
        return deleteData(key);
      }
    })
  });
  //#endregion

  //#region eventos interfaz
  const accessDetail = (id = null) => {
    localStorage.removeItem('SEER_EVTO_CLV')
    localStorage.removeItem('SEER_UNID_CLV')
    localStorage.removeItem('SEER_PTO_CLV')
    history.push({ pathname: './eventos/detalle', state: { id } })
  }

  const openModalInfo = async(id) =>{    
    let data = await fetchEventoInfo(id); 
    setEventoInfo(data);    
    setIsPopUpInfoOpen(!isPopUpInfoOpen);
}
  function canEdit(e){
    return e.row.data.estatus == 1 && permCanEdit;
  }

  function canDelete(e){
    return e.row.data.estatus == 1 && permCanDelete;
  }

  function canDelete(e){
    return e.row.data.estatus == 1 && permCanDelete;
  }

  function canRevive(e){
    return e.row.data.estatus == 2 && localStorage.getItem('grupo_id') != 9;
  }

  function canVer(e){
    return localStorage.getItem('grupo_id') != 9;
  }

  function canActivate(e){
    return e.row.data.estatus != 1 && permCanDelete;
  }
  //#endregion

  //#region render
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Eventos</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
        <EventoInfo
            isPopUpInfoOpen={isPopUpInfoOpen}
            setIsPopUpInfoOpen={setIsPopUpInfoOpen}
            eventoInfo={eventoInfo}
            titulo={"Eventos"}
        />
          <div className="dx-field-value" style={{ marginBottom: "20px" }}>
            {
                AuthCheck({
                  permission:"EVENT_ADD",
                  component: <Button icon="plus"
                  type="default"
                  text="Agregar evento"
                  onClick={()=>{accessDetail()}}/>
              })
            }
            
          </div>
          <Box width="100%" direction="row">
            
            <Item ratio={12}>
              <DataGrid key="id"
                ref={dataGridRef}
                dataSource={dataSource}
                remoteOperations={{ paging: true, sorting: true, filtering: true }}
                showBorders={true}
                columnAutoWidth={true}
                width={"100%"}
                allowColumnResizing={true}
                rowAlternationEnabled={true}>
                <FilterRow visible={true} showOperationChooser={false}/>
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                        <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                        showInfo={true} />
                <Editing
                  mode="row"
                  useIcons={true}
                  allowDeleting={AuthCheck({
                    permission:"EVENT_DELETE",
                    onlyValidator: true
                })}
                allowUpdating={AuthCheck({
                    permission:"EVENT_EDIT",
                    onlyValidator: true
                })}
                >
                  <Texts
                      confirmDeleteTitle={'Cancelar evento'}
                      confirmDeleteMessage={'¿Estas seguro de cancelar el evento?'}
                      addRow={STRING_DATAGRID_ADD_ROW}
                  />
                  </Editing>
                <Column type="buttons" width={100}
                  buttons={[
                    { hint: 'Editar', icon: 'edit', name: 'edit', visible:canEdit, onClick: (event) => accessDetail(event.row.key) },
                    {hint:'Ver',icon:'info',name:'info', visible:canVer ,onClick:(event) => openModalInfo(event.row.key)},
                    { hint: 'Eliminar', icon: 'trash', name: 'delete', visible:canDelete },
                    { hint: 'Revivir', icon: 'revert', name: 'Revivir', visible:canRevive, onClick:(event)=>{reviveEvent(event.row.key)} }
                  ]}
                />
                <Column dataField="orden" caption="Orden" width={90}/>
                <Column dataField="nombre" caption="Nombre"/>
                <Column dataField="evento_tipo" caption="Tipo"/>
                <Column dataField="ano_evento" caption="Año del evento"/>
                <Column dataField="no_entes" caption="Total de entes"/> 
                <Column dataField="no_puestos" caption="Total puestos"/>  
                <Column dataField="estatus_evento" caption="Estatus"/>
                <Column dataField="porcentaje_avance_captura" caption="% Avance Captura" allowFiltering={false} allowSorting={false}/>
                <Column dataField="porcentaje_entregas_terminadas" caption="% Entregas terminadas" allowFiltering={false} allowSorting={false}/> 
                <Column dataField="total_anexos_revisados" caption="Total de anexos revisados" allowFiltering={false} allowSorting={false}/> 
                <Column dataField = "downloading_actas_firmadas" caption="Actas firmadas" cellRender={IconRenderActaFirmadas} allowFiltering={false} allowSorting={false}/>
                <Column dataField = "dowloading_anexos" caption="Anexos" cellRender={IconRenderAnexos} allowFiltering={false} allowSorting={false}/>
                <Column dataField="fecha_publicacion" caption="Fecha del evento"/>
              </DataGrid>
            </Item>
          </Box>
        </div>
      </div>
    </React.Fragment>
  );
  //#endregion
};