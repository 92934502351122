import React, { useState,useRef ,useLayoutEffect } from 'react';
import { Button } from 'devextreme-react/button';
import { useHistory } from 'react-router-dom';
import SelectBox from 'devextreme-react/select-box';
import Box, { Item as BoxItem}  from 'devextreme-react/box';
import DataGrid, { Column, Editing, Pager, Paging, FilterRow, Selection } from 'devextreme-react/data-grid';
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {DEFAULT_PAGE_SIZE_ONE_GRID,
    DEFAULT_PAGE_SIZES,
    DEFAULT_PAGE_SIZE_CMB
} from 'constants/defaultValues';
import AlertDialog from 'components/alertDialog/alertDialog'
import { 
    getFuncionarioRevisorByIdAndSearchTerm,
    getEventoPuestoAnexoByIdFuncionarioIdEvento,
    getEventoBySearchTerm
    } from 'api';
import { getDataSourceFilter, getDataSourceSort } from 'utils/utility';
import lang from 'lang';
import AuthCheck from '../../../components/authCheck/authCheck.js';
import TerminoEntregaModal from 'components/terminoEntregaModal/index.js';
import CustomPopUp from 'components/custompopup';


export default function() {
    //#region hook de control
    const history = useHistory();
    const evento_ref = useRef(null);
    const funcionario_ref = useRef(null);
    //#endregion

    //#region hook de estado
    const funcionario = {id:0};
    const evento = {id:0};
    const busqueda_plantilla = {id_funcionario:0,id_evento:0};
    const busqueda = {...busqueda_plantilla};
    const grupo = {id:localStorage.getItem('grupo_id')};
    const [openTerminoModal, setOpenTerminoModal] = useState(false);
    const [terminoAnexoId, setTerminoAnexoId] = useState(true);
    //#endregion

    //#region Cargar datos iniciales   
    useLayoutEffect (() => {  
        let mounted = true; 
        async function fetchData(){
            /*
            let _evento = {...evento};
            if (mounted) {                
                setIsEdit(true);
                _evento = (await fetchEventos({}))[0]; 
                setEvento({value:_evento.id});
                setIsLoading(false);
            }
            */
        }
        fetchData();
        return () => { mounted = false; };
    },[]); 
    //#endregion

    //#region fetch    
    const fetchFuncionarios = (async ({funcionarioId = 0 ,seachterm = ''}, take = 10, skip = 0) =>{
        let result = [];
        const {data:{error,message,data}} =  await getFuncionarioRevisorByIdAndSearchTerm(funcionarioId, seachterm, take, skip);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    })
    const fetchEventos = (async ({eventoId = 0 ,seachterm = ''}, take = 10, skip = 0) =>{
        let result = []; 
        const grupoUsuario = parseInt(localStorage.getItem('grupo_id'));
        const {data:{error,message,data}} =  await getEventoBySearchTerm(seachterm, take, skip, grupoUsuario);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data;
        }
        return result;
    })
    const fetchAnexos = (async (id_funcionario, id_evento, id_grupo, take, skip, filter, sort) =>{
        let result= {data:[], totalCount:0};
        //alert("id_funcionario>"+id_funcionario+" - id_evento>"+id_evento+" - id_grupo>"+id_grupo)
        const {data:{error,message,data}} = await getEventoPuestoAnexoByIdFuncionarioIdEvento(id_funcionario, id_evento, id_grupo, take, skip, filter, sort);
        if(error){
            AlertDialog({message});
        }else if(data.length) {
            result = data[0];
        }
        return result;
    })
    //#endregion

    //#region dataSource
    const dataSourceFuncionarios = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["id"],
            load: (options) => {
                if(options.filter)
                    return fetchFuncionarios({eventoId:options.filter[2]}, options.take, options.skip);   
                else if(options.searchValue)
                    return fetchFuncionarios({seachterm:options.searchValue}, options.take, options.skip);    
                else 
                    return fetchFuncionarios({}, options.take, options.skip);                 
            },
            byKey: async (key) => {
                return {id:funcionario.id};
            }            
        })
    });
    const dataSourceEventos = new DataSource({
        pageSize: DEFAULT_PAGE_SIZE_CMB,
        store: new CustomStore({
            key: ["id"],
            load: (options) => {
                if(options.filter)
                    return fetchEventos({eventoId:options.filter[2]}, options.take, options.skip);   
                else if(options.searchValue)
                    return fetchEventos({seachterm:options.searchValue}, options.take, options.skip);    
                else 
                    return fetchEventos({}, options.take, options.skip);                 
            },
            byKey: async (key) => {
                return {id:evento.id};
            }            
        })
    });
    const dataSource = new DataSource({
        store: new CustomStore({
            key: "id",
            load: (loadOptions) => {
                return fetchAnexos(
                    busqueda.id_funcionario,
                    busqueda.id_evento,
                    grupo.id,
                    loadOptions.take,
                    loadOptions.skip, 
                    getDataSourceFilter(loadOptions), 
                    getDataSourceSort(loadOptions));             
            },
        })
    });

    //#endregion
    
    //#region eventos interfaz
    const goToDetail = (data = null) =>{  
        let params = {
            id_evento:data.id_evento,
            id_ente_publico:data.id_ente_publico,
            id_unidad_administrativa:data.id_unidad_administrativa,
            id_puesto:data.id_puesto,
            id:data.id_anexo}
            history.push({pathname:'/eventos/capturas/detalle',state:{data:params}})
    }

    const funcionarios_onvaluechanged = (e)=>{
        funcionario.id = e; 
    }
    
    const eventos_onvaluechanged = (e)=>{
        evento.id = e;  
    }

    const searchFilter = () =>{        
        busqueda.id_funcionario = (funcionario.id ? funcionario.id: 0);
        busqueda.id_evento = (evento.id? evento.id: 0);
        dataSource.reload();
    }
    
    const cleanFilter = () =>{
        evento.id      = 0;
        funcionario.id = 0;
        funcionario_ref.current.instance.reset();
        evento_ref.current.instance.reset();
        busqueda.id_funcionario = 0;
        busqueda.id_evento      = 0;

        dataSource.reload();
    }

    function canEdit(e){
        return true;//e.row.data.estatus == 1 && permCanEdit;
    }

    const changeOpenTerminoModal = (id = null) =>{
        console.log('pruebas', id);
        setOpenTerminoModal(!openTerminoModal)
        setTerminoAnexoId(id)
    }

    //#endregion

   //#region render
    return(
        <React.Fragment>
            {
                openTerminoModal &&  
                <CustomPopUp height={420} visible={true} onClose={()=>changeOpenTerminoModal && changeOpenTerminoModal()} title={"Termino de entrega"}>
                    <TerminoEntregaModal/>
                </CustomPopUp>
                
                
            }
            <h2 className={'content-block'}>{lang.getString("consultas.revisiones.index.title")}</h2>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                    
                    <Box width="100%" direction="column">
                        <BoxItem ratio={3} horizontalAlignment="left"> 
                            <div style={{paddingLeft:5,paddingRight:5}}>
                                <div>Revisor:</div>
                                <SelectBox
                                    ref={funcionario_ref}
                                    searchEnabled = {true} 
                                    searchMode    = "contains"
                                    displayExpr   = {(item) => item && item.nombre+' '+item.apellido_paterno+' '+item.apellido_materno}                        
                                    searchExpr    = {(item) => item && item.nombre+' '+item.apellido_paterno+' '+item.apellido_materno}    
                                    //value         = {funcionario.id}               
                                    valueExpr     = "id"
                                    dataSource    = {dataSourceFuncionarios}  
                                    onValueChange = {funcionarios_onvaluechanged}
                                    placeholder ="Todos"
                                    >
                                </SelectBox> 
                            </div>
                        </BoxItem>   
                        <BoxItem ratio={3} horizontalAlignment="left">
                            <div style={{paddingLeft:5,paddingRight:5}}>
                                <div>Evento:</div>
                                <SelectBox
                                    ref={evento_ref}
                                    searchEnabled = {true} 
                                    searchMode    = "contains"
                                    displayExpr   = {(item) => item && item.nombre}                        
                                    searchExpr    = {(item) => item && item.nombre}  
                                    //value         = {evento.id}               
                                    valueExpr     = "id"
                                    dataSource    = {dataSourceEventos}  
                                    onValueChange = {eventos_onvaluechanged}
                                    placeholder ="Todos"
                                    >
                                </SelectBox> 
                            </div>
                        </BoxItem>    
                        <BoxItem ratio={1} horizontalAlignment="right" verticalAlignment="bottom" >  
                                <div>&nbsp;</div>
                                <Button text={"Buscar"}  type="success" onClick={()=>{searchFilter()}}/>             
                        </BoxItem> 
                        <BoxItem ratio={1} horizontalAlignment="right"  verticalAlignment="bottom">            
                                <div>&nbsp;</div>
                                <Button text={"Limpiar"} type="default" onClick={()=>{cleanFilter()}}/>             
                        </BoxItem>    
                    </Box>
                    <Box width="100%" direction="row">
                        <BoxItem ratio={1}>&nbsp;</BoxItem> 
                    </Box>
                    <Box width="100%" direction="row">
                        <BoxItem ratio={1}>
                            <DataGrid key="id"
                            dataSource={dataSource}
                            remoteOperations={{paging:true, sorting:true, filtering: true}}
                            columnAutoWidth={true}
                            repaintChangesOnly={true}
                            showBorders={true} width={"100%"}
                            rowAlternationEnabled={true}>
                                <FilterRow visible={true} showOperationChooser={false}/>
                                <Paging defaultPageSize={DEFAULT_PAGE_SIZE_ONE_GRID} />
                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={DEFAULT_PAGE_SIZES}
                                        showInfo={true} />
                                <Editing
                                    mode="row"
                                    useIcons={true}
                                    allowDeleting={AuthCheck({
                                        permission:"EVENT_ACCESS",
                                        onlyValidator: true
                                    })}
                                    allowUpdating={AuthCheck({
                                        permission:"EVENT_ACCESS",
                                        onlyValidator: true
                                    })}
                                />
                                <Column caption="Acción" type="buttons" width={120}
                                buttons={[
                                    {hint:'Editar',icon:'edit' ,name:'edit', visible:canEdit,onClick:(event) => goToDetail(event.row.data)},
                                    
                                ]}
                                />
                                <Column dataField="id" visible={false} />
                                <Column dataField="revisor" caption={lang.getString("captura.caption.revisor")}/>
                                <Column dataField="evento" caption={lang.getString("captura.caption.evento")}/>
                                <Column dataField="ente_publico"  caption={lang.getString("captura.caption.ente_publico")}/>
                                <Column dataField="unidad_administrativa" caption={lang.getString("captura.caption.unidad_administrativa")} />       
                                <Column dataField="puesto" caption={lang.getString("captura.caption.puesto")} />
                                <Column dataField="anexo" caption={lang.getString("captura.caption.anexo")} />
                                <Column dataField="rubro" caption={lang.getString("captura.caption.rubro")} />
                                <Column dataField="aplica" caption={lang.getString("captura.caption.aplica")}  allowFiltering={false}/>
                                <Column dataField="justificacion" caption={lang.getString("captura.caption.justificacion")} />
                                <Column dataField="bloqueo" caption={lang.getString("captura.caption.bloqueo")}  allowFiltering={false}/>
                                <Column dataField="estatus_anexo" caption={lang.getString("captura.caption.estatus_anexo")} />
                                <Column dataField="estatus_aceptacion" caption={lang.getString("captura.caption.estatus_aceptacion")} allowFiltering={false}/>
                                
                                </DataGrid>
                        </BoxItem>   
                    </Box>
                </div>
            </div>
        </React.Fragment>
    );
    //#endregion
};
