import {instance} from '../constants/defaultValues';

export function getEntePublicoPuesto({entePublicoPuestoId = 0, take = 1, skip = 0, filter = {}, sort = {}}){
    if(entePublicoPuestoId){
        let ent = instance.post(`/api/read`,{
            "VC":"cat_ente_publico_puesto",
            "VA":"byid",
            "id":entePublicoPuestoId
          })
       
        return ent;
    }
    else{
        return instance.post(`/api/read`,{
            "VC":"cat_ente_publico_puesto",
            "VA":"listdata",
            "page":skip/take,
            "pagesize":take,
            ...filter,
            "sortby": sort.selector,
            "sortdesc": sort.desc
        })
    }
}
export function postEntePublicoPuesto(entePublicoPuesto){
    return instance.post(`/api/write`,{
        "VC":"cat_ente_publico_puesto",
        "VA":"bysystem",
        "id":entePublicoPuesto.id,
        "id_padre" : entePublicoPuesto.id_padre,
        "nombre": entePublicoPuesto.nombre,
        "clave": entePublicoPuesto.clave,
        "id_unidad_administrativa" : entePublicoPuesto.id_unidad_administrativa,
        "id_jerarquia" : entePublicoPuesto.id_jerarquia,
        "id_funcionario" : entePublicoPuesto.id_funcionario,
        "nombramiento" : entePublicoPuesto.nombramiento,
        "estatus": 1,
        "flag_titular": entePublicoPuesto.flag_titular === true ? 1 : 0,
        'id_ente_publico': entePublicoPuesto.id_ente_publico
    })
}
export function deleteEntePublicoPuesto(entePublicoPuestoId){
    return instance.post(`/api/delete`,{
        "VC":"cat_ente_publico_puesto",
        "VA":"bysystem",
        "id" : entePublicoPuestoId
    });
}

export function activatePuesto(puestoId){
    return instance.post(`/api/write`,{
        "VC":"cat_ente_publico_puesto",
        "VA":"activate_puesto",
        "id" : puestoId
    });
}


export function getPuestoByUnidadAdministrativa(unidadAdministrativaId = 0){
    return instance.post(`/api/read`,{
        "VC":"cat_ente_publico_puesto",
        "VA":"byid_unidad_administrativa",
        "unidad_administrativa_id" : unidadAdministrativaId
    });
}